import React, {useEffect, useRef} from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Highcharts from 'highcharts'
import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsReact from 'highcharts-react-official'
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import BarChartIcon from '@material-ui/icons/BarChart';
import TimeLineIcon from '@material-ui/icons/Timeline';
import TableChart from '@material-ui/icons/TableChartTwoTone';
import chartIco  from 'graph-svg.svg';
import greyIco from 'icon_finallinhacinza.svg';
import { findKey } from 'core-js/core/dict';


if (typeof Highcharts === 'object') {
  HighchartsExporting(Highcharts)
}
Highcharts.setOptions({
  colors: ["#65BCFF", "#F7999C", "#4AB796", "#F4CF98", "#37BAC0", "#6C899E"]
});
var formatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL"
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'fit-content',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    '& svg': {
      margin: theme.spacing(1.5),
    },
    '& hr': {
      margin: theme.spacing(0, 0.5),
    },
  },
}));

const numberToCurrency = function(number) {
  return parseFloat(number).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL"
  });
};



export default function ResultChart(props) {
  const [open, setOpen] = React.useState(false);
  const [chartStyle, setChartStyle] = React.useState(props.chartStyle || "line");
  const [lineResults, setLineResults] = React.useState({});
  const [fetched, setFetched] = React.useState({});
  const [costCentersFetchedByYear, setCostCentersFetchedByYear] = React.useState({});
  const [chartVisible, setChartVisible] = React.useState(true)
  const [alreadySetup, setAlreadySetup] = React.useState(false)
  const [isFetchingCostCenter, setIsFetchingCostCenter] = React.useState(false);
  const chartComponentRef = useRef(null);

  const classes = useStyles();
  let monthsArray = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', "Jun", "Jul", "Ago", 'Set', 'Out', 'Nov', 'Dez']
  let options = {}

  

  const formatYear = (year)=>{
    if(props.dataKey == "result_dashboard"){
      //switch case year
      switch(year){
        case "budget":
          return "Orçamento " + props.year
        case "current_year":
          return props.year
        case "last_year":
          return props.year - 1
        //end switch case year

        default:  return year
      }
    }else{
      return year
    }
  }
  
  const  fetchAreaData = async (key, fixed, type_id, type_label, year)=>{
    const {cost_center_heads, is_budget} = props;
    let fixed_label = Boolean(fixed) ? "fixed" : "not_fixed"
    if (isFetchingCostCenter) {
      return false
    }else{
      setIsFetchingCostCenter(true)
    }
    
    if(newCostCentersFetchedByYear?.[year]?.[type_label]?.[key]){
      return false
    }
    let details = await axios.get(`/results/${key}/cost_center_detail.json`, {params: {
        year: year,
        fixed: Boolean(fixed),
        type: type_id,
        is_budget,
    }})
    
    let newCostCentersFetchedByYear = costCentersFetchedByYear

    
    newCostCentersFetchedByYear[year] ||= {}
    newCostCentersFetchedByYear[year][type_label] ||= {}
    newCostCentersFetchedByYear[year][type_label][key] ||= {}
    
    newCostCentersFetchedByYear[year][type_label][key][Object.keys(details.data)[0]] = Object.values(details.data)[0]
    setCostCentersFetchedByYear({...costCentersFetchedByYear, ...newCostCentersFetchedByYear})
    setIsFetchingCostCenter(false)
    
    
  }
  const fetchParamLines=(year)=>{
    setFetched({...fetched, [year]: true})
    // if(fetched[year]){
    //   return false
    // }
    setChartVisible(false)
    
    axios.get(`/results/${props.p_key}/detail.json`, {params: {
        year: year,
        cost_center_mode: false,
        is_budget: false
    }}).then((result)=>{
      setLineResults({...lineResults, [year]: result.data})
      setTimeout(() =>{
        setChartVisible(true)
      }, 10) 
    })
  }
  useEffect(()=>{
    try{
      setTimeout(
        ()=>{
          // props.setSVG(chartComponentRef.current.chart.getSVG())
        },
        2000
      )
      
    }catch(e){
      console.log(e)
    }
  })

  React.useEffect(() => {
    if (["param-lines", "param-head"].includes(props.dataKey)) {
      if(!alreadySetup){
        fetchParamLines(props.year)
        setAlreadySetup(true)
      }
    }
    if((props.dataKey == "cost_center_type") ||( props.dataKey == "cost_center_type_line")){
      let {area_id,
      is_fixed,
      despesa_type_id,
      year,
      label} = props;
      if(!alreadySetup){
        
        fetchAreaData(area_id, is_fixed, despesa_type_id, label, year)
        setAlreadySetup(true)
      }
      
      
    }
    
    
  });
  const getNumerFormatted = (value, format)=>{
    
    let result = value
    if(format == "percentage"){
      result = `${parseFloat(value).toFixed(2)}%`
    }
    if(format == "integer"){
      result = new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(value))
    }
    if(!format || format == "money"){
      result = numberToCurrency(value) 
    }
    
    return result
  }

  let getChartStyle = () =>{
    let response =  chartStyle == "table" ? "column" : chartStyle 
    
    
    return response
  }

  let loadedData = (year, formula=null)=>{
    
    let {area_id, is_fixed, months} = props;
    
    if (props.dataKey== "area_header") {

      let result =  Object.values(months).map((month)=>{
        return parseFloat(props.allYearsData?.[year]?.costCenterGroupedByMonth?.[is_fixed ? "fixed" : "not_fixed"]?.[`[${area_id}, ${month}]`]) * -1
      })

      return result
    }
    if(props.dataKey == "cost_center_type"){
      let fixed_label = props.is_fixed ? "fixed" : "not_fixed"
      let cc_by_code = {}
      try{
         cc_by_code = Object.values(costCentersFetchedByYear[year][props.label][props.area_id][fixed_label])
      }catch(e){

      }
      
      let allMonths = {}
      Object.entries(monthDict).map(([month, value])=> {
        Object.values(cc_by_code).map((entry)=>{
          Object.entries(entry.months).map(([entryMonth, amountValue])=>{
            if(value == entryMonth){
              allMonths[month] ||= 0
              allMonths[month] += amountValue * -1
            }
          })
        })
      })
      return dataForFormula(allMonths)
    }
    if(props.dataKey == "cost_center_type_line"){
      let fixed_label = props.is_fixed ? "fixed" : "not_fixed"
      let cc_by_code = {}
      
      try{
         cc_by_code = Object.entries(costCentersFetchedByYear[year][props.label][props.area_id][fixed_label])
         
      }catch(e){
        
      }
      
      
      let allMonths = {}
      Object.entries(monthDict).map(([month, value])=> {
        Object.values(cc_by_code).map(([code, entry])=>{
          
          Object.entries(entry.months).map(([entryMonth, amountValue])=>{
            if(value == entryMonth && code == props.cc_key){
              allMonths[month] ||= 0
              allMonths[month] += amountValue * -1
            }
          })
        })
      })
      return dataForFormula(allMonths)
    }

    if(props.dataKey == "indicators"){
      return data(dataForFormula(props.allYearsData?.[year]?.report?.[props.formula_id]?.[props.formula_id]?.[props.dataKey]?.[props.p_key]))  
    }

    if(props.dataKey == "balance-params"){
      return dataForFormula(props?.allYearsData?.[year]?.params?.[props.parameter_id] || {})
    }
    if(props.dataKey == "balance-detail-line"){
      
      let lineValues = Object.values(props?.allYearsData?.[year] || {}).reduce((a, b)=> {return {...a, ...b}}, {})?.[props.line.id]
      
      return dataForFormula(lineValues?.months || {})
    }

    if (props.dataKey == "params") {
      let param = props.allYearsData?.[year]?.report?.[props.formula_id]?.[props.formula_id]?.["lines"]?.[props.p_key]?.[0]
      
      let paramsData = props.allYearsData?.[year]?.report?.[props.formula_id]?.[props.formula_id]?.[props.dataKey]?.[props.p_key]
      let mappedParamsDataHash = {}
      
      Object.entries(paramsData || {}).map(([key, value])=> {
        // mappedParamsDataHash[key]= value?.[param] || (typeof(value) == "object" ? 0 : value)
        mappedParamsDataHash[key]= value?.[param] || (typeof(value) == "object" ? 0 : value)
      })
      
      return dataForFormula(mappedParamsDataHash)
    }
    if(props.dataKey == "attachment_params"){
      let allYearsHash = props.allYearsData?.[props.first_level_key]?.[year]
      let mappedParamsDataHash = {}
      Object.entries(allYearsHash?.[props.title]?.sum_of_lines || {}).map(([key, value])=>{
        mappedParamsDataHash[key] = value * -1
      })
      return dataForFormula(mappedParamsDataHash || {}, year)
      
    }
    
    if (props.dataKey == "param-lines") {
        let getParam = (node, key=props.line_key)=>{
          if(node?.lines?.[key]){
            return node?.lines?.[key]?.months
          }else{
            let result =  Object.values(node.childreen).map((child)=>{
              return getParam(child, key)
            })
            try{
              return result.filter((x)=> Object.keys(x || {}).length > 0)[0]
            }catch(e){
              console.log("ERRO NO PARAM-LINES", e)
            }
            
          }
        }
        
        if (Object.values(Object.values(lineResults?.[year] || {})?.[0]?.childreen || {} ).length > 0){
          let allMonths = getParam(Object.values(lineResults?.[year])[0])
          
          let invertedMonths = {}
          Object.entries(allMonths || {}).map(([month, value])=> {
            let f_value = parseFloat(value) || 0
            invertedMonths[month] = f_value * -1
          })
          
          return  dataForFormula(invertedMonths)
        }else{
          
          return dataForFormula(Object.values(lineResults?.[year] || {})?.[0]?.lines?.[props.line_key]?.months || {}, year, true)
        }
        
      
      
    }

    if (props.dataKey == "param-head") {
      let findInChildreen = (param)=>{
        
      }
      try{
        if (Object.values(Object.values(lineResults?.[year])[0].childreen).length > 0){
          
          let extractLines = (node, param)=>{
            let response = "morudanga"
            if(node.childreen[param]){
               response = node.childreen[param].sum_of_lines
            }else{
              response = Object.values(node.childreen).map((child)=>{
                return extractLines(child, param)
              }).filter((x)=>x)[0]
            }
            
            return response
          }
          
          return dataForFormula(extractLines(Object.values(Object.values(lineResults?.[year]))[0], props.title))
        }else{
          
          return dataForFormula(Object.values(lineResults?.[year])?.[0]?.lines?.[props.line_key]?.months)
        }
        
      }catch(e){
        
      }
      dataForFormula([])
      
    }

    if(props.dataKey == "result"){
      if(props.formulaArray){
        return data(dataForFormula(props.allYearsData?.[year]?.report?.[formula?.label]?.[formula?.label]?.[props.dataKey], year))        
      }else{
        return data(dataForFormula(props.allYearsData?.[year]?.report?.[props.formula_id]?.[props.formula_id]?.[props.dataKey], year))  
      }
      
    }  

    if(props.dataKey == "line_graph"){
      if(props.formulaArray){
        return data(dataForFormula(props.allYearsData?.[year]?.report?.[formula?.label]?.[formula?.label]?.["result"], year))        
      }else{
        return data(dataForFormula(props.allYearsData?.[year]?.report?.[props.formula_id]?.[props.formula_id]?.["result"], year))  
      }
      
    }


    if(props.dataKey == "result_dashboard"){
      return data(dataForDashboardFormula(props.allYearsData, year))  // na verdade year não é year, mas a label do dashboard
    }
    if(props.dataKey == "result-segmentado"){
      let returnArray = Object.entries(props.allYearsData[year]).map(([month, data])=> tryEval(data[props.formula_id]))
      returnArray.splice(-1,1)
      return data(returnArray)
      // return data(dataForFormula(props.allYearsData?.[year]?.report?.[props.formula_id]?.[props.formula_id]?.[props.dataKey], year))  
    }  
    
    if(props.dataKey == "indicators-segmentado"){
      //companyData?.[company_id]?.['report']?.[key]?.[key]?.["params"]?.[p_key]?.[monthName]?.[param]
      let array = []
      let monthsData = Object.values(props.allYearsData[year]).map((x)=> x.report?.[props.formula_key]?.[props.formula_key]?.["indicators"]?.[props.param_key] || {})
      
      Object.entries(props.months).map(([month_name, month_number])=>{
        array[month_number - 1] ||= 0
        
        monthsData.map((companyData)=>{
          array[month_number - 1] ||= 0
          
          if(companyData?.[month_name]){
            array[month_number - 1] += (parseFloat(companyData?.[month_name]) * - 1)
          }else{
            array[month_number - 1] += 0
          }
        })
      })
      
      return array
      // return data(dataForFormula(props.allYearsData?.[year]?.report?.[props.formula_id]?.[props.formula_id]?.[props.dataKey], year))  
    }  

    if(props.dataKey == "line-segmentado"){
      //companyData?.[company_id]?.['report']?.[key]?.[key]?.["params"]?.[p_key]?.[monthName]?.[param]
      
      let array = []
      let monthsData = Object.values(props.allYearsData[year]).map((x)=> x.report?.[props.formula_key]?.[props.formula_key]?.["params"]?.[props.param_key] || {})
      
      Object.entries(props.months).map(([month_name, month_number])=>{
        array[month_number - 1] ||= 0
        
        monthsData.map((companyData)=>{
          array[month_number - 1] ||= 0
          
          if(companyData?.[month_name]?.[props.param]){
            array[month_number - 1] += companyData?.[month_name]?.[props.param]
          }else{
            array[month_number - 1] += 0
          }
        })
      })

      
      
      return array
      // return data(dataForFormula(props.allYearsData?.[year]?.report?.[props.formula_id]?.[props.formula_id]?.[props.dataKey], year))  
    }  

    if(props.dataKey == "cost-center-area-segmentado"){
      
      let areaByMonth = (monthName)=>{
        let {fixed, despesa_id} = props;
        try{
          let value = Object.values(props.allYearsData[year]).map((company)=> company?.costCenterGroupedByMonth[fixed ? "fixed" : "not_fixed"][`[${despesa_id}, ${monthDict[monthName]}]`]).filter((x)=> x).reduce((a,b)=> a + b, 0)
          return value
        }catch(e){
          return 0
        }
      }
      let array = []
      Object.entries(props.months).map(([month_name, month_number])=>{
        array.push(areaByMonth(month_name) * -1)
      })
      return array
    }
  }
  const getCategories = () =>{
    const {rangeMonths} = props
    if(rangeMonths){
      let monthLabels = []
      Object.entries(rangeMonths).map(([year_month, name]) => {
        let [iYear, number] = year_month.split(",")
        monthLabels.push(`${name.substring(0,3)}/${iYear}`)
      })
      return monthLabels
    }else{
      return monthsArray
    }
  }

  if(props.allYearsData){
    const getSeries = ()=>{
      if(props.onlyLineData){
        const {startYear, endYear} = props;
        let maxNum = Object.keys(props.rangeMonths).length
        let intervalData = range(startYear, endYear).map((year)=> loadedData(year)).reduce((a,b)=> [...a, ...b], [])
        
        // delete intervalData bigger than maxNum
        intervalData.splice(maxNum, intervalData.length - maxNum)
        return [{
          name: "Intervalo",
          data: intervalData,
          type:  getChartStyle(),
          dataLabels: {
              enabled: true,
              formatter: function () {
                  return getNumerFormatted(this.y, props.numberFormat)
              },
              style: {
                  fontWeight: 'bold'
              }
          }
        }]
      }else if((props.dataKey == "cost_center_type") ||( props.dataKey == "cost_center_type_line")){
        
        return Object.keys(costCentersFetchedByYear).map((year)=>{
          return {
            type:  getChartStyle(),
            name: year,
            data: loadedData(year),
            showInLegend: true,
            states: {
                borderColor: '#ff0000',
                hover: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function () {
                  return getNumerFormatted(this.y, props.numberFormat)
              },
                style: {
                    fontWeight: 'bold'
                }
            }
          }
        })
      }else if(props.formulaArray){
        

        let dataToLoad = props.formulaArray.map((formula, index)=>{
          let {id, type} = formula
          if(type == "formula"){
            return {
              type:  formula.format,
              name: formula?.label && formula?.year ? `${formula?.label}-${formula.year}` : "" ,
              data: loadedData(formula.year, formula),
              yAxis: formula.number_format  == "money"  ? 0 : 1,
              showInLegend: true,
              states: {
                  borderColor: '#ff0000',
                  hover: {
                      enabled: false
                  }
              },
              dataLabels: {
                  enabled: true,
                  formatter: function () {
                      return getNumerFormatted(this.y, formula.number_format)
                  },
                  style: {
                      fontWeight: 'bold'
                  }
              }
            }
          }
          if(type == "parameter"){
            let data = dataForFormula(props?.allYearsDataForParameters?.[formula.year]?.params?.[id] || {})
            
            return {
              type:  formula.format,
              name: formula?.label && formula?.year ? `${formula?.label}-${formula.year}` : "" ,
              data: data,
              yAxis: 0,
              showInLegend: true,
              states: {
                  borderColor: '#ff0000',
                  hover: {
                      enabled: false
                  }
              },
              dataLabels: {
                  enabled: true,
                  formatter: function(){
                    return getNumerFormatted(this.y, "money")
                  },
                  style: {
                      fontWeight: 'bold'
                  }
              }
            }
          }
          
        })
        
        return dataToLoad
      }
      
      else{
        let dataToLoad =  Object.entries(props.allYearsData).map(([year, x])=>{
          return {
            type:  getChartStyle(),
            name: formatYear(year),
            data: loadedData(year),
            showInLegend: true,
            states: {
                borderColor: '#ff0000',
                hover: {
                    enabled: false
                }
            },
            dataLabels: {
                enabled: true,
                formatter: function () {
                    return getNumerFormatted(this.y, props.numberFormat)
                },
                style: {
                    fontWeight: 'bold'
                }
            }
          }
        })
        
        return dataToLoad
      }
    }
    
    
    options = {
      exporting: {
        enabled: !props.withoutExporting
      },
      title: {
        text: props.title
      },
      yAxis: [{
        title: {
            text: 'Eixo primário'
        }
     }, {
        title: {
            text: 'Eixo secundário'
        },
        opposite: true
    }],

      xAxis: {
            categories: getCategories()
        },
      series: getSeries()
      }
    
  }else{
    options = {
      title: {
        text: props.title
      },
      xAxis: {
            categories: getCategories()
        },
      series: [{
        type: getChartStyle(),
        data: data()
      }]
      }
  }
  function tryEval(str) {
    try {
      let formulaSolved = eval(str)
      return formulaSolved ? formulaSolved : 0;
    } catch (e) {
      return 0
    }
  }

  function dataForDashboardFormula(result, label){
    // budget, current_year, last_year
    const { months} = props;
    let data = []
    let i = 1
    
    Object.entries(months).map(([name, number]) => {
      if(i < 13){
        data.push(tryEval(result?.[label]?.[name]))  
      }
      i += 1
    })
  
    
    return data
  }

  function dataForFormula(result, year=null, inverse=false){
    const { months, rangeMonths, onlyLineData} = props;
    let data = []
    let i = 1
    
    if(year && onlyLineData){
      Object.entries(rangeMonths).map(([year_month, name]) => {
        let [iYear, number] = year_month.split(",")
        
        if(iYear == year){
          data.push(tryEval(result?.[name]) * (inverse ? -1 : 1))  
        }
        
      })
    }else{
      Object.entries(months).map(([name, number]) => {
        if(i < 13){
          data.push(tryEval(result?.[name]) * (inverse ? -1 : 1))  
        }
        i += 1
      })
    }
    
    return data
  }
  

  function data(desordenedMonthData=props.data){
    
    let getNumber=(value)=>{
      
      if(props.inversedSignal){
        return parseFloat(value) * -1
      }else{
        return parseFloat(value)
      }
    }
    
    let result = []
    let {title, is_fixed, months, costCenterRecordsGrouped} = props;
    if(props.areas){
      result = Object.values(months).map((month)=>{
        return getNumber(costCenterRecordsGrouped[`["${title}", ${is_fixed}, ${month}]`])
      })
      
    }else if(props.param){
      result =  desordenedMonthData.map((entry)=>{ 
        try{
          return getNumber(entry[props.param])
        }catch(e){
          return 0
        }
        
      })
    }else{
      try{
        result = desordenedMonthData?.map((x)=> getNumber(x))
      }catch(e){
        console.log("erro data", e)
      }
      
    }
    return result
  }

  function handleClickOpen() {
    setOpen(true);
    
    if(props.loadYear && props.dataKey != "balance-params"){
      props.loadYear(props.year)
    }
    
  }

  function handleClose() {
    setOpen(false);
  }

  function toNumber(number=0){
    
      return parseFloat(number || 0)
    

  }

  function tryEval(str) {
    try {
      let formulaSolved = parseFloat(eval(str))
      return formulaSolved ? formulaSolved : 0;
    } catch (e) {
      return 0
    }
  }
  const getColorForButton = (year)=>{
    if((props.dataKey == "cost_center_type") ||( props.dataKey == "cost_center_type_line")){
      return costCentersFetchedByYear[year] ? 'rgb(113,172,233)' : 'rgba(0, 0, 0, 0.54)'
    }else if(props.dataKey == "param-lines"){
      return (props?.allYearsData?.[year] && lineResults[year]) ? 'rgb(113,172,233)' : 'rgba(0, 0, 0, 0.54)'
    }else{
      return props?.allYearsData?.[year] ? 'rgb(113,172,233)' : 'rgba(0, 0, 0, 0.54)'
    }
  }
  const loadYear = (year)=>{
    if (["param-lines", "param-head"].includes(props.dataKey)) {
        fetchParamLines(year)
        props.loadYear(year)
    }else if((props.dataKey == "cost_center_type") ||( props.dataKey == "cost_center_type_line")){
      let {area_id,
        is_fixed,
        despesa_type_id,
        label} = props;
        fetchAreaData(area_id, is_fixed, despesa_type_id, label, year)

    }else{
      props.loadYear(year)
    }
    
  }

  const loadAllYears = ()=>{
    if((props.dataKey == "cost_center_type") ||( props.dataKey == "cost_center_type_line") || (props.dataKey == "param-lines")){
      props.years.map((year)=>{
        loadYear(year)
      })
    }else{
      props.loadAllYears()
    }
  }

  const renderHighChart = ()=>{
    try{
      let chartOptions = {
        ...options,
        chart: {...options.chart, height: props.height || 400},
        exporting: { enabled: !props.printing }
      };

      return <HighchartsReact
      highcharts={Highcharts}
      ref={chartComponentRef}
      options={chartOptions}
    />
      // return <div>{JSON.stringify(options)}</div>
    }catch(e){
      return <div>ERROOO</div>
    }
  }
  
  const getTotal = (year) =>{
    return tryEval(props.allYearsData?.[year]?.["total"] || props.allYearsData?.[year]?.["range"])
  }
  if(props.withoutModal){
    
    return (
      <div style={{height: props.height || 400}}>
        
        {renderHighChart()}
      </div>
    )
  }else{
    return (
      <div>
        
      <div style={{marginRight: props?.icon?.marginRight || 20, cursor: 'pointer'}} onClick={handleClickOpen}>
      {/*<i style={{fontSize: 16}}  className={'fa fa-chart-bar'}/>*/}
        <img src={props.blueIco ? chartIco : greyIco} style={{width: props?.icon?.width || 16, heigth: props?.icon?.height || 16}}></img>
      </div>
        
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth={'lg'}
        >
          
          <DialogContent >
            <div className={'flex'} style={{justifyContent: 'space-between'}}>
              <Grid container alignItems="center" className={classes.root}>
                <BarChartIcon style={{cursor: 'pointer', color: chartStyle == "column" ? 'rgb(113,172,233)' : 'rgba(0, 0, 0, 0.54)'}} onClick={()=> setChartStyle("column")} />
                <Divider orientation="vertical" flexItem />
                <TimeLineIcon style={{cursor: 'pointer', color: chartStyle == "line" ? 'rgb(113,172,233)' : 'rgba(0, 0, 0, 0.54)'}} onClick={()=> setChartStyle("line")} />
                {!props.attachment && <React.Fragment>
                  <Divider orientation="vertical" flexItem />
                  <TableChart style={{cursor: 'pointer', color: chartStyle == "table" ? 'rgb(113,172,233)' : 'rgba(0, 0, 0, 0.54)'}} onClick={()=> setChartStyle("table")} />
                </React.Fragment>}
                
              </Grid>
              {!props.onlyLineData && <Grid container alignItems="center" className={classes.root}>
                {props.dataKey != "result_dashboard" && props?.years?.map((year)=>{
                  return <React.Fragment>
                    <div 
                      onClick={()=> loadYear(year)}
                      style={{
                      height: 24 + 10.5,
                      width: 24 + 10.5,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      cursor: 'pointer',
                      color: getColorForButton(year)
                    }}>{year}</div>
                    <Divider orientation="vertical" flexItem />  
                  </React.Fragment>
                })}
                {props.loadAllYears && props.dataKey != "result_dashboard" && <div style={{cursor: 'pointer'}} onClick={()=> loadAllYears()}>carregar todos os anos</div>}
              </Grid>}
            </div>
            {open   && 
            chartStyle == "table" ?
            <React.Fragment>
              <h2 colSpan={14} style={{textAlign: 'center'}} className={'table-chart-title'}>{props.title}</h2>
              <div className={'table-chart-container'}>
                <table className={'table table-chart'}>
                  <tr>
                    <td></td>
                    {monthsArray.map((month)=>{
                      return <td>{month}</td>
                    })}
                    { !props.dataKey.includes("balance") && <td>Total</td>}
                  </tr>
                  {props.years.map((year)=>{
                    
                    return <tr>
                      <td>{formatYear(year)}</td>
                      {loadedData(year)?.map((monthData)=>{
                        return <td>{getNumerFormatted(toNumber(monthData), props.numberFormat)}</td>
                      })}
                      {!props.dataKey.includes("balance") && (props.formulasTotalByYear ?

                        <td>
                          {getNumerFormatted(tryEval(props.formulasTotalByYear?.[year]?.[props.formula_fr_id]) * -1, props.numberFormat)}
                        </td>
                        : 
                        <td>{
                          props.dataKey == "result_dashboard" ?
                            getNumerFormatted(getTotal(year), props.numberFormat)
                          :
                            getNumerFormatted(loadedData(year)?.reduce((a,b)=>{
                            return toNumber(a) + toNumber(b)
                          }, 0), props.numberFormat)}</td>
                        )}
                      
                    </tr>
                  })}
                  
                </table>
              </div>
            </React.Fragment>
            
            :
            chartVisible ? <div><div class="btn" onClick={()=>{
              console.log(chartComponentRef.current.chart)
            }}> SVG</div> {renderHighChart()}</div> : <div style={{height: props.height || 400}}></div>}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}



const range = (min, max) => [...Array(max - min + 1).keys()].map(i => i + min);
const monthDict =  {
  "janeiro": 1,
  "fevereiro": 2,
  "março": 3,
  "abril": 4,
  "maio": 5,
  "junho": 6,
  "julho": 7,
  "agosto": 8,
  "setembro": 9,
  "outubro": 10,
  "novembro": 11,
  "dezembro": 12,
  "total": 13
}