import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import styled from "styled-components";
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import BrlCurrencyComponent from '../utils/BrlCurrencyComponent';

export default function BudgetRowReplaceModal(props) {
  const [open, setOpen] = React.useState(false);
  const [modifiedValue, setModifiedValue] = React.useState(props.currentAmount);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.onConfirm(modifiedValue)
    setOpen(false);
  };

  return (
    <div>
      <div variant="outlined" onClick={handleClickOpen}>
        {props.tryEval(props.currentAmount)}
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={"xl"}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">
          {`Conta: ${props.account}`}<br></br>
          {`Mês: ${props.month}`}
        </DialogTitle> */}
        <DialogTitle id="alert-dialog-title">
          <GreenText>ALTERAR VALOR</GreenText> Você pode inputar um novo valor ou criar um cálculo utilizando indicadores e premissas que você, de antemão, criou no sistema. (?) 
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {/* <TextField label={"Valor original"} disabled defaultValue={props.originalAmount} /><br/>
            <TextField label={"Valor atual"} disabled defaultValue={props.currentAmount} /><br/> */}
            <FlexTop>
              <FlexColumn>
                <FlexTop>
                  <InputArea>
                    <InputLabel htmlFor="component-outlined">Novo valor({props.month}/{props.year})</InputLabel>
                    <BrlCurrencyComponent
                      onChange={(e, value, maskedValue)=> setModifiedValue(value)} 
                      label={"Novo valor"} 
                      defaultValue={props.currentAmount}
                    />
                  </InputArea>
                  <InputArea>
                    <InputLabel htmlFor="component-outlined">Valor atual({props.month}/{props.year})</InputLabel>
                    <BrlCurrencyComponent 
                      label={"Valor atual"} 
                      disabled defaultValue={props.currentAmount}
                    />
                  </InputArea>
                </FlexTop>
                <InputArea>
                  <InputLabel htmlFor="component-outlined">Compor novo valor (Crie uma fórmula)</InputLabel>
                  <TextField
                    
                    
                  />
                </InputArea>
                <FormGroup>
                  <FormControlLabel control={<Checkbox defaultChecked />} label="Aplicar alteração proporcional até final do ano? (Abr a Dez/22)" />
                </FormGroup>

              </FlexColumn>
              <FlexColumn>
                <GreenText>Distribuir Diferença nas contas filhas baseado em:</GreenText>
                <FormGroup>
                  <FormControlLabel control={<Checkbox defaultChecked />} label="Proporcional ao valor de cada conta" />
                  <FormControlLabel control={<Checkbox />} label="Valores iguais pra cada conta" />
                  <FormControlLabel control={<Checkbox />} label="Apenas em uma conta" />
                </FormGroup>
              </FlexColumn>
              <FlexColumn>
                <GreenText>Criar justificativa</GreenText>
                <FormGroup>
                <TextField
                  id="filled-multiline-flexible"
                  label="Multiline"
                  multiline
                  rows={4}
                  variant="filled"
                />
                </FormGroup>
              </FlexColumn>
            </FlexTop>
            {/* <TextField onChange={(e)=> setModifiedValue(e.target.value)} label={"Novo valor"} defaultValue={props.currentAmount} /> */}
          </DialogContentText>
          <CurrentTable>
              <MonthTr>
                <TopTd>Nome</TopTd>
              </MonthTr>
              <MonthTr>
                <td></td>
                {Object.keys(partialMonthDict).map((month)=>{
                  return <BorderedTd>{month}/{props.year}</BorderedTd>
                })}
              </MonthTr>
              <FormulaTr>
                <td>{props.formula.label}</td>
                {Object.keys(monthDict).map((month)=>{
                  return <BorderedTd>{props.tryEval(props.valueForMonth(month))}</BorderedTd>
                })}
              </FormulaTr>
              {props.mapping?.map((param)=>{
                return <ParamTr>
                  <td>{param}</td>
                  {Object.values(partialMonthDict).map((month)=>{
                    return <BorderedTd>{props.tryEval(props.currentRows?.[param]?.[month])}</BorderedTd>
                  })}
                  
                </ParamTr>
              })}
            </CurrentTable>
        </DialogContent>
        <DialogActions>
          {/* <Button onClick={handleClose}>Cancelar</Button> */}
          <Button onClick={handleClose} autoFocus>
            Modificar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
const monthDict = {
  "janeiro": "1" ,
  "fevereiro": "2" ,
  "março": "3" ,
  "abril": "4" ,
  "maio": "5" ,
  "junho": "6" ,
  "julho": "7" ,
  "agosto": "8" ,
  "setembro": "9" ,
  "outubro": "10", 
  "novembro": "11", 
  "dezembro": "12", 
}
const partialMonthDict = {
  "jan": "1" ,
  "fev": "2" ,
  "mar": "3" ,
  "abr": "4" ,
  "mai": "5" ,
  "jun": "6" ,
  "jul": "7" ,
  "ago": "8" ,
  "set": "9" ,
  "out": "10", 
  "nov": "11", 
  "dez": "12", 
}

export const GreenText = styled.div`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.8px;
  text-align: left;
  color: #31b7bc;
`
export const FlexTop = styled.div`
  display: flex;
`
export const FlexColumn = styled.div`
  padding: 16px;
  flex: 1;
`
export const InputArea = styled.div`
  padding: 8px;
`

export const CurrentTable = styled.table`
  background-color: #e5e5e5;
  max-width: 100%;
  display: block;
  overflow-y: hidden;
  overflow-x: scroll;
`

export const ParamTr = styled.tr`
  td{
    color: #617e94;
    padding: 8px;
  }
` 
export const MonthTr = styled.tr`
  td{
    color: #2a3170;
    padding: 8px 8px 24px;
    font-size: 14px;
    text-transform: capitalize;
  }
` 


export const FormulaTr = styled.tr`
  background-color: #e5e5e5;
  td{
    color: #31b7bc;
    padding: 8px;
  }
`

const BorderedTd = styled.td`
  border-right: 1px solid #acacac;
`

const TopTd = styled.td`
  color: #2a3170;
  font-size: 14px;
  font-weight: bold;
  padding: 13px 22px !important;
`