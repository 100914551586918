import styled from "styled-components";

export const PopUpContainer = styled.div`
  right: 1rem;
  bottom: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
`;

export const PopUpButton = styled.button`
  width: 5.75rem;
  height: 5.75rem;
  border-radius: 50%;
  background-color: none;
  border: none;
  outline: none;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;

  transform: ${(props) => (props.show ? "translateX(0)" : "translateX(30rem)")};

  transition: transform 0.4s ease-in-out;
`;

export const PopUpContent = styled.div`
  width: 100%;
  height: 4.5rem;
  padding: 1.875rem 1.3125rem 1.5625rem 4.1875rem;
  background-color: #f9f9f9;
  margin-left: -3rem;
  white-space: pre-wrap;

  color: black;

  transform: ${(props) => (props.show ? "scale(1)" : "scale(0)")};

  transition: transform 0.65s ease-in-out;
`;
