import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import { PresentationsContext } from "../contexts";
const SCOPE_TRANSLATION = {
  "dre": "DRE",
  "dre_cc": "DRE - Gerencial",
  "cash_flow": "Fluxo de caixa",
  "attachment_table": "Anexos",
  "ativo": "Ativo (Balancete)",
  "passivo": "Passivo (Balancete)",
  "resultado": "Resultado (Balancete)"
}
const InputLineGraph = (props) => {
  const { state, dispatch } = useContext(PresentationsContext);

  //states
  const [formulas, setFormulas] = useState([]);
  const [items, setItems] = useState(state.graphs || []);
  const [accordionStates, setAccordionStates] = useState(null); 

  //useEffects
  useEffect(() => {
    if (items.length > 0) {
      setParameters("graphs", items);
    }
  }, [items]);

  const getRandomUUID = () => {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  };

  const addItem = (quantity) => {
    let newItems = [...items];
    if(quantity > newItems.length){
      for (let i = newItems.length; i < quantity; i++) {
        newItems.push({ key: getRandomUUID() });
      }
    }else{
      // make pop for difference, so if the difference between is 1, pop 1 item, if is 2, pop 2 items, etc
      let quantidade = newItems.length - quantity;
      for (let i = 0; i < quantidade; i++) {
        newItems.pop();
      }
    }
    
    setItems(newItems);
    setFormulas([]);
    setParameters("graph_quantity", quantity);
  };

  const changeValueItem = (index, formulaIndex, key, value) => {
    let newItems = [...state.parameters.graphs];
    try{
      newItems[index].formulas[formulaIndex][key] = value
    }catch(e){
      console.log(e)
    }
    
    
    setItems(newItems);
  };

  const changeValueFormula = (index, key, value) => {
    let newFormulas = [...state.parameters.graphs[index].formulas || []];
    newFormulas.find((item) => item.key === key).value = value;
    newFormulas.find((item) => item.key === key).index = index;

    const newItems = [...state.parameters.graphs];
    const currentFormulas = newItems[index]["formulas"] || [];
    const updatedFormulas = currentFormulas.map((item) =>
      item.key === key ? { ...item, value } : item
    );

    if (currentFormulas.every((item) => item.key !== key)) {
      updatedFormulas.push({ key, value });
    }

    setItems(
      newItems.map((item, i) =>
        i === index ? { ...item, formulas: updatedFormulas } : item
      )
    );

    setFormulas(newFormulas);
    console.log(newFormulas);
  };

  const removeFormula = (index, key) => {
    if (window.confirm("Tem certeza?")) {
      
    const newItems = [...state.parameters.graphs];
    const currentFormulas = newItems[index]["formulas"] || [];

    setItems(
      newItems.map((item, i) =>
        i === index ? { ...item, formulas: currentFormulas.filter((x)=> x.key != key) } : item
      )
    );
    }
  };

  //dispatch events
  const setParameters = (key, value) => {
    dispatch({
      type: "SET_PARAMETERS",
      payload: { ...state.parameters, [key]: value },
    });
  };

  const setGraphTitle = (index, value) =>{
    dispatch({
      type: "SET_GRAPH_TITLE",
      payload: { index,  value },
    });
  }

  const setGraphStyle = (index, value) =>{
    dispatch({
      type: "SET_GRAPH_STYLE",
      payload: { index,  value },
    });
  }

  const addFormula = (index) => {
    const newFormulas = [...state.parameters.graphs[index].formulas || []];

    newFormulas.push({ key: getRandomUUID(), index });
    
    dispatch({
      type: "SET_PARAMETERS",
      payload: {
        ...state.parameters,
        graphs: state.parameters.graphs.map((item, i) =>
          i === index ? { ...item, formulas: newFormulas } : item
        ),
      },
    });
  };

  //components
  const GraphQuantity = (props) => (
    <Select {...props}>
      {[0, 1, 2, 3, 4].map((quantity, index) => (
        <>
          {index === 0 ? (
            <option key={index}>Selecione...</option>
          ) : (
            <option key={index} value={quantity}>
              {quantity}
            </option>
          )}
        </>
      ))}
    </Select>
  );

  const GraphFormat = (props) => (
    <Select {...props}>
      <option key="0" value="">
        Selecione...
      </option>
      <option Key="1" value="line">
        Linha
      </option>
      <option Key="2" value="column">
        Barra
      </option>
    </Select>
  );

  const GraphYear = (props) => (
    <Select {...props}>
      {[0, ...(props.years || [])].map((year, index) => (
        <>
          {year === 0 ? (
            <option key={index}>Selecione...</option>
          ) : (
            <option key={index} value={year}>
              {year}
            </option>
          )}
        </>
      ))}
    </Select>
  );

  const GraphResume = (props) => <TextArea {...props}></TextArea>;

  const GraphFormula = (props) => {
    const { key } = props;
    return (
      <>
        <Select {...props}>
          <option key={0}>Selecione...</option>
          {Object.entries(state.formulas || {}).map(([scope, formulas]) => {
              return <optgroup label={SCOPE_TRANSLATION[scope]}>
                {formulas.map((option, index) => (
                  <option key={"formula_" + option.fr_id} value={"formula_" + option.fr_id}>
                      {option?.label || option?.title}
                    </option>
                ))}
              </optgroup>
            })}
            {Object.entries(state.patternParams || {}).filter((x)=> x[0] != "resultado").map(([head, params]) => {
              return <optgroup label={SCOPE_TRANSLATION[head]}>
                {params.map((option, index) => (
                  <option key={"parameter_" + option.key} value={"parameter_" + option.key}>
                      {option?.label || option?.title}
                    </option>
                ))}
              </optgroup>
            })}
        </Select>
        
      </>
    );
  };

  const accordionState = (index) => {
    if(index === accordionStates) {
      return setAccordionStates(null)
    }
    return setAccordionStates(index)
  }

  return (
    <Wrapper>
      <BoxOut>
        <label>Quantidade de Gráficos</label>
        <GraphQuantity
          value={state.parameters.graph_quantity}
          onChange={(e) => addItem(e.target.value)}
        />
      </BoxOut>
      {state.parameters?.graphs?.map((item, index) => {
        return ( 
          <Box key={`box_${index}`}>
            <div style={{display: 'flex', justifyContent: 'space-between'}} onClick={() => accordionState(index)}>
              <BoxTitle>Gráfico: {index + 1}</BoxTitle>
              <ButtonRow type="button">
                <i className={`fa fa-chevron-${accordionStates == index ? "down" : "right"}`} />
              </ButtonRow>
            </div>
            {accordionStates == index ?
            <BoxItem>
              <label>Fórmula</label>
              <>
                <label style={{ padding: 4 }}>Título</label>
                <TextInput 
                  value={item.title}
                  onChange={(e) => {
                    setGraphTitle(index, e.target.value)
                  }}
                ></TextInput>
              </>
              <>
                <label style={{ padding: 4 }}>Tipo do Gráfico</label>
                <Select 
                  value={item.graph_style}
                  onChange={(e) => {
                    setGraphStyle(index, e.target.value)
                  }}
                >
                  <option>-- Escolha o tipo do gráfico --</option>
                  <option value={'montly'}>Mensal</option>
                  <option value={'accumulated'}>Acumulado</option>
                </Select>
              </>
              
              {item.formulas?.map((formula, index2) => (
                  <>
                    <div key={index2}>
                      <BoxItem>
                        <label>Gráfico Formato</label>
                        <GraphFormat
                          value={formula.format}
                          onChange={(e) => {
                            changeValueItem(index, index2, "format", e.target.value);
                          }}
                        />
                      </BoxItem>
                      <BoxItem>
                        <label>Ano</label>
                        <GraphYear
                          value={formula.year}
                          years={props.years}
                          onChange={(e) => {
                            changeValueItem(index, index2, "year", e.target.value);
                          }}
                        />
                      </BoxItem>
                      
                      <GraphFormula
                        value={formula.value}
                        key={formula.key}
                        onChange={(e) => {
                          if (e.target.value !== "") {
                            changeValueFormula(index, formula.key, e.target.value);
                          }
                        }}
                      />
                    </div>
                    <ButtonRemove onClick={() => removeFormula(index, formula.key)}>
                      <i className="fa fa-trash" />
                    </ButtonRemove>
                    <hr/>
                  </>
                ))}
              <ButtonAdd
                key="add_formula"
                onClick={() => {
                  addFormula(index);
                }}
              >
                Adicionar fórmula
              </ButtonAdd>
            </BoxItem>: ''}
          </Box>
        );
      })}
      {/* <BoxOut>
        <label>Resumo</label>
        <GraphResume
          value={state.parameters["observation"]}
          onChange={(e) => setParameters("observation", e.target.value)}
        />
      </BoxOut> */}
    </Wrapper>
  );
};

export default InputLineGraph;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const BoxOut = styled.div`
  margin: 15px 0;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0;
  padding: 10px;
  border: 1px solid #ddd;
  background-color: #e9e9e9;
  border-radius: 5px;
`;

const BoxTitle = styled.div`
  font-weight: bold;
  margin: 10px 0;
`;

const BoxItem = styled.div`
  margin: 10px 0;
  display: flex;
  flex-direction: column;
`;

const Select = styled.select`
  background-color: #f5f5f5;
  color: #6e6e6e;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  padding: 8px;
  height: 20px;
  width: 240px;
  

`;

const TextArea = styled.textarea`
  background-color: #f5f5f5;
  color: #6e6e6e;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  padding: 8px;
  height: 60px;
  width: 240px;
  resize: none;
`;

const ButtonAdd = styled.div`
  background-color: #298793;
  color: #ffffff;
  height: 20px;
  width: 240px;
  cursor: pointer;
  text-align: center;
  padding: 8px;
  border-radius: 6px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ButtonRemove = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6e6e6e;
  width: 20px;
  border-radius: 6px;
  &:hover {
    color: #ff0000;
  }
`;


const TextInput = styled.input`
  background-color: #f5f5f5;
  color: #6e6e6e;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  padding: 8px;
  height: 20px;
  width: 240px;
`;

const ButtonRow = styled.button`
  border: none;
  background: #e9e9e9;
  color: gray;
`