import React, { useState } from "react";
import { PopUpButton, PopUpContainer, PopUpContent } from "./styles";
import ButtonSvg from "buttonsvg.svg";

export function PopUp({ content }) {
  const [showPopUp, setShowPopUp] = useState(true);

  return (
    <PopUpContainer>
      <PopUpContent show={showPopUp}>
        <div>
          {content}
        </div>
      </PopUpContent>
    </PopUpContainer>
  );
}
