import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default class ConfirmDialog extends React.Component { 
  handleClose(confirmation){
    let {yes_redirect_url, no_redirect_url} = this.props
    if (confirmation) {
      Turbolinks.visit(yes_redirect_url)
    }else{
      Turbolinks.visit(no_redirect_url)
    }
    
  }

  handleClickOpen(){

  }

  render(){
    const {message, opened} = this.props;
    return (
      <div>
        
        <Dialog
          open={opened}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">{"Upload do Balancete Concluído."}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={()=> this.handleClose(false)} color="primary">
              Não
            </Button>
            <Button onClick={()=> this.handleClose(true)} color="primary" autoFocus>
              Sim
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
  
}