import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import TextField from '@material-ui/core/TextField';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';


import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';

const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

export default function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open, balanceSheet } = props;
  const [description, setDescription] = React.useState(false);
  const [justifying, setJustifying] = React.useState(false);
  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = value => {
    onClose(value);
  };

  const saveJustificatory = async () =>{
    let result = await axios.post(`/balance_sheets/${balanceSheet.id}/inconsistence_justificatories.json`, {
      chart_formula: { description }
    });
    onClose(result.data)
  }

  let changeDescription= function(e){
    setDescription(e.target.value)
  }

  let justificatoryAction = function(e){
    if(justifying){
      saveJustificatory()
    }else{
      setJustifying(true)
    }
  }

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Diferenças entre arquivos </DialogTitle>
      
      <div style={{display: "flex", maxHeight: "80vh"}}>
        <div style={{overflow: "scroll"}}>
          <DialogTitle id="simple-dialog-title">Mês anterior</DialogTitle>
          <List>
          {balanceSheet.before_difference && balanceSheet.before_difference.map(code => (
            <ListItem button  key={code}>
              <ListItemText primary={code} />
            </ListItem>
          ))}          
          </List>
        </div>  

        <div style={{overflow: "scroll"}}>
          <DialogTitle id="simple-dialog-title">Mês posterior</DialogTitle>
          <List>
            {balanceSheet.after_difference && balanceSheet.after_difference.map(code => (
              <ListItem button  key={code}>
                <ListItemText primary={code} />
              </ListItem>
            ))}
          </List>
        </div>  
      </div>
      {(justifying || props?.justificatory) && <TextField
          id="outlined-multiline-static"
          label="Justificativa para inconsistências"
          multiline
          rows={4}
          defaultValue={props?.justificatory?.description}
          variant="outlined"
          onChange={(e)=> changeDescription(e)}
        />}
      <Button variant="contained" color="primary" onClick={()=>justificatoryAction()}>
        {justifying ? 'Salvar Justificativa' : 'Justificar'}
      </Button>
    </Dialog>
  );
}