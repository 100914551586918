import React from "react";
import LoadingDialog from './../LoadingDialog'
import Comment from './../Comment'
import MonthFilter from './../MonthFilter'
import axios from '../utils/axiosConfig';

class BalanceSegmentado extends React.Component {

  constructor(props){
  	super(props)
  	this.requestsCounter = 0
  	this.state ={
      showZeroLines: false,
      paramDetails: {},
      paramLines: {},
  		year: this.props.defaultYear,
  		activeBranches: {},
  		loading: false,
  		data: [],
  		years: [],
  		resultIds: [],
  		cash_flow_formula_ids: {},
      activeRows: {},
      companiesData: {},
      visibleMonths: {
        janeiro: true,
        fevereiro: true,
        março: true,
        abril: true,
        maio: true,
        junho: true,
        julho: true,
        agosto: true,
        setembro: true,
        outubro: true,
        novembro: true,
        dezembro: true,
      },
      months: props.months
  	}
  }

  componentDidUpdate(){
    if(this.state.year && (this.state.year != this.props.defaultYear)){
      this.changeYear(this.props.defaultYear)
    }
  }
  
  async fetchInitialParams(year=null){
    let {companies} = this.props;
  	this.setState({loading: true})
  	let self = this
    Object.values(companies).map((company_id)=>{
        
      self.requestsCounter += 1
      axios.get('/results/balances.json', {params: {
        year: year,
        company_id: company_id,
      }}).then((result)=>{
        self.requestsCounter -= 1
        
        self.setState((oldState)=>{
          oldState.companiesData[company_id] = {}
          oldState.companiesData[company_id]['cash_flow_formula_ids'] = result.data.cash_flow_formula_ids
          oldState.companiesData[company_id]['data'] = result.data.params
          oldState.companiesData[company_id]['result'] = result.data.result
          oldState.companiesData[company_id]['resultIds'] = result.data.result.result_ids
          oldState['cash_flow_formula_ids'] = result.data.cash_flow_formula_ids
          oldState['data'] = result.data.params
          oldState['result'] = result.data.result
          oldState['resultIds'] = result.data.result.result_ids
          return {...oldState}
        })
        
      })
      
    })
	
    
    
  
	
  }

  async setComments(){
    
    let comments = await axios.get('/comments.json', {params:{
      year: [this.state.year, 0]
    }})  
    
    
    this.setState({comments: comments.data})
  }

  async setYears(){
  	this.requestsCounter += 1
  	let years = await axios.get('/balance_sheets/years.json')
  	this.requestsCounter -= 1
  	this.setState({years: years.data})
    this.setComments()
  }

  componentDidMount(){
  	this.setYears()
    this.setComments()
  	this.fetchInitialParams(this.state.year)
  }

  numberToCurrency(number){
  	if(number == 0 || number){
  		return parseFloat(number).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })	
  	}
  	return "-"
	
  }
  removeAllChildren(row, rows){
    if(row.childreen_attributes.length > 0){
      rows[row.id] = false
      row.childreen_attributes.map((child)=>(this.removeAllChildren(child, rows)))
      
    }else{
      rows[row.id] = false
    }
  }

  toggleRow(parent, row){
    
  	let rows = this.state.activeBranches
  	rows[row.id] = !rows[row.id]
    if(!rows[row.id]){
      this.removeAllChildren(row, rows)  
    }else{
      if(row.is_tail){
        this.fetchDetailsForRow(row)
      }
    }
    
  	this.setState({activeBranches: rows})

  }

  async fetchDetailsForRow(row){
    this.requestsCounter += 1
  	let details = await axios.get(`/results/${row.fr_id}/detail.json?year=${this.state.year}&value_param=f_saldo_atual&segmented=true`)
  	this.requestsCounter -= 1

    let lines = {}
  	Object.entries(details?.data?.companies).map(([company_id, hash])=>{
      console.log(hash)
      let hashLines = Object.values(hash)[0].lines
      Object.entries(hashLines).map(([key, entry])=>{
        lines[key] = entry
      })
     
    })
    this.setState({
      paramLines: {
        ...this.state.paramLines,
        [row.id]: lines
      },
      paramDetails:{
        ...this.state.paramDetails,
        [row.id]: details.data
      }
    })
    
  }


  branchIsActive(row){
  	return this.state.activeBranches[row.id]
  }

  sumResults(values){
  	let result = Object.values(Object.values(values)).reduce((a,b)=> parseFloat(a)+ parseFloat(b), 0)
  	return this.numberToCurrency(result)
  	// return 0
  }

  renderChildreen(contabilParam, childreen_attributes=[], level=0){
    const {currentMonth, companies} = this.props;
  	const {months, paramDetails} = this.state;
  	const {result, resultIds, cash_flow_formula_ids, companiesData} = this.state;
  	let childreen = null
  	
	 childreen = childreen_attributes.map((child)=> {
     let total = 0
		return (<React.Fragment>
          
					<tr>
						<td className={'comment-wrapper'} style={{paddingLeft: 20 + (10 * level)}}  onClick={()=> this.toggleRow(contabilParam, child)}>

              {this.state.comments && <Comment  
                refreshComments={()=> this.setComments()}
                comments={this.state.comments}
                model_reference={'contabil_pattern'}
                month={0}
                year={0}
                model_reference_id={child.key}>
              </Comment>}
							<div style={{marginRight: 10, display: 'inline-block'}}>
	    						<i className={`fa fa-chevron-${this.branchIsActive(child) ? 'down' : 'right'}`}/>
	    					</div>
							{child.label} {cash_flow_formula_ids[child.fr_id] ? <i className={"fa fa-star"} style={{color: "#31B6BC"}}/> : ""}
						</td>
            {Object.values(companies).map((company_id)=>(
              
						  Object.entries(months).map(([name, number])=>{
                
                    if(this.props.current_company_id != company_id  && name == currentMonth && companiesData?.[company_id]?.result && companiesData?.[company_id]?.result?.params?.[child.id]?.[name]){
                      total += companiesData?.[company_id]?.result.params[child.id][name]
                    }
			            	return (name == currentMonth) && <td className={'comment-wrapper'}>
                                                        {this.state.comments && <Comment  
                                                          refreshComments={()=> this.setComments()}
                                                          comments={this.state.comments}
                                                          model_reference={'balance_sheet'}
                                                          month={number}
                                                          monthName={name}
                                                          year={this.state.year}
                                                          model_reference_id={child.id}>
                                                        </Comment>}
                                              {companiesData?.[company_id]?.result && companiesData?.[company_id]?.result.params[child.id] && this.numberToCurrency(companiesData?.[company_id]?.result.params[child.id][name])}
                                          </td>
              })
			       ))}
              <td>{this.numberToCurrency(total)}</td>
			        </tr>
			        {this.branchIsActive(child) && <React.Fragment>
			        	{this.renderChildreen(child, child.childreen_attributes, (level + 1))}  					    
			        </React.Fragment>}

              {child.is_tail && <React.Fragment>
                  {
                    Object.entries(this.state.paramLines[child.id] || {}).map(([key, value])=>{
                      let lineValue = 0
                      return <tr>
                        <td style={{paddingLeft: 30 + (10 * level)}}>
                          {value.label}
                        </td>
                        {Object.values(companies).map((company_id)=>{
                          let currentCompanyValue = paramDetails?.[child.id]?.companies?.[company_id]?.[child.label]?.lines?.[key]?.months?.[currentMonth]
                          if(currentCompanyValue && company_id != this.props.current_company_id){
                            lineValue += currentCompanyValue
                          }
                          
                          return <td>
                            {this.numberToCurrency(currentCompanyValue * -1)}
                          </td>
                        })}
                        <td>
                          {this.numberToCurrency(lineValue * -1)}
                        </td>
                      </tr>
                    })
                  }
                </React.Fragment>
                }
					
				</React.Fragment>)
        	
    })
  	
  	
  	return childreen
  	
  }

  changeYear(year){
  	this.setState({year})
  	this.fetchInitialParams(year)
  	// this.props.changeYear(year)
  }

  checkMonth(month_name, company_id){
  	let checagem = 0;
  	const {data, result} = this.state;

  	this.state.data && this.state.data.map((contabilParam)=>{
  		checagem += this.state.companiesData?.[company_id]?.result.params[contabilParam.id][month_name]
  	})
  	
  	return this.numberToCurrency(checagem);
  }

  changeVisibleMonths(event){
    // event.target.name]: event.target.checked
    let {name, checked} = event.target
    let props = this.props;
    this.setState((oldState)=>{
      oldState.visibleMonths[name] = checked
      oldState.months = {}
      Object.entries(oldState.visibleMonths).map(([name, checked])=>{
        if(checked){
          oldState.months[name] = props.months[name]
        }
      })
      
      return {...oldState}
    });  
  }

  numerize(n=0) {
    if(parseFloat(n).toString() == "NaN"){
      return 0
    }else{
      return parseFloat(n)
    }
  }

  render() {


    const {state, currentMonth, companies} = this.props;
    const {data, result, year, years, resultIds, cash_flow_formula_ids, showZeroLines, months} = this.state;

    

    return (
    	<React.Fragment>
	        <div id="table-result-wrapper">
	    		<div className="content padding-40 ">
		            <table id="table-result" className={"margin-bottom-20 without-acumulado balance-table"}>
		                <thead>
		                    <tr>
		                        <th>
		                            Nome
		                        </th>
		                        {Object.entries(companies).map(([name, numero]) => (
                              <th>{name}</th>
                            ))}
		                        <th>Total</th>
		                        
		                    </tr>
		                </thead>
		                
		                	{data.map((contabilParam)=>{
                        let total = 0
		                		return <tbody key={contabilParam.id}>
			                				<tr id="principal" style={{background: "#eff2f4"}}>
				                				<th className={'comment-wrapper'} style={{background: "#eff2f4"}}>
                                  {this.state.comments && <Comment  
                                    refreshComments={()=> this.setComments()}
                                    comments={this.state.comments}
                                    model_reference={'contabil_pattern'}
                                    month={0}
                                    year={0}
                                    model_reference_id={contabilParam.key}>
                                  </Comment>}
				                					{contabilParam.label} {cash_flow_formula_ids[contabilParam.fr_id] ? <i className={"fa fa-star"} style={{color: "#31B6BC"}}/> : ""}
				                				</th>
                                {Object.values(companies).map((company_id) => (
				                				  Object.entries(months).map(([name, number])=>{
                                      if(this.props.current_company_id != company_id && currentMonth == name && this.state.companiesData?.[company_id]?.result){
                                        total += this.numerize(this.state.companiesData?.[company_id]?.result.params[contabilParam.id][name])
                                      }
                                      
						                        	return (currentMonth == name) && <th className={'comment-wrapper'}>
                                                                  {this.state.companiesData?.[company_id]?.result && this.numberToCurrency(this.state.companiesData?.[company_id]?.result.params[contabilParam.id][name])}
                                                              </th>
                                    })
                                  ))
                                }
						                      <th>{this.numberToCurrency(total)}</th>
						                    </tr>
                                
                                  {!(result && resultIds.includes(contabilParam.id)) && this.renderChildreen(contabilParam, contabilParam.childreen_attributes)}
                                
						                    
						                    
				                       </tbody>
		                	})}
		                	<tbody>
	                		<tr>
		                    	<td>Checagem</td>
                          {Object.values(companies).map((company_id) => (
  		                    	Object.entries(months).map(([name, number])=>(
  		                        	(currentMonth == name) && <td>
  			                            {this.state.companiesData?.[company_id]?.result && this.checkMonth(name, company_id)}
  			                        </td>
  		                        ))
                          ))}
		                        
		                    </tr>
		                    </tbody>
		            </table>
		        </div>
		     </div>		
		     <LoadingDialog open={this.requestsCounter > 0}/>	
    </React.Fragment>);
  }
}

export default BalanceSegmentado;

const monthDict = {
  "01" : "jan",
  "02" : "fev",
  "03" : "mar",
  "04" : "abr",
  "05" : "mai",
  "06" : "jun",
  "07" : "jul",
  "08" : "ago",
  "09" : "set",
  "10": "out", 
  "11": "nov", 
  "12": "dez", 
}

const monthDictByName = {
  "janeiro": 1,
  "fevereiro": 2,
  "março": 3,
  "abril": 4,
  "maio": 5,
  "junho": 6,
  "julho": 7,
  "agosto": 8,
  "setembro": 9,
  "outubro": 10,
  "novembro": 11,
  "dezembro": 12,
}
