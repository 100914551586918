const getTextByKindKey = (key) => {
  switch (key) {
    case "title":
      return "Título:";
    case "observation":
      return "Observações:";
    case "year":
      return "Ano:";
    case "period":
      return "Período:";
    case "first_month":
      return "Primeiro Mês:";
    case "formula":
      return "Fórmula:";
    case "formulas":
      return "Fórmulas:";
    case "graph_quantity":
      return "Quantidade de Gráficos:";
    case "last_months_period":
      return "Mostrar Últimos 3 meses";
    case "interval_type":
      return "Mensal ou Intervalo";
    case "secondary_data_type":
      return "Comparar com Orçado ou Realizado";
    case "primary_data_date":
      return "Data do realizado"
    case "secondary_data_date":
      return "Data do comparado"
    case "show_budget":
      return "Mostrar Orçado";
    case "modality":
      return "Modalidade";
    case "flow_chart_type":
      return "Conjunto de formulas"
    case "dashboard_formulas":
      return "Formulas"
    case "last_month_ever":
      return "Sempre último mês"

    default:
      return key;
  }
};

const getMonths = {
  janeiro: 1,
  fevereiro: 2,
  março: 3,
  abril: 4,
  maio: 5,
  junho: 6,
  julho: 7,
  agosto: 8,
  setembro: 9,
  outubro: 10,
  novembro: 11,
  dezembro: 12,
};

const generateKey = (pre) => {
  return `${pre}_${new Date().getTime()}`;
};

let parseSelectedValue = (value) =>{
  let result = {
      type: null,
      id: null
  };

  if (value.startsWith("formula_")) {
      // Este é um ID de fórmula
      result.type = "formula";
      result.id = value.replace("formula_", "");
  } else if (value.startsWith("parameter_")) {
      // Este é um ID de parâmetro
      result.type = "parameter";
      result.id = value.replace("parameter_", "");
  }

  return result;
}

export { getTextByKindKey, generateKey, getMonths, parseSelectedValue };
