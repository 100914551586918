import React, { useState } from 'react';
import DownloadIcon from 'icon_download.svg';
import styled from "styled-components";
import html2pdf from "html2pdf.js";

const WidgetDownload = ({}) => {
    const [isDownloading, setIsDownloading] = useState(false);

    const generatePDF = () => {
        const element = document.querySelector('.resumo-executivo-content');
        const headerElement = element.querySelector('.header');
        setIsDownloading(true);
        let innerContent = document.querySelector('.bg-content .content');
        
        // Salvando o estilo CSS original do innerContent
        innerContent.classList.add('override-max-height');
        
        // Adicionando logo da empresa no topo do conteúdo
        const logoHTML = '<div class="logo-container" style="text-align: left; margin-bottom: 10px;"><img src="/company_logo/current" alt="Company Logo" style="width: 100px;"></div>';
        element.insertAdjacentHTML('afterbegin', logoHTML);
        
        // Ocultando o conteúdo da classe "header"
        if (headerElement) {
            headerElement.style.display = 'none';
        }
        
        // Obtendo as dimensões do elemento para definir o tamanho do PDF
        const { width, height } = element.getBoundingClientRect();
        
        // Convertendo de pixels para milímetros (Assumindo 96 DPI)
        const widthInMM = (width / 96) * 25.4;
        const heightInMM = (height / 96) * 25.4;
        
        let opt = {
            html2canvas: { scale: 2, logging: false },
            jsPDF: {
                unit: 'mm',
                format: [widthInMM, heightInMM * 1.2],
                orientation: 'landscape'
            }
        };
        
        setTimeout(async () => {
            let res = await html2pdf().set(opt).from(element).toPdf().get('pdf');
            res.save();
            
            // Restaurando o estilo original de innerContent
            innerContent.classList.remove('override-max-height');
            
            // Removendo o HTML adicional após a geração do PDF
            const logoContainer = document.querySelector('.logo-container');
            if (logoContainer) {
                logoContainer.remove();
            }
            
            // Restaurando a exibição do conteúdo da classe "header"
            if (headerElement) {
                headerElement.style.display = '';
            }
            
            setIsDownloading(false);
        }, 1000);
    };

    return(
        <>
            <div>
                <DownloadImage src={DownloadIcon} onClick={generatePDF} />
            </div>
        </>
    );
}

export default WidgetDownload;

const DownloadImage = styled.img`  
  cursor: pointer;
  width: 45px;
  height: 45px;
  margin-right: 0;
  margin-left: 3px;
  margin-top: 2px;
  &:hover {
    transition: all 0.2s;
    filter: brightness(0.85);
  }
`;
