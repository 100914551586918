import React from "react";
import LoadingDialog from './../LoadingDialog'
import Comment from './../Comment'
import ResultChart from './../ResultChart'
import MonthFilter from './../MonthFilter'
import { BlueLink, ViewerButton } from '../../styled_components/default';
import PopHover from './../PopHover'
import MonthRangeInput from './../MonthRangeInput'
import popupIco from 'popup-ico.svg';
import { popupCenter } from './../popup/popupCenter'
import downloadIco from 'bt_download.svg';
import olhoOn from 'bt_olho_on.svg'
import olhoOff from 'bt_olho_off.svg'
import EyeOff from "../svg-icons/EyeOff";
import { EyeOn } from "../svg-icons/EyeOn";
import axios from '../utils/axiosConfig';
import gif_image from "Lupa.gif";

class Attachment extends React.Component {

  constructor(props) {
    super(props)

    var startDate = new Date(Date.UTC(props.year - 1, props.month + 1))
    var endDate = new Date(Date.UTC(props.year, props.month))

    this.loading = 0
    this.state = {
      fetchedFormulas: {},
      allBalances: {},
      allFormulas: {},
      areasDict: {},
      rangeMonths: {},
      allYearsData: {},
      showZeroLines: false,
      formulas: [],
      report: [],
      labels: [],
      years: [],
      comments: {},
      startDate,
      endDate: endDate,
      startYear: startDate.getFullYear(),
      endYear: endDate.getFullYear(),
      year: this.props.defaultYear,
      recordsFetched: {},
      cost_center_mode: false,
      costCentersFetched: {},
      costCenterAreas: [],
      despesasType: {},
      costCenterRecordsGrouped: {},
      activeAreaType: {},
      activeKeys: {},
      formulasTotal: {},
      loading: 0,
      dreFormulas: {},
      visibleMonths: {
        janeiro: true,
        fevereiro: true,
        março: true,
        abril: true,
        maio: true,
        junho: true,
        julho: true,
        agosto: true,
        setembro: true,
        outubro: true,
        novembro: true,
        dezembro: true,
      },
      months: props.months


    }
  }

  tryEval(str) {
    try {
      let formulaSolved = eval(str)
      return formulaSolved ? formulaSolved : 0;
    } catch (e) {
      return 0
    }
  }
  async setComments(year) {

    let comments = await axios.get('/comments.json', {
      params: {
        year
      }
    })


    this.setState({
      comments: {
        ...this.state.comments,
        [year]: comments.data
      }
    })
  }

  async fetchBalanceData(year) {
    let balances = await axios.get('/results/balances.json', {
      params: {
        year
      }
    })
    this.setState((oldState) => {
      oldState.allBalances[year] = oldState.allBalances[year] ? oldState.allBalances[year] : {}
      oldState.allBalances[year] = balances.data.result.params
    })

  }

  async getDreFormulas() {
    let dreFormulas = await axios.get('/formulas/list.json', {
      params: {
        scope: ["dre", "dre_cc", "cash_flow"]
      }
    })
    let hashResult = {}

    dreFormulas.data.map((id) => {
      hashResult[id] = true
    })

    this.setState({ dreFormulas: hashResult })
  }
  async getDreParams() {
    let dreFormulas = await axios.get('/contabil_pattern_params/result.json')

    this.setState({ dreFormulas: hashResult.data })
  }

  async setYears() {
    const { year } = this.state;
    let years = {}
    if (this.props.is_budget) {
      years = await axios.get('/budgets/years.json')
    } else {
      years = await axios.get('/balance_sheets/years.json')
    }

    this.setState({ years: years.data, year: (year || years.data[years.data.length - 1]) })
  }

  async fetchCostCenterData(year = null) {
    const { is_budget } = this.props;
    let { startYear, endYear } = this.state;
    this.loading += 1
    let result = await axios.get('/results/cost_centers.json', {
      params: {
        year: range(startYear, endYear),
        is_budget
      }
    })

    const { data } = result
    this.loading -= 1
    this.setState({
      costCenterRecordsGrouped: data
    })
  }

  async fetchData(year = null, loadUniqueData = true) {
    const { costCenterMode, is_budget, scope } = this.props;
    this.loading += 1
    let url = `/results/attachments/${scope}.json`
    let result = await axios.get(url, {
      params: {
        year,
        is_budget
      }
    })
    const { formulas, report, labels, areas, cost_center_grouped_by_month } = result.data
    let internalFormulaIds = {}
    Object.values(report).map((x) => internalFormulaIds = { ...internalFormulaIds, ...Object.values(x)[0].formulas })
    debugger
    Object.keys(internalFormulaIds).map((formula_id) => {
      this.fetchInternalFormulaData(year, formula_id)
    })
    if (loadUniqueData) {
      this.setState({
        formulas,
        report,
        labels,
        costCenterAreas: areas,
        recordsFetched: {},
        costCenterGroupedByMonth: cost_center_grouped_by_month
      })
    }

    this.loading -= 1

    this.setState((oldState) => {
      oldState.allYearsData[year] = {
        formulas,
        report,
        labels,
        costCenterAreas: areas,
        recordsFetched: {},
        costCenterGroupedByMonth: cost_center_grouped_by_month
      }
      return { ...oldState }
    })
  }

  isFetchingFormulas(formula_ids){
    let isLoading = false
    Object.keys(formula_ids).map((formula_id)=>{
      if(this.state[`fetchingFormula${formula_id}`]){
        isLoading = true
      }
    })
      
    return isLoading
  }

  async fetchInternalFormulaData(year = null, formula_id) {
    const { costCenterMode, is_budget, scope } = this.props;
    let self = this;
    if (this.state.fetchedFormulas?.[year]?.[formula_id] == true) {
      return false
    }

    this.setState(
      {
        [`fetchingFormula${formula_id}`]: true
      }
    )

    this.setState((oldState) => {
      oldState.fetchedFormulas[year] = oldState.fetchedFormulas[year] ? oldState.fetchedFormulas[year] : {}
      oldState.fetchedFormulas[year][formula_id] = true
      return { ...oldState }
    })

    // this.loading += 1
    let url = `/results/formulas/${formula_id}.json`
    axios.get(url, {
      params: {
        year,
        is_budget
      }
    }).then((result)=>{
      let { formulas, report, labels, areas, cost_center_grouped_by_month } = result.data
      let internalFormulaIds = {}
      Object.values(report).map((x) => internalFormulaIds = { ...internalFormulaIds, ...Object.values(x)[0].formulas })

      // buscar dados internos de outras formulas
      //Object.keys(internalFormulaIds).map((formula_id)=>{
      //  this.fetchInternalFormulaData(year, formula_id)
      //})
      self.setState({
        formulas: { ...self.state.formulas, ...formulas }
      })

      // self.loading -= 1

      self.setState((oldState) => {
        oldState.allFormulas[year] = oldState.allFormulas[year] ? oldState.allFormulas[year] : {}

        oldState.allFormulas[year][formula_id] = {
          formulas,
          report,
          labels,
          costCenterAreas: areas,
          recordsFetched: {},
          costCenterGroupedByMonth: cost_center_grouped_by_month
        }
        return { ...oldState }
      })

      setTimeout(()=>{
        console.log("ALL_FORMULAS", self.state.allFormulas)
        self.setState(
          {
            [`fetchingFormula${formula_id}`]: false
          }
        )
      }, 2000)
      
    })
    
    
  }

  async componentDidMount() {
    let { startYear, endYear } = this.state;
    let x = await this.setYears()
    this.getDreFormulas()
    this.getDreParams();


    this.fetchCostCenterData()
    this.fetchData(this.state.year)
    // this.fetchDataForRange(this.state.year)
    this.setRangeDate(this.state.startDate, this.state.endDate)
    this.fetchAreas()
    range(startYear, endYear).map((year) => {
      this.setComments(year);
    })
  }

  async fetchAreaData(key, fixed, type_id, type_label) {

    const { year, costCentersFetched, startYear, endYear } = this.state;
    const { cost_center_heads, is_budget } = this.props;
    let fixed_label = Boolean(fixed) ? "fixed" : "not_fixed"

    Object.entries(cost_center_heads).map(([id, label]) => {
      if (!costCentersFetched[label]) {
        costCentersFetched[label] = {}
      }
    })

    if (costCentersFetched[type_label][key] && costCentersFetched[type_label][key][fixed_label]) {
      this.setState((oldState) => {
        oldState.costCentersFetched[type_label][key][fixed_label] = null
        return { ...oldState }
      })
    } else {
      let details = await axios.get(`results/formulas/${key}/cost_center_detail.json`, {
        params: {
          year: range(startYear, endYear),
          fixed: Boolean(fixed),
          type: type_id,
          is_budget,
        }
      })

      this.setState((oldState) => {
        if (!oldState.costCentersFetched[type_label][key]) { oldState.costCentersFetched[type_label][key] = {} }
        Object.keys(details.data).map((year) => {
          oldState.costCentersFetched[type_label][key][year] = details.data[year]
        })

        return { ...oldState }
      })
    }

  }

  async toggleAreaType(key, fixed, type_id, type_label) {

    this.setState((oldState) => {
      oldState.activeAreaType[`${key},${fixed}`] = oldState.activeAreaType[`${key},${fixed}`] ? oldState.activeAreaType[`${key},${fixed}`] : {}
      oldState.activeAreaType[`${key},${fixed}`][`${type_id}-${type_label}`] = !oldState.activeAreaType[`${key},${fixed}`][`${type_id}-${type_label}`]
      return { ...oldState }
    })

  }

  formulaIsGrouped(key) {
    const { formulas } = this.state

    return formulas[key]?.formula_type == "grouped"

  }

  setupAllYearsData() {
    let { years } = this.state;
    years.map((year) => {
      this.fetchData(year, false)
    })
  }

  loadYear(year) {
    this.fetchData(year, false)
  }

  changeYear(year) {
    this.setState({ year })
    this.props.changeYear(year)
    this.fetchData(year)
    this.fetchCostCenterData()

    this.setState({
      costCentersFetched: {},
      despesasType: {}
    })

    range(startYear, endYear).map((year) => {
      this.setComments(year);
    })
    // this.fetchDataForRange(year)
  }

  

  renderLine(rowDataByYear, line, level = 1, key) {
    try {


      let [lineKey, lineData] = line;
      let graphData = []
      
      let lineByYear = this.state.recordsFetched[key]
      const { rangeMonths } = this.state;
      let totalLine = 0
      let isInvertedNumber = this.state.resultKeys?.[key] ? 1 : -1
      return <tr>
        <td>
          <div style={{ paddingLeft: level * 10 }}>{lineData.label}</div>
        </td>
        {Object.entries(rangeMonths).map(([year_month, name]) => {
          let [year, number] = year_month.split(",")
          if (rowDataByYear?.[year]?.months) {
            lineData = rowDataByYear[year]
          } else {
            lineData = rowDataByYear[year].lines[lineKey]
          }

          try {
            if (parseFloat(lineData?.months?.[name]).toString() != "NaN") {
              graphData.push(parseFloat(lineData?.months?.[name]) * isInvertedNumber)
              totalLine += parseFloat(lineData?.months?.[name])
            }
          } catch (e) {
          }


          return (
            <td className={'comment-wrapper'}>
              {this.state.comments && <Comment
                refreshComments={() => this.setComments(year)}
                comments={this.state.comments?.[year] || {}}
                model_reference={'parameter_lines'}
                month={number}
                monthName={name}
                year={year}
                model_reference_id={lineData.label}>
              </Comment>}
              {lineData?.months?.[name] ? (parseFloat(lineData?.months?.[name] * isInvertedNumber)).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL"
              }) : "-"}
            </td>

          );
        })}
        <td className={'comment-wrapper'} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 6 }}>
            <ResultChart
              attachment
              allYearsData
              years={this.state.years}
              year={this.state.year}
              title={lineData.label}
              data={graphData}
              onlyLineData={true}
              inversedSignal={true}
              rangeMonths={rangeMonths}
              dataKey={"attachment_accounts"}
              months={this.props.months}
              startYear={this.state.startYear}
              endYear={this.state.endYear}
              
            ></ResultChart>
        </td>
      </tr>
    } catch (e) {
      debugger
    }
  }


  costCenterGroupedBySpendType(despesa_id, fixed, despesa_type_label, month, year) {
    const { months } = this.props;
    const { costCentersFetched } = this.state;

    let fixed_label = "not_fixed"
    if (fixed) {
      fixed_label = "fixed"
    }

    let rows = null;
    let result = 0
    try {
      rows = costCentersFetched[despesa_type_label][despesa_id][year][fixed_label]


      Object.values(rows).map((despesa) => {
        if (String(parseFloat(despesa.months[month])) != "NaN") {
          result += parseFloat(despesa.months[month])
        }

      })

    } catch (e) {

    }



    return result
  }

  renderCostCenterRows(despesa_id, fixed, despesa_type_label) {
    const { months } = this.state;
    const { costCentersFetched, startYear, rangeMonths } = this.state;

    let numberToCurrency = function (number = 0) {
      if (String(parseFloat(number)) == "NaN" || parseFloat(number) == undefined) {
        number = 0
      }

      return parseFloat(parseFloat(number)).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });
    };

    let fixed_label = "not_fixed"
    if (fixed) {
      fixed_label = "fixed"
    }

    let rows = null;

    try {

      rows = costCentersFetched[despesa_type_label][despesa_id][startYear][fixed_label]

    } catch (e) {

    }



    return <React.Fragment>

      {rows && Object.entries(rows).map(([key, data]) => {
        let sum = 0

        return <tr className={'cost-center-row'}>
          <td style={{ textIndent: 32 }}>{key}-{data.label}</td>
          {Object.entries(rangeMonths).map(([year_month, name]) => {
            let [year, number] = year_month.split(",")
            let dataByYear = costCentersFetched[despesa_type_label][despesa_id][year][fixed_label][key]

              (costCentersFetched[despesa_type_label][despesa_id][startYear][fixed_label])
            costCentersFetched[despesa_type_label][despesa_id][year][fixed_label]
            sum += dataByYear.months[number] ? parseFloat(dataByYear.months[number]) : 0

            return (
              <td className={'cost-center-type-column'}>
                {numberToCurrency(dataByYear.months[number]) || "-"}
              </td>
            );
          })}
          {/*<td className={'cost-center-type-total'}>{numberToCurrency(sum)}</td>*/}
        </tr>
      })}

    </React.Fragment>

  }

  // percorrer um hash e encontrar o nó que tem a chave passada
  findNode(parentLabel, hash = {}, hash_anterior, key, row_data) {
    try {
      if (parentLabel == key && hash[key]) {
        hash = hash[key].childreen
      }
      if (!key) {
        return {}
      }
      else {

        if (hash[key]) {
          if (parentLabel == key) {

          }
          return hash[key]
        } else {
          if (typeof (hash) != 'object') {
            return {}
          } else {
            for (let k in hash) {
              let node = this.findNode(hash[k].label, hash[k].childreen, hash_anterior, key, row_data)
              if (node) {
                return node
              }
            }
          }
        }
      }
    } catch (e) {
      console.log("ERRO", e)
    }


  }

  numberToCurrency(number = 0) {
    if (String(parseFloat(number)) == "NaN" || parseFloat(number) == undefined) {
      number = 0
    }

    return parseFloat(parseFloat(number)).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL"
    });
  }

  renderRow(parentLabel, param_key, row, level = 1, key = null, formula_key, contabil_pattern_param_kind, first_level_key = null) {
    let [row_key, row_data] = row
    //get random hash
    let hash = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
    let rowDataByYear = {}
    let childrenLines = (entry, month_name, year) => {

      try {
        let initial = Object.values(entry[row_data.label].lines).map((x) => x.months[month_name]).filter((a) => parseFloat(a).toString() != "NaN").reduce((a, b) => parseFloat(a) + parseFloat(b), 0)

        if (Object.values(entry[row_data.label].childreen).length > 0) {
          Object.values(entry[row_data.label].childreen).map((child) => {
            initial += childrenLines(child, month_name)
          })
        }

        return initial
      }
      catch (e) {
        console.log("ERRO AQUI", e)
        return 0
      }

    }

    const { months } = this.props;
    const { costCentersFetched, activeKeys, rangeMonths } = this.state;
    let sum = 0
    let chartArray = []
    return <React.Fragment>
      {!row_data.months ?
        <React.Fragment><tr className={'param-row'}>
          <td ><div
            onClick={() => {
              this.setState((oldState) => {
                oldState.activeKeys[formula_key] ||= {}
                oldState.activeKeys[formula_key][row_key] = !oldState.activeKeys?.[formula_key]?.[row_key]
                return { ...oldState }
              })
            }}
            style={{ paddingLeft: level * 10 }}>
            {(Object.keys(row_data.childreen).length > 0 || Object.keys(row_data.lines).length > 0) && <i className={'fa fa-chevron-right'}></i>} {row_data.label}
          </div>
          </td>
          {Object.entries(rangeMonths).map(([year_month, name]) => {
            let [year, number] = year_month.split(",")
            let lineByYear = this.state.recordsFetched[key]

            // if(lineByYear?.[year]){
            //   let monthValue = childrenLines(lineByYear[year], name)
            //   if(monthValue.toString() != "NaN"){
            //     sum += monthValue  
            //   }
            // }
            let currentData = this.state.recordsFetched?.[param_key]?.[year]
            row_data = this.findNode(parentLabel, currentData || {}, currentData, row_key, row_data)
            rowDataByYear[year] = row_data
            let monthValue = (row_data?.sum_of_lines?.[name] || 0) * -1
            chartArray.push(monthValue)
            return (
              <td className={`${year}-${row_data.id}-${name} comment-wrapper`}>
                {this.state.comments && <Comment
                  refreshComments={() => this.setComments(year)}
                  comments={this.state.comments?.[year] || {}}
                  model_reference={'param' + (this.props.is_budget ? '-orçamento' : '')}
                  month={number}
                  monthName={name}
                  year={year}
                  model_reference_id={row_key}>
                </Comment>}
                <div style={{ marginRight: 10 }}>{this.numberToCurrency(monthValue)}</div>


              </td>
            );
          })}
          <td className={'comment-wrapper'} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 6 }}>
            <ResultChart
              attachment
              loadAllYears={() => { this.setupAllYearsData() }}
              loadYear={(year) => this.loadYear(year)}
              years={this.state.years}
              year={this.state.year}
              title={row_data.label}
              data={chartArray}
              param={contabil_pattern_param_kind}
              onlyLineData={true}
              inversedSignal={true}
              p_key={row_data.id}
              rangeMonths={rangeMonths}
              dataKey={"attachment_params"}
              first_level_key={first_level_key || key}
              formula_id={formula_key}
              months={this.props.months}
              startYear={this.state.startYear}
              endYear={this.state.endYear}
              allYearsData={this.state.recordsFetched}
            ></ResultChart>
          </td>

        </tr>
          {Object.entries(row_data.childreen).map((row_child) => {
            try {
              return activeKeys?.[formula_key]?.[row_key] && this.renderRow(row_data.label, param_key, row_child, level + 1, row_key, first_level_key || key)
            } catch (e) {

            }

          })}
          {Object.entries(row_data.lines).map((row_child) => {
            try {
              return activeKeys?.[formula_key]?.[row_key] && this.renderLine(rowDataByYear, row_child, level + 1, row_key, first_level_key || key)
            } catch (e) {

            }

          })}
        </React.Fragment>
        :
        <>
          {Object.entries(rangeMonths).map(([year_month, name]) => {
            let [year, number] = year_month.split(",")
            let lineByYear = this.state.recordsFetched[key]

            let currentData = this.state.recordsFetched?.[param_key]?.[year]
            row_data = this.findNode(parentLabel, currentData || {}, currentData, row_key, row_data)
            rowDataByYear[year] = row_data

          })}
          {this.renderLine(rowDataByYear, row, level + 1, key, first_level_key)}
        </>
      }


      {activeKeys[key] && row.childreen && Object.values(row.childreen).map((child) => {
        return this.renderRow(row_data.label, param_key, child, level + 1, key)
      })}
      {activeKeys[key] && Object.values(row?.lines || {}).map((line) => {
        return this.renderLine(line, level + 1, key)
      })}
    </React.Fragment>
  }

  renderChildreenFor(key = null, level, formula_key, contabil_pattern_param_kind) {
    let data = this.state.recordsFetched?.[key]?.[this.state.startYear]

    if (data) {
      return (<React.Fragment>
        {Object.entries(data).map((row) => {
          return this.state.activeKeys?.[formula_key]?.[key] && this.renderRow("", key, row, 0 + level, key, formula_key, contabil_pattern_param_kind)
        })}
      </React.Fragment>)
    }
  }

  async fetchDetail(key, param = "difference", formula_key) {
    const { year, recordsFetched, startYear, endYear } = this.state;
    const { cost_center_heads, is_budget } = this.props;

    if (recordsFetched[key]) {
      this.setState((oldState) => {
        oldState.activeKeys[formula_key] = {}
        oldState.recordsFetched[key] = null
        return { ...oldState }
      })
    } else {
      this.setState((oldState) => {
        oldState.activeKeys[formula_key] ||= {}
        oldState.activeKeys[formula_key][key] = true
        return { ...oldState }
      })

      let details = await axios.get(`/results/${key}/detail.json`, {
        params: {
          year: range(startYear, endYear),
          value_param: param,
          cost_center_mode: this.state.cost_center_mode && cost_center_heads[key],
          is_budget
        }
      })
      let yearsFetched = Object.keys(details.data)
      // check if details.data.pattern_tree starts with string 'Resultados'
      let pattern_tree = details.data?.pattern_tree?.split(" ")
      let isResultKey = pattern_tree?.[0] == "Resultados"
      if (isResultKey) {
        this.setState({ resultKeys: { [key]: true, ...(this.state.resultKeys || {}) } })
      }


      this.setState((oldState) => {
        if (Object.values(Object.values(details.data[startYear])[0].childreen).length > 0) {
          yearsFetched.map((year) => {
            oldState.recordsFetched[key] = oldState.recordsFetched[key] ? oldState.recordsFetched[key] : {}
            oldState.recordsFetched[key][year] = Object.values(details.data[year])[0].childreen
          })

        } else {
          yearsFetched.map((year) => {
            oldState.recordsFetched[key] = oldState.recordsFetched[key] ? oldState.recordsFetched[key] : {}
            oldState.recordsFetched[key][year] = Object.values(details.data[year])[0].lines
          })
        }

        return { ...oldState }
      })
    }

  }
  async fetchDataForRange(year) {
    const { is_budget, scope } = this.props;
    this.loading += 1
    let report = await axios.get('/formulas/borboleta.json', {
      params: {
        scope,
        range: {
          start_at: (new Date(`01/01/${year}`)),
          end_at: (new Date(`12/12/${year}`))
        },
        is_budget
      }
    })
    this.loading -= 1
    this.setState({
      formulasTotal: report.data
    })
  }

 

  toggleDespesasType(key) {
    let { cost_center_heads } = this.props;
    let [area_id, is_fixed] = key

    Object.entries(cost_center_heads).map(([despesa_type_id, label]) => {
      this.fetchAreaData(area_id, is_fixed, despesa_type_id, label)
    })

    this.setState((oldState) => {
      oldState.despesasType[key] = !oldState.despesasType[key]
      if (!oldState.despesasType[key]) {
        this.state.activeAreaType[`${key}`] = {}
      }

      return { ...oldState }
    })
  }


  renderCostCenterAreas(number_format = "money") {
    const { months, cost_center_heads } = this.props;
    const { costCentersFetched, costCenterAreas, despesasType, costCenterGroupedByMonth, allYearsData } = this.state;

    let areaByMonth = (b_fixed, despesa_id, number, year) => {

      try {
        let value = allYearsData[year]?.costCenterGroupedByMonth[b_fixed ? "fixed" : "not_fixed"][`[${despesa_id}, ${number}]`]

        return value

      } catch (e) {

      }

    }


    let lines = Object.entries(costCenterAreas).map(([key, value]) => {
      const [fixed, despesa_id] = JSON.parse(key);
      let b_fixed = Boolean(fixed)
      //this.fetchAreaData(id, fixed)
      return <React.Fragment><tr>
        <td ><div onClick={() => this.toggleDespesasType([despesa_id, b_fixed])} >
          <i className={`pull-left fa fa-chevron-${despesasType[[despesa_id, b_fixed]] ? 'down' : 'right'}`} />
          {value.label}</div></td>
        {Object.entries(rangeMonths).map(([year_month, name]) => {
          let [year, number] = year_month.split(",")

          return (
            <td id="cost-center-by-year" className={'comment-wrapper'}>
              {areaByMonth(b_fixed, despesa_id, number, year)}
            </td>
          );
        })}
        <td>

        </td>
      </tr>
        {despesasType[[despesa_id, b_fixed]] && Object.entries(cost_center_heads).map(([despesa_type_id, label]) => {
          let exist = false
          try {
            exist = !!costCentersFetched[label][despesa_id][b_fixed ? "fixed" : "not_fixed"]

          } catch (e) {

          }

          return <React.Fragment>
            <tr>
              <td style={{ textIndent: 16 }} onClick={() => this.fetchAreaData(despesa_id, b_fixed, despesa_type_id, label)} >
                <i className={`pull-left fa fa-chevron-${exist ? 'down' : 'right'}`} />
                {label}
              </td>
            </tr>
            {this.renderCostCenterRows(despesa_id, b_fixed, label)}
          </React.Fragment>

        })}
      </React.Fragment>

    })

    return <React.Fragment>{lines}</React.Fragment>

  }

  percentFormat(number) {
    return `${parseFloat(number).toFixed(2)}%`
  }

  renderCostCenterParams(report, ccNumberFormat = "money") {

    let { despesasType } = this.state;

    let { cost_center_heads } = this.props;
    let numberToCurrency = function (number = 0, number_format = ccNumberFormat) {
      if (String(parseFloat(number)) == "NaN" || parseFloat(number) == undefined) {
        number = 0
      }
      if (number_format == "percentage") {
        this.percentFormat(parseFloat(number))
      }
      if (number_format == "integer") {
        return new Intl.NumberFormat('decimal', { minimumFractionDigits: 2 }).format(parseFloat(number))
      }

      if (!number_format || number_format == "money") {
        return parseFloat(number).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL"
        });
      }
    };
    const { months } = this.state;
    const { costCenterRecordsGrouped, costCenterGroupedByMonth, areasDict, allYearsData, rangeMonths } = this.state;

    return <React.Fragment>
      {report.cost_center_params.ids.map((id) => {

        let total = 0
        return <React.Fragment>{!this.isCostCenterZero(id) && this.isCostCenterInFormula(report, id) && <tr>
          <td onClick={() => this.toggleDespesasType([id[0].area.id, id[0].is_fixed])}>

            <i className={`pull-left fa fa-chevron-${despesasType[[id[0].area.id, id[0].is_fixed]] ? 'down' : 'right'}`} />
            {areasDict[id[0].area.id]} - {id[0].is_fixed ? "Fixo" : "Variável"}
          </td>
          {Object.entries(rangeMonths).map(([year_month, name]) => {
            let [year, month] = year_month.split(",")
            let row = parseFloat(costCenterRecordsGrouped?.[year]?.[`["${areasDict[id[0].area.id]}", ${id[0].is_fixed}, ${month}]`])

            if (String(row) != "NaN") {
              total += row
            }

            if (allYearsData[year]?.costCenterGroupedByMonth?.[id[0].is_fixed ? "fixed" : "not_fixed"][`[${id[0].area.id}, ${month}]`]) {
              return <td>{numberToCurrency(allYearsData?.[year]?.costCenterGroupedByMonth[id[0].is_fixed ? "fixed" : "not_fixed"][`[${id[0].area.id}, ${month}]`])}</td>
            } else {
              return <td >{numberToCurrency(costCenterRecordsGrouped?.[year]?.[`["${id[0].area.label}", ${id[0].is_fixed}, ${month}]`])}</td>
            }

          })}
          {/* <td className={'flex-chart'}> 
              <p>{numberToCurrency(total)}</p>
              <ResultChart
                loadAllYears={()=>{this.setupAllYearsData()}}
                loadYear={(year)=> this.loadYear(year)} 
                years={this.state.years}
                year={this.state.year} 

                title={id[0].area.label} 
                is_fixed={id[0].is_fixed}
                months={months}
                area_id={id[0].area.id}
                areas={true}
                dataKey={"area_header"}
                allYearsData={this.state.allYearsData}
                costCenterRecordsGrouped={costCenterRecordsGrouped}>
              </ResultChart>
              
            </td>*/}
        </tr>}
          {despesasType[[id[0].area.id, id[0].is_fixed]] && Object.entries(cost_center_heads).map(([despesa_type_id, label]) => {
            let exist = false
            try {
              exist = !!costCentersFetched[label][id[0].area.id][id[0].is_fixed ? "fixed" : "not_fixed"]

            } catch (e) {

            }
            total = 0
            return <React.Fragment>
              {!this.isDespendZero(id, label) && <tr>
                <td style={{ textIndent: 16 }} onClick={() => this.toggleAreaType(id[0].area.id, id[0].is_fixed, despesa_type_id, label)} >
                  <i className={`pull-left fa fa-chevron-${exist ? 'down' : 'right'}`} />
                  {label}
                </td>
                {Object.entries(rangeMonths).map(([year_month, name]) => {
                  let [year, number] = year_month.split(",")
                  let value = this.costCenterGroupedBySpendType(id[0].area.id, id[0].is_fixed, label, number, year)
                  if (String(value) != "NaN") {
                    total += value
                  } else {

                  }
                  return (
                    <td>
                      {numberToCurrency(value)}
                    </td>
                  );
                })}
                {/*<td className={'cost-center-type-total'}>{numberToCurrency(total)}</td>*/}
              </tr>}
              {this.state.activeAreaType[`${id[0].area.id},${id[0].is_fixed}`] && this.state.activeAreaType[`${id[0].area.id},${id[0].is_fixed}`][`${despesa_type_id}-${label}`] && this.renderCostCenterRows(id[0].area.id, id[0].is_fixed, label)}
            </React.Fragment>

          })}
        </React.Fragment>
      })}




    </React.Fragment>


  }

  getNumerFormatted(value, format, invertedValue = false) {
    // value = invertedValue ? value * -1 : value

    let result = value
    if (format == "percentage") {
      result = this.percentFormat(value)
    }
    if (format == "integer") {
      result = new Intl.NumberFormat('decimal', { minimumFractionDigits: 3 }).format(parseFloat(value))
    }
    if (!format || format == "money") {
      result = this.numberToCurrency(value)
    }

    return result
  }

  dataForFormula(result) {
    const { months } = this.props;
    let data = []
    let i = 1
    Object.entries(months).map(([name, number]) => {
      if (i < 12) {
        try {
          data.push(this.tryEval(result[name]))
        } catch (e) {
          data.push(0)
        }
      }
      i += 1
    })
    return data
  }

  getFloat(number) {
    if (parseFloat(number).toLocaleString() == "NaN") {
      return 0
    } else {
      return parseFloat(number)
    }
  }

  async fetchAreas() {

    let result = await axios.get(`/cost_center_areas.json`, {
      params: {
        query: this.state.query
      }
    });

    let hash_data = {}

    result.data.map((area) => {
      hash_data[area.id] = area.label
    })

    this.setState({ areasDict: hash_data })

  }

  isParamZero(key, param_key, param, formulaId) {
    if (this.state.showZeroLines) {
      return false
    }

    let formulaData = (year) => {
      let { allFormulas, allYearsData } = this.state
      if (formulaId) {
        return allFormulas?.[year]?.[formulaId]?.report?.[key]?.[key]
      } else {
        return allYearsData?.[year]?.report?.[key]?.[key]
      }
    }

    const { rangeMonths } = this.state;
    let isZero = true
    Object.entries(rangeMonths).map(([year_month, name]) => {
      let [year, number] = year_month.split(",")

      let param_row = formulaData(year)?.["params"][param_key][name][param]



      if (this.getFloat(param_row) != 0) {
        isZero = false
      }
    })
    return isZero

  }
  isDespendZero(id, label) {
    if (this.state.showZeroLines) {
      return false
    }

    const { months } = this.props;
    const { rangeMonths } = this.state;
    let isZero = true
    Object.entries(rangeMonths).map(([year_month, name]) => {
      let [year, month] = year_month.split(",")
      let row = this.costCenterGroupedBySpendType(id[0].area.id, id[0].is_fixed, label, month, year)
      if (this.getFloat(row) != 0) {
        isZero = false
      }
    })
    return isZero

  }

  isCostCenterZero(id) {
    if (this.state.showZeroLines) {
      return false
    }

    const { months } = this.props;
    const { costCenterRecordsGrouped, areasDict, rangeMonths } = this.state;

    let isZero = true
    Object.entries(rangeMonths).map(([year_month, name]) => {
      let [year, month] = year_month.split(",")

      let row = parseFloat(costCenterRecordsGrouped?.[year]?.[`["${areasDict[id[0]?.['area']?.id]}", ${id[0].is_fixed}, ${month}]`])

      if (this.getFloat(row) != 0) {
        isZero = false
      }
    })
    return isZero

  }

  changeVisibleMonths(event) {
    // event.target.name]: event.target.checked
    let { name, checked } = event.target
    let props = this.props;
    this.setState((oldState) => {
      oldState.visibleMonths[name] = checked
      oldState.months = {}
      Object.entries(oldState.visibleMonths).map(([name, checked]) => {
        if (checked) {
          oldState.months[name] = props.months[name]
        }
      })

      return { ...oldState }
    });
  }

  propsSetState(newState) {
    this.props.propsSetState(newState)
  }

  setRangeDate(startDate, endDate) {
    let startYear = startDate.getFullYear()
    let endYear = endDate.getFullYear()

    let years = range(startYear, endYear)
    let months = {}

    if (startYear != endYear) {
      let startYearMonths = range(startDate.getMonth() + 1, 12)
      let endYearMonths = range(1, endDate.getMonth() + 1)

      startYearMonths.map((month) => months[[startYear, month]] = monthDict[String(month).padStart(2, '0')])
      let allMonths = range(1, 12)
      years.map((year) => {
        if (year != startYear && year != endYear) {
          allMonths.map((month) => months[[year, month]] = monthDict[String(month).padStart(2, '0')])
        }
        this.fetchBalanceData(year)
      })
      endYearMonths.map((month) => months[[endYear, month]] = monthDict[String(month).padStart(2, '0')])


    } else {
      let monthsRange = range(startDate.getMonth() + 1, endDate.getMonth() + 1)
      monthsRange.map((month) => months[[startYear, month]] = monthDict[String(month).padStart(2, '0')])
    }


    this.setState({
      startYear,
      endYear,
      startDate,
      endDate
    })
    this.setState({ rangeMonths: months })
    years.forEach(year => this.fetchData(year))
    this.fetchCostCenterData()
  }

  isParamInFormula(formula, param) {
    return formula.self_params[param]
  }

  isCostCenterInFormula(formula, param) {
    let ccs = Object.keys(formula.self_cost_centers)
    let exists = false

    ccs.map((key) => {
      if (key.includes(`\"id\"=>${param[0].area.id}`) && key.includes(`\"is_fixed\"=>${param[0].is_fixed}`)) {
        exists = true
      }
    })

    return exists
  }

  isIndicatorInFormula(formula, indicator) {
    return formula.self_indices[indicator]
  }

  getAllYearsData(formulaId = null) {
    if (formulaId) {
      let yearsData = {}
      Object.entries(this.state.allFormulas).map(([year, data]) => {
        yearsData[year] = data[formulaId]
      })
      return yearsData
    } else {
      return this.state.allYearsData
    }

  }

  renderReport(key, value, formulaId = null, level = 0) {
    const { labels, formulas, recordsFetched, cost_center_mode, allYearsData, allFormulas, dreFormulas, startYear, endYear } = this.state;
    const { rangeMonths } = this.state;

    let formulaData = (year) => {
      if (formulaId) {
        return allFormulas?.[year]?.[formulaId]?.report?.[key]
      } else {
        return allYearsData?.[year]?.report?.[key]
      }
    }




    let numberToCurrency = function (number, param = "difference") {
      let result = parseFloat(number)

      if (param == "difference") {
        result = parseFloat(number)
      }

      return parseFloat(result).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });
    };


    return <React.Fragment >
      <tr id="principal" className={`formula-${formulaId ? 'without-layout' : formulas?.[key]?.formula_type} formula-${formulas?.[key]?.formula_type}-${formulas?.[key]?.layout} format-${formulas?.[key]?.number_format} formula-${formulas?.[key]?.is_only_index ? "only-index" : "more-than-index"}`}>
        <td style={{ paddingLeft: level * 16 }}>
          <div style={{display: 'flex', alignItems: 'center'}}>
            {this.isFetchingFormulas(value[key].formulas) && <img style={{width: 43}} src={gif_image}/>}
            <PopHover
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}
              withoutico description={
                <React.Fragment>
                  {this.state?.formulas?.[key]?.description && <React.Fragment><br></br><div className={"desc"}>{this.state.formulas[key].description}</div><hr></hr></React.Fragment>}
                  <div className={"desc"}>{this.props.formulas[key]}</div>

                </React.Fragment>
              }>
              {key}
            </PopHover>
          </div>
        </td>
        {Object.entries(rangeMonths).map(([year_month, name]) => {
          let [year, number] = year_month.split(",")
          let value = formulaData(year)
          let classy = "semf"


          return (
            value ? <td className={'comment-wrapper' + ' ' + classy}>

              {value[key]["result"][name] && this.getNumerFormatted(this.tryEval(value[key]["result"][name]), formulas?.[key]?.number_format, dreFormulas[formulaId])}

              {this.state.comments && <Comment
                refreshComments={() => this.setComments(year)}
                comments={this.state.comments?.[year] || {}}
                model_reference={'formula-anexo' + (this.props.is_budget ? '-orçamento' : '')}
                month={number}
                monthName={name}
                year={year}
                model_reference_id={formulas[key]?.id}>
              </Comment>}
            </td> : <React.Fragment></React.Fragment>
          );
        })}

        <td className={'comment-wrapper'} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 6 }}>
          <ResultChart
            loadAllYears={() => { this.setupAllYearsData() }}
            loadYear={(year) => this.loadYear(year)}
            years={this.state.years}
            year={this.state.year}
            numberFormat={formulas?.[key]?.number_format}
            title={key}
            p_key={key}
            dataKey={"result"}
            inversedSignal={false}
            formula_id={key}
            attachment
            months={this.props.months}
            onlyLineData={true}
            startYear={startYear}
            endYear={endYear}
            rangeMonths={rangeMonths}
            allYearsData={this.getAllYearsData(formulaId)}
          ></ResultChart>
        </td>
      </tr>
      {!formulaId && formulas[key]?.cost_center_demonstration && cost_center_mode && this.renderCostCenterAreas(formulas[key]?.number_format)}
      {!formulaId && formulas[key]?.formula_type != "formula" && this.renderCostCenterParams(value[key], formulas[key]?.number_format)}
      {!formulaId && (!formulas[key]?.cost_center_demonstration || !Boolean(cost_center_mode)) && this.formulaIsGrouped(key) && Object.entries(value[key]["params"]).map(
        ([p_key, p_value]) => {
          let chartArray = []
          return (
            this.isParamInFormula(value[key], p_key) && <React.Fragment>
              {value[key]["lines"][p_key] && Object.values(value[key]["lines"][p_key]).map((param) => (
                <React.Fragment>
                  {!this.isParamZero(key, p_key, param, formulaId) && <tr>
                    <td style={{ paddingLeft: (level + 1) * 16 }} onClick={() => this.fetchDetail(p_key, param, key)}>
                      <i className={`pull-left fa fa-chevron-${recordsFetched[p_key] ? 'down' : 'right'}`} /> {
                        labels[p_key]
                      }
                    </td>
                    {Object.entries(rangeMonths).map(([year_month, name]) => {
                      let [year, number] = year_month.split(",")

                      let p_value = formulaData(year)?.[key]?.["params"]
                      chartArray.push(p_value?.[p_key]?.[name]?.[param])
                      return (
                        p_value ? <td className={'comment-wrapper'}>
                          {this.state.comments && <Comment
                            refreshComments={() => this.setComments(year)}
                            comments={this.state.comments?.[year] || {}}
                            model_reference={'param' + (this.props.is_budget ? '-orçamento' : '')}
                            month={number}
                            monthName={name}
                            year={year}
                            model_reference_id={p_key}>
                          </Comment>}
                          {p_value[p_key][name] &&
                            numberToCurrency(p_value[p_key][name][param], param)}
                        </td> : <React.Fragment></React.Fragment>
                      );
                    })}
                    {/*<td className={'flex-chart'}>
                                    <p>{
                                        (p_value &&
                                          Object.entries(p_value)
                                            .map(([k, v])=> k != "total" && v[param])
                                            .reduce(
                                              (a, b) => parseFloat(a || 0) + parseFloat(b || 0),
                                              0
                                            )
                                            .toLocaleString("pt-BR", {
                                              style: "currency",
                                              currency: "BRL"
                                        }))
                                      }</p>
                                      <ResultChart
                                        loadAllYears={()=>{this.setupAllYearsData()}}
                                        loadYear={(year)=> this.loadYear(year)} 
                                        years={this.state.years}
                                        year={this.state.year} 
                                        title={labels[p_key]} 
                                        data={this.dataForFormula(p_value)} 
                                        param={param}
                                        p_key={p_key}
                                        dataKey={"params"}
                                        formula_id={key}
                                        months={this.props.months}
                                        allYearsData={this.state.allYearsData}
                                        >
                                        </ResultChart>
                                  </td>*/}
                    <td className={'comment-wrapper'} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 6 }}>
                      <ResultChart
                        attachment
                        loadAllYears={() => { }}
                        loadYear={(year) => {}}
                        data={chartArray}
                        years={this.state.years}
                        year={this.state.year}
                        title={labels[p_key]}
                        param={param}
                        onlyLineData={true}
                        inversedSignal={true}
                        p_key={p_key}
                        rangeMonths={this.state.rangeMonths}
                        dataKey={"attachment_params"}
                        first_level_key={ p_key}
                        formula_id={key}
                        months={this.props.months}
                        startYear={this.state.startYear}
                        endYear={this.state.endYear}
                        allYearsData={this.state.recordsFetched}
                        head={true}
                      ></ResultChart>
                    </td>
                  </tr>}
                  {this.renderChildreenFor(p_key, level, key, param)}
                </React.Fragment>
              ))}

            </React.Fragment>
          );
        }
      )}

      {!formulaId && this.formulaIsGrouped(key) && value[key]["indicators"] && Object.entries(value[key]["indicators"]).map(
        ([p_key, p_value]) => {
          let inFormula = true //this.isIndicatorInFormula(value[key], p_key)
          let total_indicators = 0

          return (
            inFormula && <React.Fragment>

              <React.Fragment>
                {<tr>
                  <td style={{ paddingLeft: (level + 1) * 16 }}>
                    <i className={`pull-left fa}`} />
                    {p_key}
                  </td>
                  {Object.entries(rangeMonths).map(([year_month, name]) => {
                    let [year, number] = year_month.split(",")
                    let indicator = allYearsData?.[year]?.report[key][key]?.indicators?.[p_key]

                    total_indicators += indicator?.[name] ? parseFloat(indicator?.[name]) : 0
                    return (
                      <td>
                        {
                          formulas[key]?.number_format == "percentage" ?
                            (
                              indicator && indicator[name] ?
                                this.percentFormat(indicator[name])
                                :
                                "0%"
                            )
                            :
                            (
                              indicator && indicator[name] ?
                                numberToCurrency(indicator[name])
                                :
                                numberToCurrency(0)
                            )
                        }


                      </td>
                    );
                  })}
                  {/*<td className={'flex'} style={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <div className={'indicator-total'}>{
                                      numberToCurrency(total_indicators)
                                    }
                                    </div>
                                    <ResultChart
                                      attachment
                                      loadAllYears={()=>{this.setupAllYearsData()}}
                                      loadYear={(year)=> this.loadYear(year)} 
                                      years={this.state.years}
                                      year={this.state.year} 
                                      title={p_key} 
                                      data={this.dataForFormula(p_value)} 
                                      p_value={p_value}
                                      p_key={p_key}
                                      dataKey={"indicators"}
                                      formula_id={key}
                                      months={this.props.months}
                                      allYearsData={this.state.allYearsData}
                                      ></ResultChart>
                                  </td>*/}
                </tr>}
              </React.Fragment>

            </React.Fragment>
          );
        }
      )}
      {!formulaId && formulas[key]?.formula_type != "formula" && Object.keys(value?.[key]?.formulas).map((formula_id) => {
        return this.renderFormulas(formula_id, level)
      })}
      {!formulaId && <tr>
        <td></td>
        {Object.entries(rangeMonths).map(() => <td></td>)}
        <td></td>
      </tr>}
    </React.Fragment>
  }

  renderFormulas(formula, level) {
    level = level + 1

    return <React.Fragment>
      {Object.entries(this.state?.allFormulas?.[this.state.startYear]?.[formula]?.report || {}).map(([key, value]) => {
        return (
          this.renderReport(key, value, formula, level)
        );
      })}
    </React.Fragment>

  }
  // convert number into string and add a '0' at end if the length of decimals is less than 3
  convertNumberToString(number, decimals) {
    let number_string = number.toString()
    if (number_string.split(",")[1] && number_string.split(",")[1].length < decimals) {
      number_string += "0".repeat(decimals - number_string.split(",")[1].length)
    }
    return number_string
  }




  render() {
    const { report, showZeroLines, startYear, startDate, endYear, endDate } = this.state;
    const { rangeMonths } = this.state;




    let numberToCurrency = function (number, param = "difference") {
      let result = parseFloat(number) * -1

      if (param == "difference") {
        result = parseFloat(number)
      }

      return parseFloat(result).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });
    };



    return (
      <React.Fragment>
        <div className="header" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 20 }}>
            <div
              title="Abrir em nova janela"
              className={'result-popup-button'}
              onClick={() => popupCenter({ url: `/popups/attachment_table`, title: 'Editar', w: window.innerWidth * 0.8, h: window.innerHeight * 0.8 })}
              style={{
                backgroundColor: '#F5F5F5',
                width: 36,
                height: 36,
                borderRadius: 4,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer'
              }}>
              <img src={popupIco} style={{ width: 14 }}></img>
            </div>
            <div className="label" style={{ marginRight: 16 }}>INDICADORES FINANCEIROS</div>
            <MonthFilter changeVisibleMonths={(e) => this.changeVisibleMonths(e)} visibleMonths={this.state.visibleMonths} months={this.props.months}></MonthFilter>

            <ViewerButton title={showZeroLines ? "Mostrar linhas zeradas" : "Ocultar linhas zeradas"} active={showZeroLines} onClick={() => this.setState({ showZeroLines: !showZeroLines })}>
              {showZeroLines ? <EyeOff height={30} width={30} /> : <EyeOn height={30} width={30} />}
            </ViewerButton>
          </div>
          <form className="form flex" style={{ paddingRight: 35, alignItems: 'center', height: '100%' }}>
            {!this.props.is_budget &&
              <React.Fragment>
                <BlueLink
                  bordered
                  active={this.props.activeTab("attachment_table")}
                  onClick={() => this.propsSetState({ activeTab: "attachment_table" })}
                > <div style={{ paddingBottom: this.props.activeTab("attachment_table") ? 0 : 0 }}>Indicadores</div> </BlueLink>

              </React.Fragment>
            }
            <div className={'margin-right-20'}>
              <MonthRangeInput
                style={{ marginRight: 20 }}
                yearRange
                startDate={this.state.startDate}
                endDate={this.state.endDate}
                onChange={(startDate, endDate) => {
                  this.setRangeDate(startDate, endDate)
                }}
              ></MonthRangeInput>
             </div>
             {this.props.can_download && <a href={`/export/attachment/2021.xlsx?begin_at=[${startYear},${startDate.getMonth() + 1}]&end_at=[${endYear},${endDate.getMonth() + 1}]`} title="Download">
                <img src={downloadIco} style={{width: 36}}></img>
              </a>}
              
            </form>
        </div>
        <div>
          <small style={{ fontSize: 10, padding: "10px 0 0 43px", display: 'block', color: 'grey' }}>* Você está visualizando números no formato n/{this.props.divisor}</small>
        </div>
        <div id="table-result-wrapper">
          <div className="content margin-left-40 margin-right-40 padding-top-40">
            <table id="table-result" className={'anexo-table'}>
              <thead>
                <tr>
                  <th>Nome</th>
                  {Object.entries(rangeMonths).map(([key, month]) => (
                    <th>{month.substring(0, 3)}/{key.split(',')[0]}</th>
                  ))}

                  <th></th>
                </tr>
              </thead>

              {Object.entries(report).map(([key, value]) => {
                return (
                  this.renderReport(key, value)
                );
              })}
            </table>
          </div>
        </div>
        <LoadingDialog open={this.loading > 0} />
      </React.Fragment>
    );
  }
}

export default Attachment;
const monthDict = {
  "01": "janeiro",
  "02": "fevereiro",
  "03": "março",
  "04": "abril",
  "05": "maio",
  "06": "junho",
  "07": "julho",
  "08": "agosto",
  "09": "setembro",
  "10": "outubro",
  "11": "novembro",
  "12": "dezembro",
}

const range = (min, max) => [...Array(max - min + 1).keys()].map(i => i + min);