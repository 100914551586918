import React from 'react';
import ReportSituationDialog from './ReportSituationDialog'
import presentationsIco from 'presentations-ico.svg';

import styled from "styled-components";
import { DownloadIcon } from './svg-icons/DownloadIcon';
import ReactTooltip from 'react-tooltip';
export default class PresentationsButton extends React.Component {
  state = {
    edit: false,
    modalIsOpen: false,
  }
 
  
  openPopup() {
    Turbolinks.visit('/presentations')
  }



  render() {
    return (

      <Button onClick={() => this.openPopup()} title="Notas">
        <img src={presentationsIco} style={{width: 20, height: 20, marginRight: 8, marginLeft: 5}}/>
        Apresentações
      </Button>
    );
  }
}
const ButtonImg = styled.img`
    width: 26.2px;
    margin-right: 10px;
    
`

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 6px;
  background-color: #31b7bc;
  color: white;
  margin: 3px;
  cursor: pointer;
  min-height: 24px;

  &:hover {
    transition: all 0.2s;
    filter: brightness(0.85);
  }
`