export const presentationsReducer = (state, action) => {
  let newState  = state;
  let {index, value} = action.payload
  switch (action.type) {
    case "SET_PARAMETERS":
      return { ...state, parameters: action.payload };
      case "SET_ACCUMULATED_FORMULAS_REPORT":
        const year = action.payload.year;
        const reportForYear = action.payload.report;
    
        // Cria uma cópia do estado atual para garantir a imutabilidade
        const updatedFormulasTotalByYearYTD = { ...state.formulasTotalByYearYTD };
    
        // Atualiza apenas os dados para o ano específico, preservando os outros
        updatedFormulasTotalByYearYTD[year] = {...updatedFormulasTotalByYearYTD[year], ...reportForYear};
    
        console.log("NEW_DATA_YTD-" + year, reportForYear);
        console.log("OLD_STATE_YTD", state.formulasTotalByYearYTD);
        console.log("NEW_STATE_YTD", updatedFormulasTotalByYearYTD);
    
        // Retorna o novo estado
        return { ...newState, formulasTotalByYearYTD: updatedFormulasTotalByYearYTD };
    case "SET_GRAPH_TITLE":
      newState.parameters.graphs[index].title = value
      return {...newState};
    case "SET_GRAPH_STYLE":
      newState.parameters.graphs[index].graph_style = value
      return {...newState};
    case "SET_ALL_YEARS_DATA":
      console.log({...state.allYearsData, ...action.payload});
      return { ...state, allYearsData: {...state.allYearsData, ...action.payload} };
    case "SET_ALL_RANGE_DATA":
      return { ...state, allRangeData: {...state.allRangeData, ...action.payload} };
    case "SET_FORMULAS":
      return { ...state, formulas: action.payload };
    case "SET_SEGMENTED_LOADING":
      return {...state, segmentedLoading: action.payload}
    case "SET_COMPANY_DATA":
      return { ...state, companyData: action.payload, segmentedLoading: false };
    case "SET_BUDGET_COMPANY_DATA":
      console.log("SET_BUDGET", action.payload)
      return { ...state, budgetCompanyData: action.payload, segmentedLoading: false };
    case "SET_EBITDA_FORMULAS":
      return { ...state, ebitda_formulas: action.payload };
    case "SET_FORMULAS_BY_ID":
      return { ...state, formulasById: action.payload };
    case "SET_DASHBOARD_FORMULAS":
      return { ...state, dashboard_formulas: action.payload };
    case "SET_LAST_MONTHS_FROM_YEARS":
      return { ...state, lastMonthsFromYears: action.payload };
    case "SET_DASHBOARD_DATA":
      return { ...state, dashboardData: action.payload };
    case "SET_PATTERN_PARAMS":
      return { ...state, patternParams: action.payload };
    case "SET_PATTERN_PARAMS_BY_ID":
      return { ...state, patternParamsById: action.payload };
    case "SET_INHERIT_PARAMS":
      return { ...state, inheritParams: action.payload };
    case "SET_ALL_YEARS_DATA_FOR_PARAMETERS":
      return { ...state, allYearsDataForParameters: {...state.allYearsDataForParameters, ...action.payload} };
    
    default:
      return state;
  }
};

