import React from 'react'

export function EyeOn(props) {
  return (
    <svg viewBox="0 0 40 40" fill="none" width="1em" height="1em" {...props}>
      <path
        d="M19.986 27c-1.963-.016-3.728-.746-5.354-1.96-1.14-.848-2.144-1.872-2.936-3.142a8.726 8.726 0 01-.487-.951c-.297-.648-.265-1.296.018-1.944.496-1.122 1.22-2.027 2.04-2.844 1.468-1.471 3.13-2.515 5.058-2.963 1.643-.38 3.251-.2 4.814.479 2.03.879 3.724 2.293 5.053 4.258.225.334.41.715.585 1.09.306.648.288 1.306 0 1.965a8.107 8.107 0 01-1.351 2.103c-1.5 1.733-3.247 2.993-5.327 3.6a7.184 7.184 0 01-2.113.309zm.36-.823c.33-.062.987-.123 1.622-.309 2.134-.622 3.89-1.964 5.323-3.857.347-.457.608-1.013.878-1.543.162-.313.14-.663-.018-.982-.176-.355-.342-.725-.554-1.054-.873-1.343-1.981-2.381-3.247-3.2-2.116-1.362-4.346-1.82-6.705-.987-1.968.695-3.617 1.975-4.954 3.755-.342.457-.595.997-.87 1.522a1.04 1.04 0 000 .957c.18.375.357.756.577 1.1.72 1.116 1.621 2.016 2.635 2.767 1.517 1.126 3.161 1.805 5.314 1.831z"
        fill="#fff"
      />
      <path
        d="M20 23.703c-1.784.005-3.243-1.65-3.243-3.682-.004-2.068 1.446-3.724 3.252-3.724 1.77 0 3.229 1.656 3.233 3.667 0 2.083-1.432 3.734-3.242 3.74zm.013-6.583c-1.355-.025-2.517 1.28-2.535 2.844-.018 1.574 1.108 2.89 2.495 2.916 1.369.026 2.53-1.275 2.549-2.854.018-1.569-1.113-2.875-2.509-2.906z"
        fill="#fff"
      />
    </svg>
  );
}

