import React from 'react';
import LogoClever from 'logo-clever.svg';
import styled from 'styled-components';
import downloadIconPDF from 'download-pdf.svg';
export const Footer = ({totalPages, setNumPage, page, numPage, generatePDF, showDownload}) => {
    return (
        page == numPage ?
        <footer style={{
            display: 'flex', 
            justifyContent: 'space-between', 
            border: 'solid 1px #e8dede',
            alignSelf: 'flex-end',
            background: 'white',
            width: '100%',
            position: 'fixed',
            bottom: 0,
            left: 0
        }}>
            <div>
                {Array.from({length: totalPages}).map((_,index) => {
                    return (
                        <Pagination current={numPage == index} onClick={() => setNumPage(index-1)}>{index+=1}</Pagination>                                           
                    )
                })  
                }
            </div>
            <div>
                <div style={{marginLeft: 10, cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 100, float: 'right', margin: 10}}>
                    {showDownload ? <div onClick={()=> generatePDF()}>
                    <img src={downloadIconPDF} style={{width: 36, marginRight: 10}}></img>
                    </div>: <div>
                        <img src={'/images/Digitando.gif'} style={{width: 36, marginRight: 10}}></img>
                    </div>}
                    {/* {showDownload ? <div onClick={()=> exportSheetsAsImages()}>
                    <img src={downloadIconPPT} style={{width: 36, marginRight: 10}}></img>
                    </div>: <div>
                        <img src={loadingIco} style={{width: 36, marginRight: 10}}></img>
                    </div>} */}
                </div>
                <img src={LogoClever} style={{width: '128px', border: 'solid 10px white'}}></img>
            </div>
        </footer> : 
        <></>
    )
}


export const Pagination = styled.button`
    margin-top: 10px;
    margin-right: 10px;
    width: 30px;
    height: 30px;
    margin-left: 10px;
    border-radius: 6.92px;
    background: white;
    color: #ACACAC;
    font: Roboto;

    ${props => props.current && `
        color: white;
        background: #31B7BC;
    `}
`