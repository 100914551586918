import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import PdfPage from './PdfPage';

import ReactDOM from 'react-dom';
class Teste extends React.Component {
    render(){
        return <div>huhuhuhu</div>
    }
}
export default Teste;

ReactDOM.render(<PdfPage />, document.getElementById('pdf'));

