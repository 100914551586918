import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import {GreyButton} from "../styled_components/balance_sheets.js";
import CodeTable from './CodeTable'
Highcharts.setOptions({
  colors: ["#65BCFF", "#F7999C", "#4AB796", "#F4CF98", "#37BAC0", "#6C899E"]
});
export default function RemovedCostCenterCodes(props) {
  const [open, setOpen] = React.useState(false);
  let visibleMonths = props.visibleMonths || {}
  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }
  function handleChange(e){
    console.log(e)
    props.changeVisibleMonths(e)
  }

  
  return (
    <div>
      
	  <div style={{marginRight: 20}} onClick={handleClickOpen}>
	 	 <GreyButton>
        Ver contas removidas
      </GreyButton>
	  </div>
      
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={'xs'}
      >
        <DialogTitle id="simple-dialog-title">Contas removidas</DialogTitle>
        <DialogContent >
          <CodeTable restoreCode={props.restoreCode} bannedCodes={props.bannedCodes}/>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}



