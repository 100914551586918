import React, {useEffect} from "react";
import { PopUp } from "../popUp";
import {
  Cell,
  Container,
  Content,
  HeaderCell,
  Row,
  TableCaption,
  TableContainer,
} from "./styles";


export function TableBalance({
  parameters,
  formulas,
  formulasMappedById,
  report,
  state,
  monthLimit,
  totalReport,
  logoCompany
}) {

  useEffect(()=>{
    try{
      adjustRowHeights()
    }catch(e){}
    
  })

  function adjustRowHeights() {
    const table1 = document.getElementById('table-mensal');
    const table2 = document.getElementById('table-total');

    const rows1 = table1.getElementsByTagName('tr');
    const rows2 = table2.getElementsByTagName('tr');

    if (rows1.length !== rows2.length) {
      console.error('As duas tabelas devem ter o mesmo número de linhas');
      return;
    }
    
    for (let i = 0; i < rows1.length; i++) {
      const rowHeight1 = rows1[i].clientHeight;
      const rowHeight2 = rows2[i].clientHeight;
      const maxHeight = Math.max(rowHeight1, rowHeight2);

      rows1[i].style.height = `${maxHeight}px`;
      rows2[i].style.height = `${maxHeight}px`;
    }
  }

  const tryEval = (str) => {
    try {
      let formulaSolved = eval(str);
      return formulaSolved ? formulaSolved : 0;
    } catch (e) {
      return 0;
    }
  };

  const percentFormat = (number) => {
    return `${parseFloat(number).toFixed(2)}%`;
  };

  
    //check if the label have a value between "Ativo", "Passivo" or "Resultado", and it should be exactly one of them
  const  isParent = (label)=> {
    const validValues = ["Ativos", "Passivos", "Resultados"];
    return validValues.includes(label);
  }

  const  isSubParent = (label)=> {
    const validValues = ["Ativo Circulante", "Ativo Não Circulante", "Imobilizado Liquido",  "Passivo Circulante", "Passivo Não Circulante"];
    return validValues.includes(label);
  }
  
  const numberToCurrency = (number = 0) => {
    if (
      String(parseFloat(number)) == "NaN" ||
      parseFloat(number) == undefined
    ) {
      number = 0;
    }

    return parseFloat(number * -1).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  const getNumberFormatted = (value, format) => {
    let result = value;
    if (format == "percentage") {
      result = percentFormat(value);
    }
    if (format == "integer") {
      result = new Intl.NumberFormat("decimal", {
        minimumFractionDigits: 2,
      }).format(parseFloat(value));
    }
    if (!format || format == "money") {
      result = numberToCurrency(value * -1);
    }

    return result;
  };

  return (
    <Container>
      {(parameters?.title || parameters?.observation) && (
        <>
          <div style={{background: 'white', borderBottom: 'solid 1px #e5e1e1', width: '100%'}}>
            <div style={{display: 'flex'}}>
              <div style={{display: 'flex', width: '100%'}}>
                <img src={logoCompany} className="logo" style={{width: '100px', margin: 10}}></img>
                <div style={{border: 'solid 15px white'}}></div>
                <span style={{paddingTop: '3%', fontSize: '24px', width: '50%'}}>{parameters?.title}</span>
                {parameters?.observation && <PopUp content={parameters.observation} />}
              </div>
            </div>
          </div>
        </>
      )}
      <Content>
        <TableContainer id={'table-mensal'} style={{ flex: 2 }}>
          <TableCaption></TableCaption>
          <thead>
            {parameters?.period && (
              <tr>
                <th></th>
                {[
                  ...new Array(
                    parseInt(parameters?.period === "6" ? "3" : parameters?.period)
                  ),
                ].map((v, index) => {
                  let month = index + parseInt(parameters?.first_month || 0);
                  return (
                    <HeaderCell>
                      {monthsDict[month]}/{parameters?.year || 2020}
                    </HeaderCell>
                  );
                })}
              </tr>
            )}
          </thead>
          <tbody>
            {formulas?.map((formula) => {
              let paramId = formula?.value?.replaceAll("parameter_", "")
              let label = state?.patternParamsById?.[paramId]?.label
              let numberFormat = formulasMappedById[formula?.value]?.number_format;

              const formulaMapped = formulasMappedById[formula?.value];
              let style =
                formulaMapped?.formula_type === "grouped"
                  ? "grouped"
                  : formulaMapped?.layout;

              //pegar o tipo da formula
              let monthValues = [];
              try{
                monthValues = [
                  ...new Array(parseInt(parameters?.period === "6" ? "3" : parameters?.period)),
                ].map((v, index) => {
                  let month = monthsDict[index + parseInt(parameters?.first_month || 0)];
                  
                  return (
                    tryEval(
                      state.allYearsDataForParameters[parameters?.year]?.params[paramId][month]
                    ) 
                  );
                });
              }catch(e){

              }
              

              return (
                <Row formulaStyle={style} isParent={isParent(label)} isSubParent={isSubParent(label)}>
                  <Cell>{label}</Cell>
                  {monthValues.map((monthValue) => {
                    return (
                      <Cell style={{textAlign: 'right'}}>
                        {getNumberFormatted(monthValue, numberFormat)}
                      </Cell>
                    );
                  })}
                </Row>
              );
            })}
          </tbody>
        </TableContainer>
       
      </Content>
      
    </Container>
  );
}

const monthsDict = {
  1: "janeiro",
  2: "fevereiro",
  3: "março",
  4: "abril",
  5: "maio",
  6: "junho",
  7: "julho",
  8: "agosto",
  9: "setembro",
  10: "outubro",
  11: "novembro",
  12: "dezembro",
};
