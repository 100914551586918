import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'

Highcharts.setOptions({
  colors: ["#65BCFF", "#F7999C", "#4AB796", "#F4CF98", "#37BAC0", "#6C899E"]
});
var moneyFormatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL"
});

var option = {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
};
var percentFormatter = new Intl.NumberFormat("pt-BR",option);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    '& svg': {
      margin: theme.spacing(1.5),
    },
    '& hr': {
      margin: theme.spacing(0, 0.5),
    },
  },
}));

export default function SegmentedChart(props) {
  const classes = useStyles();
  const [visibleCharts, setVisibleCharts] = React.useState(true);
  console.log("DATA_SEGMENTADO", props.state.companyData, props.state)
  React.useEffect(() => {
    toggleChartVisibility()  
    dataIsCompleted()
    }, [props?.state?.parameters?.graphs]);

  const toggleChartVisibility = ()=>{
    setVisibleCharts(false);
      setTimeout(() => {
        setVisibleCharts(true);
      }, 100);
  }
  function tryEval(str) {
    try {
      return eval(str) || 0;
    } catch (e) {
      return 0;
    }
  }

  function getDataForYear(company_id, year) {
    let {companyData, p_key, currentMonth} = props;
    return tryEval(companyData?.[company_id]?.[year]?.["report"]?.[p_key]?.[p_key]?.["result"]?.[currentMonth]);
  }

  function percentage(value, company_id) {
    let {companyData, p_key, companies, currentMonth, currentCompanyId, year} = props;
    let total = 0;
    Object.entries(companies).forEach(([name, row_company_id]) => {
      if (currentCompanyId !== row_company_id) {
        total += getDataForYear(row_company_id, year);
      }
    });
    if(props.format == "percentage"){
      return percentFormatter.format(value / 100)
    }else{
      return currentCompanyId !== company_id ? parseFloat((value / total) * 100).toFixed(2) + "%" : moneyFormatter.format(value);
    }
  }

  function getBudgetDataForYear(company_id, year) {
    let {budgetCompanyData, p_key, currentMonth} = props;
    return tryEval(budgetCompanyData?.[company_id]?.[year]?.["report"]?.[p_key]?.[p_key]?.["result"]?.[currentMonth]);
  }

  function budgetPercentage(value, company_id) {
    let {companyData, p_key, companies, currentMonth, currentCompanyId, year} = props;
    let total = 0;
    Object.entries(companies).forEach(([name, row_company_id]) => {
      if (currentCompanyId !== row_company_id) {
        total += getBudgetDataForYear(row_company_id, year);
      }
    });
    if(props.format == "percentage"){
      return percentFormatter.format(value / 100)
    }else{
      return currentCompanyId !== company_id ? parseFloat((value / total) * 100).toFixed(2) + "%" : moneyFormatter.format(value);
    }
  }

  function data() {
    let currentYearData = [];
    let previousYearData = [];
    let {companies, year, currentCompanyId, comparedData} = props;
    Object.entries(companies).forEach(([name, company_id]) => {
      let currentYearValue = getDataForYear(company_id, year);
      let formattedCurrentYearValue = percentage(currentYearValue, company_id);

      let previousYearValue  = 0
      let formattedPreviousYearValue = 0
      if(comparedData == "budget"){
        
        previousYearValue = getBudgetDataForYear(company_id, year);
        
      }else{
        previousYearValue = getDataForYear(company_id, year - 1);
        
      }
      
      
      
      currentYearData.push({
        name: name,
        y: currentYearValue,
        color: 'rgba(101, 188, 255, 0.5)',
        tooltipInfo: props.format == "percentage" ?  percentFormatter.format(currentYearValue / 100) : moneyFormatter.format(currentYearValue),
        dataLabels:{
          align: 'left'
        }
      });
      previousYearData.push({
        name: name,
        y: previousYearValue,
        color: 'rgba(74, 183, 150, 0.5)',
        tooltipInfo: props.format == "percentage" ?  percentFormatter.format(previousYearValue / 100) : moneyFormatter.format(previousYearValue),
        dataLabels:{
          align: 'right'
        }
      });
    });
    return { currentYearData, previousYearData };
  }

  

  let options = ()=>({
    chart: {
      type: 'column',
      borderWidth: 0,
      height: 300,
    },
    exporting: {
      enabled: false
    },
    title: {
      text: props.p_key
    },
    xAxis: {
      type: 'category'
    },
    yAxis: {
      title: {
        text: 'Valores'
      }
    },
    legend: {
      enabled: true
    },
    tooltip: {
      shared: true,
      formatter: function() {
        return this.points[0].point.tooltipInfo	
      }
    },
    plotOptions: {
      column: {
        dataLabels: {
          enabled: true, // Ativa as data labels para todas as colunas
          style: {
            color: '#000000', // Define a cor das letras
            textOutline: 'none' // Remove o contorno do texto para melhor legibilidade
          },
          formatter: function() {
            return this.point.tooltipInfo
          }
        },
        grouping: true,
        shadow: false,
        borderWidth: 0
      }
    },
  
    series: [{
      name: props.comparedData == "budget" ? `${props.year} - Orçamento` : `${props.year - 1}`,
      data: data().previousYearData,
      color: 'rgba(74, 183, 150, 0.5)',
      zIndex: 1,
      pointPlacement: +0.1,
      
    },{
      name: props.year,
      data: data().currentYearData,
      color: 'rgba(101, 188, 255, 0.5)',
      zIndex: 3,
      pointPlacement: -0.1,
      
    }]
  });

  function dataIsCompleted(){
    return !props.state.segmentedLoading
  }

  return (
    <div className={classes.root}>
      {visibleCharts && dataIsCompleted() && <HighchartsReact
        highcharts={Highcharts}
        options={options()}
      />}
    </div>
  );
}



