import React, { Children } from "react";
import PropTypes from "prop-types";
import Fuse from "fuse.js";
import {
  Select,
  FlexCenter,
  GreenLabel,
  BlueButton
} from "../../styled_components/default";

class Edit extends React.Component {
  state = {
    stage_text: "Salvar colunas"
  };

  optionsRender() {
    return this.props.balance_sheet_options.map(option => (
      <option value={option[1]}>{option[0]}</option>
    ));
  }
  saveHeaderData() {
    this.setState({ saving: true });
    this.setState({ stage_text: "Salvando linhas..." });

    axios
      .post(`/balance_sheets/${this.props.balance_sheet_id}/configure_header.json`, {
        header_columns: this.state
      })
      .then(result => {
        if (result.data.success) {
          this.setState({ stage_text: "Redirecionando para configuração..." });
          location.reload();
        }

        this.setState({ saving: false });
      });
  }

  render() {
    let {sample_data} = this.props
    return (
      <div>
        <FlexCenter>
          <div className={"margin-10"} style={{ flex: 1 }}>
            <GreenLabel> Código da Conta </GreenLabel>
            <Select
              onChange={e => this.setState({ code_column: e.target.value })}
            >
              <option>-- selecione a coluna --</option>
              {this.optionsRender()}
            </Select>
          </div>
          <div className={"margin-10"} style={{ flex: 1 }}>
            <GreenLabel> Descrição da Conta </GreenLabel>
            <Select
              onChange={e =>
                this.setState({ description_column: e.target.value })
              }
            >
              <option>-- selecione a coluna --</option>
              {this.optionsRender()}
            </Select>
          </div>
           <div className={"margin-10"} style={{ flex: 1 }}>
            <GreenLabel> Saldo Anterior </GreenLabel>
            <Select
              onChange={e =>
                this.setState({ saldo_anterior_column: e.target.value })
              }
            >
              <option>-- selecione a coluna --</option>
              {this.optionsRender()}
            </Select>
          </div>
          <div className={"margin-10"} style={{ flex: 1 }}>
            <GreenLabel> Débito </GreenLabel>
            <Select
              onChange={e => this.setState({ debito_column: e.target.value })}
            >
              <option>-- selecione a coluna --</option>
              {this.optionsRender()}
            </Select>
          </div>
          <div className={"margin-10"} style={{ flex: 1 }}>
            <GreenLabel> Crédito </GreenLabel>
            <Select
              onChange={e => this.setState({ credito_column: e.target.value })}
            >
              <option>-- selecione a coluna --</option>
              {this.optionsRender()}
            </Select>
          </div>
         
          <div className={"margin-10"} style={{ flex: 1 }}>
            <GreenLabel> Saldo Atual </GreenLabel>
            <Select
              onChange={e =>
                this.setState({ saldo_atual_column: e.target.value })
              }
            >
              <option>-- selecione a coluna --</option>
              {this.optionsRender()}
            </Select>
          </div>
        </FlexCenter>
        <FlexCenter>
          <BlueButton onClick={() => this.saveHeaderData()}>
            {this.state.stage_text}
          </BlueButton>
        </FlexCenter>

        <table className={'bordered margin-top-20'} >
            <thead>
              <tr>
                <th colspan={6} style={{textAlign: 'center'}}>Demonstração das colunas escolhidas</th>
              </tr>
            </thead>
            <tr>
              <th> Código da Conta </th>
              <th> Descrição da Conta </th>
              <th> Saldo Anterior </th>
              <th> Débito </th>
              <th> Crédito </th>
              <th> Saldo Atual </th>
            </tr>
            <tbody>
              {sample_data.map((row)=>(
                  <tr>
                    <td>{row[this.state.code_column] ? row[this.state.code_column] : "-"} </td>
                    <td>{row[this.state.description_column] ? row[this.state.description_column] : "-"} </td>
                    <td>{row[this.state.saldo_anterior_column] ? row[this.state.saldo_anterior_column] : "-"} </td>
                    <td>{row[this.state.debito_column] ? row[this.state.debito_column] : "-"} </td>
                    <td>{row[this.state.credito_column] ? row[this.state.credito_column] : "-"} </td>
                    <td>{row[this.state.saldo_atual_column] ? row[this.state.saldo_atual_column] : "-"} </td>
                  </tr>
              ))}
            </tbody>
            
          

        </table>
      </div>
    );
  }
}

export default Edit;
