import React, { useContext, useEffect } from "react";
import styled from "styled-components";
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from "@material-ui/core/FormGroup";
import MonthRangeInput from "../../MonthRangeInput";
import { PresentationsContext } from "../contexts";
import { useState } from "react";

const Input = (props) => {
  const { state, dispatch } = useContext(PresentationsContext);
  let { formulaQuantity, setFormulaQuantity, title } = props;
  let [primaryDate, setPrimaryDate] = useState(null);
  let [secondaryDate, setSecondaryDate] = useState(null);
  const setParameters = (key, value) => {
    dispatch({
      type: "SET_PARAMETERS",
      payload: { ...state.parameters, [key]: value },
    });
  };
  const MONTH_DICT = {
    1: "Janeiro",
    2: "Fevereiro",
    3: "Março",
    4: "Abril",
    5: "Maio",
    6: "Junho",
    7: "Julho",
    8: "Agosto",
    9: "Setembro",
    10: "Outubro",
    11: "Novembro",
    12: "Dezembro",
  };

  
  const renderTextInput = () => {
    return (
      <>
        <label style={{ padding: 4 }}>{title}</label>
        <TextInput {...props}></TextInput>
      </>
    );
  };

  const renderBooleanInput = () => {
    return (
      <>
        <FormGroup>
          <FormControlLabel control={<Checkbox {...props}></Checkbox>} label={title} />
        </FormGroup>
        
      </>
    );
  };

  const renderEbitdaFormulas = ()=>{
    return (
      <FormGroup>
        <label>Formula Ebitda</label>
        <Select {...props}>
          <option > -- Escolha a formula -- </option>
          {state?.ebitda_formulas?.map((formula) => {
            return <option value={formula.id}>{formula.label}</option>;
          })}
        </Select>
      </FormGroup>
    )
  }

  const renderAreaTextInput = () => {
    return (
      <>
        <label style={{ padding: 4 }}>{title}</label>
        <TextArea {...props}></TextArea>
      </>
    );
  };

  

  const renderFormulaInput = (i) => {
    return (
      <>
        <label style={{ padding: 4 }}>{title}</label>
        <Select {...props}>
          {state?.formulas?.map((formula) => {
            return <option value={formula.fr_id}>{formula.label}</option>;
          })}
        </Select>
      </>
    );
  };

  if(props.kind == "interval_type"){
    return (
      <>
        <label style={{ padding: 4 }}>{title}</label>
        <Select {...props}>
          <option > -- Mês/Intervalo --</option>
          <option value="month">Mês</option>
          <option value="period">Intervalo</option>
        </Select>
      </>
    );
  }
  if(props.kind == "flow_chart_type"){
    return (
      <>
        <label style={{ padding: 4 }}>{title}</label>
        <Select {...props}>
          <option > -- Conjunto de Formulas --</option>
          <option value="cash_flow">Fluxo de caixa total</option>
          <option value="cash_flow_only">Fluxo de caixa indireto</option>
        </Select>
      </>
    );
  }
  if(props.kind == "secondary_data_type"){
    return (
      <>
        <label style={{ padding: 4 }}>{title}</label>
        <Select {...props}>
          <option > -- Real/Orçado --</option>
          <option value="budget">Orçamento</option>
          <option value="comparado">Real</option>
        </Select>
      </>
    );
  }
  if(props.kind == "primary_data_date" && (props.presentationKind == "flow_chart" || props.parameters["interval_type"]) && !props.parameters?.last_month_ever){
    return (
      <DateInput>
        <label style={{ padding: 4 }}>{props.presentationKind == "flow_chart" ? "Mês" : title} </label>
        {props.parameters["interval_type"] == "period" ?
          <MonthRangeInput
            minYear={props.years[0]}
            maxYear={props.years[props.years.length - 1]}
            key={"primary_data_date_multiple"}
            startDate={props.parameters?.primary_data_date?.startDate}
            endDate={props.parameters?.primary_data_date?.endDate}
            onChange={(startDate, endDate) => {
              if(!props.parameters?.last_month_ever){
                setParameters("primary_data_date", {startDate: startDate, endDate: endDate}) 
              }  
              
            }}
          ></MonthRangeInput>
          :
          <MonthRangeInput
            minYear={props.years[0]}
            maxYear={props.years[props.years.length - 1]}
            key={"primary_data_date_single"}
            date={props.parameters?.primary_data_date?.endDate} 
            onChange={(date) => {
              if(!props.parameters?.last_month_ever){
                setParameters("primary_data_date", {endDate: date}) 
              }
              
            }} 
          ></MonthRangeInput>
        }
      </DateInput>
    );
  }
  if(props.kind == "secondary_data_date" && props.parameters["interval_type"] && !props.parameters?.last_month_ever){
    return (
      (props.parameters?.secondary_data_date?.endDate) ? <DateInput>
        <label style={{ padding: 4 }}>{title}</label>
        {props.parameters["interval_type"] == "month" && <MonthRangeInput
          minYear={props.years[0]}
          maxYear={props.years[props.years.length - 1]}
          key={"secondary_data_date_single"}
          date={props.parameters?.secondary_data_date?.endDate}  
          onChange={(date) => {
            if(!props.parameters?.last_month_ever){
              setParameters("secondary_data_date", {endDate: date}) 
            }
            
          }}
        ></MonthRangeInput>}
        {props.parameters["interval_type"] == "period" &&
          <MonthRangeInput
            minYear={props.years[0]}
            maxYear={props.years[props.years.length - 1]}
            key={"secondary_data_date_multiple"}
            startDate={props.parameters?.secondary_data_date?.startDate }
            endDate={props.parameters?.secondary_data_date?.endDate }
            onChange={(startDate, endDate) => {
              if(!props.parameters?.last_month_ever){
                setParameters("secondary_data_date", {startDate: startDate, endDate: endDate}) 
              }
              
            }}
          ></MonthRangeInput> 
        }
      </DateInput> : <></>
    );
  }
  if(props.kind == "ebitda_formula"){
    return renderEbitdaFormulas();
  }

  if (props.kind === "area_text") {
    return renderAreaTextInput();
  }

  if (props.kind === "text") {
    return renderTextInput();
  }

  if (props.kind === "boolean") {
    return renderBooleanInput();
  }

  if (props.kind === "formula") {
    return renderFormulaInput();
  }

  if (props.kind === "formulas") {
    return (
      <>
        {renderFormulaInput()}

        <AddFormula
          onClick={() => {
            setFormulaQuantity(formulaQuantity + 1);
          }}
        >
          Add formula
        </AddFormula>
      </>
    );
  }
  
  if (props.kind == "month") {
    return (
      <>
        <label style={{ padding: 4 }}>{title}</label>
        <Select {...props}>
          {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((month) => (
            <>
              {month === 0 ? (
                <option key={month} value={0}>Selecione...</option>
              ) : (
                <option key={month} value={month}>
                  {MONTH_DICT[month]}
                </option>
              )}
            </>
          ))}
        </Select>
      </>
    );
  }
  if(props.kind === "modality"){
    return (
      <>
        <label style={{ padding: 4 }}>{title}</label>
        <Select {...props}>
          <option > -- Modalidade --</option>
          <option value="monthly">Mês</option>
          <option value="yearly">Ano (total)</option>
        </Select>
      </>
    );
  }
  if (props.kind === "year") {
    return (
      <>
        <label style={{ padding: 4 }}>{title}</label>
        <Select {...props}>
          {[0, ...props.years].map((year) => (
            <>
              {year === 0 ? (
                <option key={year}>Selecione...</option>
              ) : (
                <option key={year} value={year}>
                  {year}
                </option>
              )}
            </>
          ))}
        </Select>
      </>
    );
  }

  if (props.kind == "period") {
    return (
      <>
        <label style={{ padding: 4 }}>{title}</label>

        <Select {...props}>
          {[0, 1, 2, 3, 4, 5, 6].map((months) => (
            <>
              {months === 0 ? (
                <option key={months} value={0}>Selecione...</option>
              ) : (
                <>
                  <option key={months} value={months}>
                    Últimos {months} meses
                  </option>
                </>
              )}
            </>
          ))}
        </Select>
      </>
    );
  }

  return <></>;
};

export default Input;

const TextArea = styled.textarea`
  background-color: #f5f5f5;
  color: #6e6e6e;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  padding: 8px;
  height: 60px;
  width: 240px;
  resize: none;
`;

const TextInput = styled.input`
  background-color: #f5f5f5;
  color: #6e6e6e;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  padding: 8px;
  height: 20px;
  width: 240px;
`;

const Select = styled.select`
  background-color: #f5f5f5;
  color: #6e6e6e;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  padding: 8px;
  height: 20px;
  width: 240px;
`;
const AddFormula = styled.div`
  background-color: #298793;
  color: #ffffff;
  height: 20px;
  width: 240px;
  cursor: pointer;
  text-align: center;
  padding: 8px;
  border-radius: 6px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;


const DateInput = styled.div`
  height: 62px;
  position: relative;
  #month-range-input{
   position: absolute;

  }
`;
