import React from "react";

export function FluxoCaixaIcon(props) {
  return (
    <svg viewBox="0 0 11 7" fill="none" {...props}>
      <path
        d="M.725 5.225L3.112 4.5 5.5 6l2.387-1.5 2.388.725M.725 1.725L3.112 1 5.5 2.5 7.887 1l2.388.725"
        stroke="currentColor"
        strokeWidth={1.45}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}