import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";

import { PresentationsContext } from "./contexts";

import { Table } from "./components/table";
import { TableBalance } from "./components/table_balance";
import { PopUp } from "./components/popUp";
import { Container, Content } from "./components/table/styles";
import ResultChart from "../ResultChart";
import DashboardWidget from "../DashboardWidget";
import ChartView from "./ChartView";
import BorboletaChart from "../BorboletaChart";
import FlowChart from "../FlowChart";
import axios from "../../components/utils/axiosCached";
import logoCoruja from 'coruja.png';
import SegmentadoView from "./SegmentadoView";
const uuid = crypto.randomUUID();

const PreVisualization = (props) => {
  if(props.printing){
    var state = props.state
  }else{
    var { state, dispatch } = useContext(PresentationsContext);
  }
  
  let { kind, parameters, report, formulasMappedById, totalReport, btnDownloads, company_id, lastMonthsFromYears } = props;
  let [chartSVG, setChargeSVG] = useState("<svg></svg>");

  const [pngChart, setPngChart] = useState("");
  const [logoCompany, setLogoCompany] = useState(`/company_logo/${company_id}`);

  

  const titleText = () => {
    return (
      <>
        <h1>{parameters.title}</h1>
        <div>{parameters.description}</div>
      </>
    );
  };

  const subHeader = () => {
    return (
      <div style={{background: 'white', borderBottom: 'solid 1px #e5e1e1', width: '100%'}}>
        <div style={{display: 'flex'}}>
          <div style={{display: 'flex', width: '100%'}}>
            <img src={logoCompany} className="logo" style={{width: '100px', margin: 10}}></img>
            <div style={{border: 'solid 15px white'}}></div>
            <span style={{fontSize: '24px', width: '50%', display: 'flex', alignItems: 'center'}}>{parameters?.title}</span>
            {parameters?.observation && <PopUp content={parameters.observation} />}
          </div>
        </div>
      </div>
    )
  }

  // convert svg string to base64
  function svgString2Image(svgString, width, height, format, callback) {
    // set default for format parameter
    format = format ? format : "png";
    // SVG data URL from SVG string
    var svgData =
      "data:image/svg+xml;base64," +
      btoa(unescape(encodeURIComponent(svgString)));
    // create canvas in memory(not in DOM)
    var canvas = document.createElement("canvas");
    // get canvas context for drawing on canvas
    var context = canvas.getContext("2d");
    // set canvas size
    canvas.width = width;
    canvas.height = height;
    // create image in memory(not in DOM)
    var image = document.createElement("img");
    // later when image loads run this
    image.onload = function () {
      // async (happens later)
      // clear canvas
      context.clearRect(0, 0, width, height);
      // draw image with SVG data to canvas
      context.drawImage(image, 0, 0, width, height);
      // snapshot canvas as png
      var pngData = canvas.toDataURL("image/" + format);
      // pass png data URL to callback
      callback(pngData);
    }; // end async
    // start loading SVG data into in memory image
    image.src = svgData;
  }

  const titleChart = () => {
    let formula = formulasMappedById[parameters.formula];
    let key = formula?.label;
    return (
      <Container>
        <Content>
          <div style={{ width: "100%" }}>
            <h1>{parameters.title}</h1>
            {formula && (
              <ResultChart
                height={"18vh"}
                year={parameters.year}
                setSVG={(svg) => setChargeSVG(svg)}
                numberFormat={formula.number_format}
                title={key}
                p_key={key}
                dataKey={"result"}
                formula_id={key}
                formula_fr_id={formula.fr_id}
                months={monthDict}
                allYearsData={report}
                withoutModal={true}
              ></ResultChart>
            )}
          </div>
        </Content>
      </Container>
    );
  };

  const tableChart = () => {
    return (
      <Table
        logoCompany={logoCompany}
        formulasMappedById={formulasMappedById}
        totalReport={totalReport}
        report={report}
        year={parameters?.year}
        parameters={parameters}
        formulas={parameters?.formulas}
        numPage={props.numPage}
      />
    );
  };

  
  const tableBalanceChart = () => {
    return (
      <TableBalance
        logoCompany={logoCompany}
        formulasMappedById={formulasMappedById}
        report={report}
        year={parameters?.year}
        parameters={parameters}
        state={state}
        formulas={parameters?.formulas}
      />
    );
  };

  const lineGraph = () => {
    return (
      <Container>
        {(parameters?.title || parameters?.observation) && (
          subHeader()
      )}
        <Content>
          <ChartWrapper>
            <ChartView
              formulasMappedById={formulasMappedById}
              report={report}
              parameters={parameters}
              state={state}
              printing={props.printing}
            ></ChartView>
          </ChartWrapper>
        </Content>
      </Container>
    );
  };

  const segmentedChart = () => {
    return (
      <Container>
        {(parameters?.title || parameters?.observation) && (
          subHeader()
      )}
        <Content>
          <ChartWrapper>
            <SegmentadoView
              formulasMappedById={formulasMappedById}
              report={report}
              childreen={props.childreen}
              currentCompanyId={props.company_id}
              parameters={parameters}
              state={state}
              printing={props.printing}
            ></SegmentadoView>
          </ChartWrapper>
        </Content>
      </Container>
    );
  };
  const borboletaChart = ()=>{
    const getTitle = ()=>{
      let {analisadoDate, 
          budgetDate, 
          comparadoDate, 
          startDate, 
          endDate, 
          comparedLabel, 
          isRange,
          secondaryDataLabel} = props.borboletaParameters

      

      if(isRange){
          let primary_date_start = startDate['analisado']
          let primary_date_end = endDate['analisado']
          let secondary_date_start = startDate[secondaryDataLabel?.replace('-range', '')]
          let secondary_date_end = endDate[secondaryDataLabel?.replace('-range', '')]

          let primary_month_start = reverseMonthsDict[primary_date_start?.getMonth() + 1]
          let primary_month_end = reverseMonthsDict[primary_date_end?.getMonth() + 1]
          let secondary_month_start = reverseMonthsDict[secondary_date_start?.getMonth() + 1]
          let secondary_month_end = reverseMonthsDict[secondary_date_end?.getMonth() + 1]

          let primary_year = primary_date_start.getFullYear()
          let secondary_year = secondary_date_start.getFullYear()

          return {
              data1: `Real(${primary_month_start}-${primary_month_end}/${primary_year})`,
              data2: `${comparedLabel || ""}(${secondary_month_start}-${secondary_month_end}/${secondary_year})`,
              data1_short: `${primary_month_start}-${primary_month_end}/${primary_year}`,
              data2_short: `${secondary_month_start}-${secondary_month_end}/${secondary_year}`
          }
      }else{
          let primary_date = endDate['analisado']
          let secondary_date = endDate[secondaryDataLabel]

          let primary_month = reverseMonthsDict[primary_date.getMonth() + 1]
          let secondary_month = reverseMonthsDict[secondary_date.getMonth() + 1]

          let primary_year = primary_date.getFullYear()
          let secondary_year = secondary_date.getFullYear()

          return {
              data1: `Real(${primary_month}/${primary_year})`,
              data2: `${comparedLabel || ""}(${secondary_month}/${secondary_year})`,
              data1_short: `${primary_month}/${primary_year}`,
              data2_short: `${secondary_month}/${secondary_year}`
          }

          
      }  
    }

    let chartParameters = {
      
      "title": getTitle(),
      "principalDataLabel": "analisado",
      "open": true,
      "divisor": 1,
      ...props.borboletaParameters,
    }
    return <Container>
        {(parameters?.title || parameters?.observation) && (
          subHeader()
      )}
        <Content>
          <ChartWrapper>
            <BorboletaChart 
                {...chartParameters}
                withoutDrillDown={true}
                height={320}
                withoutDownloadButton={true}
                ebitda_formula_id={parameters.selected_formula}
                popup={true}
                disableOpenings={true}
                printing={props.printing}
              />
          </ChartWrapper>
        </Content>
      </Container>

  }

  const flowChart = ()=>{
    const getTitle = ()=>{
      let {analisadoDate, 
          budgetDate, 
          comparadoDate, 
          startDate, 
          endDate, 
          comparedLabel, 
          isRange,
          secondaryDataLabel} = props.borboletaParameters

      

      if(isRange){
          let primary_date_start = startDate['analisado']
          let primary_date_end = endDate['analisado']
          let secondary_date_start = startDate[secondaryDataLabel?.replace('-range', '')]
          let secondary_date_end = endDate[secondaryDataLabel?.replace('-range', '')]

          let primary_month_start = reverseMonthsDict[primary_date_start?.getMonth() + 1]
          let primary_month_end = reverseMonthsDict[primary_date_end?.getMonth() + 1]
          let secondary_month_start = reverseMonthsDict[secondary_date_start?.getMonth() + 1]
          let secondary_month_end = reverseMonthsDict[secondary_date_end?.getMonth() + 1]

          let primary_year = primary_date_start.getFullYear()
          let secondary_year = secondary_date_start.getFullYear()

          return {
              data1: `Real(${primary_month_start}-${primary_month_end}/${primary_year})`,
              data2: `${comparedLabel}(${secondary_month_start}-${secondary_month_end}/${secondary_year})`,
              data1_short: `${primary_month_start}-${primary_month_end}/${primary_year}`,
              data2_short: `${secondary_month_start}-${secondary_month_end}/${secondary_year}`
          }
      }else{
          let primary_date = endDate['analisado']
          let secondary_date = endDate[secondaryDataLabel]

          let primary_month = reverseMonthsDict[primary_date.getMonth() + 1]
          let secondary_month = reverseMonthsDict[secondary_date.getMonth() + 1]

          let primary_year = primary_date.getFullYear()
          let secondary_year = secondary_date.getFullYear()

          return {
              data1: `Real(${primary_month}/${primary_year})`,
              data2: `${comparedLabel}(${secondary_month}/${secondary_year})`,
              data1_short: `${primary_month}/${primary_year}`,
              data2_short: `${secondary_month}/${secondary_year}`
          }

          
      }  
    }

    let flowParameters = {
      
      "title": getTitle(),
      "open": true,
      "divisor": 1,
      ...props.borboletaParameters,
    }
    return <Container>
        {(parameters?.title || parameters?.observation) && (
          subHeader()
      )}
        <Content>
          {(parameters?.modality && parameters?.flow_chart_type) && <ChartWrapper>
            <FlowChart 
              {...flowParameters}
              popup={true}
              printing={props.printing}
            />
          </ChartWrapper>}
        </Content>
      </Container>

  }

  const dashboardWidget = () => {
    let lastMonth = lastMonthsFromYears?.[parameters?.year]
    return (
      <Container>
        {(parameters?.title || parameters?.observation) && (
          subHeader()
      )}
        <Content>
          <div class="bg-content with-header resumo-executivo-content" style={{boxShadow: 'none', maxWidth: '100%'}}>
            <h4 style={{color: '#2B8793', fontStyle: 'bold', paddingTop: 10, paddingLeft: 20, textTransform: 'uppercase'}}>
              <span>Ano {parameters.year} (janeiro - {lastMonth})</span>
            </h4>
            <div class="content padding-left-20 padding-right-10 padding-top-40 container">
              <div class="grid-row">
                {state.parameters?.formulas?.map((formula) => {
                  return <div class="grid-item" style={{flexBasis: '33%'}}>
                          <DashboardWidget {...(state?.dashboardData?.[formula.value] || {})}/>
                        </div>

                })}
              </div>
            </div>
          </div>
        </Content>
      </Container>
    );
  };

  const emptyPage = () => {
    return (
      <Container>
        {(parameters?.title || parameters?.observation) && (
          subHeader()
      )}
        <Content>
          <div class="bg-content with-header resumo-executivo-content">
            
          </div>
        </Content>
      </Container>
    );
  };

  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "flex-start",
        paddingTop: '6vh',
        display: 'flex',
        width: "100%",
      }}
    >
      <Wrapper id={'presentation_sheet'} className={'page_content'}>
        {kind == "title_text" && titleText()}
        {kind == "title_chart" && titleChart()}
        {kind == "formula_table" && tableChart()}
        {kind == "formula_table_balance" && tableBalanceChart()}
        {kind == "line_graph" && lineGraph()}
        {kind == "segmented_chart" && segmentedChart()}
        {kind == "dashboard_widget" && dashboardWidget()}
        {kind == "borboleta_chart" && borboletaChart()}
        {kind == "flow_chart" && flowChart()}
        {kind == "empty_page" && emptyPage()}
      </Wrapper>
    </div>
  );
};

export default PreVisualization;

const Wrapper = styled.div`
  height: 900px;
  max-height: 900px;
  min-height: 900px;
  width: 1600px;
  max-width: 1600px;
  min-width: 1600px;
  border: 0px solid #e8dede !important;
  overflow: hidden;
  .bg-content .content{
    overflow: visible
  }
  .widget-footer .padding-left-16{
    padding-left: 2px !important
  }
`;

const ChartWrapper = styled.div`
  background-color: transparent;
  width: 100%;
`
const monthDict = {
  janeiro: 1,
  fevereiro: 2,
  março: 3,
  abril: 4,
  maio: 5,
  junho: 6,
  julho: 7,
  agosto: 8,
  setembro: 9,
  outubro: 10,
  novembro: 11,
  dezembro: 12,
};

const reverseMonthsDict = {
  1: 'janeiro',
  2: 'fevereiro',
  3: 'março',
  4: 'abril',
  5: 'maio',
  6: 'junho',
  7: 'julho',
  8: 'agosto',
  9: 'setembro',
  10: 'outubro',
  11: 'novembro',
  12: 'dezembro'
}
