import React from 'react'

export function ChartIcon(props) {
  return (
    <svg viewBox="0 0 11 11" fill="none" {...props}>
      <g opacity={0.5} fill="currentColor">
        <path d="M3.019 2.458a.964.964 0 01.732-.944.985.985 0 011.202.708c0 .002.003.005.003.005-.01.134.082.185.188.236.302.15.601.306.9.461.047.026.075.026.117-.018a.974.974 0 011.25-.175c.042.026.065.02.101-.01a349.7 349.7 0 011.453-1.29c.046-.04.056-.076.03-.135-.19-.469.063-1.034.542-1.222A.991.991 0 0110.863.7c.155.51-.172 1.073-.696 1.2-.23.056-.452.038-.666-.059-.04-.017-.064-.012-.095.016-.508.454-1.02.907-1.53 1.359-.03.028-.041.05-.03.094a.963.963 0 01-.527 1.108c-.526.253-1.182-.016-1.354-.566-.026-.086-.034-.177-.052-.266a.115.115 0 00-.044-.069c-.333-.174-.668-.344-1.003-.517-.05-.025-.065 0-.088.028a.977.977 0 01-.81.383.916.916 0 01-.578-.218c-.044-.036-.07-.036-.113.002-.424.383-.85.766-1.275 1.147-.041.038-.039.073-.03.119.08.385-.027.712-.334.966-.523.443-1.351.2-1.558-.447a.962.962 0 01.66-1.222.965.965 0 01.788.107c.043.028.07.028.11-.008.45-.403.898-.806 1.35-1.204.046-.04.036-.086.033-.132 0-.025 0-.048-.002-.063zM10.278.969a.364.364 0 00-.727 0c0 .2.16.358.363.358a.359.359 0 00.364-.358zm-6.64 1.484c0 .197.165.36.363.357a.366.366 0 00.364-.36A.362.362 0 004 2.095a.353.353 0 00-.361.358zM6.89 3.905a.363.363 0 00.366-.35.362.362 0 00-.364-.365.359.359 0 00-.363.358.35.35 0 00.36.357zm-5.513.781a.352.352 0 00-.361-.357.359.359 0 00-.367.355c0 .198.163.357.364.36a.357.357 0 00.364-.358zM8.965 6.692V3.865c0-.17.075-.282.227-.33a.384.384 0 01.108-.012h1.277c.212 0 .34.126.34.337v1.552c0 1.361 0 2.725-.002 4.087a.47.47 0 01-.034.19.28.28 0 01-.268.175c-.449 0-.898.002-1.346 0-.176 0-.302-.137-.302-.322-.003-.383 0-.766 0-1.146V6.692zm1.33.002V4.222c0-.09 0-.09-.09-.09h-.528c-.093 0-.093 0-.093.088v4.949c0 .086 0 .086.09.086h.534c.085 0 .088 0 .088-.083V6.694z" />
        <path d="M4.958 7.306v2.236c0 .147-.072.259-.206.304-.039.015-.085.015-.126.015-.429 0-.857-.002-1.282.003a.319.319 0 01-.333-.33c.002-.606 0-1.212 0-1.818 0-.874 0-1.75.002-2.624a.48.48 0 01.037-.19.277.277 0 01.268-.165c.444-.002.89-.002 1.334 0 .175 0 .306.137.306.317v2.252zm-.619-.003V5.422c0-.059-.018-.076-.077-.074-.186.003-.374.003-.56 0-.057 0-.075.015-.075.071.003.226 0 .451 0 .677V9.17c0 .086 0 .089.09.089h.535c.085 0 .085 0 .085-.084.002-.623.002-1.247.002-1.871zM5.503 10.391h5.157c.139 0 .247.049.31.175.097.203-.05.431-.282.434-.253.002-.508 0-.761 0-3.189 0-6.374 0-9.563-.003a.48.48 0 01-.199-.04.294.294 0 01-.157-.338.306.306 0 01.307-.23H3.39c.707.002 1.411.002 2.113.002zM7.933 7.714v1.825c0 .158-.062.256-.196.31a.314.314 0 01-.119.017c-.436 0-.872.003-1.308 0-.188 0-.314-.124-.314-.309V5.863c0-.183.131-.31.317-.31h1.303c.193 0 .322.125.322.317-.005.614-.005 1.228-.005 1.844zm-1.324-.008V9.19c0 .051.013.071.068.071.188-.002.379-.002.567 0 .054 0 .07-.017.07-.068V6.228c0-.056-.02-.066-.072-.066-.184.003-.367 0-.547 0-.086 0-.086 0-.086.084v1.46zM.034 8.315V7.098c0-.203.129-.33.333-.33h1.282c.198 0 .33.122.33.317.003.821.003 1.643 0 2.465 0 .195-.126.314-.325.314-.43 0-.864-.003-1.295.002a.324.324 0 01-.328-.324c.005-.408.003-.82.003-1.227zm.619.002v.875c0 .048.01.068.064.066.191-.003.382-.003.573 0 .054 0 .072-.018.072-.069V7.442c0-.048-.015-.065-.067-.065-.19.002-.381.002-.572 0-.055 0-.07.015-.07.068v.872z" />
      </g>
    </svg>
  )
}