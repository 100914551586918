import React from 'react'

export function DownloadIcon(props) {
  return (
    <svg viewBox="0 0 18 18" fill="none" {...props}>
      <path
        d="M4.714 7.895A.721.721 0 115.74 6.88L8.28 9.422V1.094a.719.719 0 011.438 0v8.328L12.26 6.88a.72.72 0 011.025 1.015l-3.774 3.773a.737.737 0 01-1.024 0L4.714 7.895zm12.192 2.543a.719.719 0 00-.718.718v5.031H1.813v-5.03a.719.719 0 00-1.438 0v5.03a1.438 1.438 0 001.438 1.438h14.374a1.438 1.438 0 001.438-1.438v-5.03a.719.719 0 00-.719-.72z"
        fill="currentColor"
      />
    </svg>
  )
}