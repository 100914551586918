import React from "react";

function ChartListIcon(props) {
  return (
    <svg viewBox="0 0 19 11" fill="none" {...props}>
      <path
        d="M2 10l4.125-3.913 4.5 3.13L17 1"
        stroke="currentColor"
        strokeWidth={1.2}
        strokeLinecap="round"
      />
      <path
        d="M1.5 5l4.625-4L9.5 5.5l8 3.5"
        stroke="currentColor"
        strokeWidth={1.2}
        strokeLinecap="round"
      />
    </svg>
  );
}

const MemoChartListIcon = React.memo(ChartListIcon);
export default MemoChartListIcon;
