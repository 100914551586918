import React from "react";
import formula_tutorial from "formula_tutorial.jpeg";
import ChartFormulaMentionInput from "./ChartFormulaMentionInput";
import {
  WhiteBox,
  Title,
  TextInput,
  Select,
  Label,
  Line,
  TextArea,
  GreenButton,
  EntryBox,
  BlueButton,
  FilterLabel,
  FilterSection
} from "../styled_components/default.js";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormulasExistentesSearch from "./FormulasExistentesSearch";

class ChartFormula extends React.Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();

    let chart_formula = this.props.chart_formula || {};

    this.state = {
      data: [],
      hashtagData: [],
      pattern: chart_formula.pattern || "",
      label: chart_formula.label,
      position: chart_formula.position,
      scope: chart_formula.scope,
      is_total: chart_formula.is_total,
      cost_center_demonstration: chart_formula.cost_center_demonstration,
      chart_formula_type: chart_formula.chart_formula_type,
      contabil_pattern_reference_id: chart_formula.contabil_pattern_reference_id,
      contabil_patterns: [],
      customData: this.props.customData,
      showOperationWarning: false
    };
  }

  async save() {
    const { pattern, label, scope, contabil_pattern_reference_id, position, cost_center_demonstration, is_total } = this.state;
    const {chart_id} = this.props;
    let result = null;

    if (this.props.chart_formula.id) {
      result = await axios.put(`/charts/${chart_id}/chart_formulas/${this.props.chart_formula.id}.json`, {
        chart_formula: { pattern, label, scope, contabil_pattern_reference_id, position, cost_center_demonstration, is_total }
      });
    } else {
      result = await axios.post(`/charts/${chart_id}/chart_formulas.json`, {
        chart_formula: { pattern, label, scope, contabil_pattern_reference_id, position, cost_center_demonstration, is_total }
      });
    }

    if ([201, 200].includes(result.status)) {
      alert("Formula alterada com sucesso");
      let link = `/charts/${this.props.chart_id}`;
      Turbolinks.visit(link);
    } else {
      alert("Ocorreu algum erro");
    }
  }

  componentDidMount() {
    try {
      this.setState({
        hashtagData: this.props.formulas.map(([group, display, id, type]) => ({
          id,
          display,
          group
        }))
      });
    } catch (e) {
      console.log(e);
    }
  }

  addSelectedItemToFormula = (selectedItem, prefix) => {
    let newPattern = `${this.state.pattern} ${prefix}[${selectedItem.display}]($[${selectedItem.id}])`;
    this.setState({ pattern: newPattern });
  };

  handleButtonClick = (operation) => {
    let symbol;
    switch(operation) {
      case 'Soma':
        symbol = '+';
        break;
      case 'Subtração':
        symbol = '-';
        break;
      case 'Multiplicação':
        symbol = '*';
        break;
      case 'Divisão':
        symbol = '/';
        break;
      default:
        symbol = '';
    }
    const newPattern = this.state.pattern + ' ' + symbol + ' ';
    this.setState({ pattern: newPattern }, () => {
      this.checkOperationsChart(newPattern);
    });
  };

  addTextAndRowToFormula = (textToAdd, row) => {
    let newPattern;
    row = row ? `#[${row.display}]($[${row.id}])` : "";
    newPattern = row ? `${this.state.pattern}{${row}${textToAdd}}` : `${this.state.pattern}${textToAdd}`;
    this.setState({ pattern: newPattern });
  };

  checkOperationsChart = (patternChart) => {
    const operations = ['+', '-', '*', '/'];
    let totalCount = 0;

    let cleanedPattern = patternChart.replace(/#\[[^\]]+\]\(\$\[\d+\]\)/g, '');

    cleanedPattern = cleanedPattern.replace(/\{.*?\}/g, '');
    cleanedPattern = cleanedPattern.replace(/\(.*?\)/g, '');
    cleanedPattern = cleanedPattern.replace(/!\[[^\]]+\]/g, '');
    cleanedPattern = cleanedPattern.replace(/\s+/g, ' ').trim();

    operations.forEach(op => {
      const count = (cleanedPattern.match(new RegExp(`\\s*\\${op}\\s*`, 'g')) || []).length;
      totalCount += count;
    });

    this.setState({ showOperationWarning: totalCount === 2 });
  };

  closeWarning = () => {
    this.setState({ showOperationWarning: false });
  };

  render() {
    const { data, hashtagData, label, formula_type, scope, contabil_pattern_reference_id, position, customData, cost_center_demonstration, is_total } = this.state;
    const {titleData, contabil_patterns, costCenterAreaData} = this.props;
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <div style={{ display: 'flex', marginBottom: '10px' }}>
            <div className="bg-content flex-1 margin-right-30" style={{ flex: '0 0 30%', display: 'flex', flexDirection: 'column', height: '125px' }}>
              <div className={"input"}>
                <label>Nome da formula</label>
                <input
                    placeholder={"Nome"}
                    value={label}
                    type={"text"}
                    onChange={x => this.setState({ label: x.currentTarget.value })}
                />
              </div>
              <FormControlLabel
                  control={
                    <Switch
                        checked={is_total}
                        onChange={()=> this.setState({is_total: !this.state.is_total})}
                        color="primary"
                    />
                  }
                  label="Coluna de total ?"
              />
            </div>

            <div className="flex-1" style={{ display: 'flex', flexDirection: 'column', height: '600px' }}>
              <FormulasExistentesSearch
                  formulas={this.state.hashtagData}
                  onAddItem={item => this.addSelectedItemToFormula(item, '#')}
                  chartFormula={true}
                  onAddTextAndRowToFormula={this.addTextAndRowToFormula}
                  maxHeightChartFormula={'525px'}
              />
            </div>
          </div>

          {this.state.showOperationWarning && (
              <div style={{
                marginTop: '15px',
                backgroundColor: '#FFC700',
                padding: '15px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexDirection: 'row',
                height: '3px',
                marginBottom: '-15px'
              }}>
                <div style={{textAlign: 'center', flexGrow: 1}}>
                  Você adicionou duas operações: Verifique a necessidade de utilizar ”parênteses” para identificar as operações prioritárias.
                </div>
                <button
                    onClick={this.closeWarning}
                    style={{
                      backgroundColor: 'transparent',
                      color: 'black',
                      fontSize: '16px',
                      lineHeight: '1.5',
                      padding: '5px 10px',
                      border: 'none',
                      cursor: 'pointer',
                      marginRight: '10px'
                    }}
                >
                  X fechar
                </button>
              </div>
          )}

          <div style={{
            flex: 1,
            marginTop: '15px',
            backgroundColor: '#617E94',
            padding: '15px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column'
          }}>
            <div style={{ color: 'white', marginBottom: '8px' }}>
              Monte o Cálculo aqui:&nbsp;
              <span style={{ color: 'lightgray' }}>
                            Você pode clicar nas opções acima, ou apenas digitar o tipo de operação (#) e a operação (#) desejada.
                        </span>
            </div>

            <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
              <div style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
                width: '12.5%'
              }}>
                <button
                    onClick={() => this.handleButtonClick('Soma')}
                    style={{ flex: 1, backgroundColor: '#B6FDE8', height: '45px', border: 'none', cursor: 'pointer', borderRadius: '5px' }}
                    onMouseOver={e => e.currentTarget.style.backgroundColor = '#D1FFEB'}
                    onMouseOut={e => e.currentTarget.style.backgroundColor = '#B6FDE8'}
                >
                  <span style={{ color: 'white', fontSize: '40px' }}>+</span>
                </button>
                <button
                    onClick={() => this.handleButtonClick('Subtração')}
                    style={{ flex: 1, backgroundColor: '#FFC9D6', height: '45px', border: 'none', cursor: 'pointer', borderRadius: '5px' }}
                    onMouseOver={e => e.currentTarget.style.backgroundColor = '#FFDCE0'}
                    onMouseOut={e => e.currentTarget.style.backgroundColor = '#FFC9D6'}
                >
                  <span style={{ color: 'white', fontSize: '40px' }}>-</span>
                </button>
                <button
                    onClick={() => this.handleButtonClick('Multiplicação')}
                    style={{ flex: 1, backgroundColor: '#B7DCFD', height: '45px', border: 'none', cursor: 'pointer', borderRadius: '5px' }}
                    onMouseOver={e => e.currentTarget.style.backgroundColor = '#8F9FFF'}
                    onMouseOut={e => e.currentTarget.style.backgroundColor = '#B7DCFD'}
                >
                  <span style={{ color: 'white', fontSize: '40px' }}>×</span>
                </button>
                <button
                    onClick={() => this.handleButtonClick('Divisão')}
                    style={{ flex: 1, backgroundColor: '#FFD7A3', height: '45px', border: 'none', cursor: 'pointer', borderRadius: '5px' }}
                    onMouseOver={e => e.currentTarget.style.backgroundColor = '#FFEBBE'}
                    onMouseOut={e => e.currentTarget.style.backgroundColor = '#FFD7A3'}
                >
                  <span style={{ color: 'white', fontSize: '40px' }}>÷</span>
                </button>
              </div>

              <ChartFormulaMentionInput
                  inputRef={this.input}
                  titleData={titleData}
                  data={data}
                  customData={customData}
                  costCenterAreaData={costCenterAreaData}
                  hashtagData={hashtagData}
                  value={this.state.pattern}
                  onChange={(e, n, x, m) => {
                    this.setState({ pattern: n });
                    this.checkOperationsChart(n);
                  }}
              />
              <BlueButton onClick={() => this.save()} style={{ border: 'none', height: '45px' }}>
                Salvar Formula
              </BlueButton>
            </div>
          </div>
        </div>
    );
  }
}

export default ChartFormula;