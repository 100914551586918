import styled, { css } from "styled-components";
import expandImage from 'menu-expand-icon.png';
import retractImage from 'menu-retract-icon.png';

export const ExpandButton = styled.div`
  margin-top: 10px;
  width: 80%;
  height: 30px;
  border-radius: 5px;
  background-color: #ebebeb;
  background-position: center;
  background-repeat: no-repeat;
  margin: auto;
  cursor: pointer;
  ${props =>
    props.active ?
      `
      background-image: url(${expandImage});
    `: `
      background-image: url(${retractImage});
    `

  }
`

export const FlexCenter = styled.div`
  display: flex;
  align-items: center;
`;

export const WhiteBox = styled.div`
  padding: 36px;
  border-radius: 5px;
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.1);
  border: solid 0.5px #e5e5e5;
  background-color: #ffffff;
  margin: 7px;
  flex: 1;
`;

export const Title = styled.div`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  color: #31b7bc;
  text-transform: uppercase;
  margin-bottom: 37px;
`;

export const TextArea = styled.textarea`
  height: 40px;
  border-radius: 6px;
  background-color: #f8f8f8;
  width: 100%;
  border: solid 0.5px #acacac;
  margin-bottom: 17px;
`;
export const TextInput = styled.input`
  height: 40px;
  border-radius: 6px;
  background-color: #f8f8f8;
  width: 100%;
  border: solid 0.5px #acacac;
  margin-bottom: 17px;
`;

export const Select = styled.select`
  height: 40px;
  border-radius: 6px;
  background-color: #f8f8f8;
  width: 100%;
  border-radius: 6px;
  border: solid 0.5px #acacac;
  margin-bottom: 17px;
`;

export const Label = styled.label`
  width: 39px;
  height: 11px;
  font-family: Roboto;
  font-size: 9px;
  font-weight: 500;
  letter-spacing: 0.5px;
  color: #494949;
`;

export const Line = styled.div`
  width: 100%;
  height: 1px;
  background-color: #d2d2d2;
  margin: 29px auto;
`;

export const GreenButton = styled.button`
  width: 121px;
  height: 40px;
  border-radius: 6px;
  background-color: #03ab79;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

//Param layout items

export const EntryBox = styled.div`
  width: 411px;
  height: 47px;
  border-radius: 5px;
  background-color: #f5f5f5;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  font-family: Roboto;
`;

export const FilterSection = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
`;

export const FilterLabel = styled.a`
  width: 54px;
  height: 16px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.7px;
  color: #2a3170;
  cursor: pointer;
  margin-right: 30px;
  ${props =>
    props.active &&
    `
    color: #31b7bc;
  `}
`;

export const Operation = styled.div`
  width: 8px;
  height: 14px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.6px;
  color: #ed1c24;
  text-transform: uppercase;
  ${props =>
    props.operation == "debito" &&
    `
    color: #009c53;  
  `}
`;

export const BlueButton = styled.button`
  width: 167px;
  height: 40px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0.6px;
  color: #ffffff;
  border-radius: 6px;
  background-color: #2a3170;
  cursor: pointer;
  ${props =>
    props.invalidCheck &&
    `
      background-color: #6f6f6f;
    `

  }
`;

export const AddButton = styled.a`
  width: 19px;
  height: 19px;
  border-radius: 14px;
  background-color: #03ab79;
  color: white;
  font-size: 13px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding-right: 1px;
`;

export const GreenLabel = styled.label`
  width: 79px;
  height: 13px;
  font-family: Roboto;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 0.6px;
  color: #31b7bc;
`;

export const GrayLink = styled.a`
	font-family: Roboto; 
	font-size: 12px;
	color: gray;
`

export const BlueLink = styled.a`
  font-family: Roboto;
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
  letter-spacing: 0.05em;
  color: #2a3170;
  padding: 0 15px;
  cursor: pointer;
  display: flex;
  gap: 12px;
  height: 100%;
  align-items: center;

  svg {
    color: inherit;
  }

  ${props =>
    props.active &&
    css`
      color: #31b7bc;  
      border-bottom: 4px solid #31b7bc;
    `}

  ${props =>
    (props.active && props.bordered) && css`
        color: #31b7bc;
        background: #f9f9f9;
        display: flex;
        align-items: center;
        padding: 0 20px;
        height: 95%;
      `
  }
`;

export const ViewerButton = styled.button`
  width: 32px;
  height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  
  &:hover {
    filter: brightness(0.85);
  }

  ${(props) => props.active ? css`background-color: #617E94;` : css`background-color: #617E94;`}
`

export const ChartGrid = styled.div`
  display: grid;
  grid-template-columns: ${(props) => props.attachment ? css`repeat(2, 1fr)` : css`repeat(3, 1fr)`};
  border-radius: 8px;
  background-color: #f5f5f5;
  cursor: pointer;
  padding: 0;
  height: 40px;
`

export const ChartGridItem = styled.div`
  display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #617e94;
    width: 40px;
    height: 40px;

    ${(props) => props.hasChartStyle && css`
      background-color: #0099d8;
      color: #fff;
    `}

    &:first-child {
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
    }

    transition: background-color 0.2s ease-in, color 0.2s ease-in;

    &:hover {
      background-color: #0099d8;
      color: #fff;
    }

    svg {
      width: 18px;
      height: 18px;
    }
`

export const LoadAllYearsButton = styled.button`
  border-radius: 6px;
  border: 0;
  background-color: #2a3170;
  transition: filter 0.2s ease-in;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    filter: brightness(1.75);
  }
`

export const YearsButtons = styled(ChartGrid)`
  grid-template-columns: ${(props) => props.size && css`repeat(${props.size}, 1fr)`};
  margin-right: 15px;
`

export const YearButton = styled(ChartGridItem)`
  width: 80px;
`

export const BorboletaChartHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  a {
    height: 40px;
    width: 40px;
    background-color: #31b7bc;
    transition: filter 0.2s ease-in;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      color: #fff;
    }

    &:hover {
      filter: brightness(0.75);
    }
  }
`

export const BorboletaChartDownloadFooter = styled.a`
  height: 40px;
    width: 40px;
    background-color: #31b7bc;
    transition: filter 0.2s ease-in;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: center;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 10;

    svg {
      color: #fff;
    }

    &:hover {
      filter: brightness(0.75);
    }
`
