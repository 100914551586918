import * as React from "react";

function ChartTableIcon(props) {
  return (
    <svg viewBox="0 0 20 21" fill="none" {...props}>
      <path
        stroke="currentColor"
        strokeWidth={1.2}
        strokeLinecap="round"
        d="M4.619 10.4h1.466M4.619 13.066h1.466M4.619 15.733h1.466M9.119 10.4h1.466M9.119 13.066h1.466M9.119 15.733h1.466M13.619 10.4h1.466M13.619 13.066h1.466M13.619 15.733h1.466M1.267 6.4h17.467M5.4 1.4V.6M14.4 1.4V.6"
      />
      <rect
        x={1.267}
        y={2.267}
        width={17.467}
        height={17.467}
        rx={2.067}
        stroke="currentColor"
        strokeWidth={1.2}
      />
    </svg>
  );
}

const MemoChartTableIcon = React.memo(ChartTableIcon);
export default MemoChartTableIcon;
