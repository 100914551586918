import React from "react";

export function EyeOff(props) {
  return (
    <svg viewBox="0 0 40 40" fill="none" width="1em" height="1em" {...props}>
      <path
        d="M30.181 18.728l-.851.276c.193.303.355.628.522.948.183.342.21.716.021 1.053-.319.568-.616 1.157-1.019 1.653-1.66 2.029-3.698 3.468-6.174 4.135-.736.198-1.504.27-1.88.33-2.497-.027-4.404-.755-6.16-1.962a13.896 13.896 0 01-1.221-.943l-.951.315c.527.501 1.097.953 1.703 1.367 1.886 1.3 3.933 2.083 6.21 2.1.831.005 1.646-.11 2.45-.325 2.42-.65 4.446-2.001 6.18-3.859.627-.667 1.17-1.406 1.567-2.255.33-.71.355-1.41 0-2.105-.13-.243-.256-.49-.397-.728zM10.472 22.063l.852-.275c-.141-.249-.272-.508-.408-.767a1.04 1.04 0 010-1.025c.314-.557.612-1.141 1.009-1.632 1.551-1.912 3.458-3.285 5.746-4.024 2.732-.893 5.317-.402 7.772 1.059.601.358 1.17.755 1.703 1.201l.961-.314a13.234 13.234 0 00-4.074-2.563c-1.813-.728-3.678-.92-5.584-.513-2.236.48-4.169 1.599-5.871 3.17-.951.876-1.792 1.841-2.367 3.048-.329.695-.365 1.39-.02 2.084.088.182.182.37.281.551z"
        fill="#fff"
      />
      <path
        d="M23.276 20.988c-.23 1.477-1.474 2.624-2.915 2.602a2.893 2.893 0 01-2.08-.965l-.877.287c.69.948 1.776 1.56 2.988 1.56 2.022-.006 3.641-1.654 3.75-3.765l-.866.281zM17.56 19.737c.335-1.334 1.51-2.343 2.848-2.32a2.886 2.886 0 011.896.765l.914-.297c-.69-.827-1.698-1.35-2.815-1.35-1.938 0-3.521 1.52-3.746 3.5l.904-.298zM30.772 16.549L9 23.679l.228.774L31 17.323l-.228-.774z"
        fill="#fff"
      />
    </svg>
  );
}

export default EyeOff;
