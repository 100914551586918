

import React, { useEffect, useState } from 'react';
import LoadingDialog from '../LoadingDialog';

export default function Form(props) {
  const [loadingPage, setLoadingPage] = useState(false)

  const handleSubmit = (event) => {
    event.preventDefault(); // Prevenir o comportamento padrão
    setLoadingPage(true); // Mostrar o diálogo de carregamento

    // Obter os valores do formulário
    const year = document.getElementById('year').value;
    const month = document.getElementById('month').value;

    // Substitua 'URL_DO_SEU_ENDPOINT' pelo seu endpoint real
    axios.get('/', { params: {year, month}})
      .then(response => {
      })
      .catch(error => {
        
      })
      .finally(() => {
        Turbolinks.visit(`/?year=${year}&month=${month}`)
      });
  };
  
  return (
    <div>
      
       <form class="form flex" onSubmit={handleSubmit}>
        <div class="margin-right-20 home-select with-date-ico home">
            {}
            <select name="year" id="year">
                {props.years.map((year)=>{
                    return <option selected={parseFloat(props.current_year) == parseFloat(year)} value={year}>{year}</option>
                })}
            </select>
            </div>
            <div class="margin-right-20 home-select with-date-ico home">
            <select name="month" id="month">
                <option selected={"1" == props.current_month} value="1">janeiro</option>
                <option selected={"2" == props.current_month} value="2">fevereiro</option>
                <option selected={"3" == props.current_month} value="3">março</option>
                <option selected={"4" == props.current_month} value="4">abril</option>
                <option selected={"5" == props.current_month} value="5">maio</option>
                <option selected={"6" == props.current_month} value="6">junho</option>
                <option selected={"7" == props.current_month} value="7">julho</option>
                <option selected={"8" == props.current_month} value="8">agosto</option>
                <option selected={"9" == props.current_month} value="9">setembro</option>
                <option selected={"10" == props.current_month} value="10">outubro</option>
                <option selected={"11" == props.current_month} value="11">novembro</option>
                <option selected={"12" == props.current_month} value="12">dezembro</option>
                <option selected={"ytd" == props.current_month} value="ytd">acumulado</option>
            </select>
        </div>
        <button class="submit-button" type="submit">
        Filtrar
        </button>
       </form>
       <LoadingDialog open={loadingPage} />
    </div>
  );
}
