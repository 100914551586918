import React from "react"
import IntlCurrencyInput from "react-intl-currency-input"

const currencyConfig = {
  locale: "pt-BR",
  formats: {
    number: {
      BRL: {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      },
    },
  },
};
export const numberToCurrency = function(number) {
  return parseFloat(number).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL"
  });
};

const BrlCurrencyComponent = (props) => {
  const handleChange = (event, value, maskedValue) => {
    event.preventDefault();

    console.log(value); // value without mask (ex: 1234.56)
    console.log(maskedValue); // masked value (ex: R$1234,56)
  };
  /* convert
    'font: inherit;
    color: currentColor;
    width: 100%;
    border: 0;
    height: 1.1876em;
    margin: 0;
    display: block;
    padding: 6px 0 7px;
    min-width: 0;
    background: none;
    box-sizing: content-box;
    animation-name: mui-auto-fill-cancel;
    letter-spacing: inherit;
    animation-duration: 10ms;
    -webkit-tap-highlight-color: transparent;
    padding: 18.5px 14px;' to react style */
  return(
    <IntlCurrencyInput
        style={{
            font: "inherit",
            color: "currentColor",
            width: "100%",
            border: "0",
            height: "1.1876em",
            margin: "0",
            display: "block",
            minWidth: "0",
            background: "none",
            boxSizing: "content-box",
            animationName: "mui-auto-fill-cancel",
            letterSpacing: "inherit",
            animationDuration: "10ms",
            "-webkit-tap-highlight-color": "transparent",
            padding: "18.5px 3px",
            border: "1px solid",
            borderRadius: '5px'
        }}
    
        currency="BRL" config={currencyConfig}
            onChange={handleChange} {...props} />
  );
}

export default BrlCurrencyComponent;