import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';


import Typography from '@material-ui/core/Typography';
import { blue } from '@material-ui/core/colors';

const emails = ['username@gmail.com', 'user02@gmail.com'];
const useStyles = makeStyles({
  avatar: {
    backgroundColor: blue[100],
    color: blue[600],
  },
});

export default function SimpleDialog(props) {
  const classes = useStyles();
  const { onClose, selectedValue, open, balanceSheet } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = value => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">Diferenças entre arquivos</DialogTitle>
      <div style={{display: "flex", maxHeight: "80vh"}}>
        <div style={{overflow: "scroll"}}>
          <DialogTitle id="simple-dialog-title">Mês anterior</DialogTitle>
          <List>
          {balanceSheet.before_difference && balanceSheet.before_difference.map(code => (
            <ListItem button  key={code}>
              <ListItemText primary={code} />
            </ListItem>
          ))}          
          </List>
        </div>  

        <div style={{overflow: "scroll"}}>
          <DialogTitle id="simple-dialog-title">Mês posterior</DialogTitle>
          <List>
            {balanceSheet.after_difference && balanceSheet.after_difference.map(code => (
              <ListItem button  key={code}>
                <ListItemText primary={code} />
              </ListItem>
            ))}
          </List>
        </div>  
      </div>
      
    </Dialog>
  );
}