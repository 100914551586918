import styled from "styled-components";

export const PopUpContainer = styled.div`
  position: absolute;
  right: 1rem;
  bottom: 5rem;
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;
`



export const PopUpButton = styled.button`
  width: 5.75rem;
  height: 5.75rem;
  border-radius: 50%;
  background-color: none;
  border: none;
  outline: none;
  z-index: 10;

  display: flex;
  align-items: center;
  justify-content: center;

  transform: ${((props) => props.show ? 'translateX(0)' : 'translateX(30rem)')};

  transition: transform 0.4s ease-in-out;
`

export const PopUpContent = styled.div`
  width: 33rem;
  max-height: 11.5rem;
  padding: 1.875rem 1.3125rem 1.5625rem 4.1875rem;
  background-color: #187581;
  margin-left: -3rem;

  color: white;

  transform: ${(props) => props.show ? 'scale(1)' : 'scale(0)'};

  transition: transform 0.65s ease-in-out;
`

