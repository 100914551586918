import React from "react";
import LoadingDialog from './../LoadingDialog'

import PopHover from './../PopHover'
import axios from '../utils/axiosConfig'
// import moment
import moment from 'moment';
const SCOPES = ["analisado", "budget", "comparado"];
import './detalhe_borboleta.css';
import BorboletaChart from "../BorboletaChart";
let pendingRequests = 0;
import Grid from '@material-ui/core/Grid';
// Intercept XMLHttpRequest
const originalOpen = XMLHttpRequest.prototype.open;
XMLHttpRequest.prototype.open = function() {
  this.addEventListener('loadstart', function() {
    pendingRequests++;
  });
  this.addEventListener('loadend', function() {
    pendingRequests--;
  });
  originalOpen.apply(this, arguments);
};



class DreDetail extends React.Component {

  constructor(props) {
    super(props)
    let months = {}
    
    Object.entries(props.months).map(([key, value]) => {
      if (Object.keys(props.visible_months?.[props.defaultYear] || {}).includes(key)) {
        months[key] = value
      }
      if (props.is_budget) {
        months[key] = value
      }
    })

    this.loading = 0
    this.state = {
      comparedLabel: "comparado",
      alreadyFetched: {},
      chart_formulas: {},
      chartFormulas: [],
      formulasTotalByYear: {},
      formulasTotalByYearYTD: {},
      areasDict: {},
      allYearsData: {},
      showZeroLines: false,
      formulas: [],
      report: [],
      labels: [],
      formulaOpened: {},
      years: [],
      groupedReport: {},
      chartData: {},
      year: this.props.defaultYear,
      recordsFetched: {},
      cost_center_mode: false,
      costCentersFetched: {},
      costCentersFetchedByYear: {},
      costCenterAreas: [],
      despesasType: {},
      costCenterRecordsGrouped: {},
      activeAreaType: {},
      activeKeys: {},
      formulasTotal: {},
      loading: 0,
      visibleMonths: props.visible_months[props.defaultYear] || {
        janeiro: true,
        fevereiro: true,
        março: true,
        abril: true,
        maio: true,
        junho: true,
        julho: true,
        agosto: true,
        setembro: true,
        outubro: true,
        novembro: true,
        dezembro: true,
      },
      months: months


    }
  }

  tryEval(str) {
    try {
      let formulaSolved = eval(str)
      return formulaSolved ? formulaSolved : 0;
    } catch (e) {
      return 0
    }
  }

  setupChartData() {
    let data = {}
    let types = ["analisado", "budget", "comparado"]
    let self = this;

    types.map((type) => {
        let scopeDate = this.props[`${type}_date`]
        Object.entries(this.state.allYearsData[scopeDate.year]?.[type]?.report).map(([form_id, value]) => {
            let month = monthDictComplete[scopeDate.month]
            let formula_id = this.state.formulas[form_id].fr_id
            try {
                data[`[\"${formula_id}\", \"${type}\"]`] = eval(Object.values(value)[0].result[month]) * -1
            } catch (e) {

            }
        })


    })

    this.setState({ chartData: data })
}

  async setComments(year) {

    let comments = await axios.get('/comments.json', {
      params: {
        year
      }
    })


    this.setState({ comments: comments.data })
  }

  async setYears() {
    const { year } = this.state;
    let years = {}
    if (this.props.is_budget) {
      years = await axios.get('/budgets/years.json')
    } else {
      years = await axios.get('/balance_sheets/years.json')
    }

    this.setState({ years: years.data, year: (year || years.data[years.data.length - 1]) })
  }

  async fetchCostCenterData(year = null, scopeLabel) {
    let is_budget = scopeLabel == "budget"
    this.loading += 1
    let result = await axios.get('/results/cost_centers.json', {
      params: {
        year,
        is_budget
      }
    })

    const { data } = result

    this.loading -= 1
    this.setState({
      [`costCenterRecordsGrouped-${scopeLabel}`]: data
    })
  }

  setupInitialDates() {
    let { month, year } = this.props.analisado_date

    let lastDate = moment(`${month}-01-${year}`, "MM-DD-YYYY").toDate()
    let lastDateYearBefore = moment(`${month}-01-${year - 1}`, "MM-DD-YYYY").toDate()


    this.setState({ analisadoDate: lastDate })
    this.setState({ comparadoDate: lastDateYearBefore })
    this.setState({ budgetDate: lastDate })

    this.fetchReport(lastDate, null, "analisado")
    this.fetchReport(lastDateYearBefore, null, "comparado")
    this.fetchReport(lastDate, true, "budget")

    
}

  async fetchReport(date, is_budget = null, entity = null, updatePairs = true) {
    let yearAgoDate = new Date(date)
    let currentYear = date.getFullYear()
    yearAgoDate.setFullYear(currentYear - 1)
    let newstate = {}
    newstate[entity + "Date"] = date

    if (entity == "analisado" && updatePairs) {
        newstate['budgetDate'] = date
        newstate['comparadoDate'] = yearAgoDate
    }

    this.setState(newstate)
    let report = {}
    
    this.requestsCounter += 1
    this.forceUpdate();
    
    report = await axios.get('/formulas/borboleta.json', {
        params: {
            date: date,
            is_budget
        }
    })
    this.setState({
        reportsByDate: {
            ...this.state.reportsByDate,
            ...{ [`${date}-${is_budget}`]: report }
        }
    })
    this.requestsCounter -= 1
    setTimeout(()=>{
        this.alreadLoadedDates[`${date}-${is_budget}`] = true
    }, 100)
    


    let response = this.state.groupedReport
    response[entity] = report.data


    this.setState({ groupedReport: response })
    this.setComments();
    if (entity == "analisado") {

        this.fetchReport(newstate['budgetDate'], true, 'budget')
        this.fetchReport(newstate['comparadoDate'], null, 'comparado')
    }
    if (entity != 'comparado') {
        this.fetchData(date.getFullYear(), entity, is_budget)
    }

}

  async fetchData(year = null, label,loadUniqueData = true, formulaIds=false) {
    const { costCenterMode, is_budget } = this.props;
    this.loading += 1
    let self = this;
    // if(this.state?.allYearsData?.[year]?.[label] && !formulaIds){
    //   this.loading -= 1
    //   return false
    // }
    if(formulaIds){
      this.setState((oldState)=>{
        oldState.chartFormulas.push(formulaIds)
        oldState.chartFormulas = [... new Set(oldState.chartFormulas)]
        return {...oldState}
      })
    }
    

    let url =  `/results/${this.props.scope}.json` 
    let scopeDate = this.props[`${label}_date`]
    let result = await axios.get(url, {
      params: {
        range: scopeDate?.month == "total" ? [1, this.props.month] : null,
        year,
        is_budget: (label == "budget") || is_budget
      }
    })
    
    let { formulas, report, labels, areas, cost_center_grouped_by_month } = result.data
    let formulaParams = {}
    if(!formulaIds){
      formulaParams = {
        formulas
      }
    }
    
    if (loadUniqueData) {
      this.setState({
        ...formulaParams,
        report,
        labels,
        costCenterAreas: areas,
        recordsFetched: {},
        costCenterGroupedByMonth: cost_center_grouped_by_month
      })
    }

	  this.loading -= 1
    
    this.setState((oldState) => {
      oldState.allYearsData[year] = {
        ...oldState.allYearsData[year], 
        [label]: {
          ...formulaParams,
          report,
          labels,
          costCenterAreas: areas,
          recordsFetched: {},
          costCenterGroupedByMonth: cost_center_grouped_by_month
        }
      }
      return { ...oldState }
    })

    setTimeout(()=>{
      self.setupChartData();
      var contentElement = document.querySelector('.bg-content .content');
      if (contentElement) {
          contentElement.scrollLeft = contentElement.scrollWidth;
      }
    }, 200)

  }

  async componentDidMount() {
    let {start_date, analisado_date, comparado_date, budget_date} = this.props;
    let scopes  = ["analisado", "comparado", "budget"];
    this.setComments(this.state.year);
    this.fetchGraphFormulas()
    
    this.fetchData(analisado_date.year)
    this.setupInitialDates()
    scopes.map((label) => {
      this.fetchData(this.props[`${label}_date`].year, label, false)
      this.fetchCostCenterData(this.props[`${label}_date`].year, label)
    })
    
    this.fetchAreas()
    
  }

  

  async fetchAreaData(key, fixed, type_id, type_label, scopeLabel) {
    const { costCentersFetched } = this.state;
    const { cost_center_heads, is_budget } = this.props;
    let fixed_label = Boolean(fixed) ? "fixed" : "not_fixed"

    let year = this.props[`${scopeLabel}_date`].year 

    Object.entries(cost_center_heads).map(([id, label]) => {
      if (!costCentersFetched[label]) {
        costCentersFetched[label] = {}
      }
    })

    if (costCentersFetched[type_label][key] && costCentersFetched[type_label][key][fixed_label]) {
      this.setState((oldState) => {
        oldState.costCentersFetched[type_label][key][fixed_label] = null
        return { ...oldState }
      })
    } else {
      let details = await axios.get(`/results/${key}/cost_center_detail.json`, {
        params: {
          year: year,
          fixed: Boolean(fixed),
          type: type_id,
          is_budget: scopeLabel == "budget",
        }
      })

      this.setState((oldState) => {
        if (!oldState.costCentersFetched[type_label][key]) { oldState.costCentersFetched[type_label][key] = {} }

        oldState.costCentersFetched[type_label][key][Object.keys(details.data)[0]] = Object.values(details.data)[0]
        return { ...oldState }
      })
      this.setState((oldState) => {
        oldState.costCentersFetchedByYear[year] ||= {}
        oldState.costCentersFetchedByYear[year][scopeLabel] ||= {}
        oldState.costCentersFetchedByYear[year][scopeLabel][type_label] ||= {}
        oldState.costCentersFetchedByYear[year][scopeLabel][type_label][key] ||= {}

        oldState.costCentersFetchedByYear[year][scopeLabel][type_label][key][Object.keys(details.data)[0]] = Object.values(details.data)[0]
        return { ...oldState }
      })
    }

  }

  async toggleAreaType(key, fixed, type_id, type_label) {

    this.setState((oldState) => {
      oldState.activeAreaType[`${key},${fixed}`] = oldState.activeAreaType[`${key},${fixed}`] ? oldState.activeAreaType[`${key},${fixed}`] : {}
      oldState.activeAreaType[`${key},${fixed}`][`${type_id}-${type_label}`] = !oldState.activeAreaType[`${key},${fixed}`][`${type_id}-${type_label}`]
      return { ...oldState }
    })

  }

  formulaIsGrouped(key) {
    const { formulas } = this.state

    return formulas?.[key]?.formula_type == "grouped"

  }

  setupAllYearsData() {
    let { years } = this.state;
    years.map((year) => {
      this.loadYear(year, false)
    })
  }

  loadYear(year, loadUniqueData = false, formulaIds=false) {
    ["analisado", "budget"].map((label) =>{ 
		  this.fetchData(year, label,loadUniqueData, formulaIds)
    	
	  })
  }

  defineDefaultVisibleMonths(year) {
    let months = {}
    Object.entries(this.props.months).map(([key, value]) => {
      if (Object.keys(this.props.visible_months[year]).includes(key)) {
        months[key] = value
      }
    })

    this.setState({ months, visibleMonths: this.props.visible_months[year] });
  }

  changeYear(year) {
    this.setState({ year })
    this.props.changeYear(year)
    this.fetchData(year)
    this.fetchCostCenterData(year)
    this.setComments(year)
    this.setState({
      costCentersFetched: {},
      despesasType: {}
    })
    
    this.defineDefaultVisibleMonths(year)
  }

  renderLine(line, options = {}, level = 1) {

    const { months, showZeroLines, formulas } = this.state;
    console.log("ROW_PATH", options.rowPath);
    let linesToInt = []
    let totalLine = 0
    let valueExists = false
    Object.entries(this.props.months).map(([name, number]) => {
      if (parseFloat(line.months[name]).toString() != "NaN") {
        if (parseFloat(line.months[name]) != 0) {
          valueExists = true
        }
        linesToInt.push(parseInt(line.months[name]) || 0)
        totalLine += parseFloat(line.months[name]) || 0
      }
    })
    // remove all values equal 0 from linestoInt
    // linesToInt = linesToInt.filter((value)=>{
    //   return value != 0
    // })
    function findReferenceByPath(recordsFetched, scope, path) {
      // Começa do escopo especificado
      let currentRef = recordsFetched[path[0]][scope];
    
      // Itera pelos elementos do caminho, começando do segundo elemento
      for (let i = 1; i < path.length; i++) {
        const key = path[i];
        // Verifica se existe "childreen" e se a chave existe dentro dele
        if (currentRef?.childreen && currentRef?.childreen?.[key]) {
          currentRef = currentRef.childreen[key];
        } else if (currentRef?.[key]) {
          // Se não for "childreen", apenas atualiza a referência
          currentRef = currentRef?.[key];
        } else {
          // Retorna null se o caminho não for encontrado
          return null;
        }
      }
      return currentRef;
    }
    let lineIsZero = true
    {SCOPES.map((scopeLabel)=>{
      let scopeDate = this.props[`${scopeLabel}_date`]
      
      let reference = findReferenceByPath(this.state.recordsFetched, scopeLabel, options.rowPath)
      let scopeValue = this.state.recordsFetched?.[options.p_key]?.[scopeLabel]?.[options.line_key]?.months?.[monthDictComplete[scopeDate.month]]
      if(scopeDate.month == "total"){
        scopeValue = Object.values(this.state.recordsFetched?.[options.p_key]?.[scopeLabel]?.[options.line_key]?.months || {}).reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
      }
      if(!options.rowPath.includes(options.line_key)){
          scopeValue = reference?.lines?.[options.line_key]?.months?.[monthDictComplete[scopeDate.month]]
          if(scopeDate.month == "total"){
          scopeValue = Object.values(reference?.lines?.[options.line_key]?.months || {}).reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
        }
      }
      if((scopeValue > 0) || (scopeValue < 0)){
        lineIsZero = false
      }
      
      
    })}

    return !lineIsZero && <tr className={"account-line"}>
      <td>
        <div style={{ paddingLeft: level * 10 }}>{line.label}</div>
      </td>
      {SCOPES.map((scopeLabel)=>{
          let scopeDate = this.props[`${scopeLabel}_date`]
          
          let reference = findReferenceByPath(this.state.recordsFetched, scopeLabel, options.rowPath)
          let scopeValue = this.state.recordsFetched?.[options.p_key]?.[scopeLabel]?.[options.line_key]?.months?.[monthDictComplete[scopeDate.month]]
          if(scopeDate.month == "total"){
            scopeValue = 0
            
            Object.keys(this.props.visible_months[this.props.year]).map((monthName)=>{
              let monthValue = parseFloat(this.state.recordsFetched?.[options.p_key]?.[scopeLabel]?.[options.line_key]?.months?.[monthName])
              if(monthValue){
                scopeValue += monthValue
              }
            })
            
          }
          if(!options.rowPath.includes(options.line_key)){
              scopeValue = reference?.lines?.[options.line_key]?.months?.[monthDictComplete[scopeDate.month]]
              if(scopeDate.month == "total"){
                scopeValue = 0
                Object.keys(this.props.visible_months[this.props.year]).map((monthName)=>{
                  scopeValue += reference?.lines?.[options.line_key]?.months?.[monthName]
                })
              
            }
          }
          
          
          return <td>
            <div className={''}>
              {
                this.numberToCurrency(scopeValue)
              }
            </div>
          </td>
        })}
        
       

    </tr>
  }


  costCenterGroupedBySpendType(despesa_id, fixed, despesa_type_label, month, scopeLabel) {
    const { months } = this.props;
    const { costCentersFetchedByYear } = this.state;

    let fixed_label = "not_fixed"
    if (fixed) {
      fixed_label = "fixed"
    }

    let rows = null;
    let result = 0
    try {
      let year = this.props[`${scopeLabel}_date`].year

      rows = costCentersFetchedByYear[year][scopeLabel][despesa_type_label][despesa_id][fixed_label]

      
      Object.values(rows).map((despesa) => {
        if (String(parseFloat(despesa.months[month])) != "NaN") {
          result += parseFloat(despesa.months[month])
        }

      })

    } catch (e) {

    }



    return result
  }

  renderCostCenterRows(despesa_id, fixed, despesa_type_label, despesa_type_id = null) {
    const { months, showZeroLines } = this.state;
    const { costCentersFetchedByYear } = this.state;
    const { cost_center_heads, is_budget } = this.props;
    let numberToCurrency = function (number = 0) {
      if (String(parseFloat(number)) == "NaN" || parseFloat(number) == undefined) {
        number = 0
      }

      return parseFloat(number * -1).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });
    };

    let fixed_label = "not_fixed"
    if (fixed) {
      fixed_label = "fixed"
    }
    
    let rows = {}
    SCOPES.map((scopeLabel)=>{
      let year = this.props[`${scopeLabel}_date`].year;
      rows = {...costCentersFetchedByYear?.[year]?.[scopeLabel]?.[despesa_type_label]?.[despesa_id]?.[fixed_label], ...rows};
    })

 

    


    return <React.Fragment>
      {rows && Object.entries(rows)?.sort(([keyA], [keyB]) => keyA.localeCompare(keyB))?.map(([key, data]) => {
        let lineIsZero = true;

        {SCOPES.map((scopeLabel)=>{
          let scopeDate = this.props[`${scopeLabel}_date`]
          rows = costCentersFetchedByYear?.[scopeDate.year]?.[scopeLabel]?.[despesa_type_label]?.[despesa_id]?.[fixed_label]
    
          let costCenterAreaValue = rows?.[key]?.months?.[scopeDate?.month]
          if (scopeDate?.month == "total"){
            costCenterAreaValue = Object.values(rows?.[key]?.months || {}).reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
          }
          if((costCenterAreaValue > 0) || (costCenterAreaValue < 0)){
            lineIsZero = false
          }
        })}

        return !lineIsZero && <tr className={'cost-center-row'}>
          <td style={{ textIndent: 32 }}>{key}-{data.label}</td>
          {SCOPES.map((scopeLabel)=>{
              let scopeDate = this.props[`${scopeLabel}_date`]
              rows = costCentersFetchedByYear?.[scopeDate.year]?.[scopeLabel]?.[despesa_type_label]?.[despesa_id]?.[fixed_label]

              let costCenterAreaValue = rows?.[key]?.months?.[scopeDate?.month]
              if (scopeDate?.month == "total"){
                costCenterAreaValue = Object.values(rows?.[key]?.months || {}).reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
              }
              
              return <td className={''}>
                
                <p>{numberToCurrency(costCenterAreaValue)}</p>
              </td>
          })}
          
          
          
        </tr>
      })}

    </React.Fragment>

  }
  numberToCurrency(number = 0) {
    if (String(parseFloat(number)) == "NaN" || parseFloat(number) == undefined) {
      number = 0
    }

    return parseFloat(number * -1).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL"
    });
  }

  renderRow(row, options = {}, level = 1, key = null) {
    let rowPath = options.rowPath;
    console.log("ROW_ROW_PATH", rowPath)
    let childrenLines = (entry, month_name) => {
      try {
        let initial = Object.values(entry.lines).map((x) => x.months[month_name]).filter((a) => parseFloat(a).toString() != "NaN").reduce((a, b) => parseFloat(a) + parseFloat(b), 0)

        if (Object.values(entry.childreen).length > 0) {
          Object.values(entry.childreen).map((child) => {
            initial += childrenLines(child, month_name)
          })
        }

        return initial
      }
      catch (e) {
        console.log("ERRO AQUI", e)
        return 0
      }

    }

    const { months } = this.state;
    const { costCentersFetched, activeKeys, formulas } = this.state;
    function findReferenceByPath(recordsFetched, scope, path) {
      // Começa do escopo especificado
      try{
        let currentRef = recordsFetched[path[0]][scope];
    
        // Itera pelos elementos do caminho, começando do segundo elemento
        for (let i = 1; i < path.length; i++) {
          const key = path[i];
          // Verifica se existe "childreen" e se a chave existe dentro dele
          if (currentRef.childreen && currentRef.childreen[key]) {
            currentRef = currentRef.childreen[key];
          } else if (currentRef[key]) {
            // Se não for "childreen", apenas atualiza a referência
            currentRef = currentRef[key];
          } else {
            // Retorna null se o caminho não for encontrado
            return null;
          }
        }
        return currentRef;
      }catch(e){
        return {}
      }
      
    }

    let line_keys = []

    return <React.Fragment>
      {!row.months ?
        <tr className="account-number-row">
           <td >
            <div
              onClick={() => {

                this.setState((oldState) => {
                  if (row.label) {

                    oldState.activeKeys[key] = oldState.activeKeys[key] ? oldState.activeKeys[key] : {}
                    oldState.activeKeys[key][row.label] = oldState.activeKeys[key][row.label] ? null : {}
                  } else {
                    oldState.activeKeys[key] = oldState.activeKeys[key] ? null : {}
                  }

                  return { ...oldState }
                })
              }}
              style={{ paddingLeft: (level + 1) * 10 }}>
              <i className={`pull-left fa fa-chevron-${activeKeys[key]?.[row.label] ? 'down' : 'right'}`} />
              {row.label}
            </div>
          </td>
          {SCOPES.map((scopeLabel)=>{
            let scopeDate = this.props[`${scopeLabel}_date`]
            let reference = findReferenceByPath(this.state.recordsFetched, scopeLabel, options.rowPath)
            let scopeValue = reference.sum_of_lines?.[monthDictComplete[scopeDate.month]]


            if(scopeDate.month == "total"){
              scopeValue = 0
              Object.keys(this.props.visible_months[this.props.year]).map((monthName)=>{
                scopeValue += reference?.sum_of_lines?.[monthName]
              })
              
            }
            
            
            return <td>
              <div className={'row-value'}>

                {
                  this.numberToCurrency(scopeValue)
                }
              </div>
            </td>
          })}
          
          
          
        </tr>
        :
        this.renderLine(row, options, level + 1)
      }


      {activeKeys[key]?.[row.label] && row.childreen && Object.entries(row.childreen)?.sort(([keyA], [keyB]) => keyA.localeCompare(keyB))?.map(([child_key, child]) => {
        let currentRowPath = [...rowPath, child_key]
        return this.renderRow(child, { ...options, line_key: child_key, rowPath: currentRowPath }, level + 1)
      })}
      {(activeKeys[key]?.[row.label]) && row.lines && Object.keys(row.childreen).length < 1 && Object.entries(row.lines).map(([line_key, line]) => {

        return this.renderLine(line, { ...options, line_key, rowPath }, level + 2)
      })}
    </React.Fragment>
  }

  renderChildreenFor(key = null, options) {
    let data = {}
    SCOPES.map((scopeLabel)=>{
      data = {...this.state.recordsFetched?.[key]?.[scopeLabel], ...data};
    })

    
    
    if (data) {
      return (<React.Fragment>
        {Object.entries(data)?.sort(([keyA], [keyB]) => keyA.localeCompare(keyB))?.map(([row_id, row]) => {
          let rowPath = [key]
          rowPath = [...rowPath, row_id]
          return this.renderRow(row, { ...options, line_key: row_id, rowPath }, 0, key)
        })}
      </React.Fragment>)
    }
  }

  async fetchDetail(key) {
    const {recordsFetched } = this.state;
    const { cost_center_heads, is_budget, start_date } = this.props;
    const scopes = ['analisado', 'comparado', 'budget']; // Define os escopos
    let fetchedByScope = {}

    if(this.state.recordsFetched[key]){
      this.setState({
        recordsFetched: {
          ...this.state.recordsFetched,
          [key]: null
        }
      })
      return
    }
    // Itera sobre cada escopo e executa a requisição para o ano correspondente
    for (const scope of scopes) {
      const year = this.props[`${scope}_date`]?.year; // Assume que this.props[`${scope}_date`] contém o ano correto
      const scopedKey = `${scope}_${key}`; // Estrutura da chave para incluir escopo e key
  
      if (!fetchedByScope[scopedKey]) {
        fetchedByScope[scopedKey] = true;
        try {
          let details = await axios.get(`/results/${key}/detail.json`, {
            params: {
              year: year,
              cost_center_mode: this.state.cost_center_mode && cost_center_heads[key],
              is_budget: scope === 'budget', // Define is_budget baseado no escopo
            }
          });
  
          // Atualiza o state com os novos dados, organizados por escopo
          this.setState((oldState) => {
            // Inicializa o objeto do escopo se ainda não existir
            if (!oldState.recordsFetched[key]) {
              oldState.recordsFetched[key] = {};
            }
  
            if (Object.keys(Object.values(details.data || {})[0].childreen || {}).length > 0) {
              oldState.recordsFetched[key][scope] = Object.values(details.data)[0].childreen;
            } else {
              oldState.recordsFetched[key][scope] = Object.values(details.data)[0].lines;
            }
  
            return { ...oldState };
          });
        } catch (error) {
          console.error("Erro ao buscar detalhes:", error);
        }
      }
    }
  }
  

  fetchYTD(year, label, formulaIds){

    if(this.state.formulasTotalByYearYTD?.[year]?.[label] && !formulaIds){
      return false
    }
    axios.get('/formulas/borboleta.json', {
      params: {
        range: {
          start_at: (new Date(`01/01/${year}`)),
          end_at: (new Date(`${this.props.month}/12/${year}`))
        },
        scope: this.props.scope,
		    is_budget: label == "budget" ? true : null,
        formula_ids: this.state.chartFormulas
      }
    }).then((report)=>{
      this.setState((oldState) => {
        oldState.formulasTotalByYearYTD[year] = {
          ...oldState.formulasTotalByYearYTD[year],
          [label]: report.data
        }
        return { ...oldState }
      })
    })

  }

  paramLabel(value, param) {
    let label;
    if (param == "difference") {
      label = <label title={"diferença"} style={{ color: "#232D6A" }}>dif</label>
    }
    if (param == "f_saldo_atual") {
      label = <label title={"saldo atual"} style={{ color: "#3FABAE" }}>sat</label>
    }
    if (param == "f_saldo_anterior") {
      label = <label title={"saldo anterior"} style={{ color: "#EFBF6A" }}>san</label>
    }

    // return <div>{value} {label}</div>
    return <div>{value}</div>
  }

  toggleDespesasType(key) {
    let { cost_center_heads } = this.props;
    let [area_id, is_fixed] = key

    Object.entries(cost_center_heads).map(([despesa_type_id, label]) => {
      SCOPES.map((scopeLabel)=>{
        this.fetchAreaData(area_id, is_fixed, despesa_type_id, label, scopeLabel)
      })
      
    })

    this.setState((oldState) => {
      oldState.despesasType[key] = !oldState.despesasType[key]
      if (!oldState.despesasType[key]) {
        this.state.activeAreaType[`${key}`] = {}
      }

      return { ...oldState }
    })
  }


  renderCostCenterAreas(number_format = "money") {
    const { months, cost_center_heads } = this.props;
    const { costCentersFetched, costCenterAreas, despesasType, costCenterGroupedByMonth } = this.state;

    let areaByMonth = (b_fixed, despesa_id, number) => {

      try {
        let value = costCenterGroupedByMonth[b_fixed ? "fixed" : "not_fixed"][`[${despesa_id}, ${number}]`]

        return value

      } catch (e) {

      }

    }


    let lines = Object.entries(costCenterAreas).map(([key, value]) => {
      const [fixed, despesa_id] = JSON.parse(key);
      let b_fixed = Boolean(fixed)
      //this.fetchAreaData(id, fixed)
      return <React.Fragment><tr>
        <td ><div onClick={() => this.toggleDespesasType([despesa_id, b_fixed])} >
          <i className={`pull-left fa fa-chevron-${despesasType[[despesa_id, b_fixed]] ? 'down' : 'right'}`} />
          {value.label}</div></td>
        {Object.entries(this.props.months).map(([name, number]) => {

          return (
            <td className={'comment-wrapper'}>

              {areaByMonth(b_fixed, despesa_id, number)}
            </td>
          );
        })}
        <td>

        </td>
      </tr>
        {despesasType[[despesa_id, b_fixed]] && Object.entries(cost_center_heads).map(([despesa_type_id, label]) => {
          let exist = false
          try {
            exist = !!costCentersFetched[label][despesa_id][b_fixed ? "fixed" : "not_fixed"]

          } catch (e) {

          }

          return <React.Fragment>
            <tr>
              <td style={{ textIndent: 16 }} onClick={() => this.fetchAreaData(despesa_id, b_fixed, despesa_type_id, label)} >
                <i className={`pull-left fa fa-chevron-${exist ? 'down' : 'right'}`} />
                {label}
              </td>
            </tr>
            {this.renderCostCenterRows(despesa_id, b_fixed, label)}
          </React.Fragment>

        })}
      </React.Fragment>

    })

    return <React.Fragment>{lines}</React.Fragment>

  }

  percentFormat(number) {
    return `${parseFloat(number).toFixed(2)}%`
  }

  renderCostCenterParams(report, formulaId, cc_format = "money") {

    let { despesasType } = this.state;

    let { cost_center_heads, is_budget } = this.props;
    
    let numberToCurrency = function (number = 0, number_format = cc_format) {
      if (String(parseFloat(number)) == "NaN" || parseFloat(number) == undefined) {
        number = 0
      }
      if (number_format == "percentage") {
        this.percentFormat(parseFloat(number * -1))
      }
      if (number_format == "integer") {
        return new Intl.NumberFormat('decimal', { minimumFractionDigits: 2 }).format(parseFloat(number * -1))
      }

      if (!number_format || number_format == "money") {
        return parseFloat(number * -1).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL"
        });
      }
    };
    const { months } = this.state;
    const { costCenterRecordsGrouped, costCenterGroupedByMonth, areasDict, formulas } = this.state;

    let costCenterArray = Object.keys(report.self_cost_centers)

    let idValuesCostCenter = costCenterArray.map(function (element) {
      let fixed = element.replace(/=>/g, ':').replace(/\\"/g, '"');
      let parsed = JSON.parse(fixed);
      return parsed[0].area.id;
    });

    return <React.Fragment>
      {report.cost_center_params.ids.map((id) => {
        let row = 0
        let total = 0
        return idValuesCostCenter.includes(id[0].area.id) && <React.Fragment>{<tr>
          <td onClick={() => this.toggleDespesasType([id[0].area.id, id[0].is_fixed])}>
            <i className={`pull-left fa fa-chevron-${despesasType[[id[0].area.id, id[0].is_fixed]] ? 'down' : 'right'}`} />
            {areasDict[id[0].area.id]} - {id[0].is_fixed ? "Fixo" : "Variável"}
          </td>
          {SCOPES.map((scopeLabel)=>{
            //this.props[`${dataLabel}_date`].month this.props.end_date[dataLabel].month interate between months
            let scopeDate = this.props[`${scopeLabel}_date`]
            let total = 0
            
            row = parseFloat(this.state[`costCenterRecordsGrouped-${scopeLabel}`]?.[`["${areasDict[id[0].area.id]}", ${id[0].is_fixed}, ${scopeDate.month}]`])
            if (scopeDate?.month == "total"){
              row = 0
              Object.keys(this.props.months).map((month)=>{
                if(parseFloat(this.state[`costCenterRecordsGrouped-${scopeLabel}`]?.[`["${areasDict[id[0].area.id]}", ${id[0].is_fixed}, ${month}]`])){
                  row += parseFloat(this.state[`costCenterRecordsGrouped-${scopeLabel}`]?.[`["${areasDict[id[0].area.id]}", ${id[0].is_fixed}, ${month}]`])
                }
                
              })
            }
           
            return <td>
              <p>{numberToCurrency(row)}</p>
            </td>
          })}
          
          
          
          
        </tr>}
          {despesasType[[id[0].area.id, id[0].is_fixed]] && Object.entries(cost_center_heads).map(([despesa_type_id, label]) => {
            let exist = false
            try {
              exist = this.state.activeAreaType?.[`${id[0].area.id},${id[0].is_fixed}`]?.[`${despesa_type_id}-${label}`]
              
            } catch (e) {

            }
            total = 0
            let despesaValue = 0
            return <React.Fragment>
              {<tr>
                <td style={{ textIndent: 16 }} onClick={() => this.toggleAreaType(id[0].area.id, id[0].is_fixed, despesa_type_id, label)} >
                  <i className={`pull-left fa fa-chevron-${exist ? 'down' : 'right'}`} />
                  {label}
                </td>
                {SCOPES.map((scopeLabel)=>{
                   let scopeDate = this.props[`${scopeLabel}_date`]
                   if (scopeDate?.month == "total"){
                    despesaValue = 0
                    Object.keys(this.props.months).map((month)=>{
                      despesaValue += this.costCenterGroupedBySpendType(id[0].area.id, id[0].is_fixed, label, month, scopeLabel)
                    })
                  }else{
                    despesaValue = this.costCenterGroupedBySpendType(id[0].area.id, id[0].is_fixed, label, scopeDate.month, scopeLabel)
                  }
                  return <td className={''}>
                    <p>{numberToCurrency(despesaValue)}</p>
                  </td>
                })}
                
                
                
              </tr>}
              
              {this.state.activeAreaType[`${id[0].area.id},${id[0].is_fixed}`] && this.state.activeAreaType[`${id[0].area.id},${id[0].is_fixed}`][`${despesa_type_id}-${label}`] && this.renderCostCenterRows(id[0].area.id, id[0].is_fixed, label, despesa_type_id)}
            </React.Fragment>

          })}
        </React.Fragment>
      })}




    </React.Fragment>


  }

  getNumerFormatted(value, format) {
    let result = value
    if (format == "percentage") {
      result = this.percentFormat(value)
    }
    if (format == "integer") {
      result = new Intl.NumberFormat('decimal', { minimumFractionDigits: 2 }).format(parseFloat(value))
    }
    if (!format || format == "money") {
      result = this.numberToCurrency(value * -1)
    }

    return result
  }

  dataForFormula(result) {
    const { months } = this.props;
    let data = []
    let i = 1
    Object.entries(this.props.months).map(([name, number]) => {
      if (i < 12) {
        data.push(this.tryEval(result[name]))
      }
      i += 1
    })
    return data
  }

  getFloat(number) {
    if (parseFloat(number).toLocaleString() == "NaN") {
      return 0
    } else {
      return parseFloat(number)
    }
  }

  async fetchAreas() {

    let result = await axios.get(`/cost_center_areas.json`, {
      params: {
        query: this.state.query
      }
    });

    let hash_data = {}

    result.data.map((area) => {
      hash_data[area.id] = area.label
    })

    this.setState({ areasDict: hash_data })

  }

  isParamZero(p_value, param) {
    if (this.state.showZeroLines) {
      return false
    }

    const { months } = this.props;
    let isZero = true
    Object.entries(months).map(([name, number]) => {

      if (this.getFloat(p_value[name][param]) != 0) {
        isZero = false
      }
    })
    return isZero

  }
  isDespendZero(id, label) {
    if (this.state.showZeroLines) {
      return false
    }

    const { months } = this.props;
    let isZero = true
    Object.entries(months).map(([name, number]) => {
      let row = this.costCenterGroupedBySpendType(id[0].area.id, id[0].is_fixed, label, number)
      if (this.getFloat(row) != 0) {
        isZero = false
      }
    })
    return isZero

  }

  isCostCenterZero(id) {
    if (this.state.showZeroLines) {
      return false
    }

    const { months } = this.props;
    const { costCenterRecordsGrouped, areasDict } = this.state;

    let isZero = true
    Object.values(months).map(month => {

      let row = parseFloat(costCenterRecordsGrouped[`["${areasDict[id[0]?.['area']?.id]}", ${id[0].is_fixed}, ${month}]`])

      if (this.getFloat(row) != 0) {
        isZero = false
      }
    })
    return isZero

  }


  sortEntriesByKey = (entries) => {

    let extractName = (entry) => {
      const regex = /\)\s*([\w\s]+)\s*/;
      const match = regex.exec(entry[0]);
      return match ? match[1].trim() : entry[0];
    };

    return entries.sort((a, b) => {
      const nameA = extractName(a);
      const nameB = extractName(b);
      return nameA.localeCompare(nameB);
    });
  };

  

  propsSetState(newState) {
    this.props.propsSetState(newState)
  }

  renderFormula(key, value, formulaID) {
    const { report, labels, formulas, year, years, recordsFetched, cost_center_mode, formulasTotal, showZeroLines, formulaOpened, months, allYearsData  } = this.state;
    let { indicatorsMap } = this.props

    let numberToCurrency = function (number) {
      return parseFloat(number).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });
    };
    try{
      formulas[key].layout
    }catch(e){
      debugger
    }
    let className = `formula-${formulas?.[key]?.formula_type} formula-${formulas?.[key]?.formula_type}-${formulas[key].layout} format-${formulas[key].number_format} formula-${formulas[key].is_only_index ? "only-index" : "more-than-index"}`
    let idName = "principal"
    if (formulaID) {
      className = ""
      idName = ""
    }

    let paramsForFormula = {}
    SCOPES.map((scopeLabel)=>{
      let year = this.props[`${scopeLabel}_date`].year;
      paramsForFormula = {...allYearsData[year]?.[scopeLabel]?.report?.[key]?.[key]?.["params"], ...paramsForFormula}
    })
    
    return <>

      {!formulaID && formulas?.[key]?.formula_type != "formula" && Object.entries(value?.[key]?.["formulas"])?.map(([f_key, f_data]) => {
        return this.renderFormula(f_data?.label, report[f_data?.label], true)
      })}
      {!formulaID && ((formulas[key].layout != "grupo_especial") || formulaOpened[key]) && <React.Fragment>
        {/* {!formulaID && formulas[key].cost_center_demonstration && cost_center_mode && this.renderCostCenterAreas(formulas[key].number_format)} */}
        {!formulaID && formulas?.[key]?.formula_type != "formula" && this.renderCostCenterParams(value[key], key, formulas[key].number_format)}
        {!formulaID && (!formulas[key].cost_center_demonstration || !Boolean(cost_center_mode)) && !formulas[key].is_only_index && this.formulaIsGrouped(key) && this.sortEntriesByKey(Object.entries(paramsForFormula))?.map(
          ([p_key, p_value]) => {
            return (
              <React.Fragment>
                {value[key]["lines"][p_key] && Object.values(value[key]["lines"][p_key]).map((param) => {

                  if (Object.keys(value[key]["self_params"]).includes(p_key)) {
                    return <React.Fragment>
                      <tr>
                        <td onClick={() => this.fetchDetail(p_key)}>
                          <i className={`pull-left fa fa-chevron-${recordsFetched?.[p_key]?.["analisado"] ? 'down' : 'right'}`} /> {
                            this.paramLabel(labels[p_key], param)
                          }
                        </td>
                        {SCOPES.map((scopeLabel)=>{
                          let scopeDate = this.props[`${scopeLabel}_date`]
                          
                          
                          
                          let new_p_value = allYearsData[scopeDate.year]?.[scopeLabel]?.report?.[key]?.[key]?.["params"]?.[p_key]
                          let paramValue = new_p_value?.[monthDictComplete[scopeDate.month]]?.[param]
                          if (scopeDate.month == "total"){
                            paramValue = 0;

                            Object.values(this.props.months).map((month)=>{
                              if(new_p_value?.[month]?.[param]){
                                paramValue += new_p_value?.[month]?.[param]
                              }
                              
                            })

                          }
                          return <td>
                            <div className={''}>
                              {
                                numberToCurrency(paramValue || 0)
                              }
                            </div>
                          </td>
                        })}
                        
                      
                        
                        
                      </tr>
                      {this.renderChildreenFor(p_key, {
                        p_key,
                        param,
                        key,
                        p_value
                      })}
                    </React.Fragment>
                  }
                })}

              </React.Fragment>
            );
          }
        )}

        {!formulaID && this.formulaIsGrouped(key) && value[key]["indicators"] && Object.entries(value[key]["indicators"]).map(
          ([p_key, p_value]) => {
            let indicatorExists = indicatorsMap[p_key]
            

            
            return (
              indicatorExists && <React.Fragment>

                <React.Fragment>
                  {<tr>
                    <td >
                      <i className={`pull-left fa}`} />
                      {p_key}
                    </td>
                    {SCOPES.map((scopeLabel)=>{
                      let scopeDate = this.props[`${scopeLabel}_date`]
                    
                      let new_p_value = allYearsData[scopeDate.year]?.[scopeLabel]?.report?.[key]?.[key]?.["indicators"]?.[p_key]
                      let scopeValue = 0;
                      if(scopeDate.month == "total"){
                        Object.values(this.props.months).map((month)=>{
                          scopeValue += new_p_value?.[monthDictComplete[month]] ? parseFloat(new_p_value?.[monthDictComplete[month]]) : 0  
                        })
                      }else{
                        scopeValue = new_p_value?.[monthDictComplete[scopeDate.month]] ? parseFloat(new_p_value?.[monthDictComplete[scopeDate.month]]) : 0
                      }
                      return <td>
                        <div className={''}>
                          {
                            numberToCurrency(scopeValue)
                          }
                        </div>
                      </td>
                    })}
                    
                    
                  </tr>}
                </React.Fragment>

              </React.Fragment>
            );
          }
        )}
      </React.Fragment>}
      <tr id={idName} className={className}>
        <td onClick={() => { this.setState({ formulaOpened: { ...formulaOpened, [key]: !formulaOpened[key] } }) }}>
          {formulas[key].layout == "grupo_especial" && <i style={{ margin: '4px 5px 0 0' }} className={`pull-left fa fa-chevron-${formulaOpened[key] ? 'down' : 'right'}`} />}
          
          <PopHover

            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'left',
            }}
            withoutico description={
              <React.Fragment>
                {this.state?.formulas?.[key]?.description && <React.Fragment><br></br><div className={"desc"}>{this.state.formulas[key].description}</div><hr></hr></React.Fragment>}
                <div className={"desc"}>{this.props.formulas[key]}</div>

              </React.Fragment>
            }>
            {key}
          </PopHover>
        </td>
      {SCOPES.map((scopeLabel) => {
        let monthName = monthDictComplete?.[this.props[`${scopeLabel}_date`]?.month]
        let year = this.props[`${scopeLabel}_date`].year
        let monthValue = allYearsData[year]?.[scopeLabel]?.report?.[key]?.[key]?.["result"]?.[monthName]
        return (
          <td className={'comment-wrapper'}>

            {this.getNumerFormatted(this.tryEval(monthValue), formulas[key].number_format)}
          </td>
        );
        })}
        
        
        
      </tr>
      
    </>
  }

  async fetchGraphFormulas() {
    
    let req = await axios.get('/charts/situations/borboleta.json')
    let { charts, title } = req.data
    this.setState({ chart_formulas: charts })
    this.setState({ chart_title: title })
}

  changeChartTab = (tab) => {
    this.setState({ comparedLabel: tab })
    this.setState({chartIsHidden: true})
    let self = this;
    setTimeout(function () {
      self.setState({chartIsHidden: false})
    }, 500)
  }

  render() {
    const { report, labels, formulas, year, years, recordsFetched, cost_center_mode, formulasTotal, showZeroLines, formulaOpened } = this.state;
    const { months, chart_formulas, chartData } = this.state;
    let {start_date, end_date, analisado_date, budget_date, comparado_date} = this.props;

    let chartParameters = {
      
      "title": "Comparativo de resultados",
      "principalDataLabel": "analisado",
      "open": true,
      "divisor": 1,
      disableTabs: true,
      chart: chart_formulas,
      chartTitle: "",
      chartData: chartData,
      currentMonth: this.props.analisado_date.month,
      analisadoDate: moment({ year: this.props.analisado_date.year, month: this.props.analisado_date.month - 1, day: 1 }).toISOString(),
      budgetDate: moment({ year: this.props.budget_date.year, month: this.props.budget_date.month - 1, day: 1 }).toISOString(),
      comparadoDate: moment({ year: this.props.comparado_date.year, month: this.props.comparado_date.month - 1, day: 1 }).toISOString(),
      comparedLabel: "comparado",
      principalDataLabel: "analisado",
      secondaryDataLabel: this.state.comparedLabel,
      isRange: false,
      year: this.state.parameters?.year,
      ...this.props.borboletaParameters,
    }

    let oldParameters = {
      
      
      "divisor": 1,
      "popup": true
    }

    chartParameters = {
      ...chartParameters,
      ...oldParameters
    }


    return (
      <React.Fragment>
        <div style={{display: 'flex'}}>
          <div id="table-result-wrapper" style={{
                maxHeight: "92vh",
                display: "flex",
                overflowY: "scroll",
                maxWidth: "800px",
                overflowX: "clip",
          }}>
            
            <div className="content margin-left-40 margin-right-40 ">
              <div>
                <small style={{ fontSize: 10, display: 'block', color: 'grey' }}>* Você está visualizando números no formato n/{this.props.divisor}</small>
              </div>
              <table id="table-result" className="dre detail-borboleta">
                <thead>
                  <tr>
                    <th></th>
                    <th>Real <br></br> {monthDict[analisado_date.month]} {analisado_date.year}</th>
                    <th>Orçamento <br></br> {monthDict[budget_date.month]} {budget_date.year}</th>
                    <th>Real <br></br> {monthDict[comparado_date.month]} {comparado_date.year}</th>
                    

                  </tr>
                </thead>

                {Object.entries(report).map(([key, value]) => {
                  
                  return (
                    <React.Fragment>
                      <tbody className={`body-${formulas?.[key]?.formula_type} body-${formulas?.[key]?.formula_type}-${formulas?.[key]?.layout}`}>
                        {this.renderFormula(key, value)}
                      </tbody>
                    </React.Fragment>
                  );
                })}
              </table>
            </div>
          </div>
          <div style={{
            flex: 1,
          }}>
            {this.loading == 0 && Object.keys(chartParameters).length > 0 && <div
              className="fixed-window"
            >
              
              <BorboletaChart 
                  {...chartParameters}
                  title={
                    {
                      "data1": `Real(${titleMonthDictComplete[this.props.analisado_date.month]}/${this.props.analisado_date.year})`,
                      "data2": `Orçado(${titleMonthDictComplete[this.props.budget_date.month]}/${this.props.budget_date.year})`,
                      "data1_short": `Real`,
                      "data2_short": `Orçado`
                    }
                  }
                  secondaryDataLabel={"budget"}
                  withoutDrillDown={true}
                  height={320}
                  withoutDownloadButton={true}
                  popup={true}
                  disableOpenings={true}
                  printing={true}
                />
              <hr></hr>
              <BorboletaChart 
                  {...chartParameters}
                  title={
                    {
                      "data1": `Real(${titleMonthDictComplete[this.props.analisado_date.month]}/${this.props.analisado_date.year})`,
                      "data2": `Real(${titleMonthDictComplete[this.props.comparado_date.month]}/${this.props.comparado_date.year})`,
                      "data1_short": `${titleMonthDictComplete[this.props.analisado_date.month]}/${this.props.analisado_date.year}`,
                      "data2_short": `${titleMonthDictComplete[this.props.comparado_date.month]}/${this.props.comparado_date.year}`
                    }
                  }
                  secondaryDataLabel={"comparado"}
                  withoutDrillDown={true}
                  height={320}
                  withoutDownloadButton={true}
                  popup={true}
                  disableOpenings={true}
                  printing={true}
                />
              </div>}
          </div>
        </div>
        <LoadingDialog open={this.loading > 0} />
      </React.Fragment>
    );
  }
}

export default DreDetail;
const monthDict = {
  "1": "jan",
  "2": "fev",
  "3": "mar",
  "4": "abr",
  "5": "mai",
  "6": "jun",
  "7": "jul",
  "8": "ago",
  "9": "set",
  "10": "out",
  "11": "nov",
  "12": "dez",
}
const monthDictComplete = {
  1: "janeiro",
  2: "fevereiro",
  3: "março",
  4: "abril",
  5: "maio",
  6: "junho",
  7: "julho",
  8: "agosto",
  9: "setembro",
  10: "outubro",
  11: "novembro",
  12: "dezembro",
  "total": "range"
}
const titleMonthDictComplete= {
  1: "janeiro",
  2: "fevereiro",
  3: "março",
  4: "abril",
  5: "maio",
  6: "junho",
  7: "julho",
  8: "agosto",
  9: "setembro",
  10: "outubro",
  11: "novembro",
  12: "dezembro",
  "total": "YTD"
}

const monthDictByName = {
  "janeiro": 1,
  "fevereiro": 2,
  "março": 3,
  "abril": 4,
  "maio": 5,
  "junho": 6,
  "julho": 7,
  "agosto": 8,
  "setembro": 9,
  "outubro": 10,
  "novembro": 11,
  "dezembro": 12,
}