import React from "react";
import LoadingDialog from './../LoadingDialog'
import Comment from './../Comment'
import MonthFilter from './../MonthFilter'
import popupIco from 'popup-ico.svg';
import { popupCenter } from './../popup/popupCenter'
import olhoOn from 'bt_olho_on.svg'
import olhoOff from 'bt_olho_off.svg'
import ResultChart from "../ResultChart";
import downloadIco from 'bt_download.svg';
//import styled
import styled from 'styled-components'
import { ViewerButton } from "../../styled_components/default";
import EyeOff from "../svg-icons/EyeOff";
import { EyeOn } from "../svg-icons/EyeOn";
import axios from '../utils/axiosConfig';

class Balance extends React.Component {

  constructor(props) {
    super(props)
    this.requestsCounter = 0

    let months = {}

    Object.entries(props.months).map(([key, value]) => {
      if (Object.keys(props.visible_months?.[props.defaultYear] || {}).includes(key)) {
        months[key] = value
      }
      if (props.is_budget) {
        months[key] = value
      }
    })

    this.state = {
      recordsFetchedByYear: {},
      liquidProfitFormula: {},
      allYearsData: {},
      showZeroLines: false,
      year: this.props.defaultYear,
      activeBranches: {},
      recordsFetched: {},
      activeKeys: {},
      loading: false,
      data: [],
      years: [],
      resultIds: [],
      cash_flow_formula_ids: {},
      activeRows: {},
      periods: {},
      visibleMonths: props.visible_months[props.defaultYear] || {
        janeiro: true,
        fevereiro: true,
        março: true,
        abril: true,
        maio: true,
        junho: true,
        julho: true,
        agosto: true,
        setembro: true,
        outubro: true,
        novembro: true,
        dezembro: true,
      },
      months: months
    }
  }

  async fetchInitialParams(year = null, changeData = true) {
    this.setState({ loading: true })
    this.requestsCounter += 1
    let result = await axios.get('/results/balances.json', {
      params: {
        year: year
      }
    })
    this.requestsCounter -= 1
    this.setState({ loading: false })
    if (changeData) {
      this.setState({ cash_flow_formula_ids: result.data.cash_flow_formula_ids })
      this.setState({ data: result.data.params })
      this.setState({ result: result.data.result })
      this.setState({ resultIds: result.data.result.result_ids })
    }
    this.setState({
      allYearsData: {
        ...this.state.allYearsData,
        [year]: result.data.result
      }
    })

  }

  // fetch company liquid profit formula
  fetchCompanyLiquidProfitFormula(year = this.state.year) {
    this.requestsCounter += 1
    axios.get('/results/liquid_profit_formula.json', {
      params: {
        year
      }
    })
      .then(response => {
        this.requestsCounter -= 1
        this.setState({ liquidProfitFormula: Object.values(response.data.values)[0] })
        console.log(Object.values(response.data.values)[0])
      })
      .catch(error => {
        this.requestsCounter -= 1
        console.log(error)
      })
  }

  async fetchDetail(key, year = this.state.year, hide = true, updateLocal = true) {
    const { recordsFetched } = this.state;
    const { cost_center_heads, is_budget } = this.props;

    if (recordsFetched[key] && hide) {
      this.setState((oldState) => {
        oldState.activeKeys[key] = {}
        oldState.recordsFetched[key] = null
        return { ...oldState }
      })
    } else {
      let details = await axios.get(`/results/${key}/detail.json`, {
        params: {
          year: year,
          value_param: 'f_saldo_atual',
          tail: true

        }
      })

      this.setState((oldState) => {
        let dataFetched = {}
        if (Object.values(Object.values(details.data)[0].childreen).length > 0) {
          dataFetched = Object.values(details.data)[0].childreen
        } else {
          dataFetched = Object.values(details.data)[0].lines
        }

        if (updateLocal) {
          oldState.recordsFetched[key] = dataFetched
        }

        oldState.recordsFetchedByYear[year] ||= {}
        oldState.recordsFetchedByYear[year][key] = dataFetched
        return { ...oldState }
      })
    }

  }

  async setComments() {

    let comments = await axios.get('/comments.json', {
      params: {
        year: [this.state.year, 0]
      }
    })


    this.setState({ comments: comments.data })
  }

  async setYears() {
    this.requestsCounter += 1
    let years = await axios.get('/balance_sheets/years.json')
    this.requestsCounter -= 1
    this.setState({ years: years.data })
    this.setComments()
  }

  componentDidMount() {
    this.setYears()
    this.setComments()
    this.fetchConflicts()
    this.fetchInitialParams(this.state.year)
    this.fetchCompanyLiquidProfitFormula()
    this.fetchGetPeriods()
  }

  numberToCurrency(number) {
    if (number == 0 || number) {
      return parseFloat(number).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    }
    return "-"

  }
  removeAllChildren(row, rows) {
    if (row.childreen_attributes.length > 0) {
      rows[row.id] = false
      row.childreen_attributes.map((child) => (this.removeAllChildren(child, rows)))

    } else {
      rows[row.id] = false
    }
  }

  toggleRow(parent, row) {

    let rows = this.state.activeBranches
    rows[row.id] = !rows[row.id]
    if (!rows[row.id]) {
      this.removeAllChildren(row, rows)
    }

    this.setState({ activeBranches: rows })
  }

  branchIsActive(row) {
    return this.state.activeBranches[row.id]
  }

  sumResults(values) {
    let result = Object.values(Object.values(values)).reduce((a, b) => parseFloat(a) + parseFloat(b), 0)
    return this.numberToCurrency(result)
    // return 0
  }

  renderChildreenFor(key = null, level) {

    let data = this.state.recordsFetched[key]
    if (data) {
      return (<React.Fragment>
        {Object.values(data).map((row) => (this.renderRow(row, level, key)))}
      </React.Fragment>)
    }
  }

  renderRow(row, level = 0, key = null) {

    let childrenLines = (entry, month_name) => {
      try {
        let initial = Object.values(entry.lines).map((x) => x.months[month_name]).filter((a) => parseFloat(a).toString() != "NaN").reduce((a, b) => parseFloat(a) + parseFloat(b), 0)

        if (Object.values(entry.childreen).length > 0) {
          Object.values(entry.childreen).map((child) => {
            initial += childrenLines(child, month_name)
          })
        }

        return initial
      }
      catch (e) {
        console.log("ERRO AQUI", e)
        return 0
      }

    }

    const { months } = this.props;
    const { costCentersFetched, activeKeys } = this.state;
    let sum = 0

    return <React.Fragment>
      {row &&<>
        {!row?.months ?
          <tr>
            <td ><div
              onClick={() => {

                this.setState((oldState) => {
                  if (row.label) {

                    oldState.activeKeys[key] = oldState.activeKeys[key] ? oldState.activeKeys[key] : {}
                    oldState.activeKeys[key][row.label] = oldState.activeKeys[key][row.label] ? null : {}
                  } else {
                    oldState.activeKeys[key] = oldState.activeKeys[key] ? null : {}
                  }

                  return { ...oldState }
                })
              }}
              style={{ paddingLeft: level * 10 }}>
              {row.label}
            </div>
            </td>
            {Object.entries(months).map(([name, number]) => {
              let monthValue = childrenLines(row, name)
              if (monthValue.toString() != "NaN") {
                sum += monthValue
              }

              console.log(sum)
              return (
                <td className="comment-wrapper">
                  {this.state.comments && <Comment
                    refreshComments={() => this.setComments()}
                    comments={this.state.comments}
                    model_reference={'balance_sheet_row'}
                    month={number}
                    year={this.state.year}
                    model_reference_id={key}>
                  </Comment>}
                  {this.numberToCurrency(monthValue)}
                </td>
              );
            })}
            <td>
              {this.numberToCurrency(sum)}
            </td>
          </tr>
          :
          this.renderLine(row, level, key)
        }
      </>}


      {activeKeys[key] && row.childreen && Object.values(row.childreen).map((child) => {
        return this.renderRow(child, level + 1, key)
      })}
      {(activeKeys[key] && activeKeys[key][row.label]) && row.lines && Object.keys(row.childreen).length < 1 && Object.values(row.lines).map((line) => {
        return this.renderLine(line, level + 1, key)
      })}
    </React.Fragment>
  }

  removeSufix(code) {
    let { sufix } = this.props;

    return code.replace("-" + sufix, "")
  }

  defineDefaultVisibleMonths(year) {
    let months = {}
    Object.entries(this.props.months).map(([key, value]) => {
      if (Object.keys(this.props.visible_months[year]).includes(key)) {
        months[key] = value
      }
    })

    this.setState({ months, visibleMonths: this.props.visible_months[year] });
  }

  renderLine(line, level = 0, key) {
    const { months, year } = this.state;
    let totalLine = 0
    line.id = this.removeSufix(line.id)
    return <tr className="line">
      <td>
        <div style={{ paddingLeft: level * 10 }}>{line.label}</div>
      </td>
      {Object.entries(months).map(([name, number]) => {
        if (parseFloat(line.months[name]).toString() != "NaN") {
          totalLine += parseFloat(line.months[name])
        }

        return (
          <td style={{ paddingLeft: 16 }} className="comment-wrapper">
            {this.state.comments && <Comment
              refreshComments={() => this.setComments()}
              comments={this.state.comments}
              model_reference={'balance_sheet_line'}
              month={number}
              year={this.state.year}
              model_reference_id={line.id}>
            </Comment>}
            {line.months[name] ? (parseFloat(line.months[name]) * - 1).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL"
            }) : "-"}
          </td>
        );
      })}
      <td >

        <ResultChart
          loadAllYears={() => {
            this.state.years.map((year) => {
              this.fetchDetail(key, year, false, false)
            })
          }}
          loadYear={(year) => {
            this.fetchDetail(key, year, false, false)
          }}
          years={this.state.years}
          year={this.state.year}
          title={line.label}
          line={line}
          allYearsData={this.state.recordsFetchedByYear}
          dataKey={"balance-detail-line"}
          months={this.props.months}
          sufixes={this.props.all_childreen_sufixes}
        />
      </td>
    </tr>
  }

  getDataForParam(param) {
    const { result, resultIds, cash_flow_formula_ids, showZeroLines, recordsFetched } = this.state;
    console.log(result.params[param.id])

    return result.params[param.id]
  }

  renderChildreen(contabilParam, childreen_attributes = [], level = 0) {
    const { months } = this.state;
    const { result, resultIds, cash_flow_formula_ids, showZeroLines, recordsFetched } = this.state;
    let childreen = null

    childreen = childreen_attributes.map((child) => {
      let empty = true

      {
        Object.entries(months).map(([name, number]) => {
          let monthValue = parseFloat(result?.params?.[child.id]?.[name])

          if (monthValue.toString() != "NaN") {
            empty = false

          }
        })
      }

      if (empty == true && !showZeroLines) {
        return null
      } else {
        return (<React.Fragment>
          <tr>
            <td className={'comment-wrapper'} style={{ paddingLeft: 20 + (10 * level) }} onClick={() =>
              !child.is_tail ? this.toggleRow(contabilParam, child) : this.fetchDetail(child.fr_id)
            }>

              {this.state.comments && <Comment
                refreshComments={() => this.setComments()}
                comments={this.state.comments}
                model_reference={'contabil_pattern'}
                month={0}
                year={0}
                model_reference_id={child.key}>
              </Comment>}
              <div style={{ marginRight: 10, display: 'inline-block' }}>

                {!child.is_tail && <i className={`fa fa-chevron-${this.branchIsActive(child) ? 'down' : 'right'}`} />}
                {child.is_tail && <i className={`pull-left fa fa-chevron-${recordsFetched[child.fr_id] ? 'down' : 'right'}`} />}
              </div>
              {child.label} {cash_flow_formula_ids[child.fr_id] ? <i className={"fa fa-star"} style={{ color: "#31B6BC" }} /> : ""}
            </td>
            {Object.entries(months).map(([name, number]) => (
              <td className={'comment-wrapper'}>
                {this.state.comments && <Comment
                  refreshComments={() => this.setComments()}
                  comments={this.state.comments}
                  model_reference={'balance_sheet'}
                  month={number}
                  monthName={name}
                  year={this.state.year}
                  model_reference_id={child.id}>
                </Comment>}
                {result && result.params[child.id] && this.numberToCurrency(result.params[child.id][name])}

              </td>
            ))}
            <td>
              <ResultChart
                loadAllYears={() => {
                  this.state.years.map((year) => {
                    this.fetchInitialParams(year, false)
                  })
                }}
                loadYear={(year) => this.fetchInitialParams(year, false)}
                years={this.state.years}
                year={this.state.year}
                title={child.label}
                parameter_id={child.id}
                allYearsData={this.state.allYearsData}
                dataKey={"balance-params"}
                months={this.props.months}
              />
            </td>
          </tr>
          {this.renderChildreenFor(child.fr_id, level)}
          {this.branchIsActive(child) && <React.Fragment>
            {this.renderChildreen(child, child.childreen_attributes, (level + 1))}
          </React.Fragment>}

        </React.Fragment>)
      }

    })


    return childreen

  }

  changeYear(year) {
    this.setState({ year })
    this.fetchInitialParams(year)
    this.props.changeYear(year)
    this.fetchCompanyLiquidProfitFormula(year)
    this.defineDefaultVisibleMonths(year)
    Object.keys(this.state.recordsFetched).map((key, i) => {
      setTimeout(() => {
        this.fetchDetail(key, year, false)
      }, 1000 * i)

    })
  }

  checkMonth(month_name) {
    let checagem = 0;
    const { data, result } = this.state;

    this.state.data && this.state.data.map((contabilParam) => {
      checagem += result.params[contabilParam.id][month_name]
    })

    return this.numberToCurrency(checagem);
  }

  changeVisibleMonths(event) {
    // event.target.name]: event.target.checked
    let { name, checked } = event.target
    let props = this.props;
    this.setState((oldState) => {
      oldState.visibleMonths[name] = checked
      oldState.months = {}
      Object.entries(oldState.visibleMonths).map(([name, checked]) => {
        if (checked) {
          oldState.months[name] = props.months[name]
        }
      })

      return { ...oldState }
    });
  }

  fetchConflicts() {
    axios.get('/configured_patterns/childreen_conflicts.json').then((conflicts) => {
      this.setState({ conflicts: conflicts?.data })
    })
  }

  checkResultWithLiquidProfit(monthNumber, monthName, isResult, value) {
    let { months, periods, year } = this.state;
    // create a inversion of hash months to transform keys into values and values into keys
    let monthsInverted = {}
    Object.entries(months).map(([name, number]) => {
      monthsInverted[number] = name
    })

    if (isResult) {
      // loop until monthNumber
      let total = 0
      for (let number = 1; number <= monthNumber; number++) {
        try {
          if (number <= monthNumber) {
            if (periods[year] == "trimestral") {
              if (monthNumber % 3 == 0) {
                if (monthNumber - number <= 2) {
                  total += parseFloat(eval("(" + this.state.liquidProfitFormula?.result?.[monthsInverted[number]] + ") * -1")) || 0
                }
              }
              if (monthNumber % 3 == 2) {
                if (monthNumber - number <= 1) {
                  total += parseFloat(eval("(" + this.state.liquidProfitFormula?.result?.[monthsInverted[number]] + ") * -1")) || 0
                }
              }
              if (monthNumber % 3 == 1) {
                if (monthNumber - number <= 0) {
                  total += parseFloat(eval("(" + this.state.liquidProfitFormula?.result?.[monthsInverted[number]] + ") * -1")) || 0
                }
              }

            } else if (periods[year] == "yearly") {
              total += parseFloat(eval("(" + this.state.liquidProfitFormula?.result?.[monthsInverted[number]] + ") * -1")) || 0
            }

          }

        } catch (e) { }
      }
      if (periods[year] == "monthly") {
        total = parseFloat(eval("(" + this.state.liquidProfitFormula?.result?.[monthsInverted[monthNumber]] + ") * -1")) || 0
      }
      console.log("number", monthNumber)
      console.log("checagem", parseInt(total), parseInt(value), parseInt(total) - ((parseInt(value))))
      let check = parseInt(total) - parseInt(value)
      return check <= 1 && check >= -1 ? "valid_result" : "invalid_result"
    } else {
      return ""
    }
  }

  // fetch get_periods using axios
  fetchGetPeriods() {
    axios.get('/liquid_profits/get_periods.json').then((periods) => {
      this.setState({ periods: periods?.data })
    })
  }

  getBeforeMonthsSum(monthNumber) {
    let { months, year, periods } = this.state;
    let beforeSum = 0
    Object.entries(months).map(([name, number]) => {
      if (number < monthNumber) {
        if (periods[year] == "trimestral") {

          if (monthNumber % 3 == 0) {
            if (monthNumber - number <= 2) {
              beforeSum += parseFloat(eval("(" + this.state.liquidProfitFormula?.result?.[name] + ") * -1")) || 0
            }
          }
          if (monthNumber % 3 == 2) {
            if (monthNumber - number <= 1) {
              beforeSum += parseFloat(eval("(" + this.state.liquidProfitFormula?.result?.[name] + ") * -1")) || 0
            }
          }

        }
        else if (periods[year] == "yearly") {
          try{
            beforeSum += parseFloat(eval("(" + this.state.liquidProfitFormula?.result?.[name] + ") * -1")) || 0
          }catch(e){
          }
          
        }

      }

    })
    if (periods[year] == "trimestral") {
      // if month is in (4, 7, 10)
      if (monthNumber == 4 || monthNumber == 7 || monthNumber == 10) {
        return 0
      }
    }
    return beforeSum
    // return 0

  }

  renderConflicts() {
    let lastCode = null;
    let { conflicts } = this.state;

    return conflicts?.length > 0 && !this.state.hideConflicts && <div className={"conflicts"} style={{ padding: 10 }}>
      <ConflictExternalWrapper>
        <ConflictContent>
          <ConflictScroll>
            <h3>Conflitos</h3>
            <ul>
              {conflicts?.map(([company, code, parametro, descriptions]) => {
                let differentCode = lastCode != code;
                lastCode = code;
                return <React.Fragment>
                  {differentCode && <hr></hr>}
                  {differentCode && <li>{code} {descriptions[0]}</li>}
                  <li><strong>{parametro}</strong> na empresa {company}</li>
                </React.Fragment>
              })}
            </ul>
          </ConflictScroll>
          <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', height: '10%' }}>
            <CloseButton onClick={() => this.setState({ hideConflicts: true })}>
              Fechar
            </CloseButton>
          </div>
        </ConflictContent>
      </ConflictExternalWrapper>
    </div>
  }

  render() {


    const { state } = this.props;
    const { data, result, year, years, resultIds, cash_flow_formula_ids, showZeroLines, months, conflicts } = this.state;



    return (
      <React.Fragment>
        <div className="header" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 20 }}>
            <div
              title="Abrir em nova janela"
              className={'result-popup-button'}
              onClick={() => popupCenter({ url: `/popups/balance`, title: 'Editar', w: window.innerWidth * 0.8, h: window.innerHeight * 0.8 })}
              style={{
                backgroundColor: '#F5F5F5',
                width: 36,
                height: 36,
                borderRadius: 4,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer'
              }}>
              <img src={popupIco} style={{ width: 14 }}></img>
            </div>
            <div className="label" style={{ marginRight: 16 }}>BALANÇO</div>
            <MonthFilter changeVisibleMonths={(e) => this.changeVisibleMonths(e)} visibleMonths={this.state.visibleMonths} months={this.props.months}></MonthFilter>

            <ViewerButton title={showZeroLines ? "Mostrar linhas zeradas" : "Ocultar linhas zeradas"} active={showZeroLines} onClick={() => this.setState({ showZeroLines: !showZeroLines })}>
              {showZeroLines ? <EyeOff height={30} width={30} /> : <EyeOn height={30} width={30} />}
            </ViewerButton>
          </div>
          <form className="form flex" style={{ paddingRight: 35, alignItems: 'center', height: '100%' }}>
            <div className={'with-date-ico'}>
              <select className={'margin-left-20'} onChange={(e) => this.changeYear(e.target.value)}>
                {years.map((option_year) => (
                  <option value={option_year} selected={year == option_year}> {option_year} </option>
                ))}
              </select>
            </div>
            {this.props.can_download &&  <a style={{marginLeft: 20}} href={`/export/balance_xlsx/${year}.xlsx`}>
              <img src={downloadIco} style={{width: 36}}></img>
            </a>}
          </form>
	        </div>
          <small style={{fontSize: 10, padding: "10px 0 0 43px", display: 'block', color: 'grey'}}>* Você está visualizando números no formato n/{this.props.divisor}</small>
          
          {this.renderConflicts()}
          
	        <div id="table-result-wrapper">
	    		<div className="content margin-left-40 margin-right-40 padding-top-40 ">
		            <table id="table-result" className={"margin-bottom-20  balance-table"}>
		                <thead>
		                    <tr>
		                        <th>
		                            Nome
		                        </th>
		                        {Object.values(months).map((month)=>(
		                        	<th>
			                            {monthDict[`${month}`.padStart(2, '0')]}/{year}
			                        </th>
		                        ))}
		                        
		                        
		                    </tr>
		                </thead> 
		                
		                	{data.map((contabilParam)=>{
		                		return <tbody key={contabilParam.id}>
			                				<tr id="principal" className="formula-grouped formula-grouped-null format-money formula-more-than-index">
				                				<th className={'comment-wrapper '}>
                                  {this.state.comments && <Comment  
                                    refreshComments={()=> this.setComments()}
                                    comments={this.state.comments}
                                    model_reference={'contabil_pattern'}
                                    month={0}
                                    year={0}
                                    model_reference_id={contabilParam.key}>
                                  </Comment>}
				                					{contabilParam.label} {cash_flow_formula_ids[contabilParam.fr_id] ? <i className={"fa fa-star"} style={{color: "#31B6BC"}}/> : ""}
				                				</th>
				                				{Object.entries(months).map(([name, number])=>(
						                        	<th className={`comment-wrapper ${this.checkResultWithLiquidProfit(number, name, resultIds.includes(contabilParam.id), result?.params[contabilParam.id]?.[name])}`}>
                                        {this.state.comments && <Comment  
                                          refreshComments={()=> this.setComments()}
                                          comments={this.state.comments}
                                          model_reference={'balance_sheet'}
                                          month={number}
                                          monthName={name}
                                          year={this.state.year}
                                          model_reference_id={contabilParam.id}>
                                        </Comment>}
							                            {result && this.numberToCurrency(result.params[contabilParam.id][name])}
							                        </th>
						                        ))}
                                    
						                        
						                    </tr>
						                    {!(result && resultIds.includes(contabilParam.id)) && this.renderChildreen(contabilParam, contabilParam.childreen_attributes)}
						                    
				                       </tbody>
		                	})}
		                	<tbody>
	                		<tr>
		                    	<td>Checagem</td>
		                    	{Object.entries(months).map(([name, number])=>(
		                        	<td>
			                            {result && this.checkMonth(name)}
			                        </td>
		                        ))}
		                        
		                    </tr>
		                    </tbody>
                        { this.state?.liquidProfitFormula?.result && <tbody>
                          <tr></tr>
                          <tr>
                            <td> Lucro Líquido</td>
                          {
                            
                          Object.entries(months).map(([name, number])=>{
                            let beforeSum = this.getBeforeMonthsSum(number)
                            let currentValue = 0
                            try{
                              currentValue = parseFloat(eval("(" +this.state.liquidProfitFormula?.result?.[name] + ") * -1")) || 0
                            }catch(e){}
                            
                            return <>
                              <td>{currentValue ? this.numberToCurrency( beforeSum + currentValue) : "-"}</td>  
                              </>
                            
                          })
                          }
                          </tr>
                          
                      
                        </tbody>}
		            </table>
		        </div>
		     </div>		
		     <LoadingDialog open={this.requestsCounter > 0}/>	
    </React.Fragment>);
  }
}

export default Balance;

const monthDict = {
  "01": "jan",
  "02": "fev",
  "03": "mar",
  "04": "abr",
  "05": "mai",
  "06": "jun",
  "07": "jul",
  "08": "ago",
  "09": "set",
  "10": "out",
  "11": "nov",
  "12": "dez",
}

//update
//conflict modal
const ConflictExternalWrapper = styled.div`
  position: absolute;
  background: rgba(0,0,0,0.5);
  z-index: 1000;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
`
const ConflictContent = styled.div`
  position: absolute;
  background: white;
  z-index: 1000;
  height: 80vh;
  width: 50vw;
  left: 31vw;
  top: 10vh;
  border: 1px solid;
  padding: 20px;
  display: flex;
  flex-direction: column;

`

const ConflictScroll = styled.div`
  overflow-y: scroll;
  height: 90%;
`
const CloseButton = styled.div`
  border: 1px solid;
  padding: 12px;
  display: block;
  width: 117px;
  text-align: center;
  float: right;
  cursor: pointer;
  height: 18px;

`
