import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import Highcharts from 'highcharts'
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from 'highcharts-react-official'
import popupIco from 'popup-ico.svg';
import {popupCenter} from './popup/popupCenter'
import moment, { months } from 'moment';
import { Divider, Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import axios from 'axios';
import styled from 'styled-components';

window.moment = moment;
var formatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL"
});
Highcharts.setOptions({
    colors: ["#65BCFF", "#F7999C", "#4AB796", "#F4CF98", "#37BAC0", "#6C899E"]
});
const range = ( a , b ) => Array.from( new Array( b > a ? b - a : a - b ), ( x, i ) => b > a ? i + a : a - i );
HighchartsMore(Highcharts);
export default class FlowChart extends React.Component {
    
    state ={
        currentSituation: "cash_flow",
        activeRow: {
            formula: {},
            area: {},
            param: {},
            cost_center: {}
        },
        dataLabels: {},
        legendData: {
            data1: this?.props?.principalDataLabel?.replace("-range", ""),
            data2: this?.props?.secondaryDataLabel?.replace("-range", "")
        },
        currentTable: "variation",
        paramActive: {},
        chartIsVisible: true,
        areasDict: {},
        activeCosts: {},
        formulaIsVisible: {},
        balanceRows: {},
        costCentersFetched: {},
        despesasType: {},
        costCenterMonthReports: {},
        reports: {},
        currentFormulaData: [],
        currentColumnId: null,
        
        options: {
            yAxis: {
                stackLabels: {
                    style: {
                        color: 'black'
                    },
                    enabled: true,
                    y: -30
                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    pointPadding: 0,
                    groupPadding: 0,
                    dataLabels: {
                        enabled: false
                    }
                }
            },
          chart: {
                type: 'waterfall'
            },

            title: {
                text: `${this.props.currentMonth}`
            },

            xAxis: {
                type: 'category'
            },

            yAxis: {
                title: {
                    text: 'R$'
                }
            },

            legend: {
                enabled: false
            },

            tooltip: {
                pointFormat: '<b>${point.y:,.2f}</b> R$'
            },

            series: [{
                upColor: "#65BCFF",
                color: "#F7999C",
                data: [{
                    name: 'Start',
                    y: 120000
                }, {
                    name: 'Product Revenue',
                    y: 569000
                }, {
                    name: 'Service Revenue',
                    y: 231000
                }, {
                    name: 'Positive Balance',
                    color: Highcharts.getOptions().colors[1]
                }, {
                    name: 'Fixed Costs',
                    y: -342000
                }, {
                    name: 'Variable Costs',
                    y: -233000
                }, {
                    name: 'Balance',
                    isSum: true,
                    color: Highcharts.getOptions().colors[1]
                }],
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return formatter.format(this.y);
                    },
                    enabled: true,
                    // y: -70,
                    style: {
                        fontWeight: 'bold',
                        textShadow: 'none',
                        color: 'black'
                    }
                },
                pointPadding: 0
            }]
        }
    }

    async showDataFor(id, formula, currentTotal, label){
        let self = this;
        this.setState({
            currentColumnId: id,
            currentFormula: formula,
            currentTotal,
            currentLabel: label
        })
        let year = self.props.year;
        let formulaData = await axios.get(`/flow_reports/${id}/${year}.json`)
        
        
        this.setState({currentFormulaData: Object.values(formulaData?.data || {})})
        this.setState({noFlowFormula: Object.values(formulaData?.data || {}).length == 0})
        
    }

    setupChartData(situation="cash_flow"){
        let self = this;
        this.setState({chartIsVisible: false})
        let {comparedLabel} = this.props;
        
        let { principalDataLabel, secondaryDataLabel, isRange, chartData, currentMonth } = this.props;
        
        // if (isRange.toString() === "true") {
        //     principalDataLabel = principalDataLabel + "-range"
        //     secondaryDataLabel = secondaryDataLabel + "-range"
        // }

        let newChartData = {}
        let newChartDataFirstMonth = {}   
        
        Object.entries(chartData).map(([key, value])=>{
            
            try{
                newChartData[key] = eval(value?.[currentMonth]) * -1
                newChartDataFirstMonth[key] = eval(value?.["janeiro"]) * -1
            }catch(e){
                console.log("ERRO", e)
            }
            
        })
        
        let entries = Object.values(this.props?.chart?.[situation] || this.props?.chart || {})
        let totalYvalue = 0
        
        let data = entries.map(([label, formula, id], i)=>{

            try{
                let alreadyUpdated = false
                let newFormula = formula
                let newFormulaComponents = newFormula.split(' ').map((x)=> x.trim() )
                let replaceData = newChartData
                if (i == 0 && currentMonth == "total"){
                    replaceData = newChartDataFirstMonth
                }
                Object.entries(replaceData).map(([key, value])=>{
                   
                    if (value.toString().search("Infinity") > -1) {
                        if(newFormulaComponents.includes(String(key))){
                            newFormula = newFormula.replace(`${key}`, "0")
                        }else{
                            newFormula = newFormula.replace(`[${key}]`, "0")
                        }
                        
                    }else{
                        
                        
                        if(newFormulaComponents.includes(String(key)) && !alreadyUpdated ){
                            newFormula = newFormula.replace(`${key}`, parseFloat(value) * -1)    
                            alreadyUpdated = true
                        }
                        
                    }
                    
                })
                
                let yValue = eval(newFormula)
                
                if(typeof yValue != "number"){
                    yValue = 0
                }
                totalYvalue += yValue
                return {
                    events:{
                        click: ()=> self.showDataFor(id, formula, yValue, label)
                    },
                    color: i == 0 ? "#617e94" : null,
                    name: label,
                    y: yValue.toString() == "NaN" ? 0 : yValue,
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            return formatter.format(this.y);
                        },
                        enabled: true,
                        verticalAlign: yValue > 0 ? 'top' : 'bottom',
                        y: yValue > 0 ? -24 : 24,
                        style: {
                            fontWeight: 'bold',
                            textShadow: 'none',
                            color: 'black'
                        }
                    },
                }
            }catch(e){
                debugger
            }
            
        })
        
        data.push({
            color: "#617e94",
            isSum: true,
            name: "Caixa Final",
            dataLabels: {
                enabled: true,
                formatter: function () {
                    return formatter.format(this.y);
                },
                enabled: true,
                verticalAlign: totalYvalue > 0 ? 'top' : 'bottom',
                y: totalYvalue > 0 ? -24 : 24,
                style: {
                    fontWeight: 'bold',
                    textShadow: 'none',
                    color: 'black'
                }
            },
        })
        data = data.filter((x)=> x /* só dados validos */)

        
        this.setState((oldState)=>{
            oldState.options.series[0].data = data
            oldState.dataIsSetted = true
            return {...oldState}
        })
        setTimeout(()=>{
            this.setState({chartIsVisible: true})
        }, 1)
        
        this.forceUpdate()

    }

      handleResizeChart = () => {
	this.setState({
	  dataIsSetted: false,
	});

	clearTimeout(this.resizeTimer);
	this.resizeTimer = setTimeout(() => {
	  this.setState({
	    dataIsSetted: true,
	  });
	}, 300); 
      };

    renderHighChart(){
        const {options, dataIsSetted} = this.state;
        
        return dataIsSetted && <HighchartsReact
                highcharts={Highcharts}
                options={{...options, exporting: { enabled: !this.props.printing }}}
              />   
    }

    getIndicatorLine(indicator_months, data_label ){
        const {principalDataLabel, startDate, endDate} = this.props
        let {legendData} = this.state;
        let total = 0;
        
        let legend = legendData[data_label]
        
        if(principalDataLabel?.search("range") >= 0){
            let legend_str = legend.replace("-range", "")
            
            let startMonth = moment(startDate[legend_str]).toDate().getMonth() + 1
            let endMonth = moment(endDate[legend_str]).toDate().getMonth() + 1
            
            for (let month = startMonth; month <= endMonth; month++) {
                // console.log(monthsDict[month])
                let monthValue = parseFloat(indicator_months[monthsDict[month]])
                if(monthValue > 0){
                    total += monthValue
                }
            }
            
        }else{
            total =  indicator_months[monthsDict[this.getCurrentMonth(data_label)]]
        }
        
        return total

        
    }

    getBalanceSheetLineValue(line, data_label){
        // let {legendData} = this.state;
        const { currentMonth } = this.props
        let total = line.months[currentMonth] * -1;
        
        // let legend = legendData[data_label]
        // if(principalDataLabel?.search("range") >= 0){
        //     let legend_str = legend.replace("-range", "")
            
        //     let startMonth = moment(startDate[legend_str]).toDate().getMonth() + 1
        //     let endMonth = moment(endDate[legend_str]).toDate().getMonth() + 1
            
        //     for (let month = startMonth; month <= endMonth; month++) {
        //         total += line.months[monthsDict[month]]
        //     }
            
        // }else{
        //     total =  line.months[monthsDict[this.getCurrentMonth(data_label)]]
        // }
        
        return total

        
    }

    async toggleActive(id, activeType){
        try{
            const {activeRow} = this.state;
            this.setState({activeRow: {
                ...activeRow,
                [activeType]: {...activeRow[activeType], [id]: !activeRow[activeType][id]}}
            })
        }catch(e){}
        
    }


    async fetchDataForFormula(formula){
        this.toggleActive(formula.fr_id, "formula");
        ["data1", "data2"].map(async (data_label)=>{
            
            let year = this.getCurrentYear(data_label)
            const {principalDataLabel, secondaryDataLabel} = this.props
            let is_budget = null;
            
            if (secondaryDataLabel?.search("budget") >= 0 && data_label == "data2") {
                is_budget = true
            }

            let params = {formula_ids: [formula.fr_id], year: year, is_budget}
            let formulaData = await axios.get(`/results/dre_cc.json`, {params})
            // this.toggleFormula(formula)
            
            this.setState((oldState)=>{
                oldState.reports[[data_label, formula.id]] = formulaData.data
                return {...oldState}
            })

            try{
                let costCenterMonthData = await axios.get(`/results/cost_centers.json`, {params})
                this.setState((oldState)=>{
                    oldState.costCenterMonthReports[[data_label, year]] = costCenterMonthData.data
                    return {...oldState}
                })
            }catch(e){
                // console.log("ERROOO", e)
                
            }
        })
         this.setState((oldState)=>{
            oldState.formulaIsVisible[formula.id] = !oldState.formulaIsVisible[formula.id]
            return {...oldState}
        })
    }

    componentDidMount(){
        this.fetchAreas()
        if(this.props.popup){
            this.setupChartData()
        }
        let {principalDataLabel, secondaryDataLabel} = this.props
        let {analisadoDate, budgetDate, comparadoDate, isRange, startDate, endDate} = this.props
        let dataDates = {}
        
        let dataLabels = {}
        
        this.setState({
            dataLabels,
            dataDates
        })
      window.addEventListener('resize', this.handleResizeChart);
    }

    getCurrentMonth(data_label){
        let {endDate, startDate, isRange} = this.props;
        let {legendData} = this.state;
        if(isRange?.toString() === "true"){
            data_label = data_label.replace("-range", "")
            return moment(endDate[legendData[data_label]]).toDate().getMonth() + 1;
        }else{
            return moment(this.state.dataDates[data_label]).toDate().getMonth() + 1;
        }
        
    }

    getCurrentYear(data_label){
        let {endDate, startDate, isRange} = this.props;
        let {legendData} = this.state;
        
        if(isRange?.toString() === "true"){
            data_label = data_label.replace("-range", "")
            return moment(endDate[legendData[data_label]]).toDate().getFullYear();
        }else{
            return moment(this.state.dataDates[data_label]).toDate().getFullYear();    
        }
        
    }
    
    async fetchAreaData(key, fixed, type_id, type_label){
        
        
        ["data1", "data2"].map(async (data_label)=>{
            let year = this.getCurrentYear(data_label)

            const {costCentersFetched} = this.state;
            const {cost_center_heads} = this.props;
            let fixed_label = Boolean(fixed) ? "fixed" : "not_fixed"

            
            if(!costCentersFetched[data_label]){
              costCentersFetched[data_label] = {}
            }

            Object.entries(cost_center_heads).map(([id, label])=>{
              if(!costCentersFetched[data_label][label]){
                costCentersFetched[data_label][label] = {}
              }
            })
            
            if(costCentersFetched[data_label][type_label][key] && costCentersFetched[data_label][type_label][key][fixed_label]){
              this.setState((oldState)=>{
                oldState.costCentersFetched[data_label][type_label][key][fixed_label] = null
                return {...oldState}
              })
            }else{
              let details = await axios.get(`/results/${key}/cost_center_detail.json`, {params: {
                  year: year,
                  fixed: Boolean(fixed),
                  type: type_id,
                  is_budget: this.state.legendData[data_label].search("budget") >= 0 ? true : false
              }})
              
              this.setState((oldState)=>{
                
                if(!oldState.costCentersFetched[data_label][type_label][key])
                { oldState.costCentersFetched[data_label][type_label][key] = {} }

                if (!oldState.costCentersFetched[data_label][type_label][key][Object.keys(details.data)[0]])
                { oldState.costCentersFetched[data_label][type_label][key][Object.keys(details.data)[0]] = {}}
                
                oldState.costCentersFetched[data_label][type_label][key][Object.keys(details.data)[0]] = Object.values(details.data)[0]
                
                return {...oldState}
              })
            }
        })

    }

    toggleDespesasType(key, year){
        let {cost_center_heads} = this.props;
        let [area_id, is_fixed] = key
        this.toggleActive(key, "area");
        Object.entries(cost_center_heads).map(([despesa_type_id, label])=>{
          this.fetchAreaData(area_id, is_fixed, despesa_type_id, label)  
        })
        
        
        this.setState((oldState)=>{
          oldState.despesasType[key] = !oldState.despesasType[key]
          return {...oldState}
        })
    }

    async getBalanceRows(key){
        this.toggleActive(key, "param");
        ["data1", "data2"].map(async (data_label)=>{
            let year = this.getCurrentYear(data_label)
            let details = await axios.get(`/results/${key}/detail.json`, {params: {
                  year: year,
                  cost_center_mode: true,
                  is_budget: this.state.legendData[data_label] == "budget" ? true : false
              }})

            this.setState((oldState)=>{
                oldState.balanceRows[[data_label, year,key]] = Object.values(details.data)[0]
                return {...oldState}
            })
        })
    }

    numberToCurrency(number) {
      if (String(parseFloat(number)) == "NaN" || parseFloat(number) == undefined) {
        number = 0
      }
      
      return parseFloat(number).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });
    };

    getMonthRange(data, label, is_fixed, data_label){
        
        try{
            let {isRange, startDate, endDate} = this.props;
            let {legendData} = this.state;
            
            if(isRange.toString() === "true"){
                let legend = legendData[data_label]
                
                let firstMonth = moment(startDate[legend]).toDate().getMonth() + 1
                let endMonth = moment(endDate[legend]).toDate().getMonth() + 1
                let result = 0
                range(firstMonth, endMonth + 1).map((month_i)=>{
                    result += parseFloat(data[`["${label}", ${is_fixed}, ${month_i}]`])
                })
                return this.numberToCurrency(result)
            }else{
                return this.numberToCurrency(data[`["${label}", ${is_fixed}, ${this.getCurrentMonth(data_label)}]`])
            }
        }catch(e){
            return 0
        }
        
    }
    
    getFormatedFormula(){
        let {chartData, principalDataLabel, secondaryDataLabel} = this.props;
        let {currentFormula, currentFormulaData, currentTotal} = this.state 
        if(this.props.isRange){
            principalDataLabel = principalDataLabel?.replace("-range", "") + "-range"
            secondaryDataLabel = secondaryDataLabel?.replace("-range", "") + "-range"
        }
        
        let removeDataStr = currentFormula.replace("data1", principalDataLabel ).replace("data2", secondaryDataLabel)

        let formulaData = {}
        
        Object.values(currentFormulaData).map((arr)=>{
            arr.map((el)=>{
                formulaData[el.fr_id] = el.label
            })
        })

        Object.entries(chartData).map(([key,value])=>{
            let [form_id, type] = JSON.parse(key)

            removeDataStr = removeDataStr.replace(key, `${formulaData[form_id]}-${translate[type]}(${this.numberToCurrency(value)})`)
        })

        let upColor = "#00529c"
        let color =  "#ca2149"

        return <div style={{
                    fontSize: 17,
                    color: currentTotal > 0 ? upColor : color}}>
                {removeDataStr + " = " + this.numberToCurrency(currentTotal)}
                </div>
    }
    
    getFormattedDataLabel(data_label, withoutRange=false){
        let {legendData} = this.state
        if(this.props.isRange?.toString() === "true" && !withoutRange){
            return legendData[data_label]?.replace("-range", "") + "-range"
        }else{
            return legendData[data_label]?.replace("-range", "")
        }
    }

    getChartData(fr_id, data_label, number_format="money"){
        let {chartData, currentMonth} = this.props;
        let {legendData} = this.state
        if (number_format == "money" || number_format ==  null) {
            return this.numberToCurrency(eval(chartData[fr_id]?.[currentMonth]))    
        }else{
            return this.integerFormat(chartData[[fr_id]?.[currentMonth]])    
        }
        
    }
    
    getCostCenterLineData(reports, data_label, formula, key){
        const { currentMonth } = this.props
        try{
            return this.numberToCurrency(reports[`${data_label},${formula.id}`]["report"][formula.label][formula.label]["params"][key]?.[currentMonth]?.["difference"] * -1)
        }catch(e){
            return 0
        }
    }


    getCostCenterDetailMonths(cost_center, data_label){
        let {isRange, startDate, endDate} = this.props;
        let {legendData} = this.state;
        
        try{
            if(isRange.toString() === "true"){
                let legend = legendData[data_label]
                
                let firstMonth = moment(startDate[legend]).toDate().getMonth() + 1
                let endMonth = moment(endDate[legend]).toDate().getMonth() + 1
                let result = 0
                range(firstMonth, endMonth + 1).map((month_i)=>{
                    result += this.convertIntoNumber(cost_center.months[month_i])
                })
                return result
            }else{
                return cost_center.months[this.getCurrentMonth(data_label)]    
            }
        }catch(e){
            return 0
        }
    }
    integerFormat(number, title){
        if (title) {
            return new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(number).toFixed(2) )
        }else{
            return new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(number).toFixed(2)  * -1)
        }
        
    }

    // differenceBetweenData(data1, data2, type="money"){
    //     // Não me pergunte porque fiz isso, pra mim isso não tem lógica, foi o cliente que pediu assim
    //     // Ultima alteração 19/02 e o céu está aberto, com algumas nuvens, acho que chove hoje
        
        
    //     try{
    //         if(data1.toString().search(',') == -1){
    //             data1 = parseFloat(data1.toString().split("R$").join("").replace(/ /g,'').split(" ").join(''))    
    //         }else{
    //             data1 = parseFloat(data1.toString().split(".").join("").split("R$").join("").split(",").join(".").replace(/ /g,'').split(" ").join(''))    
    //         }
            
    //         if(data2.toString().search(',') == -1){
    //             data2 = parseFloat(data2.toString().split("R$").join("").replace(/ /g,'').split(" ").join(''))    
    //         }else{
    //             data2 = parseFloat(data2.toString().split(".").join("").split("R$").join("").split(",").join(".").replace(/ /g,'').split(" ").join(''))                    
    //         }
            
            
            
    //         let difference = (data1 - data2)
                
                
            
    //         if (type == null || type== "money") {
    //             return parseFloat(difference).toLocaleString("pt-BR", {
    //                 style: "currency",
    //                 currency: "BRL"
    //             });
                    
    //         }else if (type == "integer"){
    //             return this.integerFormat(difference)
    //         }
            
            
    //     }catch(e){
    //         return 0
    //     }
    // }

    otherDataLabel(data_label){
        if (data_label == "data1") {
            return "data2"
        }
        if (data_label == "data2") {
            return "data1"
        }
    }

    renderCostCenterDetail(level, data_label, label, x){
        const {currentFormulaData, costCentersFetched} = this.state;
        try{
           return <React.Fragment>
                {costCentersFetched[data_label] && costCentersFetched[data_label][label] && costCentersFetched[data_label][label][x[0].area.id] && Object.entries(costCentersFetched[data_label][label][x[0].area.id][x[0].is_fixed ? `fixed` : `not_fixed`]).map(([id, cost_center])=>{
                     return <tr className={'borboleta cost-center-row'}>
                        <td  style={{textIndent: (level * 8) + 50}}>{id}-{cost_center.label}</td>
                        <td>{this.numberToCurrency(this.getCostCenterDetailMonths(cost_center, data_label))}</td>
                        {Object.keys(currentFormulaData).map((key)=>(
                            key != data_label &&  
                            <React.Fragment>
                                <td>{costCentersFetched?.[key]?.[label] && costCentersFetched[key][label][x[0].area.id] && costCentersFetched[key][label][x[0].area.id][x[0].is_fixed ? `fixed` : `not_fixed`][id] && this.numberToCurrency(this.getCostCenterDetailMonths(costCentersFetched[key][label][x[0].area.id][x[0].is_fixed ? `fixed` : `not_fixed`][id], key))}</td>
                                <td>
                                    {/* {this.differenceBetweenData(this.numberToCurrency(this.getCostCenterDetailMonths(cost_center, data_label)), this.numberToCurrency(this.getCostCenterDetailMonths(costCentersFetched?.[key]?.[label]?.[x[0].area.id]?.[x[0].is_fixed ? `fixed` : `not_fixed`]?.[id], key)))} */}
                                </td>
                            </React.Fragment>
                        ))}
                    </tr>
                })}
            </React.Fragment> 
        }catch(e){
            
        }
        
    }

    async fetchAreas() {
    
        let result = await axios.get(`/cost_center_areas.json`, {
          params: {
            query: this.state.query
          }
        });

        let hash_data = {}
        
        result.data.map((area)=>{
          hash_data[area.id] = area.label
        })
        
        this.setState({areasDict: hash_data })  
        
    }

    toggleCost(label, area){
        this.toggleActive(`${area},${label}`, "cost_center")
        this.setState((oldState)=>{
            oldState.activeCosts[label] = !oldState.activeCosts[label]
            return {...oldState}
        })
    }

    costIsActive(label){
        return this.state.activeCosts[label]
    }

    
    convertIntoNumber(n=0){
        if((parseFloat(n) != NaN) || parseFloat(n) != undefined){
            return parseFloat(n)
        }else{
            return 0
        }
    }

    renderFormula(report, label, param_labels, head_groups){
        let firstMonth = this.props.currentMonth;
        let endMonth = this.props.currentMonth;
        let {paramActive, currentTable} = this.state
        if(this.props.currentMonth == "total"){
            firstMonth = "janeiro"
            endMonth = this.props.lastMonth
        }
        let months = []
        let skip = false
        Object.entries(monthsDict).map(([number, name])=>{
            if(!skip){
                months.push(name)
            }
            //break the loop if the name is equal to currentMonth
            if(name == endMonth){
                skip = true
            }
        })

        const totalMonths = months.length;
        const baseColSpan = Math.floor(totalMonths / 4); // ColSpan base para cada um dos 4 elementos
        const extraColSpan = totalMonths % 4
        const totalByVariation = {
            atual: 0,
            anterior: 0,
            difference: 0
        }
        const totalByHistoric = {
            janeiro: 0,
            fevereiro: 0,
            "março": 0,
            abril: 0,
            maio: 0,
            junho: 0,
            julho: 0,
            agosto: 0,
            setembro: 0,
            outubro: 0,
            novembro: 0,
            dezembro: 0
        }
        let acumuladoMensalIndex = {
            janeiro: 0,
            fevereiro: 0,
            "março": 0,
            abril: 0,
            maio: 0,
            junho: 0,
            julho: 0,
            agosto: 0,
            setembro: 0,
            outubro: 0,
            novembro: 0,
            dezembro: 0
        }
        return <> 
            {Object.entries(head_groups).map(([head_label, fr_ids])=>{
                let acumulado = {
                    atual: 0,
                    anterior: 0,
                    resultado: 0
                }
                let acumuladoMensal = {
                    janeiro: 0,
                    fevereiro: 0,
                    "março": 0,
                    abril: 0,
                    maio: 0,
                    junho: 0,
                    julho: 0,
                    agosto: 0,
                    setembro: 0,
                    outubro: 0,
                    novembro: 0,
                    dezembro: 0
                }
                let multiplier = 1
                let colReference = "difference"
                if(head_label != "resultado"){
                    multiplier = -1
                    colReference = "f_saldo_atual"
                }
                return <>
                {currentTable == "variation" && <><thead>
                    <tr className={'first-line'}>
                        <th>
                            {head_label.toUpperCase()}
                        </th>
                        <th>
                            {head_label == "resultado" ? "-" : "Saldo Anterior"}
                        </th>
                        <th>
                            {head_label == "resultado" ? "-" : "Saldo Atual"}
                        </th>
                        <th>
                            {head_label == "resultado" ? "Valor" : "Diferença"}
                        </th>
                    </tr>
                </thead>
                {fr_ids.map((fr_id)=>{
                    let saldoAnterior = this.convertIntoNumber(report.params[fr_id]?.[firstMonth]?.["f_saldo_anterior"])
                    let saldoAtual = this.convertIntoNumber(report.params[fr_id]?.[endMonth]?.[colReference])
                    let saldoResultado  = 0
                    
                    
                    months.map((month)=>{
                        if(this.props.currentMonth == "total"){
                            saldoResultado += this.convertIntoNumber(report.params[fr_id]?.[month]?.[colReference])
                        }else if(month == this.props.currentMonth){
                            saldoResultado += this.convertIntoNumber(report.params[fr_id]?.[month]?.[colReference])
                        }
                    })

                    acumulado["anterior"] += saldoAnterior
                    acumulado["atual"] += saldoAtual
                    acumulado["resultado"] += saldoResultado
                    totalByVariation.atual += saldoAtual * multiplier
                    totalByVariation.anterior += saldoAnterior * multiplier
                    totalByVariation.difference +=  head_label == "resultado" ? saldoResultado : ((saldoAtual - saldoAnterior))
                    return <>
                        <tr id="principal" className={`formula-grouped format-money formula-more-than-index`} >
                            <td>
                               {param_labels[fr_id]}
                            </td>
                            <td>
                                {head_label == "resultado" ? "-" : this.numberToCurrency(saldoAnterior * multiplier)}
                            </td>
                            <td>
                                {head_label == "resultado" ? "-" : this.numberToCurrency(saldoAtual * multiplier)}
                            </td>
                            <td>
                                {head_label == "resultado" ? this.numberToCurrency(saldoResultado) : this.numberToCurrency(saldoAtual - saldoAnterior)}
                            </td>
                        </tr>
                    </>
                })}
                <tr className={`formula-grouped format-money`}  >
                    <TotalTd>
                        Total
                    </TotalTd>
                    <TotalTd>
                        { head_label == "resultado" ? "-" : this.numberToCurrency(acumulado["anterior"] * multiplier)}
                    </TotalTd>
                    <TotalTd>
                        { head_label == "resultado" ? "-" : this.numberToCurrency(acumulado["atual"] * multiplier)}
                    </TotalTd>
                    <TotalTd>
                        { head_label == "resultado" ? this.numberToCurrency(acumulado["resultado"]) : this.numberToCurrency(acumulado["atual"] - acumulado["anterior"])}
                    </TotalTd>
                </tr>
                <tr>
                    <td colSpan={4}></td>
                </tr>
                
                </>}
                {currentTable == "history" && <><thead>
                        <tr className={'first-line'}>
                            <th colSpan={months.length + 1}>
                                {head_label.toUpperCase()}
                            </th>
                            
                        </tr>
                        <tr className={'first-line'}>
                            <th></th>
                            {months.map((month)=>{
                                return <th>
                                    {month}
                                </th>
                            })}
                        </tr>
                    </thead>
                    {fr_ids.map((fr_id)=>{
                        return <>
                            <tr id="principal" className={`formula-grouped format-money formula-more-than-index`} >
                                <td>{param_labels[fr_id]}</td>
                                {months.map((month)=>{
                                    let saldoAtual = this.convertIntoNumber(report.params[fr_id]?.[month]?.[colReference])
                                    acumuladoMensal[month] += saldoAtual
                                    totalByHistoric[month] += (saldoAtual * multiplier)
                                    return <td>
                                        {this.numberToCurrency(saldoAtual * multiplier)}
                                    </td>
                                })}
                            </tr>
                        </>
                    })}
                     <tr className={`formula-grouped format-money`}  >
                        <TotalTd>
                            Total
                        </TotalTd>
                        {months.map((month)=>{
                            return <TotalTd>
                                {this.numberToCurrency(acumuladoMensal[month] * multiplier)}
                            </TotalTd>
                        })}
                    </tr>
                    </>}
                </>
            })}
            {Object.keys(report.indicators || {}).length > 0 && 
            <>
            {currentTable == "variation" && <>
                    <thead>
                        <tr className={'first-line'}>
                            <th>
                                Indicadores
                            </th>
                            <th>
                            -
                            </th>
                            <th>
                            -
                            </th>
                            <th>
                            -
                            </th>
                        </tr>
                    </thead>
                {Object.entries(report.indicators).map(([key, indicatorsByMonth])=>{
                    
                    let saldoResultado  = 0
                    
                    
                    months.map((month) => {
                        if (this.props.currentMonth === "total" || month === this.props.currentMonth) {
                            saldoResultado += this.convertIntoNumber(indicatorsByMonth?.[month]);
                        }
                    });
                    

                    totalByVariation.difference +=  saldoResultado
                    return <>
                        <tr id="principal" className={`formula-grouped format-money formula-more-than-index`} >
                            <td>
                            {key}
                            </td>
                            <td>
                                -
                            </td>
                            <td>
                                -
                            </td>
                            <td>
                                {this.numberToCurrency(saldoResultado)}
                            </td>
                        </tr>
                    </>
                })}
            </>}
            {currentTable == "history" && <>
                <thead>
                        <tr className={'first-line'}>
                            <th colSpan={months.length + 1}>
                                Indicadores
                            </th>
                            
                        </tr>
                        <tr className={'first-line'}>
                            <th></th>
                            {months.map((month)=>{
                                return <th>
                                    {month}
                                </th>
                            })}
                        </tr>
                    </thead>
                {Object.entries(report.indicators).map(([key, indicatorsByMonth])=>{
                    
                    let saldoResultado  = 0
                    
                    
                    months.map((month) => {
                        if (this.props.currentMonth === "total" || month === this.props.currentMonth) {
                            saldoResultado += this.convertIntoNumber(indicatorsByMonth?.[month]);
                        }
                    });
                    

                    
                    return <>
                        <tr id="principal" style={{height: 46}} className={`formula-grouped format-money formula-more-than-index`} >
                            <th>{key}</th>
                            {months.map((month)=>{
                                acumuladoMensalIndex[month] += this.convertIntoNumber(indicatorsByMonth[month])
                                console.log(`antes ${month}`, totalByHistoric[month])
                                totalByHistoric[month] += this.convertIntoNumber(indicatorsByMonth[month])
                                console.log(`depois ${month}`, totalByHistoric[month])
                                return <th>
                                    {indicatorsByMonth[month]}
                                </th>
                            })}
                        </tr>
                    </>
                })}
                <tr className={`formula-grouped format-money`}  >
                        <TotalTd>
                            Total
                        </TotalTd>
                        {months.map((month)=>{
                            return <TotalTd>
                                {acumuladoMensalIndex[month]}
                            </TotalTd>
                        })}
                    </tr>
            </>}
            </>
            }
            <tr>
                <td colSpan={4}></td>
            </tr>
            
            {currentTable == "variation" &&<tr>
                <TotalTd>Total Geral</TotalTd>
                <TotalTd>{Object.keys(head_groups || {})?.includes("resultado") ? "-" : this.numberToCurrency(totalByVariation["anterior"])}</TotalTd>
                <TotalTd>{Object.keys(head_groups || {})?.includes("resultado") ? "-" : this.numberToCurrency(totalByVariation["atual"])}</TotalTd>
                <TotalTd>{this.numberToCurrency(totalByVariation["difference"])}</TotalTd>
            </tr>}
            {
                currentTable == "history" && <tr>
                    <TotalTd>Total Geral</TotalTd>
                    {months.map((month)=>{
                        return <TotalTd>
                            {this.numberToCurrency(totalByHistoric[month])}
                        </TotalTd>
                    })}
                </tr>
            }
        </>
    }

    

    closeTable(){
        this.setState({currentColumnId: null})
    }
    renderContent(){
        const handleChange = (event, newValue) => {
            this.setState({currentTab: newValue});
          };
        let loadedFormulas = {}
        let botBorder = (showStyle) => {
            if (showStyle){
                return {
                    borderBottom: "3px solid",
                    marginBottom: 10,
                    paddingBottom: 10
                }
            }else{
                return {}
            }
            
        }
        const {options,
             reports,
             dataLabels,
             costCenterMonthReports,
             despesasType,
             costCentersFetched,
             balanceRows,
             currentFormula,
             legendData,
             noFlowFormula,
             currentLabel,
             currentFormulaData
         } = this.state;
        const {disableTabs} = this.props;
        
        return <div style={{display: 'flex', flexDirection: 'column'}}>
            {!disableTabs && <Box sx={{ width: '100%', typography: 'body1' }}>
                <Tabs TabIndicatorProps={{style: {background:'#298793'}}} value={this.state.currentSituation} onChange={handleChange} >
                    <Tab 
                            
                        onClick={()=> {
                        this.setState({currentSituation: 'cash_flow'})
                        this.setupChartData('cash_flow')
                    }} label={this.props?.titles?.['cash_flow']} value="cash_flow" />
                    <Tab onClick={()=> {
                        this.setState({currentSituation: 'cash_flow_only'})
                        this.setupChartData('cash_flow_only')
                    }} label={this.props?.titles?.['cash_flow_only']} value="cash_flow_only" />
                </Tabs>
            </Box>}
            <div style={{width: "100%", ...botBorder(this.state.currentColumnId)}}>
                <h2 style={{textAlign: 'center'}}>{this.props.chartTitle}</h2>
                {this.state.chartIsVisible &&  this.renderHighChart()}    
            </div>

            {currentFormulaData?.length > 0 && <h2 style={{textAlign: 'center', margin: 10}}>{currentLabel}</h2>}
            {noFlowFormula && <h3 style={{textAlign: 'center', margin: 10}}>O detalhamento das contas de Resultado será realizado exclusivamente no Gráfico da "Visão Comparativo". Aqui, concentraremos o detalhamento nas contas patrimoniais. Continue explorando sua experiência.</h3>}
            {currentFormulaData?.length > 0 && <Box sx={{ width: '100%', typography: 'body1' }}>
                <Tabs TabIndicatorProps={{style: {background:'#298793'}}} value={this.state.currentTable} onChange={handleChange} >
                    <Tab 
                        onClick={()=> {
                        this.setState({currentTable: 'variation'})
                    }} label={"Variação"} value="variation" />
                    <Tab onClick={()=> {
                        this.setState({currentTable: 'history'})
                    }} label={"Histórico"} value="history" />
                </Tabs>
            </Box>}
            {currentFormulaData?.map((formula) => {
                    console.log(formula)
                    
                    return <>
                    
                        <table className="table borboletachart" style={{ margin: '10px', width: '99%'  }}>
                            {formula && Object.entries(formula.values || {})?.map(([label, value]) => {
                                let alreadLoaded = loadedFormulas[label] ? true : false;
                                loadedFormulas[label] = true;
                                return !alreadLoaded && this.renderFormula(value, label, formula.labels, formula.head_groups);
                            })}
                        </table>
                    </>
                })}
            

        </div>
    }
    generatePopUp(){
        let self = this
        axios.post(`/flow_chart_popups.json`, {
            props: self.props
        }).then(function(result){
            popupCenter({url: `/flow_chart_popups/${result.data.popup_id}`, title: 'Gráfico', w: window.innerWidth * 0.8, h: window.innerHeight * 0.8})
        });
    }
    
    render(){
      
      let {open, handleClose, chartData} = this.props
      
      if(this.props.popup){
          return (<div>
              {this.renderContent()}
          </div>)
      }
      else{
      return (
        <div>
          <Dialog
            onEnter={()=> this.setupChartData()}
            open={open}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={"lg"}
            PaperComponent={PaperComponent}
            
          >
            
            <DialogContent>
                {this.renderContent()}
                
            </DialogContent>
            <DialogActions>
              <div style={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
                    <Button onClick={handleClose} 
                    style={{
                        background: "#298793",
                        color: "white", 
                        marginLeft: 62
                    }}>
                        Fechar
                    </Button>
                </div>
                <Button 
                    style={{
                        border: '1px solid',
                        zIndex: 20,
                        visibility: this.state.currentColumnId ? 'visible' : 'hidden',
                        color: "white",
                        background: "#5BB1FE"
                    }}
                    onClick={()=> this.closeTable()} 
                    >
                            <i className="fa fa-chevron-up"></i>
                </Button>
              
              </div>
              
              
              
            </DialogActions>
          </Dialog>
        </div>
      );
    }
    }
}

function Chevron({active}){
    return(
        <i style={{margin: "0 10px"}} className={`fa fa-chevron-${active ? 'down' : 'right'}`}></i>
    )
}

function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}


const monthsDict = {
     1: "janeiro",
     2: "fevereiro",
     3: "março",
     4: "abril",
     5: "maio",
     6: "junho",
     7: "julho",
     8: "agosto",
     9: "setembro",
     10: "outubro",
     11: "novembro",
     12: "dezembro",
}

const reverseMonthDict ={
    "janeiro": 1,
    "fevereiro": 2,
    "março": 3,
    "abril": 4,
    "maio": 5,
    "junho": 6,
    "julho": 7,
    "agosto": 8,
    "setembro": 9,
    "outubro": 10,
    "novembro": 11,
    "dezembro": 12

}
const translate = {
    "analisado": "Real",
    "comparado": "Real",
    "budget": "Orçado"
}

const TotalTd = styled.td`
    background-color: #c0e9eb;
`