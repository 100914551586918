import React from 'react';
import Fuse from "fuse.js";
import styles from "./styles/FormulasLayoutStyles";
import Button from "@material-ui/core/Button";
import ReactDOM from "react-dom";
import {BlueButton} from "../styled_components/default";
import {
    overlayStyle,
    modalStyle,
    modalContentStyle,
    modalTitleStyle,
    modalDescriptionStyle,
    closeButtonStyle
} from './styles/ModalStyles';


class FormulasExistentesSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paramsQueryFormulas: "",
            anchorEl: null,
            activeRowId: null,
            dropdownVisible: false,
            dropdownTimeout: null,
            openModal: false,
            months: "",
            selectedRow: null,
            activeButton: null,
        };
        this.dropdownContainer = document.createElement('div');
        document.body.appendChild(this.dropdownContainer);
        this.modalContainer = null;
    }

    componentDidMount() {
        this.modalContainer = document.createElement('div');
        document.body.appendChild(this.modalContainer);
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.modalContainer && !prevState.openModal && this.state.openModal) {
            this.forceUpdate();
        }
    }

    handleOpenModal = (row) => {
        this.setState({ openModal: true, selectedRow: row });
    }

    handleCloseModal = () => {
        this.setState({ openModal: false });
        this.refreshModalParams();
    }

    componentWillUnmount() {
        document.body.removeChild(this.dropdownContainer);
        if (this.state.dropdownTimeout) {
            clearTimeout(this.state.dropdownTimeout);
        }
        if (this.modalContainer) {
            document.body.removeChild(this.modalContainer);
        }
    }

    handleAddButtonClick = (row) => {
        if (this.props.onAddItem) {
            this.props.onAddItem(row);
        }
    }

    textPeriod = () => {
        const operationType = this.state.tabActive;
        const months = this.state.months;
        return `.${operationType}_${months}`;
    }

    refreshModalParams = () => {
        this.setState({
            selectedRow: null,
            openModal: false,
            months: "",
            tabActive: null
        });
    }

    showDropdown = (rowId, row, event) => {
        this.setState({ dropdownVisible: true, activeRowId: rowId, anchorEl: event.currentTarget, selectedRow: row });
    };

    attemptHideDropdown = () => {
        const timeoutId = setTimeout(() => {
            if (!this.isMouseOverDropdown) {
                this.setState({ dropdownVisible: false });
            }
        }, 300);
        this.setState({ dropdownTimeout: timeoutId });
    };

    handleMouseEnterDropdown = () => {
        this.isMouseOverDropdown = true;
    };

    handleMouseLeaveDropdown = () => {
        this.isMouseOverDropdown = false;
        this.attemptHideDropdown();
    };

    handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            this.handleCloseModal();
            this.refreshModalParams();
        }
    }

    renderParams(formulas) {
        let filtered = {};

        var combinedData = formulas.map(item => ({
            title: "FÓRMULA",
            display: item.display || "",
            group: item.group || "",
            id: item.id || ""
        }));

        if (this.state.paramsQueryFormulas.length < 1) {
            filtered.formulas = combinedData;
        } else {
            var options = {
                shouldSort: true,
                threshold: 0.3,
                distance: 1000,
                maxPatternLength: 1500,
                minMatchCharLength: 3,
                keys: ["display"]
            };

            var fuse = new Fuse(combinedData, options);
            filtered.formulas = fuse.search(this.state.paramsQueryFormulas);
        }

        const htmlRender = filtered.formulas.map((item, index) => (
            <div
                style={{
                    ...styles.listItem,
                    backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white',
                }}
                key={index}
            >
                <Button onClick={() => this.handleAddButtonClick(item)}
                        onMouseEnter={(e) => this.showDropdown(item.id, item, e)}
                        onMouseLeave={this.attemptHideDropdown}
                >
                    <small style={{ fontStyle: 'italic', color: "#bababa", fontSize: '10px', whiteSpace: 'normal' }}>
                        {item.title ? (item.title + " -> ") : ""}
                        {item.group ? (item.group + " -> ") : ""}
                    </small>
                    &nbsp;{item.display}
                </Button>

                {this.props.isAttachment && this.state.dropdownVisible && this.state.activeRowId === item.id && ReactDOM.createPortal(
                    <div
                        style={{
                            position: 'fixed',
                            top: this.state.anchorEl.getBoundingClientRect().top -5,
                            left: this.state.anchorEl.getBoundingClientRect().right + 10,
                            zIndex: 1000,
                            backgroundColor: '#617E94',
                            boxShadow: '0px 0px 8px rgba(0,0,0,0.2)',
                            borderRadius: '4px',
                        }}
                        onMouseEnter={this.handleMouseEnterDropdown}
                        onMouseLeave={this.handleMouseLeaveDropdown}
                    >
                        <div style={{ borderBottom: '1px solid white' }}>
                            <Button
                                style={{
                                    color: 'white',
                                    padding: '11px 15px',
                                    display: 'block',
                                    border: 'none',
                                    width: '100%',
                                    background: 'none',
                                    textAlign: 'left',
                                }}
                                onClick={() => this.handleOpenModal(this.state.selectedRow)}
                            >
                                Adicionar Período
                            </Button>
                        </div>
                    </div>,
                    this.dropdownContainer
                )}

                {this.props.chartFormula && (
                    <div style={{ float: 'right', position: 'relative' }}>
                        <Button
                            onMouseEnter={() => this.setState({ activeButton: {buttonName: 'analisado', rowId: item.id} })}
                            onMouseLeave={() => this.setState({ activeButton: null })}
                            style={{
                                backgroundColor: '#03AB79',
                                color: 'white',
                                borderRadius: '15px',
                                padding: '2px 15px',
                                margin: '0 5px',
                                border: 'none',
                                fontSize: '10px'
                            }}
                            onClick={() => this.props.onAddTextAndRowToFormula(".data1", item)}
                        >
                            Analisado <span style={{ marginLeft: '10px', fontSize: '14px', verticalAlign: 'middle' }}>+</span>
                        </Button>
                        {this.state.activeButton && this.state.activeButton.buttonName === 'analisado' && this.state.activeButton.rowId === item.id && <div style={{
                            position: 'absolute',
                            top: '-8px',
                            right: '100%',
                            backgroundColor: '#f0f0f0',
                            border: '1px solid #ccc',
                            zIndex: 1,
                            borderRadius: '8px',
                            width: '480px'
                        }}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '10px 15px',
                                background: '#03AB79',
                                borderRadius: '6px',
                                color: 'white',
                                textAlign: 'left',
                                flexWrap: 'wrap',
                                height: 'auto',
                                whiteSpace: 'normal',
                                overflow: 'hidden',
                                fontSize: '12px'
                            }}>
                                <p style={{ margin: 0 }}>Utiliza o valor do período do ANO ATUAL (de janeiro até o mês do último upload de balanço)</p>
                            </div>
                        </div>}
                        <Button
                            onMouseEnter={() => this.setState({ activeButton: {buttonName: 'comparado', rowId: item.id} })}
                            onMouseLeave={() => this.setState({ activeButton: null })}
                            style={{
                            backgroundColor: '#0099D8',
                            color: 'white',
                            borderRadius: '15px',
                            padding: '2px 15px',
                            margin: '0 5px',
                            border: 'none',
                            fontSize: '10px'
                        }}
                                onClick={() => this.props.onAddTextAndRowToFormula(".data2", item)}>
                            Comparado <span style={{ marginLeft: '10px', fontSize: '14px', verticalAlign: 'middle' }}>+</span>
                        </Button>
                        {this.state.activeButton && this.state.activeButton.buttonName === 'comparado' && this.state.activeButton.rowId === item.id && <div style={{
                            position: 'absolute',
                            top: '-8px',
                            right: '100%',
                            backgroundColor: '#f0f0f0',
                            border: '1px solid #ccc',
                            zIndex: 1,
                            borderRadius: '8px',
                            width: '480px'
                        }}>
                            <div style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '10px 15px',
                                background: '#0099D8',
                                borderRadius: '6px',
                                color: 'white',
                                textAlign: 'left',
                                flexWrap: 'wrap',
                                height: 'auto',
                                whiteSpace: 'normal',
                                overflow: 'hidden',
                                fontSize: '12px'
                            }}>
                                <p style={{ margin: 0 }}>Utiliza o valor do período do ANO ANTERIOR (de janeiro até o mês referência - que é do último upload de balanço do ano)
                                </p>
                            </div>
                        </div>}
                    </div>
                )}
            </div>
        ));

        return htmlRender;
    }

    render() {
        const { formulas } = this.props;

        return (
            <div style={styles.bgContent}>
                <div style={styles.header}>
                    <div style={styles.blockTitle}>
                        <span style={styles.icon}>#</span> FÓRMULAS EXISTENTES
                    </div>
                    <div>
                        <input
                            style={styles.searchInput}
                            placeholder={"Buscar um item nas fórmulas"}
                            onChange={e => {
                                let newValue = e.target.value;
                                this.setState({ paramsQueryFormulas: newValue });
                            }}
                        />
                    </div>
                </div>
                <div style={{
                    ...styles.dataContainer,
                    overflowY: 'auto',
                    maxHeight: this.props.maxHeightChartFormula || '675px'
                }}>
                    {formulas ? this.renderParams(formulas) : <div>Carregando...</div>}
                </div>

                {this.state.openModal && (
                    <div style={overlayStyle} onClick={this.handleOverlayClick}>
                        <div style={modalStyle}>
                            <button style={closeButtonStyle} onClick={this.handleCloseModal}>&times;</button>
                            <div style={modalContentStyle}>
                                <div style={modalTitleStyle}>Adicionar Período</div>
                                <div style={modalDescriptionStyle}>
                                    Você pode visualizar a SOMA ou a MÉDIA dos últimos meses que desejar. Ex: A média dos últimos 6 meses (informe o “tipo de operação: média” e a “quantidade: 6”
                                </div>

                                <div style={{ paddingTop: '20px', display: 'flex', alignItems: 'center' }}>
                                    <div style={{ ...modalTitleStyle, fontSize: '15px', marginRight: '5px' }}>Fórmula:</div>
                                    <div style={{ ...modalDescriptionStyle, marginBottom: '2.1px' }}>{this.state.selectedRow.display}</div>
                                </div>

                                <div style={{ paddingTop: '20px' }} className={"input"}>
                                    <label style={{ color: 'white' }}>Tipo Operação</label>
                                    <div style={styles.tabs}>
                                        {['soma', 'media'].map((format) => (
                                            <div
                                                key={format}
                                                onClick={() => this.setState({ tabActive: format })}
                                                style={{ ...styles.tab, ...(this.state.tabActive === format ? styles.tabActive : {}) }}
                                            >
                                                {format === 'soma' ? 'SOMA' : 'MÉDIA'}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div style={{ paddingTop: '20px' }} className={"input"}>
                                    <label style={{ color: 'white' }}>Quantidade (em meses)</label>
                                    <input
                                        value={this.state.months}
                                        type="text"
                                        style={{ width: '50%' }}
                                        onChange={(x) => this.setState({ months: x.currentTarget.value })}
                                    />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                    <BlueButton style={{ border: 'none' }} onClick={() => {
                                        this.props.onAddTextAndRowToFormula(this.textPeriod(), this.state.selectedRow);
                                        this.handleCloseModal();
                                        this.refreshModalParams();
                                    }}>
                                        Aplicar
                                    </BlueButton>
                                </div>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        );
    }
}

export default FormulasExistentesSearch;
