import React, { useState } from 'react';
import cleverviewFinImage from './images/fin_menu.png';
import cleverviewCashImage from './images/cash_menu.png';
import gtImage from './images/gt_menu.png';
import { Logo } from '../../styled_components/header/box';
// Dados dos sistemas com imagens importadas
const systemsData = [
  {
    name: 'Cleverview Fin',
    image: cleverviewFinImage,
    url: 'https://sistema.cleverview.com.br',
  },
  {
    name: 'Cleverview Cash',
    image: cleverviewCashImage,
    url: 'https://cash.cleverview.com.br/',
  },
  {
    name: 'Cleverview GT',
    image: gtImage,
    url: '/consulting',
  },
];

const SystemSelector = () => {
  const [selectedSystem, setSelectedSystem] = useState(systemsData[0]);
  const [showOptions, setShowOptions] = useState(false);

  const handleSystemClick = () => {
    setShowOptions(!showOptions);
  };

  const handleSelectSystem = (system) => {
    window.open(system.url, '_blank');
  };
  

  return (
    <div>
      {/* Imagem do sistema selecionado */}
      <div onClick={handleSystemClick} style={{ cursor: 'pointer' }}>
        <Logo></Logo>
      </div>

      {/* Lista de sistemas disponíveis */}
      {showOptions && (
        <div style={{ position: 'absolute', backgroundColor: '#23323f', padding: '8px', width: 244, zIndex: 9 }}>
          {systemsData
            .map((system) => (
              <div key={system.name} onClick={() => handleSelectSystem(system)} style={{border: "solid 1px #617e94", padding: "16px 25px", marginBottom: '18px', cursor: 'pointer' }}>
                <img src={system.image} alt={system.name} style={{width: '100%', height: 'auto'}}  />
              </div>
            ))}
        </div>
      )}
    </div>
  );
};

export default SystemSelector;
