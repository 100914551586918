import React from 'react';
import ReactDOM from 'react-dom';
import Button from '@material-ui/core/Button';
import Fuse from "fuse.js";
import styles from "./styles/FormulasLayoutStyles";
import {
    InterrogationIcon
} from "./styles/svgIcons";
import {
    closeButtonStyle,
    modalContentStyle,
    modalDescriptionStyle,
    modalStyle,
    modalTitleStyle,
    overlayStyle
} from "./styles/ModalStyles";
import {BlueButton} from "../styled_components/default";

class ContasContabeisSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabActive: "ativos",
            paramsQueryContas: "",
            anchorEl: null,
            activeRowId: null,
            dropdownVisible: false,
            dropdownTimeout: null,
            selectedRow: null
        };
        this.dropdownContainer = document.createElement('div');
        document.body.appendChild(this.dropdownContainer);
    }

    componentWillUnmount() {
        document.body.removeChild(this.dropdownContainer);
        if (this.state.dropdownTimeout) {
            clearTimeout(this.state.dropdownTimeout);
        }
    }

    handleAddButtonClick = (row) => {
        if (this.props.onAddItem) {
            this.props.onAddItem(row);
        }
    }

    showDropdown = (rowId, event) => {
        this.setState({ dropdownVisible: true, activeRowId: rowId, anchorEl: event.currentTarget });
    };

    attemptHideDropdown = () => {
        const timeoutId = setTimeout(() => {
            if (!this.isMouseOverDropdown) {
                this.setState({ dropdownVisible: false });
            }
        }, 300);
        this.setState({ dropdownTimeout: timeoutId });
    };

    handleOpenModal = (row) => {
        this.setState({ openModal: true, selectedRow: row });
    };

    handleCloseModal = () => {
        this.setState({ openModal: false });
        this.refreshModalParams();
    }

    textPeriod = () => {
        const operationType = this.state.tabActivePeriod;
        const months = this.state.months;
        return `.${operationType}_${months}`;
    };

    refreshModalParams = () => {
        this.setState({
            selectedRow: null,
            openModal: false,
            months: "",
            tabActivePeriod: null
        });
    };

    handleMouseEnterDropdown = () => {
        this.isMouseOverDropdown = true;
    };

    handleMouseLeaveDropdown = () => {
        this.isMouseOverDropdown = false;
        this.attemptHideDropdown();
    };

    renderParams(data, titleData) {
        let filtered = {};
        const tabActiveUpperCase = this.state.tabActive.toUpperCase();

        data = data.filter((row) => {
            const title = titleData[row.id].toUpperCase();
            return title.startsWith(tabActiveUpperCase + " •") || title === tabActiveUpperCase;
        });

        if (this.state.paramsQueryContas.length < 1) {
            filtered = { data };
        } else {
            var options = {
                shouldSort: true,
                threshold: 0.4,
                distance: 1000,
                maxPatternLength: 1500,
                minMatchCharLength: 3,
                keys: ["display", "title"]
            };
            var combinedData = data.map(item => ({
                ...item,
                title: titleData[item.id] || "",
                display: item.display || ""
            }));
            var fuse = new Fuse(combinedData, options);
            filtered.data = fuse.search(this.state.paramsQueryContas);
        }

        return filtered.data.map((item, index) => (
            <div
                style={{
                    ...styles.listItem,
                    backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white',
                }}
                key={index}
            >
                <Button onClick={() => this.handleAddButtonClick(item)}
                        onMouseEnter={(e) => this.showDropdown(item.id, e)}
                        onMouseLeave={this.attemptHideDropdown}>
                    <small style={{ fontStyle: 'italic', color: "#bababa", fontSize: '10px', whiteSpace: 'normal' }}>
                        {titleData[item.id] ? (titleData[item.id] + " -> ") : ""}
                    </small>
                    &nbsp;{item.display}
                </Button>
                {this.state.dropdownVisible && this.state.activeRowId === item.id && ReactDOM.createPortal(
                    <div
                        style={{
                            position: 'fixed',
                            top: this.state.anchorEl.getBoundingClientRect().top,
                            left: this.state.anchorEl.getBoundingClientRect().right + 10,
                            zIndex: 1000,
                            backgroundColor: '#617E94',
                            boxShadow: '0px 0px 8px rgba(0,0,0,0.2)',
                            borderRadius: '4px',
                        }}
                        onMouseEnter={this.handleMouseEnterDropdown}
                        onMouseLeave={this.handleMouseLeaveDropdown}
                    >
                        <div style={{ borderBottom: '1px solid white' }}>
                            <Button
                                style={{
                                    color: 'white',
                                    padding: '12px 16px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    border: 'none',
                                    width: '200px',
                                    background: 'none',
                                }}
                                onClick={() => this.props.onAddTextAndRowToFormulaInput(".difference", item)}
                            >
                                Diferença
                                <div style={{ position: 'relative' }}>
                                    <button
                                        style={{ background: 'none', border: 'none' }}
                                        onMouseEnter={() => this.setState({ showTooltip: `${item.id}-diferenca` })}
                                        onMouseLeave={() => this.setState({ showTooltip: null })}
                                    >
                                        <InterrogationIcon />
                                    </button>
                                    {this.state.showTooltip === `${item.id}-diferenca` && (
                                        <div style={{
                                            position: 'absolute',
                                            top: '-36px',
                                            left: '45px',
                                            backgroundColor: '#617E94',
                                            border: '1px solid #ccc',
                                            zIndex: 1,
                                            borderRadius: '8px',
                                            width: '350px'
                                        }}>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                padding: '15px 20px',
                                                background: '#617E94',
                                                borderRadius: '6px',
                                                color: 'white',
                                                textAlign: 'center',
                                                flexWrap: 'wrap',
                                                fontSize: '11px'
                                            }}>
                                                Busca o valor das colunas débito+crédito para o cubo selecionado (caso não se use nenhuma sintaxe após a escolha do cubo, a coluna de Débito+Crédito é escolhida como padrão)
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Button>
                        </div>

                        <div style={{ borderBottom: '1px solid white' }}>
                            <Button
                                style={{
                                    color: 'white',
                                    padding: '12px 16px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    border: 'none',
                                    width: '100%',
                                    background: 'none',
                                }}
                                onClick={() => this.props.onAddTextAndRowToFormulaInput(".f_saldo_anterior", item)}
                            >
                                Saldo Anterior
                                <div style={{ position: 'relative' }}>
                                    <button
                                        style={{ background: 'none', border: 'none' }}
                                        onMouseEnter={() => this.setState({ showTooltip: `${item.id}-saldo-anterior` })}
                                        onMouseLeave={() => this.setState({ showTooltip: null })}
                                    >
                                        <InterrogationIcon />
                                    </button>
                                    {this.state.showTooltip === `${item.id}-saldo-anterior` &&  (
                                        <div style={{
                                            position: 'absolute',
                                            top: '-23px',
                                            left: '45px',
                                            backgroundColor: '#f0f0f0',
                                            border: '1px solid #ccc',
                                            zIndex: 1,
                                            borderRadius: '8px',
                                            width: '350px'
                                        }}>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                padding: '15px 20px',
                                                background: '#617E94',
                                                borderRadius: '6px',
                                                color: 'white',
                                                textAlign: 'center',
                                                flexWrap: 'wrap'
                                            }}>
                                                Busca o valor da coluna saldo anterior para o cubo selecionado.
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Button>
                        </div>
                        <div style={{ borderBottom: '1px solid white' }}>
                            <Button
                                style={{
                                    color: 'white',
                                    padding: '12px 16px',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    border: 'none',
                                    width: '100%',
                                    background: 'none',
                                }}
                                onClick={() => this.props.onAddTextAndRowToFormulaInput(".f_saldo_atual", item)}
                            >
                                Saldo Atual
                                <div style={{ position: 'relative' }}>
                                    <button
                                        style={{ background: 'none', border: 'none' }}
                                        onMouseEnter={() => this.setState({ showTooltip: `${item.id}-saldo-atual` })}
                                        onMouseLeave={() => this.setState({ showTooltip: null })}
                                    >
                                        <InterrogationIcon />
                                    </button>
                                    {this.state.showTooltip === `${item.id}-saldo-atual` && (
                                        <div style={{
                                            position: 'absolute',
                                            top: '-25px',
                                            left: '45px',
                                            backgroundColor: '#f0f0f0',
                                            border: '1px solid #ccc',
                                            zIndex: 1,
                                            borderRadius: '8px',
                                            width: '350px'
                                        }}>
                                            <div style={{
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                padding: '15px 20px',
                                                background: '#617E94',
                                                borderRadius: '6px',
                                                color: 'white',
                                                textAlign: 'center',
                                                flexWrap: 'wrap'
                                            }}>
                                                Busca o valor da coluna saldo atual para o cubo selecionado.
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Button>
                        </div>
                        <Button
                            style={{
                                color: 'white',
                                padding: '12px 16px',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                border: 'none',
                                width: '100%',
                                background: 'none',
                            }}
                            onClick={() => this.handleOpenModal(item)}
                        >
                            Adicionar Período
                            <div style={{ position: 'relative' }}>
                                <button
                                    style={{ background: 'none', border: 'none' }}
                                >
                                </button>
                            </div>
                        </Button>
                    </div>,
                    this.dropdownContainer
                )}
            </div>
        ));
    }

    render() {
        const { data, titleData } = this.props;
        const { tabActive, tabActivePeriod } = this.state;

        return (
            <div style={styles.bgContent}>
                <div style={{...styles.header, marginBottom: '10px'}}>
                    <div style={styles.blockTitle}>
                        <span style={styles.icon}>@</span>CONTAS CONTÁBEIS
                    </div>
                    <div>
                        <input
                            style={styles.searchInput}
                            placeholder={"Buscar um item nas contas"}
                            onChange={e => {
                                let newValue = e.target.value;
                                this.setState({ paramsQueryContas: newValue });
                            }}
                        />
                    </div>
                </div>
                <div style={{ ...styles.tabs, width: '50%', display: 'flex', margin: '10px auto' }}>
                    <div onClick={() => this.setState({ tabActive: "ativos" })}
                         style={{ ...styles.tab, ...(tabActive === "ativos" ? styles.tabActive : {}) }}>
                        Ativos
                    </div>
                    <div onClick={() => this.setState({ tabActive: "passivos" })}
                         style={{ ...styles.tab, ...(tabActive === "passivos" ? styles.tabActive : {}) }}>
                        Passivos
                    </div>
                    <div onClick={() => this.setState({ tabActive: "resultados" })}
                         style={{ ...styles.tab, ...(tabActive === "resultados" ? styles.tabActive : {}) }}>
                        Resultados
                    </div>
                </div>
                <div style={styles.dataContainer}>
                    {data ? this.renderParams(data, titleData) : <div>Carregando...</div>}
                </div>

                {this.state.openModal && (
                    <div style={overlayStyle} onClick={this.handleOverlayClick}>
                        <div style={modalStyle}>
                            <button style={closeButtonStyle} onClick={this.handleCloseModal}>&times;</button>
                            <div style={modalContentStyle}>
                                <div style={modalTitleStyle}>Adicionar Período</div>
                                <div style={modalDescriptionStyle}>
                                    Você pode visualizar a SOMA ou a MÉDIA dos últimos meses que desejar. Ex: A média dos últimos 6 meses (informe o “tipo de operação: média” e a “quantidade: 6”
                                </div>

                                <div style={{ paddingTop: '20px', display: 'flex', alignItems: 'center' }}>
                                    <div style={{ ...modalTitleStyle, fontSize: '15px', marginRight: '5px' }}>Conta:</div>
                                    <div style={{ ...modalDescriptionStyle, marginBottom: '2.1px' }}>{this.state.selectedRow.display}</div>
                                </div>

                                <div style={{ paddingTop: '20px' }} className={"input"}>
                                    <label style={{ color: 'white' }}>Tipo Operação</label>
                                    <div style={styles.tabs}>
                                        {['soma', 'media'].map((format) => (
                                            <div
                                                key={format}
                                                onClick={() => this.setState({ tabActivePeriod: format })}
                                                style={{ ...styles.tab, ...(this.state.tabActivePeriod === format ? styles.tabActive : {}) }}
                                            >
                                                {format === 'soma' ? 'SOMA' : 'MÉDIA'}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div style={{ paddingTop: '20px' }} className={"input"}>
                                    <label style={{ color: 'white' }}>Quantidade (em meses)</label>
                                    <input
                                        value={this.state.months}
                                        type="text"
                                        style={{ width: '50%' }}
                                        onChange={(x) => this.setState({ months: x.currentTarget.value })}
                                    />
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
                                    <BlueButton style={{ border: 'none' }} onClick={() => {
                                        this.props.onAddTextAndRowToFormulaInput(this.textPeriod(), this.state.selectedRow);
                                        this.handleCloseModal();
                                        this.refreshModalParams();
                                    }}>
                                        Aplicar
                                    </BlueButton>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

        );
    }
}

export default ContasContabeisSearch;
