import React from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';


export default class FormDialog extends React.Component {
  state = {
    configuredAtLeastOnce: {},
    item: {
      operation: "credito"
    },
    

  }

  onEnter(param){
    this.setState({item: {...this.state.item, ...this.props.currentItem}})      
  }

  handleChange(event){
    let newValue = event.currentTarget.value
    console.log(newValue);
    this.setState((oldState)=>{
      oldState.item.operation = newValue
      return {...oldState}
    })
    
  }

  getDefaultValue(currentItem){
    const {trabalhistas_id, operacionais_id} = this.props;
    const {configuredAtLeastOnce} = this.state;
    
    let alreadyConfigured = [trabalhistas_id, operacionais_id].includes(currentItem.id)
    if(alreadyConfigured || configuredAtLeastOnce?.[currentItem.id] ){ 
      if(!this.props.lastHeader[currentItem.head_of_cost_center]){
        return currentItem.head_of_cost_center
      }else if(this.props.lastHeader[currentItem.head_of_cost_center] == currentItem.id ){
        return currentItem.head_of_cost_center  
      }else{
        return null
      }
    }else{
      return null
    }
    
  }

  saveCostCenterConfig(currentRoute, currentIndex, item){
    const {saveEdit} = this.props;
    const {configuredAtLeastOnce} = this.state;
    this.setState({configuredAtLeastOnce: {...configuredAtLeastOnce, [item.id]: true}})
    saveEdit(currentRoute, currentIndex, item)

  }

  render() {
    const { open, handleClose, currentItem, currentRoute, currentIndex } = this.props;
    const {item} = this.state;

    return (
      <div>
        <Dialog
          open={open}
          onEnter={this.onEnter.bind(this)}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Editar parametro</DialogTitle>
          <DialogContent style={{minWidth: 552}}>
            <DialogContentText>
              Definições do parametro
            </DialogContentText>
            
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label="Novo parametro"
              type="email"
              onChange={(event)=> {
                let newValue = event.currentTarget.value
                this.setState((oldState)=>{
                  console.log(newValue);
                  oldState.item.label = newValue
                  return {...oldState}
                })

              }}
              defaultValue={currentItem.label}
              fullWidth
            />
            <div>
              <hr/>
              <DialogContentText>
                <a onClick={()=> this.setState({showAdvanced: !this.state.showAdvanced})}>Avançado <i className={`fa fa-chevron-${this.state.showAdvanced ? 'down' : 'right'}`}/></a>
              </DialogContentText>
              
              {this.state.showAdvanced && <div><DialogContentText>
                Este parametro é cabeça das despesas
              </DialogContentText>
              <RadioGroup defaultValue={this.getDefaultValue(currentItem)} 
                          aria-label="gender" 
                          name="customized-radios" 
                          onChange={(event)=> {
                                      let newValue = event.currentTarget.value
                                      this.props.setLastHeader(newValue, currentItem.id)
                                      this.setState((oldState)=>{
                                        console.log(newValue);
                                        oldState.item.head_of_cost_center = newValue
                                        return {...oldState}
                                      })

                                    }}
                          >
                <FormControlLabel
                  value="trabalhistas"
                  control={<Radio color="primary" />}
                  label="Trabalhistas"
                  labelPlacement="trabalhistas"
                />
                <FormControlLabel
                  value="operacionais"
                  control={<Radio color="primary" />}
                  label="Gerais"
                  labelPlacement="operacionais"
                />
              </RadioGroup>
              </div>}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={()=> this.saveCostCenterConfig(currentRoute, currentIndex, item)} color="primary">
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
