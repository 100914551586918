import React from "react";
import formula_tutorial from "formula_tutorial.jpeg";
import FormulaMentionInput from "./FormulaMentionInput";
import {
    BlueButton
} from "../styled_components/default.js";
import ContasContabeisSearch from './ContasContabeisSearch';
import FormulasExistentesSearch from "./FormulasExistentesSearch";
import IndicadoresFormulasSearch from "./IndicadoresFormulasSearch";
import CentroDeCustoFormulasSearch from "./CentroDeCustoFormulasSearch";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import styles from "./styles/FormulasLayoutStyles";
import ReactDOM from "react-dom";
import {
    LineIcon,
    SquareIcon,
    GrupoAbertoIcon,
    EyeIcon,
    CheckDownIcon,
    ShareIcon
} from "./styles/svgIcons";

class Formula extends React.Component {
    constructor(props) {
        super(props);
        this.input = React.createRef();
        this.toggleButtonRef = React.createRef();

        let formula = this.props.formula || {};

        this.state = {
            data: [],
            hashtagData: [],
            pattern: formula.pattern || "",
            label: formula.label || "",
            position: formula.position,
            scope: formula.scope,
            description: formula.description,
            layout: formula.layout,
            number_format: formula.number_format,
            cost_center_demonstration: formula.cost_center_demonstration,
            formula_type: formula.formula_type,
            contabil_pattern_reference_id: formula.contabil_pattern_reference_id,
            contabil_patterns: [],
            customData: this.props.customData,
            showPortal: false,
            showOperationWarning: false
        };
    }

    async save() {
        const { number_format, pattern, label, scope, formula_type, contabil_pattern_reference_id, position, cost_center_demonstration, layout, description } = this.state;
        let result = null;

        const formulaData = { description, layout, number_format, pattern, label, scope, formula_type, contabil_pattern_reference_id, position, cost_center_demonstration };

        try {
            if (this.props.formula.id) {
                result = await axios.put(`/formulas/${this.props.formula.id}.json`, { formula: formulaData });
            } else {
                formulaData.contabil_pattern_reference_id = this.props.contabil_pattern_id;
                result = await axios.post(`/formulas.json`, { contabil_pattern_id: this.props.contabil_pattern_id, formula: formulaData });
            }

            if ([201, 200].includes(result.status)) {
                alert("Formula alterada com sucesso")
                let link = `/formulas?contabil_pattern_id=${this.props.contabil_pattern_id ? this.props.contabil_pattern_id : this.props.formula.contabil_pattern_reference_id}`

                if(scope.search("attachment") >= 0){
                    link = `/attachments/formulas?contabil_pattern_id=${this.props.contabil_pattern_id ? this.props.contabil_pattern_id : this.props.formula.contabil_pattern_reference_id}`
                }
                Turbolinks.visit(link)
            } else {
                alert("Ocorreu algum erro");
            }
        } catch (error) {
            if (error.response) {
                // A solicitação foi feita e o servidor respondeu com um código de status que cai fora do intervalo de 2xx
                let errorMessages = [];
                for (let field in error.response.data) {
                    let messages = error.response.data[field];
                    messages.forEach(message => {
                        errorMessages.push(`${field}: ${message}`);
                    });
                }
                alert(`Erros de validação:\n${errorMessages.join('\n')}`);
            } else if (error.request) {
                // A solicitação foi feita, mas nenhuma resposta foi recebida
                console.error(error.request);
                alert("Erro na solicitação, sem resposta do servidor");
            } else {
                // Algo aconteceu na configuração da solicitação que acionou um erro
                console.error('Error', error.message);
                alert("Erro inesperado");
            }
            console.error(error.config);
        }
    }

    componentDidMount() {
        try {
            this.setState({
                data: this.props.params.map(([display, id]) => ({ id, display })),
                hashtagData: this.props.formulas.map(([group, display, id]) => ({
                    id,
                    display,
                    group
                })),
            });
        } catch (e) {
            console.error(e);
        }
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    handleClickOutside = (event) => {
        if (
            this.state.showPortal &&
            this.portalRef &&
            !this.portalRef.contains(event.target) &&
            this.toggleButtonRef.current !== event.target
        ) {
            this.closePortal();
        }
    };

    scopes(scope) {
        let scopeOptions = [];

        if (this.props.is_attachment) {
            scopeOptions = [
                { label: 'ANEXO - TABELA', value: 'attachment_table' }
            ];
        } else {
            scopeOptions = [
                { label: 'FLUXO DE CAIXA', value: 'cash_flow' },
                { label: 'DRE', value: 'dre' },
                { label: 'DRE GERENCIAL', value: 'dre_cc' }
            ];
        }

        return (
            <div style={styles.tabs}>
                {scopeOptions.map((option) => (
                    <div
                        key={option.value}
                        onClick={() => this.setState({ scope: option.value })}
                        style={{ ...styles.tab, ...(this.state.scope === option.value ? styles.tabActive : {}) }}
                    >
                        {option.label}
                    </div>
                ))}
            </div>
        );
    }

    handleButtonClick = (operation) => {
        let symbol;
        switch(operation) {
            case 'Soma':
                symbol = '+';
                break;
            case 'Subtração':
                symbol = '-';
                break;
            case 'Multiplicação':
                symbol = '*';
                break;
            case 'Divisão':
                symbol = '/';
                break;
            default:
                symbol = '';
        }
        const newPattern = this.state.pattern + ' ' + symbol + ' ';
        this.setState({ pattern: newPattern }, () => {
            this.checkOperations(newPattern);
        });
    };

    addSelectedItemToFormula = (selectedItem, prefix) => {
        let newPattern;

        if (prefix === '@') {
            newPattern = `${this.state.pattern} @[${selectedItem.display}](${selectedItem.id})`;
        } else if (prefix === '!') {
            newPattern = `${this.state.pattern} ![${selectedItem.id}]`;
        } else if (prefix === '?') {
            newPattern = `${this.state.pattern} ?[${selectedItem.display}]($[@cc${selectedItem.id}])`;
        } else {
            newPattern = `${this.state.pattern} ${prefix}[${selectedItem.display}]($[${selectedItem.id}])`;
        }

        this.setState({ pattern: newPattern });
    }

    addTextAndRowToFormulaInput = (textToAdd, row) => {
        let newPattern;

        row = `@[${row.display}](${row.id})`;

        if (row) {
            newPattern = `${this.state.pattern}{${row}${textToAdd}}`;
        } else {
            newPattern = `${this.state.pattern}${textToAdd}`;
        }

        this.setState({ pattern: newPattern });
    }

    addTextAndRowToFormula = (textToAdd, row) => {
        let newPattern;
        row = `#[${row.display}]($[${row.id}])`;
        if (row) {
            newPattern = `${this.state.pattern}{${row}${textToAdd}}`;
        } else {
            newPattern = `${this.state.pattern}${textToAdd}`;
        }
        this.setState({ pattern: newPattern });
    }

    openPortal = (event) => {
        this.setState({ showPortal: true });
    };

    closePortal = () => {
        this.setState({ showPortal: false });
    }

    showFormulas = () => {
        const previews = [];
        const formulaPreview = this.formulasPreview();
        const formulaGroupedPreview = this.formulasGroupedPreview();
        if (formulaPreview) {
            previews.push(formulaPreview);
        }
        if (formulaGroupedPreview) {
            previews.push(formulaGroupedPreview);
        }
        return previews;
    };

    formulasPreview = () => {
        const { formula_type, layout, label } = this.state;
        if (formula_type === 'formula') {
            const options = {
                'formula': {'normal': 'FormulaNormal', 'grupo_especial': 'FormulaEspecial'}
            };
            const option = options[formula_type]?.[layout];
            const styleMap = {
                'FormulaNormal': {
                    backgroundColor: '#c0e9eb',
                    color: '#2A3170',
                    fontWeight: 'bold'
                },
                'FormulaEspecial': {
                    backgroundColor: 'white',
                    color: '#5d92c5',
                    fontStyle: 'italic',
                    fontWeight: 'bold'
                }
            };
            const commonStyle = {
                height: '35px',
                padding: '10px',
                borderRadius: '6px',
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'left'
            };
            const style = { ...commonStyle, ...styleMap[option] };
            return (
                <div style={{ textAlign: 'left', width: '100%', borderTop: '2px solid #d1d0d0', position: 'relative' }}>
                    <div style={{ position: 'absolute', top: '-20px', right: 0, color: '#2A3170', fontWeight: 'bold' }}>
                        Valores por mês / ano
                    </div>
                    <div style={{ position: 'relative' }}>
                        <div style={{ display: 'flex', width: '100%' }}>
                            <div style={{ flex: 1 }}></div>
                        </div>
                        <div style={style}>{label}</div>
                        <div style={{ position: 'absolute', top: 0, bottom: 0, left: '50%', borderLeft: '2px solid #d1d0d0' }}></div>
                    </div>
                </div>
            );
        }
        return null;
    };

    formulasGroupedPreview = () => {
        const { formula_type, layout, label } = this.state;
        if (formula_type === 'grouped') {
            const options = {
                'grouped': {'normal': 'FormulaGrupoNormal', 'grupo_especial': 'FormulaGrupoEspecial'}
            };

            const option = options[formula_type]?.[layout];

            const styleMap = {
                'FormulaGrupoNormal': {
                    backgroundColor: '#eff2f4',
                    color: '#2A3170',
                    fontWeight: 'bold'
                },
                'FormulaGrupoEspecial': {
                    backgroundColor: '#c0e9eb',
                    color: '#2A3170',
                    fontStyle: 'italic',
                    fontWeight: 'bold'
                }
            };
            const commonStyle = {
                height: '35px',
                padding: '10px',
                borderRadius: '6px',
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'left'
            };

            const style = { ...commonStyle, ...styleMap[option] };
            const pattern = this.state.pattern;
            const regex = /@\[(.*?)\]|!\[(.*?)\]|\?\[(.*?)\]|#\[(.*?)\]/g;
            let match;
            const names = [];

            while ((match = regex.exec(pattern)) !== null) {
                const name = match[1] || match[2] || match[3] || match[4];
                names.push(name);
            }

            return (
                <div style={{ textAlign: 'left', width: '100%', borderTop: '2px solid #d1d0d0', position: 'relative' }}>
                    <div style={{ position: 'absolute', top: '-20px', right: 0, color: '#2A3170', fontWeight: 'bold' }}>
                        Valores por mês / ano
                    </div>
                    <div style={{ position: 'relative' }}>
                        <div style={{ display: 'flex', width: '100%' }}>
                            <div className="custom-scroll" style={{
                                flex: 1,
                                padding: '6px',
                                maxHeight: '175px',
                                overflowY: 'auto',
                                scrollbarWidth: 'thin',
                                scrollbarColor: '#d1d0d0 #ffffff'
                            }}>
                                <style>
                                    {`
                            .custom-scroll::-webkit-scrollbar {
                                width: 8px;
                            }
                            .custom-scroll::-webkit-scrollbar-thumb {
                                background: #d1d0d0;
                            }
                            .custom-scroll::-webkit-scrollbar-track {
                                background: #ffffff;
                            }
                        `}
                                </style>
                                {names.map((name, index) => (
                                    <div style={{ marginBottom: '5px' }} key={index}>
                                        <i className="pull-left fa fa-chevron-right"></i>
                                        {name}
                                    </div>
                                ))}
                            </div>
                            <div style={{ flex: 1 }}></div>
                        </div>
                        <div style={style}>
                            {option === 'FormulaGrupoEspecial' ? <i className="pull-left fa fa-chevron-down"></i> : null}
                            {label}
                        </div>
                        <div style={{ position: 'absolute', top: 0, bottom: 0, left: '50%', borderLeft: '2px solid #d1d0d0' }}></div>
                    </div>
                </div>
            );
        }
        return null;
    };

    checkOperations = (pattern) => {
        const operations = ['+', '-', '*', '/'];
        let totalCount = 0;

        let cleanedPattern = pattern.replace(/#\[[^\]]+\]\(\$\[\d+\]\)/g, '');
        cleanedPattern = cleanedPattern.replace(/\{.*?\}/g, '');
        cleanedPattern = cleanedPattern.replace(/\(.*?\)/g, '');
        cleanedPattern = cleanedPattern.replace(/!\[[^\]]+\]/g, '');

        cleanedPattern = cleanedPattern.replace(/\s+/g, ' ').trim();

        operations.forEach(op => {
            const count = (cleanedPattern.match(new RegExp(`\\s*\\${op}\\s*`, 'g')) || []).length;
            totalCount += count;
        });

        console.log(totalCount);

        this.setState({ showOperationWarning: totalCount === 2 });
    };

    closeWarning = () => {
        this.setState({ showOperationWarning: false });
    };

    render() {
        const { number_format, data, hashtagData, label, formula_type, scope, contabil_pattern_reference_id, position, customData, cost_center_demonstration, layout, description } = this.state;
        const {titleData, contabil_patterns, costCenterAreaData} = this.props;

        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                <div style={{ display: 'flex' }}>
                    <div className="bg-content flex-1 margin-right-30" style={{ flex: '0 0 30%', display: 'flex', flexDirection: 'column' }}>
                        <div className="input" style={{ marginBottom: 10 }}>
                            <label>Nome</label>
                            <input
                                placeholder="Nome"
                                value={this.state.label}
                                type="text"
                                style={{ width: '100%' }}
                                onChange={(x) => this.setState({ label: x.currentTarget.value })}
                            />
                        </div>
                        <div className="input" style={{ marginBottom: 27 }}>
                            <label>Descrição</label>
                            <textarea
                                style={{ width: '100%', height: '80px', boxSizing: 'border-box', resize: 'vertical' }}
                                placeholder="Descrição"
                                value={this.state.description}
                                onChange={(x) => this.setState({ description: x.currentTarget.value })}
                            />
                        </div>

                        <div className={"input"} style={{ marginBottom: '20px' }}>
                            <label>Tipo (Exibição em tela)</label>
                            <div style={styles.tabs}>
                                {['grouped', 'formula'].map((type) => (
                                    <div
                                        key={type}
                                        onClick={() => this.setState({ formula_type: type })}
                                        style={{ ...styles.tab, ...(this.state.formula_type === type ? styles.tabActive : {}) }}
                                    >
                                        {type === 'grouped' ?
                                            <><GrupoAbertoIcon style={{ marginRight: '8px' }} fillColor={this.state.formula_type === 'grouped' ? "white" : "black"} /> GRUPO ABERTO</>
                                            :
                                            <><LineIcon style={{ marginRight: '8px' }} /> LINHA DE TOTAL</>}
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className={"input"} style={{ marginBottom: '20px' }}>
                            <label>Layout</label>
                            <div style={styles.tabs}>
                                {['normal', 'especial', 'grupo_especial'].map((type) => (
                                    <div
                                        key={type}
                                        onClick={() => this.setState({ layout: type })}
                                        style={{ ...styles.tab, ...(this.state.layout === type ? styles.tabActive : {}) }}
                                    >
                                        <span style={{ fontSize: '24px', padding: '3px' }}>
                                            {this.state.layout === type  ?
                                                <CheckDownIcon selected={this.state.layout === type}/>
                                                :
                                                <SquareIcon selected={this.state.layout === type}/>}
                                        </span>
                                        {{'normal': "NORMAL", 'especial': 'ESPECIAL', 'grupo_especial': 'GRUPO ESPECIAL'}[type]}
                                    </div>
                                ))}
                            </div>

                        </div>

                        <div className={"input"} style={{ marginBottom: '20px' }}>
                            <label>Escopo</label>
                            {this.scopes(scope)}
                        </div>

                        <div className={"input"}>
                            <label>Formato</label>
                            <div style={styles.tabs}>
                                {['integer', 'money', 'percentage'].map((format) => (
                                    <div
                                        key={format}
                                        onClick={() => this.setState({ number_format: format })}
                                        style={{ ...styles.tab, ...(this.state.number_format === format ? styles.tabActive : {}) }}
                                    >
                                        {format === 'integer' ? 'VALOR NUMÉRICO' : format === 'money' ? 'VALOR MONETÁRIO' : 'PORCENTAGEM'}
                                    </div>
                                ))}
                            </div>
                        </div>

                        { scope == "dre" && <FormControlLabel
                            control={
                                <Switch
                                    checked={cost_center_demonstration}
                                    onChange={()=> this.setState({cost_center_demonstration: !this.state.cost_center_demonstration})}
                                    color="primary"
                                />
                            }
                            label="Mostrar no centro de custos"
                        />}
                    </div>

                    <div className="flex-1 margin-right-30" style={{ flex: '0 0 35%', flexDirection: 'column' }}>
                        <ContasContabeisSearch
                            data={this.state.data}
                            titleData={this.props.titleData}
                            onAddItem={item => this.addSelectedItemToFormula(item, '@')}
                            onAddTextAndRowToFormulaInput={this.addTextAndRowToFormulaInput}
                        />
                    </div>

                    <div className="flex-1" style={{ display: 'flex', flexDirection: 'column' }}>
                        <FormulasExistentesSearch
                            formulas={this.state.hashtagData}
                            onAddItem={item => this.addSelectedItemToFormula(item, '#')}
                            onAddTextAndRowToFormula={this.addTextAndRowToFormula}
                            isAttachment={this.props.is_attachment}
                        />
                    </div>

                </div>

                <div style={{ display: 'flex', marginTop: '30px', paddingBottom: '130px' }}>
                    <div className="flex-1 margin-right-30" style={{ flex: '0 0 35.5%', flexDirection: 'column', height: '250px' }}>
                        <IndicadoresFormulasSearch
                            indicadores={this.state.customData}
                            onAddItem={item => this.addSelectedItemToFormula(item, '!')}
                        />
                    </div>

                    <div className="flex-1" style={{ display: 'flex', flexDirection: 'column', height: '250px' }}>
                        <CentroDeCustoFormulasSearch
                            contas={this.props.costCenterAreaData}
                            onAddItem={item => this.addSelectedItemToFormula(item, '?')}
                        />
                    </div>
                </div>

                {this.state.showOperationWarning && (
                    <div style={{
                        position: 'fixed',
                        bottom: '90px',
                        width: '100%',
                        left: '0%',
                        backgroundColor: '#FFC700',
                        padding: '15px',
                        zIndex: 1,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        textAlign: 'center',
                        height: '3px'
                    }}>
                        <div style={{flexGrow: 1, textAlign: 'center'}}>
                            Você adicionou duas operações: Verifique a necessidade de utilizar ”parênteses” para identificar as operações prioritárias.
                        </div>
                        <button
                            onClick={this.closeWarning}
                            style={{
                                backgroundColor: 'transparent',
                                color: 'black',
                                fontSize: '16px',
                                lineHeight: '1.5',
                                padding: '5px 10px',
                                border: 'none',
                                cursor: 'pointer',
                                marginRight: '10px'
                            }}
                        >
                            X fechar
                        </button>
                    </div>
                )}

                <div style={{
                    position: 'fixed',
                    bottom: '0',
                    width: '100%',
                    left: '0%',
                    marginTop: '10px',
                    backgroundColor: '#617E94',
                    padding: '15px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <div style={{ color: 'white', marginBottom: '8px' }}>
                        Monte o Cálculo aqui:&nbsp;
                        <span style={{ color: 'lightgray' }}>
                            Você pode clicar nas opções acima, ou apenas digitar o tipo de operação (?) e a operação (?) desejada.
                        </span>
                    </div>

                    <div style={{ display: 'flex', width: '100%', alignItems: 'center', justifyContent: 'center', gap: '20px' }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: '10px',
                            width: '12.5%'
                        }}>
                            <button
                                onClick={() => this.handleButtonClick('Soma')}
                                style={{ flex: 1, backgroundColor: '#B6FDE8', height: '45px', border: 'none', cursor: 'pointer', borderRadius: '5px' }}
                                onMouseOver={e => e.currentTarget.style.backgroundColor = '#D1FFEB'}
                                onMouseOut={e => e.currentTarget.style.backgroundColor = '#B6FDE8'}
                            >
                                <span style={{ color: 'white', fontSize: '40px' }}>+</span>
                            </button>
                            <button
                                onClick={() => this.handleButtonClick('Subtração')}
                                style={{ flex: 1, backgroundColor: '#FFC9D6', height: '45px', border: 'none', cursor: 'pointer', borderRadius: '5px' }}
                                onMouseOver={e => e.currentTarget.style.backgroundColor = '#FFDCE0'}
                                onMouseOut={e => e.currentTarget.style.backgroundColor = '#FFC9D6'}
                            >
                                <span style={{ color: 'white', fontSize: '40px' }}>-</span>
                            </button>
                            <button
                                onClick={() => this.handleButtonClick('Multiplicação')}
                                style={{ flex: 1, backgroundColor: '#B7DCFD', height: '45px', border: 'none', cursor: 'pointer', borderRadius: '5px' }}
                                onMouseOver={e => e.currentTarget.style.backgroundColor = '#8F9FFF'}
                                onMouseOut={e => e.currentTarget.style.backgroundColor = '#B7DCFD'}
                            >
                                <span style={{ color: 'white', fontSize: '40px' }}>×</span>
                            </button>
                            <button
                                onClick={() => this.handleButtonClick('Divisão')}
                                style={{ flex: 1, backgroundColor: '#FFD7A3', height: '45px', border: 'none', cursor: 'pointer', borderRadius: '5px' }}
                                onMouseOver={e => e.currentTarget.style.backgroundColor = '#FFEBBE'}
                                onMouseOut={e => e.currentTarget.style.backgroundColor = '#FFD7A3'}
                            >
                                <span style={{ color: 'white', fontSize: '40px' }}>÷</span>
                            </button>
                        </div>

                        <FormulaMentionInput
                            inputRef={this.input}
                            titleData={titleData}
                            data={data}
                            customData={customData}
                            costCenterAreaData={costCenterAreaData}
                            hashtagData={hashtagData}
                            value={this.state.pattern}
                            onChange={(e, n, x, m) => {
                                this.setState({ pattern: n });
                                this.checkOperations(n);
                            }}
                            style={{ flex: 1, border: 'none', alignSelf: 'center' }}
                        />
                        <BlueButton onClick={() => this.save()} style={{ border: 'none', height: '45px' }}>
                            Salvar Formula
                        </BlueButton>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '3%'
                        }}>
                            <button
                                ref={this.toggleButtonRef}
                                onClick={this.openPortal}
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    flex: 1,
                                    backgroundColor: '#F4A837',
                                    height: '45px',
                                    border: 'none',
                                    cursor: 'pointer',
                                    borderRadius: '5px'
                                }}
                            >
                            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', color: 'white', fontSize: '40px' }}>
                                <EyeIcon/>
                            </span>
                            </button>
                            {
                                this.state.showPortal && ReactDOM.createPortal(
                                    <div
                                        ref={(node) => { this.portalRef = node; }}
                                        style={{
                                            position: 'fixed',
                                            bottom: '105px',
                                            right: '0px',
                                            backgroundColor: 'white',
                                            border: 'none',
                                            borderRadius: '5px',
                                            zIndex: 1000,
                                            width: '600px',
                                            marginRight: '27px'
                                        }}>
                                        <div style={{...styles.bgContent, height: '300px'}}>
                                            <div style={{...styles.header, height: '70px'}}>
                                                <div style={{...styles.blockTitle, fontSize: '13px'}}>
                                                    VISUALIZE COMO SUA FÓRMULA APARECERÁ NO SISTEMA
                                                </div>
                                            </div>
                                            <div style={{...styles.contentBetween, justifyContent: 'left', padding: '35px'}}>
                                                { (this.state.formula_type === null || this.state.layout === null) && (
                                                    "Para visualizar como sua fórmula aparecerá no sistema é necessário selecionar o Tipo (Exibição em tela) e o Layout."
                                                ) }
                                                {this.showFormulas()}
                                            </div>
                                        </div>
                                        <div style={{ ...styles.bottomPreview, display: 'flex', justifyContent: 'space-between' }}>
                                            <a
                                                href="http://cleverview.com.br/central-ajuda/formulas-e-parametro/entendendo-as-formulas-do-sistema-2/"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{ ...styles.blockTitle, fontSize: '13px', color: 'white', textDecoration: 'none' }}
                                            >
                                                AINDA TEM DÚVIDAS? CLIQUE AQUI E ACESSE NOSSA CENTRAL DE AJUDA
                                            </a>
                                            <div>
                                                <ShareIcon />
                                            </div>
                                        </div>
                                    </div>,
                                    document.body
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Formula;
