import React from "react";
import Select from 'react-select';
import AutoComplete from "./AutoComplete";
import { GreenButton } from "../styled_components/default";
import styled from "styled-components";

class CompanyUsers extends React.Component {
  state = {
    allowedUsers: [],
    query: "",
    users: [],
    roles: [
      {value: "admin", label: "Admin"},
      {value: "consultor", label: "Consultor"},
    ]
  };

  async fetchRoles() {
    let result = await axios.get(`/roles.json`);
    this.setState({roles: result.data.map((role)=> ({value: role.id, label: role.label})) })
  }


  async fetchUsers() {
    if(this.props.company){
      let result = await axios.get(`/companies/${this.props.company}/company_users.json`, {
        params: {
          query: this.state.query
        }
      });
      this.setState({allowedUsers: result.data })  
    }
  }


  async searchUsers() {
    // try{
    //   this.cancelToken.cancel('operation canceled by the user');
    // }catch(e){
    //   console.log(e)
    // }
    
    // let CancelToken = axios.CancelToken;
    // let cancelToken = CancelToken.source();

    // this.cancelToken = cancelToken;
    
    // let result = await axios.get("/users/search.json", {
    //   cancelToken: cancelToken.token,
    //   params: {
    //     query: this.state.query
    //   }
    // });

    // this.setState({users: result.data.map((user)=> ({value: user.id, label: user.email}) )})
  }

  handleChange(user) {
    this.setState({ currentUser: user });
  }

  handleInputChange(query) {
    this.setState({ query });
    this.searchUsers();
  }

  async addAllowedUser(){

    let result = await axios.post(`/companies/${this.props.company}/company_users.json`, {
        user_id: this.state.currentUser.value,
        is_new: this.state.currentUser.__isNew__,
        company_id: this.props.company,
        role_id: this.state.currentRole   
    });

    this.setState((oldState)=>{
      oldState.allowedUsers = result.data
      return {...oldState}
    })
    
  }

  async removeUser(user){
    
    let result = await axios.delete(`/companies/${this.props.company}/company_users/${user.user.id}.json`);

    this.setState((oldState)=>{
      oldState.allowedUsers = result.data
      return {...oldState}
    })
  }

  componentDidMount() {
    this.fetchRoles();
    this.fetchUsers();
  }

  async updateUserRole(role, user){
    let result = await axios.put(`/companies/${this.props.company}/company_users/${user.user.id}.json`, {
      role_id: role
    });

    this.setState((oldState)=>{
      oldState.allowedUsers = result.data
      return {...oldState}
    })
  }

  render() {
    let { users, roles } = this.state;
    return (
      <div>
        <div style={{ display: "flex", alignItems: 'center' }}>
          <AutoComplete
            data={users}
            handleInputChange={this.handleInputChange.bind(this)}
            handleChange={this.handleChange.bind(this)}
          />
          <div style={{width: 200}} className={'margin-left-10'}>
            <Select
              options={roles}
              placeholder={"Permissão"}
              onChange={(role)=>{ this.setState({currentRole: role.value})}}
              TextFieldProps={{
                label: 'Regra de acesso',
                InputLabelProps: {
                  htmlFor: 'react-select-single',
                  shrink: true,
                },
              }}
            />  
          </div>
          <GreenButton className={'margin-left-10'} onClick={this.addAllowedUser.bind(this)}>ADICIONAR</GreenButton>
        </div>
        <table className={'table'}>
          {this.state.allowedUsers.map((user)=>(
            <tr>
              <td>
                {user.user.name}  
              </td>
              <td>
                {user.user.email}  
              </td>
              <InputTd>
                <Select
                  options={roles}
                  value={roles.find((role)=> role.value == user.role.id)}
                  placeholder={"Permissão"}
                  onChange={(role)=>{ this.updateUserRole(role.value, user)}}
                  TextFieldProps={{
                    label: 'Regra de acesso',
                    InputLabelProps: {
                      htmlFor: 'react-select-single',
                      shrink: true,
                    },
                  }}
                />
                
              </InputTd>
              <td>
                
                  <i onClick={()=> this.removeUser(user)} className={'fa fa-close'}/>
                
              </td>
            </tr>
          ))}
        </table>
      </div>
    );
  }
}

export default CompanyUsers;


const InputTd = styled.td`
  >div{
    margin-right: 20px !important;
  }
  min-width: 160px;
`