import React, {useReducer} from "react";
import { PresentationsProvider } from "./contexts";
import { initialState as initialPresentationsState, presentationsReducer } from "../presentations/contexts/stores";
import { SheetForm } from "./sheetForm";

const EditMain = (props) => {
  return (
    <PresentationsProvider parameters={props.parameters}>
      <SheetForm {...props} />
    </PresentationsProvider>
  );
};


const PrintingMain = (props) => {
  // Cria uma nova cópia de initialState para evitar a mutação direta
  const initialState = { ...initialPresentationsState, parameters: props.parameters || {} };
  
  const [state, dispatch] = useReducer(presentationsReducer, initialState);
   
  return (
    <SheetForm  {...props} state={state} printing={true} dispatch={dispatch} />
  );
};


const Main = (props) => {
  if (props.printing) {
    return <PrintingMain {...props} />;
  } else {
    return <EditMain {...props} />;
    
  }
};

export default Main;