import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


export default class BorboletaConfigPercentColor extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      value_for_color_red_percent_change: this.props.red || '',
      value_for_color_green_percent_change: this.props.green || ''
    }
  }

  handleSave = () => {
    let { value_for_color_green_percent_change, value_for_color_red_percent_change } = this.state

    this.props.updateValueColors(this.props.companyId, value_for_color_green_percent_change, value_for_color_red_percent_change)
    this.props.onClose()
  };

  handleInputChangeColorValue = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  }

  render() {
    
    const { open, onClose } = this.props;
    const { value_for_color_green_percent_change, value_for_color_red_percent_change} = this.state

    return (
      <Dialog open={open} onClose={onClose}>
	<DialogTitle>Configurar variação</DialogTitle>
	<DialogContent>
	    <TextField
	      label="Colorir de verde variações acima de:"
	      name="value_for_color_green_percent_change"
	      value={value_for_color_green_percent_change}
	      onChange={(event) => this.handleInputChangeColorValue(event)}
	      fullWidth
	      margin="normal"
	    />
	    <TextField
	      label="Colorir de vermelho variações abaixo de:"
	      name="value_for_color_red_percent_change"
	      value={value_for_color_red_percent_change}
	      onChange={(event) => this.handleInputChangeColorValue(event)}
	      fullWidth
	      margin="normal"
	    />
	  <Button style={{ backgroundColor: 'green' }} onClick={this.handleSave}>Salvar</Button>
	</DialogContent>
      </Dialog>
    );
  }
}

