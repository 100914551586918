import React from "react";
import LoadingDialog from './../LoadingDialog'
import BalanceSegmentado from './BalanceSegmentado'
import RemovedAmount from './../RemovedAmount'
import actionCable from 'actioncable';
import axios from '../utils/axiosConfig';

const CableApp = {}

CableApp.cable = actionCable.createConsumer('ws://agrega.devlabs.digital/cable')

class Segmentado extends React.Component {
  
  constructor(props){
    super(props)
    this.requestsCounter = 0
    this.cable_counting = 0
    this.state ={
      removedAmounts: {},
      months: {total: 13, ...this.props.months},
      companyData: {},
      formulas: [],
      report: [],
      labels: [],
      companies: [],
      years: [],
      year: this.props.defaultYear,
      recordsFetched: {},
      cost_center_mode: false,
      costCentersFetched: {},
      costCenterAreas:[],
      despesasType: {},
      costCenterRecordsGrouped: {},
      activeAreaType: {}, 
      activeKeys: {},
      formulasTotal: {},
      formulasReport: {},
      dataModel: {},
      currentMonth: "janeiro",
      currentModality: "dre",
      modalities: {
        "dre": "DRE",
        "dre_cc": "DRE - Gerencial",
        "cash_flow": "Fluxo de caixa",
        "balance_sheet": "Balancete"
      }

    }
    if (props.rails_env == "development") {
      CableApp.cable = actionCable.createConsumer('ws://localhost:3000/cable')
    }
  }



  tryEval(str) {
    try {
      let formulaSolved = eval(str)
      return formulaSolved ? formulaSolved : 0;
    } catch (e) {
      return 0
    }
  }

  refreshRemovedAmounts(){
    this.setRemovedAmounts()
  }

  async setRemovedAmounts(year=this.state.year){
    
    let removedAmounts = await axios.get('/removed_amounts.json', {params:{
      year
    }})  
    
    
    this.setState({removedAmounts: removedAmounts.data})
  }

  async setYears(){
    let years = await axios.get('/balance_sheets/years.json')
    this.setState({years: years.data})
  }

  async fetchCostCenterData(year=null, company_id){
    const {is_budget} = this.props;
     this.setState({loading: true})
     this.requestsCounter += 1
    let result = await axios.get('/results/cost_centers.json', {params: {
        year,
        is_budget,
        company_id
    }})
    this.requestsCounter -= 1
    const {data} = result
    
    this.setState((oldState)=>{
      oldState.companyData[company_id] = {
        costCenterRecordsGrouped: data
      }      
      if(Object.keys(dataModel).length == 0){
        oldState.dataModel = report  
        oldState.formulas = formulas
        oldState.labels = labels
        oldState.costCenterAreas = areas
        oldState.costCenterGroupedByMonth = cost_center_grouped_by_month
      }
      return {...oldState}
    })
    
  }

  async fetchData(year=null, company_id){
    let {currentModality} = this.state
    if(currentModality == "balance_sheet"){
      return false
    }
    this.requestsCounter += 1
    const {costCenterMode, is_budget} = this.props;
    
    let dataModel = {}
    this.setState({loading: true})
    let url = `/results/${currentModality}.json`
    let result = await axios.get(url, {params: {
        company_id,
        year,
        is_budget
    }})
    const {formulas, report, labels, areas, cost_center_grouped_by_month} = result.data
    this.requestsCounter -= 1
    this.setState((oldState)=>{
      oldState.companyData[company_id] = {
        formulas, report, labels, costCenterAreas: areas, recordsFetched: {}, costCenterGroupedByMonth: cost_center_grouped_by_month
      }      
      if(Object.keys(dataModel).length == 0){
        oldState.dataModel = report  
        oldState.formulas = formulas
        oldState.labels = labels
        oldState.costCenterAreas = areas
        oldState.costCenterGroupedByMonth = cost_center_grouped_by_month
      }
      return {...oldState}
    })
    
  }

  async fetchCompanies(){
    let data = await axios.get("/companies/childreen.json")
    let companies = data.data
    Object.values(companies).map((company_id)=>{
      this.fetchData(this.state.year, company_id)
      this.fetchCostCenterData(this.state.year, company_id)
    })
    
    this.setState({companies})
  }

  async setupCable(year){
    let self = this
    CableApp.cable.subscriptions.create({
      channel: 'TotalChannel'
    },
    {
      received: (data)=>{
        if (data.counter) {
          this.cable_counting += parseInt(data.counter)
          this.forceUpdate()

          if(this.cable_counting == 0){
            setTimeout(function(){
              self.fetchDataForRange(year)  
            }, 1000)
          }
        }
      }
    }
    )
  }

  async componentDidMount(){
    const {year} = this.state
    let x = await this.setYears()
    await this.fetchCompanies();

    // this.fetchCostCenterData(this.state.year)
    // this.fetchData(this.state.year)
    this.fetchDataForRange(year)
    this.setRemovedAmounts(year)
    this.setupCable(year)
  }

  async fetchAreaData(key, fixed, type_id, type_label){
    
    const {year, costCentersFetched} = this.state;
    const {cost_center_heads, is_budget} = this.props;
    let fixed_label = Boolean(fixed) ? "fixed" : "not_fixed"

    Object.entries(cost_center_heads).map(([id, label])=>{
      if(!costCentersFetched[label]){
        costCentersFetched[label] = {}
      }
    })

    if(costCentersFetched[type_label][key] && costCentersFetched[type_label][key][fixed_label]){
      this.setState((oldState)=>{
        oldState.costCentersFetched[type_label][key][fixed_label] = null
        return {...oldState}
      })
    }else{
      let details = await axios.get(`/results/${key}/cost_center_detail.json`, {params: {
          year: year,
          fixed: Boolean(fixed),
          type: type_id,
          is_budget,
      }})
      
      this.setState((oldState)=>{
        if(!oldState.costCentersFetched[type_label][key])
        { oldState.costCentersFetched[type_label][key] = {} }
        
        oldState.costCentersFetched[type_label][key][Object.keys(details.data)[0]] = Object.values(details.data)[0]
        return {...oldState}
      })
    }

  }

  async toggleAreaType(key, fixed, type_id, type_label){
    
    this.setState((oldState)=>{
      oldState.activeAreaType[`${key},${fixed}`] = oldState.activeAreaType[`${key},${fixed}`] ? oldState.activeAreaType[`${key},${fixed}`] : {}
      oldState.activeAreaType[`${key},${fixed}`][`${type_id}-${type_label}`] = !oldState.activeAreaType[`${key},${fixed}`][`${type_id}-${type_label}`]
      return {...oldState}
    })

  }

  formulaIsGrouped(key){
    const {formulas} = this.state

    return formulas[key]?.formula_type == "grouped"

  }

  changeYear(year){
    this.setState({year})
    this.props.changeYear(year)
    this.fetchData(year)
    
    this.setState({
      costCentersFetched: {},
      despesasType: {}
    })
    this.fetchDataForRange(year)
    this.setRemovedAmounts(year)
    
    
  }

  // renderLine(line, level=0){
  //   let { months} = this.state;
  //   let totalLine = 0
  //   return <tr>
  //     <td>
  //       <div style={{paddingLeft: level * 10}}>{line.label}</div>
  //     </td>
  //     {Object.entries(companies).map(([name, number]) => {
  //         if(parseFloat(line.months[name]).toString() != "NaN"){
  //           if(number != this.props.current_company_id){
  //             totalLine += parseFloat(line.months[name])    
  //           }
  //         }
          
  //         return (
  //           <td>
  //             {line.months[name] ? (parseFloat(line.months[name]) * - 1).toLocaleString("pt-BR", {
  //                 style: "currency",
  //                 currency: "BRL"
  //               }) : "-"}
  //           </td>
  //         );
  //       })}
  //     <td>{(totalLine * -1).toLocaleString("pt-BR", {
  //                 style: "currency",
  //                 currency: "BRL"
  //               }) }</td>
  //   </tr>
  // }


  costCenterGroupedBySpendType(despesa_id, fixed, despesa_type_label, month){
    const {months} = this.props;
    const {costCentersFetched} = this.state;

    let fixed_label = "not_fixed"
    if (fixed) {
      fixed_label = "fixed"
    }

    let rows = null;
    let result = 0
    try{
      
      rows = costCentersFetched[despesa_type_label][despesa_id][fixed_label]


      Object.values(rows).map((despesa)=>{
        if(String(parseFloat(despesa.months[month])) != "NaN"){
          result += parseFloat(despesa.months[month])  
        }
        
      })
      
    }catch(e){

    }
    

    
    return result
  }

  renderCostCenterRows(despesa_id, fixed, despesa_type_label){
    const {months} = this.props;
    const {costCentersFetched} = this.state;

    let numberToCurrency = function(number=0) {
      if (String(parseFloat(number)) == "NaN" || parseFloat(number) == undefined) {
        number = 0
      }
      
      return parseFloat(number * -1).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });
    };

    let fixed_label = "not_fixed"
    if (fixed) {
      fixed_label = "fixed"
    }

    let rows = null;

    try{
      
      rows = costCentersFetched[despesa_type_label][despesa_id][fixed_label]
      
    }catch(e){

    }

    
    
    return <React.Fragment>
      {rows && Object.entries(rows).map(([key, data])=> {
        let sum = 0
        
        return <tr>
        <td style={{textIndent: 32}}>{key}</td>
        {Object.entries(companies).map(([name, number]) => {
          sum += data.months[number] ? parseFloat(data.months[number]) : 0
          
          return (
            <td>
              {numberToCurrency(data.months[number]) || "-"}
            </td>
          );
        })}
        <td>{numberToCurrency(sum)}</td>
      </tr>})}

    </React.Fragment>
    
  }
  numberToCurrency(number=0) {
    if (String(parseFloat(number)) == "NaN" || parseFloat(number) == undefined) {
      number = 0
    }
    
    return parseFloat(number * -1).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL"
    });
  }

  renderRow(row, level=0, key=null){
    
    let childrenLines = (entry, month_name)=>{
      try{
        let initial = Object.values(entry.lines).map((x)=> x.months[month_name]).filter((a)=> parseFloat(a).toString() != "NaN").reduce((a,b)=> parseFloat(a) + parseFloat(b), 0)

        if (Object.values(entry.childreen).length > 0) {
          Object.values(entry.childreen).map((child)=> {
            initial += childrenLines(child, month_name)
          })
        }

        return initial
      }
      catch(e){
        console.log("ERRO AQUI", e)
        return 0
      }
      
    }

    let { months} = this.state;
    const  {costCentersFetched, activeKeys} = this.state;
    let sum = 0
    
    return <React.Fragment>
        {!row.months ?
          <tr>
            <td ><div 
                onClick={()=> {
                  
                  this.setState((oldState)=>{
                    if(row.label){
                      
                      oldState.activeKeys[key] = oldState.activeKeys[key] ? oldState.activeKeys[key] : {}
                      oldState.activeKeys[key][row.label] = oldState.activeKeys[key][row.label] ? null : {}
                    }else{
                      oldState.activeKeys[key] = oldState.activeKeys[key] ? null : {}  
                    }
                    
                    return {...oldState}
                  })
                }}
                style={{paddingLeft: level * 10}}>
                  {row.label}
                </div>
            </td>
            {Object.entries(companies).map(([name, number]) => {
              let monthValue = childrenLines(row, name)
              if(monthValue.toString() != "NaN"){
                sum += monthValue  
              }
              
              console.log(sum)
              return (
                <td>
                  {this.numberToCurrency(monthValue)}
                </td>
              );
            })}
            <td>
              {this.numberToCurrency(sum)}
            </td>
        </tr>
      :
        this.renderLine(row, level)
      }

      
      {activeKeys[key] && row.childreen && Object.values(row.childreen).map((child)=>{
          return this.renderRow(child, level + 1)
        })}
      {(activeKeys[key] && activeKeys[key][row.label]) && row.lines &&  Object.keys(row.childreen).length < 1 && Object.values(row.lines).map((line)=>{
          return this.renderLine(line, level + 1)
        })}
      </React.Fragment>
  }
 
  renderChildreenFor(key=null){
    
    let data = this.state.recordsFetched[key]
    if(data){
      return (<React.Fragment>
        {Object.values(data).map((row)=>(this.renderRow(row, 0, key)))}
      </React.Fragment>)
    }
  }

  async fetchDetail(key){
    const {year, recordsFetched} = this.state;
    const {cost_center_heads, is_budget} = this.props;
    
    if(recordsFetched[key]){
      this.setState((oldState)=>{
        oldState.activeKeys[key] = {} 
        oldState.recordsFetched[key] = null
        return {...oldState}
      })
    }else{
      let details = await axios.get(`/results/${key}/detail.json`, {params: {
          year: year,
          cost_center_mode: this.state.cost_center_mode && cost_center_heads[key],
          is_budget
      }})
      
      this.setState((oldState)=>{
        
        if (Object.values(Object.values(details.data)[0].childreen).length > 0){
          oldState.recordsFetched[key] = Object.values(details.data)[0].childreen
        }else{
          oldState.recordsFetched[key] = Object.values(details.data)[0].lines
        }
        
        return {...oldState}
      })
    }
    
  }
  async fetchDataForRange(year, company_id=null){
      const {is_budget, scope} = this.props;
      const {currentModality, currentMonth} = this.state

      if(currentModality == "balance_sheet"){
        return false
      }
        
      let report = await axios.get('/formulas/borboleta.json',{params: {
          scope: currentModality,
          company_id,
          consolidado: true,
          with_removed_amounts: true,
          range: {
            start_at: (new Date(`${monthDict[currentMonth]}/01/${year}`)), 
            end_at: (new Date(`${monthDict[currentMonth]}/01/${year}`))
          },
          is_budget
      }})

      this.setState((oldState)=>{
        oldState.formulasTotal = report.data
        return {...oldState}
      })
  }

  paramLabel(value, param){
    let label;
    if (param == "difference") {
      label = <label title={"diferença"} style={{color: "#232D6A"}}>dif</label>
    }
    if (param == "f_saldo_atual") {
      label = <label title={"saldo atual"} style={{color: "#3FABAE"}}>sat</label> 
    }
    if (param == "f_saldo_anterior") {
      label = <label title={"saldo anterior"} style={{color: "#EFBF6A"}}>san</label>
    }

    return <div>{value} {label}</div>
  }

  toggleDespesasType(key){
    let {cost_center_heads} = this.props;
    let [area_id, is_fixed] = key

    Object.entries(cost_center_heads).map(([despesa_type_id, label])=>{
      this.fetchAreaData(area_id, is_fixed, despesa_type_id, label)  
    })
    
    this.setState((oldState)=>{
      oldState.despesasType[key] = !oldState.despesasType[key]
      if(!oldState.despesasType[key]){
        this.state.activeAreaType[`${key}`] = {}  
      }
      
      return {...oldState}
    })
  }


  renderCostCenterAreas(number_format="money"){
    const { months, cost_center_heads} = this.props;
    const  {costCentersFetched, costCenterAreas, despesasType, costCenterGroupedByMonth, companyData, currentMonth, companies} = this.state;
    
    let areaByMonth = (b_fixed, despesa_id, number)=>{

      try{
        
        let value = companyData[number]?.costCenterGroupedByMonth[b_fixed ? "fixed" : "not_fixed"][`[${despesa_id}, ${monthDict[currentMonth]}]`]
        
        return value
        
      }catch(e){
        console.log(e)
      }
      
    }
    

    let lines = Object.entries(costCenterAreas).map(([key, value])=>{
      const [fixed, despesa_id] = JSON.parse(key);
      let b_fixed = Boolean(fixed)
      //this.fetchAreaData(id, fixed)
      return <React.Fragment><tr>
        <td ><div onClick={()=> this.toggleDespesasType([despesa_id, b_fixed])} >
        <i className={`pull-left fa fa-chevron-${despesasType[[despesa_id, b_fixed]] ? 'down' : 'right'}`}/>
        {value.label}</div></td>
        {Object.entries(companies).map(([name, number]) => {
          return (
            <td>
              {areaByMonth(b_fixed, despesa_id, number)}
            </td>
          );
        })}
        <td>
          
        </td>
      </tr>
      
      </React.Fragment>

    })

    return <React.Fragment>{lines}</React.Fragment>

  }

  percentFormat(number){
    return `${parseFloat(number).toFixed(2)}%`
  }

  renderCostCenterParams(report, number_format="money"){

    let {despesasType} = this.state;

    let {cost_center_heads} = this.props;
    let numberToCurrency = function(number=0, number_format=number_format) {
      if (String(parseFloat(number)) == "NaN" || parseFloat(number) == undefined) {
        number = 0
      }

      if(number_format == "percentage"){
        this.percentFormat(parseFloat(number * -1))
      }

      if(number_format == "integer"){
        return new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(number * -1))
      }

      if(!number_format || number_format == "money"){
        return parseFloat(number * -1).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL"
        });
      }
    };
    let { months} = this.state;
    const {costCenterRecordsGrouped} = this.state;
    
    return  <React.Fragment>
      {report.cost_center_params.ids.map((id)=>{
          
          let total = 0
         return  <React.Fragment><tr>
            <td onClick={()=> this.toggleDespesasType([id[0].area.id, id[0].is_fixed])}>
              
              <i className={`pull-left fa fa-chevron-${despesasType[[id[0].area.id, id[0].is_fixed]] ? 'down' : 'right'}`}/>
              {id[0].area.label} - {id[0].is_fixed ? "Fixo" : "Variável"}
            </td>
            {Object.entries(companies).map(([name, number]) => {
              let row = parseFloat(costCenterRecordsGrouped[`["${id[0].area.label}", ${id[0].is_fixed}, ${month}]`])

              if(String(row) != "NaN"){
                total += row
              }  

              if(costCenterGroupedByMonth[id[0].is_fixed ? "fixed" : "not_fixed"][`[${id[0].area.id}, ${month}]`]){
                return <td>{numberToCurrency(costCenterGroupedByMonth[id[0].is_fixed ? "fixed" : "not_fixed"][`[${id[0].area.id}, ${month}]`])}</td>  
              }else{
                return <td >{numberToCurrency(costCenterRecordsGrouped[`["${id[0].area.label}", ${id[0].is_fixed}, ${month}]`])}</td>  
              }
              

              
            })}
            <td> {numberToCurrency(total)}</td>
          </tr>
          {despesasType[[id[0].area.id, id[0].is_fixed]] && Object.entries(cost_center_heads).map(([despesa_type_id, label])=>{
            let exist = false
            try{
              exist = !!costCentersFetched[label][id[0].area.id][id[0].is_fixed ? "fixed" : "not_fixed"]  

            }catch(e){

            }
            total = 0
            return <React.Fragment>
              <tr>
                <td style={{textIndent: 16}} onClick={()=> this.toggleAreaType(id[0].area.id, id[0].is_fixed, despesa_type_id, label)} >
                  <i className={`pull-left fa fa-chevron-${exist ? 'down' : 'right'}`}/>
                  {label}
                </td>  
                 {Object.entries(companies).map(([name, number]) => {
                    let value = this.costCenterGroupedBySpendType(id[0].area.id, id[0].is_fixed, label, number )
                    if (String(value) != "NaN") {
                      total += value  
                    }else{
                      
                    }                    
                    return (
                      <td>
                        {numberToCurrency(value)}
                      </td>
                    );
                  })}
                <td>{numberToCurrency(total)}</td>
              </tr>
            {this.state.activeAreaType[`${id[0].area.id},${id[0].is_fixed}`] && this.state.activeAreaType[`${id[0].area.id},${id[0].is_fixed}`][`${despesa_type_id}-${label}`] && this.renderCostCenterRows(id[0].area.id, id[0].is_fixed, label)}
            </React.Fragment>

          })}
          </React.Fragment>
        })}

        
      
      
    </React.Fragment>
                         

  }
  getNumerFormattedWithoutReduced(value, format){
    let {removedAmounts} = this.state;
    
    let result = value
    if(format == "percentage"){
      result = this.percentFormat(value)
    }
    if(format == "integer"){
      result = new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(value))
    }
    if(!format || format == "money"){
      result = this.numberToCurrency((value)) 
    }
    
    return result
  }

  getNumerFormatted(value, format, year, month, formula_id){
    if(!year){
      return this.getNumerFormattedWithoutReduced(value, format)
    }
    let {removedAmounts} = this.state;
    let key = `[${year}, "${month}", ${formula_id}]`
    let removedValue = parseFloat(removedAmounts[key])

     if (String(removedValue) == "NaN" || removedValue == undefined) {
        removedValue = 0
      }

    let result = value
    if(format == "percentage"){
      result = this.percentFormat(value - removedValue)
    }
    if(format == "integer"){
      result = new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(value) - removedValue)
    }
    if(!format || format == "money"){
      result = this.numberToCurrency((value - removedValue) * -1) 
    }
    
    return result
  }

  changeMonth(currentMonth){
    let self = this;
    this.setState({currentMonth}, function(){
      self.changeModality(self.state.currentModality)
    })
  }

  async changeModality(currentModality){
    let self = this;
    let {currentMonth} = this.state
   
    this.setState({currentModality}, function(){
       if(currentModality == "balance_sheet"){
        if(currentMonth == 13){
          this.changeMonth(1)
          alert("Balancete não possui total, então o mês foi alterado para janeiro")
        }
        
      }else{  
        this.fetchCompanies();
        this.fetchDataForRange(this.state.year)
      }
      
    })  
   
  }

  paramLabel(value, param){
    let label;
    if (param == "difference") {
      label = <label title={"diferença"} style={{color: "#232D6A"}}>dif</label>
    }
    if (param == "f_saldo_atual") {
      label = <label title={"saldo atual"} style={{color: "#3FABAE"}}>sat</label> 
    }
    if (param == "f_saldo_anterior") {
      label = <label title={"saldo anterior"} style={{color: "#EFBF6A"}}>san</label>
    }

    return <div>{value} {label}</div>
  }
  numberToCurrency(number) {
    return parseFloat(number).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL"
    });
  };

  getFloat(number){
    if (parseFloat(number).toLocaleString() == "NaN"){
      return 0
    }else{
      return parseFloat(number)
    }
  }

  isParamZero(p_value, param){
    if(this.state.showZeroLines){
      return false
    }

    let { months} = this.state;
    let isZero = true
    Object.entries(months).map(([name, number]) => {
      
      if(this.getFloat(p_value[name][param]) != 0){
        isZero = false
      }
    })
    return isZero

  }
  

  renderLine(key, value){
    const { report, 
            labels, 
            formulas, 
            year, 
            years, 
            recordsFetched, 
            cost_center_mode, 
            formulasTotal, 
            companies, 
            dataModel, 
            companyData, 
            month, 
            currentMonth,
            modalities,
            currentModality } = this.state;
    let { months} = this.state;
    
    return (!formulas[key]?.cost_center_demonstration || !Boolean(cost_center_mode)) && this.formulaIsGrouped(key) && Object.entries(value[key]["params"]).map(
      ([p_key, p_value]) => {
        let total = 0
        
        return (
          <React.Fragment>
            {value[key]["lines"][p_key] && Object.values(value[key]["lines"][p_key]).map((param)=>(
              <React.Fragment>
              {!this.isParamZero(p_value, param) && <tr>
                  <td >
                     {
                      this.paramLabel(labels[p_key], param)
                    }
                  </td>
                  {/*<td className={'comment-wrapper'}>
                                      {p_value[name] &&
                                        this.numberToCurrency(p_value[currentMonth][param])}
                                    </td>*/}
                  {Object.entries(companies).map(([name, company_id]) => {
                    let line_value = companyData?.[company_id]?.['report']?.[key]?.[key]?.["params"]?.[p_key]?.[currentMonth]?.[param]

                    if(line_value){
                      if(company_id != this.props.current_company_id){
                        total += this.getFloat(line_value)  
                      }
                      
                    }
                    return (
                      (company_id != this.props.current_company_id) && <td>
                        {this.numberToCurrency(line_value)}
                      </td>
                    );
                  })}
                  {/*<td>
                    <RemovedAmount 
                      removedAmounts={this.state.removedAmounts}
                      refreshRemovedAmounts={()=> this.refreshRemovedAmounts()}
                      year={year}
                      month={currentMonth}
                      reference_id={p_key}
                      reference_model={'param'}
                     />
                  </td>*/}
                  <td>{this.numberToCurrency(total)}</td>
                  {Object.entries(companies).map(([name, company_id]) => {
                    let line_value = companyData?.[company_id]?.['report']?.[key]?.[key]?.["params"]?.[p_key]?.[currentMonth]?.[param]

                    return (
                      (company_id == this.props.current_company_id) && <td>
                        {this.numberToCurrency(line_value)}
                      </td>
                    );
                  })}
                </tr>}
              
            </React.Fragment>
            ))}

          </React.Fragment>
        );
      }
    )
  }

  renderIndicators(key, value){
    const { report, 
            labels, 
            formulas, 
            year, 
            years, 
            recordsFetched, 
            cost_center_mode, 
            formulasTotal, 
            companies, 
            dataModel, 
            companyData, 
            month, 
            currentMonth,
            modalities,
            currentModality } = this.state;
    let { months} = this.state;
    {this.formulaIsGrouped(key) && value[key]["indicators"] && Object.entries(value[key]["indicators"]).map(
      ([p_key, p_value]) => {
        
        
        return (
          <React.Fragment>
            
              <React.Fragment>
                {<tr>
                  <td >
                    <i className={`pull-left fa}`}/> 
                    {p_key}
                  </td>
                  {Object.entries(companies).map(([name, company_id]) => {
                    let line_value = companyData?.[company_id]?.['report']?.[key]?.[key]?.["indicators"]?.[currentMonth]

                    if(line_value){
                      if(company_id != this.props.current_company_id){
                        total += this.getFloat(line_value)
                      }
                    }
                    return (
                      <td>
                        {this.numberToCurrency(line_value)}
                      </td>
                    );
                  })}
                  p_value[name]
                  <td></td>
                  <td className={'flex'}>
                    
                  </td>
                </tr>}
            </React.Fragment>
            
          </React.Fragment>
        );
      }
    )}
  }

  render() {
    const { report, 
            labels, 
            formulas, 
            year, 
            years, 
            recordsFetched, 
            cost_center_mode, 
            formulasTotal, 
            companies, 
            dataModel, 
            companyData, 
            month, 
            currentMonth,
            modalities,
            currentModality } = this.state;
    let { months} = this.state;


    let numberToCurrency = function(number) {
      return parseFloat(number).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });
    };

    return (
      <React.Fragment>
        <div className="header" style={{display: 'flex', justifyContent: 'space-between' }}>
          <div style={{display: 'flex', justifyContent: 'space-between' }}>
            <div className="label" style={{marginRight: 16}}>RESUMO EXECUTIVO</div>
             
          </div> 
          <form className="form" style={{paddingRight: 35}}>
              <select onChange={(e)=> this.changeModality(e.target.value)}>
                {Object.entries(modalities).map(([id, name])=>(
                  <option value={id} selected={currentModality == id }> {name} </option>
                ))}
                
                
              </select>
              <select onChange={(e)=> this.changeMonth(e.target.value)}>
                {Object.entries(months).sort((a,b)=> a[1] - b[1]).map(([name, number])=>{
                  if(!(name == "total" && currentModality == "balance_sheet")){
                    return <option value={name} selected={currentMonth == name }> {name} </option>  
                  }
                })}
                
                
              </select>
              <select onChange={(e)=> this.changeYear(e.target.value)}>
                {years.map((option_year)=>(
                  <option value={option_year} selected={year == option_year }> {option_year} </option>
                ))}
                
                
              </select>
            </form>
        </div>
        {currentModality == "balance_sheet" ? (
          <BalanceSegmentado companies={companies} months={this.props.months} defaultYear={this.props.defaultYear} currentMonth={currentMonth} year={year}/>)
         :
          <div id="table-result-wrapper">
          <div className="content padding-40">
            <table id="table-result">
              <thead>
                <tr>
                  <th>Nome</th>
                  {Object.entries(companies).map(([name, numero]) => (
                    (numero != this.props.current_company_id) && <th>{name}</th>
                  ))}
                  {/*<th>Movimentações</th>*/}
                  <th>
                    {this.cable_counting > 0 ? 
                      <div>
                        <div style={{color: 'red'}}>(Recarregando {this.cable_counting} formulas)</div>
                        <br/><div >Acumulado</div>
                      </div>
                      :
                      <div >Acumulado</div>
                    }
                    
                    
                  </th>
                  {Object.entries(companies).map(([name, numero]) => (
                    (numero == this.props.current_company_id) && <th>{name}</th>
                  ))}
                </tr>
              </thead>

              {Object.entries(dataModel).map(([key, value]) => {
                let formulaTotal = 0
                return (
                  <tbody>
                    {this.renderCostCenterParams(key, value)}
                    {this.renderLine(key, value)}
                    {this.renderIndicators(key, value)}
                    <tr id="principal" className={`formula-${formulas?.[key]?.formula_type} format-${formulas?.[key]?.number_format} formula-${formulas?.[key]?.is_only_index ? "only-index" : "more-than-index"}`}>
                      <td>{key}</td>
                      {Object.entries(companies).map(([name, company_id]) => {
                        if(company_id != this.props.current_company_id){
                          formulaTotal += this.tryEval(companyData?.[company_id]?.["report"]?.[key]?.[key]?.["result"]?.[currentMonth])
                        }
                        
                        return (
                          (company_id != this.props.current_company_id) && <td>
                            {this.getNumerFormatted(this.tryEval(companyData?.[company_id]?.["report"]?.[key]?.[key]?.["result"]?.[currentMonth]), formulas[key]?.number_format)}
                          </td>
                        );
                      })}
                      {/*<td>
                        <RemovedAmount 
                          removedAmounts={this.state.removedAmounts}
                          refreshRemovedAmounts={()=> this.refreshRemovedAmounts()}
                          year={year}
                          month={currentMonth}
                          reference_id={formulas?.[key]?.fr_id}
                          reference_model={'formula'}
                         />
                      </td>*/}
                      <td>
                        
                        {
                          <label data-value={this.tryEval(formulasTotal[formulas[key].fr_id])} style={{color: (this.tryEval(formulasTotal[formulas[key].fr_id])) <= 0 ? "#03ab79" : "#ca2149" }}>
                            {this.getNumerFormatted(this.tryEval(formulasTotal[formulas[key].fr_id]) , formulas[key].number_format, year, currentMonth, formulas?.[key]?.fr_id)}  
                          </label>
                          
                        }
                      </td>
                      {Object.entries(companies).map(([name, company_id]) => {
                        if(company_id != this.props.current_company_id){
                          formulaTotal += this.tryEval(companyData?.[company_id]?.["report"]?.[key]?.[key]?.["result"]?.[currentMonth])
                        }
                        
                        return (
                          (company_id == this.props.current_company_id) && <td>
                            {this.getNumerFormatted(this.tryEval(companyData?.[company_id]?.["report"]?.[key]?.[key]?.["result"]?.[currentMonth]), formulas[key]?.number_format)}
                            
                          </td>
                        );
                      })}
                    </tr>
                    <tr></tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </div>
        }
        
        <LoadingDialog open={this.requestsCounter > 0}/>
      </React.Fragment>
    );
  }
}

export default Segmentado;
const monthDict = {
  "janeiro": 1,
  "fevereiro": 2,
  "março": 3,
  "abril": 4,
  "maio": 5,
  "junho": 6,
  "julho": 7,
  "agosto": 8,
  "setembro": 9,
  "outubro": 10,
  "novembro": 11,
  "dezembro": 12,
  "total": 13
}