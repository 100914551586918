import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import PopHover from './PopHover';
import Avatar from '@material-ui/core/Avatar';
import { Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Select from 'react-select';
import moment from 'moment';


const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
    color: "rgb(68,185,189)"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 360
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonClass: {
    backgroundColor: '#fff',
    marginBottom: 3,
    color: '#2a3170',
    borderColor: '#2a3170',
    borderWidth: 1
  }, 
  details: {
    padding: theme.spacing(1),
    color: '#0D0D0D',
    fontSize: 10
  },
  detailsTooltip: {
    padding: theme.spacing(1),
    color: '#fff',
    fontSize: 10
  },
  commentContainer: {
    backgroundColor: '#fff',
    padding: theme.spacing(1),
    borderRadius: '10px',
    marginBottom: theme.spacing(1),
    display: 'flex',
    alignItems: 'center'
  },
  commentsTooltip: {
    backgroundColor: '#F2EFEE',
    padding: theme.spacing(1),
    borderRadius: '10px',
    marginBottom: theme.spacing(0.5),
    display: 'flex',
    alignItems: 'center'
  },
  modal: {
    backgroundColor: '#F2EFEE'
  },
  TextField: {
    backgroundColor: '#fff'
  }
}));

const formattedDate = (date) => moment(date).format('DD/MM/YYYY HH:mm');

export default function Comment(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [description, setDescription] = React.useState(null);
  const [editingComment, setEditingComment] = React.useState(null);
  const [users, setUsers] = React.useState([]);
  const [filteredUsers, setFilteredUsers] = React.useState([]);
  const [filter, setFilter] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState(null);
  const [showSelect, setShowSelect] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setDescription('');
    setShowSelect(false);
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get('/comments/users-comments.json');
      const userList = response.data;
      setUsers(userList);
    } catch (error) {
      console.error('Erro ao buscar usuários:', error);
    }
  };

  const handleInputChange = (e) => {
    setDescription(e.target.value);
    const inputValue = e.target.value;
    const atIndex = inputValue.lastIndexOf('@');
    if (inputValue.includes('@')){
      fetchUsers()
    } else {
      setFilteredUsers([]);
    }
    if (atIndex >= 0 && inputValue[atIndex + 1]) {
      const filter = inputValue.substring(atIndex + 1);
      setFilter(filter);
    } else {
      setShowSelect(false)
      setFilter('');
    }    
    for (let i = 0; i < inputValue.length; i++) {
      const letra = inputValue[i];
      setShowSelect(letra == "@");
    }
  };

  const handleEdit = (comment) => {
    setEditingComment(comment);
    setDescription(comment.description);
  };

  const handleCancelEdit = () => {
    setEditingComment(null);
    setDescription('');
  };

  const handleSaveEdit = async () => {
    await axios.put(`/comments/${editingComment.id}.json`, {
      description: description 
    });
    props.refreshComments();
    handleCancelEdit();
  };


const handleUserSelect = (selectedOption) => {
  const username = selectedOption ? selectedOption.name : '';
  const pattern = /@\S+/g; 
  setSelectedUser(selectedOption);

  if (description.includes("@")) {
        setDescription(description.replace(filter, '') + `${username}`);
  } else {
    setDescription(description.replace(pattern, `${username}`));
  }

  setShowSelect(false);
};


  const handleKeyPress = (event) => {
    if (event.key === 'Enter' && filteredUsers.length > 0) {
      event.preventDefault(); 
      handleUserSelect(filteredUsers[0]);
      selectRef.current.focus();
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  let deleteComment = async (id) => {
    await axios.delete(`/comments/${id}.json`);
    props.refreshComments();
  };

  let {year, month, model_reference, model_reference_id } = props

  let  createComment = async function(){
    await axios.post("/comments.json", {
      year,
      month,
      model_reference,
      model_reference_id,
      description
    })
    props.refreshComments();
    
    setDescription('');
    sendNotification();
    handleClose();
  }

  let sendNotification = async function(){
    if (selectedUser) {
      await axios.post("/notifications.json",  {
        description, 
        selectedUser
      })
    } else {
      return
    }
  }

  let comments = props?.comments.filter && props?.comments.filter(comment => 
    comment.year == null || comment.year == year && 
    comment.month == month && 
    comment.model_reference == model_reference && 
    comment.model_reference_id == model_reference_id
  );
  comments = comments || [];

  const lastComment = comments.length > 0 ? comments[comments.length -1].description : null 

  React.useEffect(() => {
    if (users && filter) {
      const filteredUsers = users.filter(user =>
        user.name && user.name.toLowerCase().startsWith(filter.toLowerCase())
      );
      setFilteredUsers(filteredUsers);
      if (filteredUsers.length > 0) {
	setShowSelect(true);
      }
    } else {
      setFilteredUsers(users);
    }
  }, [filter, users]);
 
  return (
    <div className={`comment-block ${showSelect ? 'show-select' : ''}`}>
      
      {
        comments.length > 0 ?
        <div onClick={handleClick}><PopHover  anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'left',
          }}
          withoutico description={
            <React.Fragment>
               {comments.length ? (comments.slice(-5).map((comment) => (
                  <Grid container spacing={0} alignItems="flex-start" key={comment.id}>
                    <Grid item xs={12}>
                      <Typography className={classes.detailsTooltip}>{comment.user}</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.commentsTooltip}>
                        <p style={{ color: 'black', margin: '100' }}>{comment.description}</p>
                      </Typography>
                    </Grid>
                  </Grid>
              ))): ''}
            </React.Fragment>
            }>
        </PopHover></div> :
        <i className={'fa fa-edit edit'}  onClick={handleClick} >
        </i>
      }
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.modal}>
          <div style={{display: 'flex', justifyContent: 'space-between'}}>
            <Typography className={classes.typography}>Comentários</Typography>
            <div style={{position: 'absolute', top: 0, right: 0}}>
              <IconButton onClick={()=> handleClose()}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>
          <div style={{marginLeft: 10, width: 360}}>
            {comments.length ? (comments.map((comment) => (
              <Grid container spacing={1} alignItems="flex-start" key={comment.id}>
                <Grid item>
                  <Avatar alt={comment.user} src={comment.user_avatar} style={{ width: 30, height: 30, margin: 5 }}/>
                </Grid>
                <Grid item xs>
                  <Typography className={classes.details}>{`${comment.user} - ${formattedDate(comment.create)}`}</Typography>
                  <Typography className={classes.commentContainer}>{comment.description}</Typography>
                  <div style={{marginLeft: 183}}>
                    <Button style={{ color: '#0D0D0D', fontSize: '0.7rem' }} size="small" onClick={() => handleEdit(comment)}>
                      Editar
                    </Button>
                    <Button  style={{ color: '#0D0D0D', fontSize: '0.7rem' }} size="small" onClick={() => deleteComment(comment.id)}>
                      Excluir
                    </Button>
                  </div>
                </Grid>
              </Grid>
            ))
            ): ''}
          </div>
          <FormControl variant="outlined" className={classes.formControl} style={{ height: showSelect ? 300 : 'auto' }}>
            <TextField
              className={classes.TextField}
              id="outlined-multiline-static"
              label="Insira seu comentário aqui"
              multiline
              rows={1}
              value={description}
              placeHolder="Insira o comentário aqui"
              variant="outlined"
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
            />
            {showSelect  && (
	      <div style={{ marginTop: -80}}>
		<Select
		  options={filteredUsers}
		  hideLabel
		  onChange={handleUserSelect}
		  getOptionLabel={(user) => user.name}
		  getOptionValue={(user) => user.id}
		  menuIsOpen={true}
	          styles={{
		    control: (provided) => ({ ...provided, backgroundColor: 'transparent', border: 'none' }),
		    placeholder: (provided) => ({ ...provided, display: 'none' }),
		    singleValue: (provided) => ({ ...provided, display: 'none' }),
		    indicatorSeparator: (provided) => ({ ...provided, display: 'none' }),
		  }}
		/>
	      </div>
            )}
          </FormControl>
          { !editingComment ?
            <Button style={{float: 'right', margin: "16px 10px 10px", color:"#31b7bc" }} variant="contained"  onClick={()=> createComment()}>
              Comentar
            </Button>
            :
            <div>
              <Button style={{ color: '#fff', fontSize: '0.8rem', margin: "26px 10px 10px" }} size="small" variant="contained"  onClick={()=> handleSaveEdit()}>
                Salvar
              </Button>
              <Button style={{ color: '#fff', fontSize: '0.8rem', margin: "26px 10px 10px" }} size="small" variant="contained"  onClick={()=> handleCancelEdit()}>
                Cancelar
              </Button>
            </div>
          }
        </div>
      </Popover>
    </div>
  );
}

