import React from "react";

export function WidgetChartIcon(props) {
  return (
    <svg viewBox="0 0 23 23" fill="none" {...props}>
      <path
        d="M1.25 9.002a1 1 0 011-1h2a1 1 0 011 1v11a1 1 0 01-1 1h-2a1 1 0 01-1-1v-11zm9-1a1 1 0 01-1-1v-5a1 1 0 011-1h2a1 1 0 011 1v5a1 1 0 01-1 1h-2zm8 6a1 1 0 01-1-1v-11a1 1 0 011-1h2a1 1 0 011 1v11a1 1 0 01-1 1h-2z"
        stroke="#617E94"
        strokeWidth={2}
      />
    </svg>
  );
}
