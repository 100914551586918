import React, { useState, useEffect } from 'react';
import axios from 'axios';

const ConfigurationTableRow = ({ balanceSheetCode, accountDescription, areas, isNew, companyCostCenterAreas }) => {
    
  // Estado local para guardar os checks de cada área.
  const [areaChecks, setAreaChecks] = useState({});

  useEffect(() => {
    // Atualiza o estado local com os valores iniciais.
    let initialChecks = {};
    areas.forEach(area => {
      initialChecks[area.id] = companyCostCenterAreas[`[\"${balanceSheetCode}\", ${area.id}]`] > 0;
    });
    setAreaChecks(initialChecks);
  }, [areas, balanceSheetCode, companyCostCenterAreas]);

  const handleChange = (event, areaId) => {
    // Atualiza o estado local com o novo valor do check.
    setAreaChecks({...areaChecks, [areaId]: event.target.checked});

    // Faça a requisição axios aqui
    axios.post('/cost_centers/check_conflict', {
      codigo: balanceSheetCode,
      area: areaId,
      fixo: event.target.checked,
    })
    .then(response => {
      let data = response.data;
      let messages = [];
      data.conflicts.forEach(conflict => {
          let message = `A empresa ${conflict.company.razao_social} tem um conflito com o código ${conflict.code}. Este código é `;
          message += conflict.is_fixed ? 'fixo.' : 'variável.';
          messages.push(message);
      });

      // Mostra um alerta para cada mensagem
      if(messages.length > 0){
        alert(messages.join('\n'))
      }
      

    })
    .catch(error => {
      console.log(error);
    });
  };

  return (
    <>
        <tr className={isNew ? 'newrow' : ''}>
            <th>{balanceSheetCode}</th>
            <th>{accountDescription}</th>
            {areas.map(area => (
                <th key={area.id}>
                <input type="hidden" name="company_cost_center_areas[][cost_center_area_id]" value={area.id} />
                <input type="hidden" name="company_cost_center_areas[][code]" value={balanceSheetCode} />
                <input
                    type="checkbox"
                    name="company_cost_center_areas[][is_fixed]"
                    checked={areaChecks[area.id]}
                    onChange={(event) => handleChange(event, area.id)}
                />
                </th>
            ))}
        </tr>
    </>
  );
};

export default ConfigurationTableRow;