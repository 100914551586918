import React from "react"


export function CalendarIcon(props) {
  return (
    <svg viewBox="0 0 15 15" fill="none" {...props}>
      <path
        stroke="#fff"
        strokeWidth={0.7}
        strokeLinecap="round"
        d="M2.35 8.317h1.967M2.35 10.983h1.967M6.35 8.317h1.967M6.35 10.983h1.967M10.35 8.317h1.967M10.35 10.983h1.967M.35 5.983h13.97M2.983 1.983v-1.3M11.983 1.983v-1.3"
      />
      <rect
        x={0.35}
        y={2.35}
        width={14.3}
        height={11.3}
        rx={2.317}
        stroke="#fff"
        strokeWidth={0.7}
      />
    </svg>
  );
}
