import React from 'react'; 
import { TableStyles, Td, Th, Tr, Separator, RoundButton } from "./style.js";
import icon_edit from "icons-presentations/icons-editar.svg";
import icon_print from "icons-presentations/print-btn.svg";
import icon_tit from "icons-presentations/icons-title.svg";
import icon_delete from "icons-presentations/icons-delete.svg";
import axios from "axios";

const TableIndex = ({data}) => {
  const columns = [
    {
      Header: "Descrição"
    }, 
    {
      Header: "Ações"
    }
  ]

  const handleDelete = (id) => {
    if (window.confirm('Tem certeza que deseja excluir este registro')) {
      const csrfToken = document.querySelector('meta[name=csrf-token]').content;

      const headers = {
        'X-CSRF-Token': csrfToken,
      };

      axios.delete(`/presentations/${id}.json`, { headers })
        .then((res) => {
          alert("Registro excluido");
          Turbolinks.visit(`/presentations`);
        })
        .catch((error) => {
          alert("Registro nao pode ser excluido");
        });
    }
  };

  return (
    <>
      <TableStyles>
        <thead>
          <Tr>
            {columns.map((column, index) => (
              <Th
                style={{
                  width:
                    column.Header === "Ações"
                      ? 100
                      : column.Header === "Descrição"
                      ? 100
                      : "100%",
									marginLeft: 35
                }}
                key={index}
              >
                {column.Header}
              </Th>
            ))}
          </Tr>
        </thead>
        <tbody>
          {data.map((value, index) => {
            return (
              <Tr style={{background: index % 2 == 0 ? '#c7bdbd' : ''}}>
                <Td
                  style={{
                    display: "flex",
                    justifyContent: "initial",
                    alignItems: "center",
                    height: 34,
                  }}
                >
                  <div style={{ width: 600 }}><span style={{ fontFamily: 'Roboto', fontSize: 14}}>{value.label}</span></div>
                </Td>
                <Td style={{
											display: 'flex',
											alignContent: 'stretch',
											placeContent: 'center flex-end',
											flexWrap: 'wrap',
											justifyContent: 'flex-start',
									}}>
                        <RoundButton
                          title={"visualizar"}
                          onClick={() => { Turbolinks.visit(`/presentations/${value.id}`)}
                          }
                        >
													<img src={icon_print} style={{width: 30}} title="visualizar"></img>
                        </RoundButton>
                        {value.enable_to_edit && <><RoundButton
                          title={"edit"}
                          onClick={(e) => { Turbolinks.visit(`/presentations/${value.id}/presentation_sheets/${value.first_page_url}`) }}
                        >
                  				<img src={icon_edit} style={{width: 30}} title={"editar"}></img>
                        </RoundButton>
                        <RoundButton
                          title={"título"}
                          onClick={(e) => { Turbolinks.visit(`/presentations/${value.id}/edit`) }}
                        >
                  				<img src={icon_tit} style={{width: 30}} title={"editar titulo"}></img>
                        </RoundButton>
                        <RoundButton
                          title={"deletar"}
                          onClick={() => handleDelete(value.id)}
                        >
                  				<img src={icon_delete} style={{width: 30}} title={"excluir"}></img>
                        </RoundButton></>}


								</Td>
              </Tr>
            );
          })}
        </tbody>
      </TableStyles>
    </>
  );

}


export default TableIndex;
