import React from 'react'

export function FileIcon(props) {
  return (
    <svg viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M7.833 8.667a.833.833 0 01.834-.834h6.666a.833.833 0 010 1.667H8.667a.833.833 0 01-.834-.833zm.834 4.166h6.666a.834.834 0 000-1.666H8.667a.834.834 0 000 1.666zM12 14.5H8.667a.833.833 0 100 1.667H12a.833.833 0 000-1.667zM22 3.667V14.99a1.657 1.657 0 01-.49 1.177l-5.343 5.343a1.656 1.656 0 01-1.177.49H3.667A1.667 1.667 0 012 20.333V3.667A1.667 1.667 0 013.667 2h16.666A1.666 1.666 0 0122 3.667zM3.667 20.333H14.5v-5a.833.833 0 01.833-.833h5V3.667H3.667v16.666zm12.5-4.166v2.99l2.99-2.99h-2.99z"
        fill="#fff"
      />
    </svg>
  );
}
