import React from "react";
import ParamDifferenceModal from './ParamDifferenceModal'
class ParamDialog extends React.Component {
  state = {
    opened: false,
    balanceSheet: {}
  }
  constructor(props) {
    super(props);
    
  }
  
  hasDifference(){
    const {balanceSheet} = this.state;
    try{
      return balanceSheet.not_configured_codes.length > 0
    }catch(e){}
    
  }

  componentDidMount(){
    this.setState({balanceSheet: JSON.parse(this.props.balanceSheet)})
  }

  handleClickOpen(){
    this.setState({opened: true});
  };

  handleClose(value){
    this.setState({opened: false});
    setSelectedValue(value);
  };

  render() {
    const {balanceSheet} = this.state;
    return (
      <div>
        {this.hasDifference() && 
          <i onClick={this.handleClickOpen.bind(this)} 
          className={ 'fa fa-exclamation-circle'} 
          style={{fontSize: 20, color: "#FDD04B", cursor: "pointer"}}
          />
        }
        <ParamDifferenceModal balanceSheet={balanceSheet}  open={this.state.opened} onClose={this.handleClose.bind(this)} />
      </div>
    );
  }
}

export default ParamDialog;
