import React, {useEffect, useContext, useRef} from 'react';
import PresentationsResultChart from '../../PresentationsResultChart';
import styled from 'styled-components';
import { PresentationsContext } from "../contexts";
import {parseSelectedValue} from '../helpers';
import ResultChart from '../../ResultChart';
import SegmentedChart from './Chart';


export default function SegmentadoView ({
    parameters,
    report,
    childreen,
    currentCompanyId,
    state,
    printing
}) {
    
    const {formulasById, patternParamsById, companyData} = state
    const wrapperRef = useRef(null);
    
    // useEffect(() => {
    //     const observer = new IntersectionObserver(
    //         (entries) => {
    //             const entry = entries[0];
    //             if(entry.isIntersecting){
    //               toggleChartVisibility();
    //             }
    //         },
    //         { threshold: 0.1 } // Ajuste este valor conforme necessário
    //     );
    
    //     if (wrapperRef.current) {
    //         observer.observe(wrapperRef.current);
    //     }
    
    //     return () => {
    //         if (wrapperRef.current) {
    //             observer.unobserve(wrapperRef.current);
    //         }
    //     };
    // }, []);
  return (
    <Wrapper ref={wrapperRef}>
        {parameters?.graphs?.map((graph, index) => {
         let formula = null
         let formulaArray = null
          try{
            formulaArray = graph.formulas.map((selectedOption)=>{
              let {type, id} = parseSelectedValue(selectedOption?.value)
              if(type == "parameter"){
                formula = patternParamsById[id];
              }
              else if(type == "formula"){
                formula = formulasById[id];
              }
              
              return {...formula, format: selectedOption.format, year: selectedOption.year, type}
              
            })
            
          }catch(e){
            return <></>
          }
          
          var element = document.getElementById('presentation_sheet');
          
          var height = 350;

          let key = formula?.label;
          return (
            <Container>
              <SegmentedChart
                state={state}
                companyData={state.companyData}
                budgetCompanyData={state.budgetCompanyData}
                formulaData={formulaArray[0]}
                companies={childreen}
                year={formulaArray[0].year}
                format={formulaArray[0].number_format}
                p_key={key}
                comparedData={graph?.formulas?.[0]?.comparedData}
                currentMonth={monthDict[graph?.formulas?.[0]?.month]}
                currentCompanyId={currentCompanyId}
              >
              </SegmentedChart>
              
            </Container>
          );
        })}
    </Wrapper>
  );
}


const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Container = styled.div`
    flex: 1;
    flex-basis: 40%;
    height: 320px;
    max-width: 48%;
    display: inline-block;
`;

const monthDict = {
   1: "janeiro",
   2: "fevereiro",
   3: "março",
   4: "abril",
   5: "maio",
   6: "junho",
   7: "julho",
   8: "agosto",
   9: "setembro",
   10: "outubro",
   11: "novembro",
   12: "dezembro",
   "range": "range"
};


