import React, {useEffect, useContext, useRef} from 'react';
import PresentationsResultChart from '../../PresentationsResultChart';
import styled from 'styled-components';
import { PresentationsContext } from "../contexts";
import {parseSelectedValue} from '../helpers';
import ResultChart from '../../ResultChart';



export default function ChartView ({
    parameters,
    report,
    state,
    printing
}) {
    const [visibleCharts, setVisibleCharts] = React.useState(true);
    const {formulasById, patternParamsById} = state
    const wrapperRef = useRef(null);

    useEffect(() => {
        toggleChartVisibility()
      }, [parameters.graphs]);

    const toggleChartVisibility = ()=>{
      setVisibleCharts(false);
        setTimeout(() => {
          setVisibleCharts(true);
        }, 1000);
    }
    // useEffect(() => {
    //     const observer = new IntersectionObserver(
    //         (entries) => {
    //             const entry = entries[0];
    //             if(entry.isIntersecting){
    //               toggleChartVisibility();
    //             }
    //         },
    //         { threshold: 0.1 } // Ajuste este valor conforme necessário
    //     );
    
    //     if (wrapperRef.current) {
    //         observer.observe(wrapperRef.current);
    //     }
    
    //     return () => {
    //         if (wrapperRef.current) {
    //             observer.unobserve(wrapperRef.current);
    //         }
    //     };
    // }, []);
  return (
    <Wrapper ref={wrapperRef}>
        {parameters?.graphs?.map((graph, index) => {
         let formula = null
         let formulaArray = null
          try{
            formulaArray = graph.formulas.map((selectedOption)=>{
              let {type, id} = parseSelectedValue(selectedOption?.value)
              if(type == "parameter"){
                formula = patternParamsById[id];
              }
              else if(type == "formula"){
                formula = formulasById[id];
              }
              
              return {...formula, format: selectedOption.format, year: selectedOption.year, type}
              
            })
            
          }catch(e){
            return <></>
          }
          
          var element = document.getElementById('presentation_sheet');
          
          var height = 350;

          let key = formula?.label;
          return (
            <Container>
              {(graph.graph_style == "montly" && visibleCharts ) && <PresentationsResultChart
                year={graph?.year}
                height={height}
                setSVG={(svg) => {}}
                numberFormat={formula?.number_format || "money"}
                chartStyle={graph.format}
                title={graph.title}
                printing={printing}
                p_key={key}
                dataKey={"line_graph"}
                formulaArray={formulaArray}
                formula_id={key}
                formula_fr_id={formula?.fr_id}
                months={monthDict}
                allYearsData={report}
                allYearsDataForParameters={state.allYearsDataForParameters}
                withoutModal={true}
              />}
              {(graph.graph_style == "accumulated" && visibleCharts) && <>
                <ResultChart
                  renderOnlyHorizontal={true}
                  year={graph?.formulas?.[0]?.year}
                  formulasTotalByYearYTD={state.formulasTotalByYearYTD}
                  years={[graph?.formulas?.[0]?.year]}
                  height={height}
                  setSVG={(svg) => {}}
                  numberFormat={formula?.number_format || "money"}
                  chartStyle={graph.format}
                  title={graph.title}
                  printing={printing}
                  p_key={key}
                  dataKey={"result"}
                  formulaArray={formulaArray}
                  formula_id={key}
                  formula_fr_id={formula?.fr_id}
                  months={monthDict}
                  allYearsData={report}
                  allYearsDataForParameters={state.allYearsDataForParameters}
                  withoutModal={true}
                />
              </>}
              
            </Container>
          );
        })}
    </Wrapper>
  );
}


const Wrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
`;

const Container = styled.div`
    flex: 1;
    flex-basis: 40%;
    height: 350px;
    max-width: 48%;
    display: inline-block;
`;

const monthDict = {
  janeiro: 1,
  fevereiro: 2,
  março: 3,
  abril: 4,
  maio: 5,
  junho: 6,
  julho: 7,
  agosto: 8,
  setembro: 9,
  outubro: 10,
  novembro: 11,
  dezembro: 12,
};