import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FlexCenter, ExpandButton } from "../styled_components/default";
import ColapsedItem from "./ColapsedItem";

import dashboardImage from 'menu-dashboard-icon.svg';
import menuResultIcon from 'menu-result-icon.svg';
import oramentoIcon from 'orcamento-icon.svg';
import menuUploadIcon from 'menu-upload-icon.svg';
import menuConsultingIcon from "menu-consulting-icon.png";
import menuParameterizationIcon from "menu-parameterization-icon.svg";
import LoadingDialog from './LoadingDialog'
import consultingIcon from 'consulting.svg';
class LateralMenu extends React.Component {
  state = {
    activeItem: 'dashboard',
    collapsed: true,
    customParamLinks: [],
    expandItemSelected: ''
  };

  componentDidMount() {
    this.setCustomParamLinks()
  }

  componentDidMount(){
    let {can_balance_sheets, can_cost_centers, can_budgets, can_contabil_patterns, can_formulas, can_charts, can_front_dashboard_formulas, can_indicators} = this.props.authorizations
    let {is_higher_parent_company, max_string_length_per_year} = this.props;
    
    this.setState({customParamLinks: [
      (can_contabil_patterns && is_higher_parent_company ? { label: "Configurar Padrão", href: "/contabil_pattern/configure" } : null),
      (can_balance_sheets ? { label: "Parametrizar Balancete - De/Para", href: "/balance_sheets/new" } : null),
      (can_balance_sheets ? { label: "Parametrizar Centros de Custo", href: "/cost_centers/setup_params" } : null),
      (can_indicators && is_higher_parent_company ? { label: "Indicadores", href: "/indicators" } : null ),
      (can_formulas  && is_higher_parent_company?  {label: "Formulas", href: "/formulas" } : null),
      (can_formulas  ?  {label: "Formulas dos Anexos", href: "/attachments/formulas" } : null),
      (can_front_dashboard_formulas && is_higher_parent_company ? { label: "Formulas - Gráfico", href: "/charts" } : null),
      (can_front_dashboard_formulas ? { label: "Dashboard Widgets", href: "/front_dashboard_formulas" } : null),
      (can_front_dashboard_formulas ? { label: "Periodo de checagem", href: "/liquid_profits" } : null),
      (max_string_length_per_year ? { label: "Diferenciação da parametrização anual", href: "/company_yearly_restrictions" } : null)
    ]})
    
  }

  setActiveItem = function (id) {
    this.setState({ activeItem: id });
    this.setState({ collapsed: false })
  };


  activeInclude(path) {
    const urlPath = window.location.pathname
    const uploadPaths = [
      '/balance_sheets',
      '/cost_centers',
      '/budgets/upload',
      '/ledgers'
    ]
    const parametrizationPaths = [
      '/contabil_pattern/configure',
      '/formulas',
      '/charts',
      '/front_dashboard_formulas',
      '/indicators',
      '/balance_sheets/new'
    ]

    if (urlPath === path) {
      return true
    }

    if (path === 'upload' && uploadPaths.includes(urlPath)) {
      return true
    }

    if (path === 'parametrization' && parametrizationPaths.includes(urlPath)) {
      return true
    }
  }

  expandItem(item) {
    this.setState({
      expandItemSelected: item,
      collapsed: false
    })
  }

  toggleCollapse() {
    if (!this.state.collapsed) {
      this.expandItem(null)
    }
    this.setState({ collapsed: !this.state.collapsed })
  }

  render() {
    let { collapsed, expandItemSelected } = this.state;

    const { can_balance_sheets,
      can_cost_centers,
      can_budgets,
      can_read_balance_sheets,
      can_parameters,
      can_upload,
      can_consulting } = this.props.authorizations
    
      const {max_string_length_per_year} = this.props

    return (
      <div style={{ width: collapsed ? 100 : 260, transition: "all 300ms ease-in-out", overflow: "hidden" }}>
        <ColapsedItem
          key={"Dashboard"}
          collapsed={collapsed}
          onClick={() => this.onClick("dashboard")}
          active={this.activeInclude("dashboard")}
          href="/"
          label="Dashboard"
          icoSrc={dashboardImage}
        />

        {can_read_balance_sheets && <ColapsedItem
          key={"Resultado"}
          has_inconsistence={this.props.has_inconsistence}
          collapsed={collapsed}
          active={this.activeInclude("/results")}
          href="/results"
          label="Resultado"
          icoSrc={menuResultIcon}
        />}


        {can_read_balance_sheets && <ColapsedItem
          key={"Orçamento"}
          collapsed={collapsed}
          active={this.activeInclude("/results/budgets")}
          href="/results/budgets"
          label="Orçamento"
          icoSrc={oramentoIcon}
        />}

        {can_consulting && <ColapsedItem
          key={"Gestão de tarefas"}
          collapsed={collapsed}
          active={this.activeInclude("/consulting")}
          href="/consulting"
          label="Gestão de tarefas"
          icoSrc={consultingIcon}
        />}
        {can_upload && <ColapsedItem
          key={"Upload"}
          collapsed={collapsed}
          onClick={() => this.expandItem("upload")}
          active={this.activeInclude("upload")}
          expanded={expandItemSelected === 'upload'}
          label="Upload"
          icoSrc={menuUploadIcon}
          submenuLinks={[
            (can_balance_sheets ? { label: "Balancete", href: "/balance_sheets" } : null),
            (can_cost_centers ? { label: "Centro de custo", href: "/cost_centers" } : null),
            (can_cost_centers ? { label: "Razão Contábil", href: "/ledgers" } : null),
            (can_budgets ? { label: "Orçamento", href: "/budgets/upload" } : null),
            (can_parameters ? { label: "Controle de uploads", href: "/upload_settings" } : null)
            
          ]}
        />}

        { /*<ColapsedItem
                 key={"Consultoria"}
                 collapsed={collapsed}
                 onClick={() => this.expandItem("consultoria")}
                 active={this.activeInclude("consultoria")}
                 expanded={expandItemSelected === 'consultoria'}
                 label="Consultoria"
                 icoSrc={menuConsultingIcon}
                 submenuLinks={[
                   { label: "bla", href: "consultoria/#" },
                   { label: "bla", href: "consultoria/#" },
                   { label: "bla", href: "consultoria/#" }
                 ]}
               />*/}

        {can_parameters && <ColapsedItem
          key={"Parametrização"}
          collapsed={collapsed}
          onClick={() => this.expandItem("parametrization")}
          active={this.activeInclude("parametrization")}
          expanded={expandItemSelected === 'parametrization'}
          label="Parametrização"
          icoSrc={menuParameterizationIcon}
          submenuLinks={[

            ...this.state.customParamLinks
            // { label: "Parametrizar Centro de custo", href: "/cost_centers/new" }
          ]}
        />}

        <ExpandButton onClick={() => this.toggleCollapse()} active={collapsed} />

      </div>
    );
  }
}

export default LateralMenu;
