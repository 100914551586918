import React from "react"

export function BorboletaIcon(props) {
  return (
    <svg viewBox="0 0 9 13" fill="none" {...props}>
      <path
        stroke="currentColor"
        strokeWidth={1.45}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.725 1.225v10.55M7.725 9.775h.55M7.725 6.775h.55M7.725 3.775h.55M.725 9.775h.55M.725 6.775h.55M.725 3.775h.55"
      />
    </svg>
  )
}

BorboletaIcon.propTypes = {}