import * as React from "react";

function CartColumnIcon(props) {
  return (
    <svg viewBox="0 0 10 13" fill="none" {...props}>
      <path
        stroke="currentColor"
        strokeWidth={1.2}
        strokeLinecap="round"
        d="M1.4 12.066V2.6M4.067 12.066V6.6M6.734 12.066V3.933M9.4 12.066V1.267"
      />
    </svg>
  );
}

const MemoCartColumnIcon = React.memo(CartColumnIcon);
export default MemoCartColumnIcon;
