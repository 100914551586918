import styled, {css} from "styled-components";
import React from "react";


export const RoundButton = styled.div`   
 width: 32px;
 height: 33.4px;
 flex-grow: 0;
 margin: 1.1px 4px 0.7px;
 padding: 0;
 border-radius: 19px;
 display: flex;
 justify-content: center;
 align-items: center;
 color: white; 
 cursor: pointer;
`

export const Separator = styled.div`
  width: 10px;
`;

export const Row = css`
  display: flex;
  align-items: center;
`

export const TableStyles = styled.table`
  width: 100%;
  border-spacing: 0 10px;
`;

export const Tr = styled.tr`
  td {
    border-top: 1px solid transparent;
    border-bottom: 1px solid #e5e5e5;

    &:first-child {
      border-left: 1px solid #e5e5e5;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }

    &:last-child {
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
      border-left: 1px solid #cfc8c8;
    }
  }

  ${({ white, theme }) =>
    white
      ? {
          "&:nth-child(odd)": {
            backgroundColor: white,
          },
        }
      : null}
`;

export const ThStyled = styled.th`
  text-align: left;
  font-size: 1.1rem;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.55px;
  white-space: nowrap;
  color: ${({ theme }) => theme.blueLight};
  padding-left: 2rem;
`;

const TdStyled = styled.td`
  background-color: rgba(248, 248, 248, 0.5);
  color: #424242;
  padding-left: 2rem;
  font-size: 12px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.05rem;
`;

const Cell = styled.div`
  ${Row};
`;

export const Td = ({ children, ...props }) => {
  return (
    <TdStyled>
      <Cell {...props}>{children}</Cell>
    </TdStyled>
  );
};

export const Th = ({ children, ...props }) => {
  return (
    <ThStyled {...props}>
      <Cell {...props}>{children}</Cell>
    </ThStyled>
  );
};
