export default {
  control: {
    backgroundColor: "#f5f5f5",
    height: 200,
    fontSize: 16,
    fontWeight: 'normal',
    borderRadius: 6
  },
  highlighter: {
    overflow: 'hidden'
  },
  input: {
    margin: 0,
    width: '100%',
    boxSizing: 'border-box',
    height: 45,
  },
  '&singleLine': {
    control: {
      display: 'inline-block',
      height: 45,
      width: 1000,
      paddingTop: 10
    },
    highlighter: {
      padding: 1,
      border: '2px inset transparent',
    },
    input: {
      padding: 1,
      borderRadius: 6,
      border: 'solid 0.5px #acacac',
    },
  },
  suggestions: {
    list: {
      position: 'fixed',
      top: '55.5%',
      left: '50.5%',
      transform: 'translate(-50%, -50%)',
      backgroundColor: "#f5f5f5",
      border: '1px solid rgba(0,0,0,0.15)',
      fontSize: 14,
      maxHeight: '600px',
      overflowY: 'auto',
      width: '1000px'
    },
    item: {
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
};
