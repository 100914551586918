import React from 'react';
import TableIndex from './components/table_index/index.js'

const pageIndexTable = ({data}) => {

  return (
    <>
      <br />
      <TableIndex data={data} />
    </>
  )
}

export default pageIndexTable;
