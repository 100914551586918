import React from "react";

export function AnexoIcon(props) {
  return (
    <svg viewBox="0 0 9 15" fill="none" {...props}>
      <path
        d="M4.5 9V2.955C4.429 2.029 4.062 1.5 2.75 1.5c-1.313 0-1.708.623-1.75 1.455v7.636c.048 1.039.22 1.527.875 2.182.878.696 1.75.727 2.625.727s1.75 0 2.625-.727.87-1.217.875-2.182V5.136"
        stroke="currentColor"
        strokeWidth={1.47}
        strokeLinecap="round"
      />
    </svg>
  )
}