import React from 'react';
import ConfigurationTableRow from './ConfigurationTableRow';  // Importando o componente de linha

const ConfigurationTable = ({ costCenterRecords, areas, companyCostCenterAreas }) => {
  return (
      <table className="table bordered cost-center">
        <thead>
          <tr>
            <th>Conta</th>
            <th>Descrição</th>
            <th colSpan={areas.length}>Areas</th>
          </tr>
          <tr>
            <th></th>
            <th></th>
            {areas.map(area => <th key={area.id}>{area.label}</th>)}
          </tr>
        </thead>
        <tbody>
          {costCenterRecords.map(record =>
            <ConfigurationTableRow
              key={record.id}
              balanceSheetCode={record.balance_sheet_code}
              accountDescription={record.account_description}
              areas={areas}
              isNew={record.is_new}
              companyCostCenterAreas={companyCostCenterAreas}
            />
          )}
        </tbody>
      </table>
  );
};

export default ConfigurationTable;