import React from 'react';
import {
    BlockTitle,
    SearchField,
    WhiteBox
} from "../styled_components/balance_sheets";
import Fuse from "fuse.js";
import styles from "./styles/FormulasLayoutStyles";
import Button from "@material-ui/core/Button";

class CentroDeCustoFormulasSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paramsQueryCentroDeCusto: "",
        };
    }

    handleAddButtonClick = (row) => {
        if (this.props.onAddItem) {
            this.props.onAddItem(row);
        }
    }

    renderParams(contas) {
        let filtered = {};
        var combinedData = contas.map(item => ({
            display: item.display || "",
            id: item.id
        }));

        if (this.state.paramsQueryCentroDeCusto.length < 1) {
            filtered.contas = combinedData;
        } else {
            var options = {
                shouldSort: true,
                threshold: 0.3,
                distance: 1000,
                maxPatternLength: 1500,
                minMatchCharLength: 3,
                keys: ["display"]
            };
            var fuse = new Fuse(combinedData, options);
            filtered.contas = fuse.search(this.state.paramsQueryCentroDeCusto);
        }

        const htmlRender = filtered.contas.map((item, index) => (
            <div
                style={{
                    ...styles.listItem,
                    backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white',
                }}
                key={index}
            >
                <Button onClick={() => this.handleAddButtonClick(item)}>
                    <small style={{ fontStyle: 'italic', color: "#bababa", fontSize: '10px', whiteSpace: 'normal' }}>
                        {item.title ? (item.title + " -> ") : ""}
                    </small>
                    &nbsp;{item.display}
                </Button>
            </div>
        ));

        return htmlRender;
    }

    render() {
        const { contas } = this.props;
        return (
            <div style={styles.bgContent}>
                <div style={styles.header}>
                    <div style={styles.blockTitle}>
                        <span style={styles.icon}>?</span> CONTAS POR CENTRO DE CUSTO E TIPO DE DESPESA (FIXO/VARIÁVEL)
                    </div>
                    <div>
                        <input
                            style={styles.searchInput}
                            placeholder={"Pesquise um item"}
                            onChange={e => {
                                let newValue = e.target.value;
                                this.setState({ paramsQueryCentroDeCusto: newValue });
                            }}
                        />
                    </div>
                </div>
                <div style={{...styles.dataContainer, overflowY: 'auto', maxHeight: '175px'}}>
                    {contas ? this.renderParams(contas) : <div>Carregando...</div>}
                </div>
            </div>
        );
    }
}

export default CentroDeCustoFormulasSearch;
