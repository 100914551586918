import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";
import moment from "moment";
import { PresentationsContext } from "./contexts";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import {
  getAllYearsData,
  getAllRangeData,
  getAllFormulas,
  getEbitdaFormulas,
  getAllDashBoardFormulas,
  getAllPresentations,
  getAllPages,
  getLastMonthsFromYears,
  getDashboardData,
  getAllParameters,
  getAllParametersInherit, 
  getAllYearsDataForParameters
} from "./services/api";

import { getTextByKindKey, getMonths, parseSelectedValue } from "./helpers";
import Input from "./components/input";
import InputFormulas from "./components/inputFormulas";
import InputChartFormulas from "./components/inputChartFormulas";
import InputLineGraph from "./components/inputLineGraph";
import InputSegmented from "./components/inputSegmented";
import PreVisualization from "./preVisualization";
import axios from "../../components/utils/axiosCached"
import originalAxios  from 'axios';
import { array } from "prop-types";
import PromiseQueue from "../utils/promiseQueue";

const paramsQueue = new PromiseQueue();

export function SheetForm(props) {
  let alreadLoadedDates = {};
  const months = {
    janeiro: 1,
    fevereiro: 2,
    março: 3,
    abril: 4,
    maio: 5,
    junho: 6,
    julho: 7,
    agosto: 8,
    setembro: 9,
    outubro: 10,
    novembro: 11,
    dezembro: 12,
  };
  if(props.printing){
    var { state, dispatch } = props;
  }else{
    var { state, dispatch } = useContext(PresentationsContext);
  }
  
  //local states
  const [currentYear, setCurrentYear] = useState(0);
  const [currentFormulas, setCurrentFormulas] = useState([]);
  const [currentPeriod, setCurrentPeriod] = useState(0);
  const [currentFirstMonth, setCurrentFirstMonth] = useState(0);
  const [formulaQuantity, setFormulaQuantity] = useState(1);
  const [reportsByDate, setReportsByDate] = useState({});
  const [chartReport, setChartReport] = useState({});
  const [maps, setMaps] = useState({});
  const [presentations, setPresentations] = useState([]);
  const [pages, setPages] = useState([]);
  const [chartFormulas, setChartFormulas] = useState({});
  const [chartTitle, setChartTitle] = useState("");
  const [chartData, setChartData] = useState({});
  const [hidePrevisualization, setHidePrevisualization] = useState(false)
  const [rangeReport, setRangeReport] = useState({})
  const [dreReport, setDreReport] = useState({})
  const [dreFormulas, setDreFormulas] = useState({})
  const [flowReport, setFlowReport] = useState({})
  const [flowFormulas, setFlowFormulas] = useState({})
  const [flowChartData, setFlowChartData] = useState({})
  const [kind, setKind] = useState(props.kind || "formula_table");
  const [loadYearList, setLoadYearList] = useState([])
  const [processedDates, setProcessedDates] = useState(new Set());
  const [dataModel, setDataModel] = useState({});
  const [companies, setCompanies] = useState([]);
  const [companyData, setCompanyData] = useState({});
  const [budgetCompanyData, setBudgetCompanyData] = useState({});
  const [year, setYear] = useState(null);
  const [requestsCounter, setRequestsCounter] = useState(0);
  const [currentModality, setCurrentModality] = useState(null); // Supondo que este valor venha de algum prop ou contexto

  useEffect(()=>{
    defineDate()

  }, [state.lastMonthsFromYears])

  useEffect(()=>{
    defineDate()

  }, [state.parameters.last_month_ever, kind])

  const defineDate =() =>{
    let primaryDateTmp = [defaultDate(1, "primary"), defaultDate(2, "primary")]
    let secondaryDateTmp = [defaultDate(1, "secondary"), defaultDate(2, "secondary")]
    
    const newPrimaryDataDate = ((!state.parameters?.primary_data_date?.startDate && !state.parameters?.primary_data_date?.endDate) || state.parameters?.last_month_ever) ? { startDate: primaryDateTmp[0], endDate: primaryDateTmp[1] } : state.parameters.primary_data_date;

    const newSecondaryDataDate = ((!state.parameters?.secondary_data_date?.startDate && !state.parameters?.secondary_data_date?.startDate) || state.parameters?.last_month_ever || kind == "line_graph") ? {
        startDate: state.parameters.secondary_data_type == "budget" ? primaryDateTmp[0] : secondaryDateTmp[0],
        endDate: state.parameters.secondary_data_type == "budget" ? primaryDateTmp[1] : secondaryDateTmp[1]
    } : state.parameters.secondary_data_date;

    dispatch({
      type: "SET_PARAMETERS",
      payload: {
        ...state.parameters,
        primary_data_date: newPrimaryDataDate,
        secondary_data_date: newSecondaryDataDate,
      },
    });
    
  }

  function defaultDate(datePosition=1, year="primary") {
    let years = getLastTwoYears();
    let currentYear = years[1];
    let lastYear = currentYear - 1;
    if(year == "primary"){
      return createDateFromYear(datePosition == 1, currentYear)
    }else if(year == "secondary"){
      if(state.parameters.secondary_data_type == "budget"){
        return createDateFromYear(datePosition == 1, currentYear)
      }else{
        return createDateFromYear(datePosition == 1, lastYear)
      }
      
    }
  }

  async function fetchData(year = null, company_id, formulaIds = null, isBudget=null) {
    if (currentModality === "balance_sheet") {
      return false;
    }
    setRequestsCounter(current => current + 1);
    
    formulaIds = formulaIds.map((f) => {
      let {type, id} = parseSelectedValue(f);
      if(type == "formula"){
        return id
      }
    }).filter((f) => f);
    let lastYear = 0
    state?.parameters?.graphs?.map((x)=> x.formulas?.map((f)=>  {
      if(lastYear < f.year){
        lastYear = f.year
      }
    }))
    dispatch({
      type: 'SET_SEGMENTED_LOADING',
      payload: true  // Envio do estado completo atualizado
    })

    const url = `/results/formulas/group.json`;
    const result = await axios.get(url, {
      params: {
        company_id,
        year,
        formula_key: 'fr_id',
        formulas_ids: formulaIds,
        is_budget: isBudget,
        range: [1, monthDictByName[state.lastMonthsFromYears[lastYear]]]
      },
    });

    const { formulas, report, labels, areas, cost_center_grouped_by_month } = result.data;
    setRequestsCounter(current => current - 1);

    // Função para preparar dados para atualização
    const prepareDataForState = current => ({
      ...current,
      [company_id]: {
        ...current[company_id],
        [year]: {
          formulas,
          report,
          labels,
          costCenterAreas: areas,
          recordsFetched: {},
          costCenterGroupedByMonth: cost_center_grouped_by_month
        }
      }
    });

    // Atualizar estado e então dispatch para Redux
    if (isBudget) {
      setBudgetCompanyData(current => {
        const newState = prepareDataForState(current);
        dispatch({
          type: 'SET_BUDGET_COMPANY_DATA',
          payload: newState  // Envio do estado completo atualizado
        });
        return newState;
      });
    } else {
      setCompanyData(current => {
        const newState = prepareDataForState(current);
        dispatch({
          type: 'SET_COMPANY_DATA',
          payload: newState  // Envio do estado completo atualizado
        });
        return newState;
      });
    }

    // Atualização do dataModel caso necessário
    if (Object.keys(dataModel).length === 0) {
      setDataModel(report);
    }
  }



  async function fetchCompanies(year=2024, formulaIds) {
    
    Object.values(props.childreen).map( async id => {
      fetchData(year, id, formulaIds);
      fetchData(year - 1, id, formulaIds);
      fetchData(year, id, formulaIds, true);
    });
  }

  useEffect(() => {
    if(kind == "segmented_chart"){
      let years = {};

      state?.parameters?.graphs?.map((x)=> x.formulas?.map((f)=>  {
        if(!years[f.year]) years[f.year] = []
        years[f.year] = [...new Set([...years[f.year], f.value])]
      }))

      Object.entries(years).map( async ([year, formulas_ids])=>{
        fetchCompanies(year, formulas_ids)
      })
      
    }
    
  }, [kind, state.parameters])

  function getLastTwoYears(){
    // Obter as chaves do objeto e converter para números inteiros
    const years = Object.keys(state.lastMonthsFromYears).map(Number);

    const sortedYears = years.sort((a, b) => a - b);

    // Se houver apenas um ano no array, duplicá-lo
    if (sortedYears.length === 1) {
        return [sortedYears[0], sortedYears[0]];
    }

    // Caso contrário, pegar os dois últimos anos
    return sortedYears.slice(-2);
  }

  function createDateFromYear(firstDatePosition, year) {
    // Configura a localidade do Moment.js para Português do Brasil
    moment.locale('pt-br');

    // Obter o último ano do objeto
    const years = Object.keys(state.lastMonthsFromYears).map(Number);
    const lastYear = years.sort((a, b) => a - b).slice(-1)[0];

    // Verifica se o ano está presente no objeto
    if (state.lastMonthsFromYears.hasOwnProperty(year)) {
        // Obtém o mês do último ano, independentemente do ano fornecido
        let monthName = state.lastMonthsFromYears[lastYear];
        if(firstDatePosition){
          monthName = "janeiro"
        }
        // Cria a data usando o mês do último ano e o ano fornecido
        const date = moment(`${monthName} 01 ${year}`, 'MMMM DD YYYY');
        // Retorna a data formatada
        return date.toDate();
    } else {
        return false;
    }
  }
  //useEffects
  useEffect(() => {
    const fetchData = async () => {
      getAllFormulas().then(({ formulas, formulasById }) => {
        setAllFormulas(formulas, formulasById);
      })
      .catch((error) => {
        console.error('Erro ao carregar fórmulas:', error);
      });

      getEbitdaFormulas().then(({ ebitda_formulas }) => {
        setEbitdaFormulas(ebitda_formulas);
      })
      .catch((error) => {
        console.error('Erro ao carregar fórmulas:', error);
      });

      getAllParameters().then(({ parameters, parametersById }) => {
        setPatternParams(parameters, parametersById);
      })
      .catch((error) => {
        console.error('Erro ao carregar parâmetros:', error);
      });

      getAllParametersInherit().then(({ parameters }) => {
        setInheritParams(parameters);
      })

      getAllDashBoardFormulas().then(({ dashboard_formulas }) => {
        setAllDashBoardFormulas(dashboard_formulas);
      })
      .catch((error) => {
        console.error('Erro ao carregar dashboard_formulas:', error);
      });

      getAllPresentations().then((presentations) => {
        setPresentations(presentations);
      })
      .catch((error) => {
        console.error('Erro ao carregar apresentações:', error);
      });

      getAllPages(props.id).then((pages) => {
        setPages(pages.pages);
      })
      .catch((error) => {
        console.error('Erro ao carregar páginas:', error);
      });

      getLastMonthsFromYears().then((lastMonthsFromYears) => {
        setLastMonthsFromYears(lastMonthsFromYears);
      })
      .catch((error) => {
        console.error('Erro ao carregar últimos meses dos anos:', error);
      });

      setMaps(props.map);
    };

    fetchData();
  }, []);

  useEffect(() => {
    if(kind == "flow_chart"){
      fetchGraphFormulas(state.parameters.flow_chart_type || "cash_flow");
    }else{
      fetchGraphFormulas("borboleta");
    }
    
  }, [state.parameters.flow_chart_type, kind]);

  useEffect(() => {
    getDashboardData(state.parameters.year).then((dashboardData) => {
      setDashboardData(dashboardData.data);
    });
  }, [state.parameters.year]);

  

  useEffect( ()=>{
    if(loadYearList[0]){
      console.log("loadYearList", loadYearList)
      loadYearList[0]()
      array.shift();
    }
  }, [loadYearList])

  useEffect(() => {
    
    let { year, period, first_month: firstMonth } = state.parameters;
    let lastMonth = 12;

    if(Object.keys(state.lastMonthsFromYears || {}).length == 0){
      return 
    }
    //últimos 3 meses
    if(year && state.lastMonthsFromYears[year]){
      const month = state.lastMonthsFromYears[year];
      lastMonth = months[month.toLowerCase()] || 0;
      firstMonth = lastMonth - (period - 1)
    }
      
    

    year = parseInt(year || 0);
    period = parseInt(period || 0);
    firstMonth = parseInt(firstMonth || 0);

    const yearChanged = year >= 2020 && currentYear !== year;
    const firstMonthChanged = currentFirstMonth !== firstMonth;
    const formulasChanged = currentFormulas !== state.parameters.formulas;

    if (yearChanged  || firstMonthChanged || formulasChanged) {
      const fetchData = async () => {
        if(kind != "line_graph"){
          handleGetAllYearsData(year)
          handleGetAllRangeData(lastMonth, year)
          handleGetAllYearsDataForParameters(year);
        }
      };
      fetchData();
      
      setCurrentYear(year);
      setCurrentPeriod(period);
      setCurrentFirstMonth(firstMonth);
      setCurrentFormulas(state.parameters.formulas);
    }
  }, [state.parameters, state.parameters.formulas, state.lastMonthsFromYears]);

  useEffect(() => {
    if(state.parameters.last_months_period){
      dispatch({
        type: "SET_PARAMETERS",
        payload: { ...state.parameters, ['period']: 3, ['first_month']: 10 },
      });
    }
  }, [state.parameters.last_months_period]);

  useEffect(()=>{
    let years = {};

    state?.parameters?.graphs?.map((x)=> x.formulas?.map((f)=>  {
      if(!years[f.year]) years[f.year] = []
      years[f.year] = [...new Set([...years[f.year], f.value])]
    }))
    

    console.log("YEARS", years);
    Object.entries(years).map( async ([year, formulas_ids])=>{
      handleGetAllYearsData(year, formulas_ids);
      handleGetAllYearsDataForParameters(year, formulas_ids);
    })

  }, [state.parameters.graphs])

  useEffect(() => {
    if (currentFirstMonth > 0)
      setParameters("first_month", currentFirstMonth.toString());
  }, [currentFirstMonth]);


  const onDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedPages = Array.from(pages);
    const [movedItem] = reorderedPages.splice(result.source.index, 1);
    reorderedPages.splice(result.destination.index, 0, movedItem);
    setPosition(movedItem.id, result.destination.index + 1)
    // Aqui você pode atualizar a ordem no seu estado e/ou backend
    setPages(reorderedPages);
  };

  //methods
  const handleGetAllYearsData = async (year = 2022, formulas_ids=false) => {
    if(!formulas_ids){
      formulas_ids = state.parameters.formulas?.map((f)=> "formula_" + f.value)
    }
    
    const result = await getAllYearsData(year, formulas_ids);

    if (result) {
      const { formulas, report, labels, areas, cost_center_grouped_by_month } =
        result.data;

      setAllYearsData(year, {
        formulas,
        report,
        labels,
        costCenterAreas: areas,
        recordsFetched: {},
        costCenterGroupedByMonth: cost_center_grouped_by_month,
      });
    }
  };

  //methods
  const handleGetAllYearsDataForParameters = async (year = 2022, formulas_ids=false) => {
    if(!formulas_ids){
      formulas_ids = state.parameters.formulas?.map((f)=> f.value)
    }
    
    const result = await getAllYearsDataForParameters(year, formulas_ids);

    if (result) {
      setAllYearsDataForParameters(year, {
        params: result.data.params
      })
    }
  };

  const handleGetAllRangeData = async (lastMonth, year) => {
    if(year < 2000) return;
    let lastYear = year - 1;
    let pastYear = null
    
    let formulas_ids = state.parameters.formulas?.map((f)=> f.value);

    
    let formulasChanged = currentFormulas !== state.parameters.formulas;
    
    if(!state.allRangeData[year] || formulasChanged){
      let currentYear = await getAllRangeData(lastMonth, year, formulas_ids);
      dispatch({
        type: "SET_ALL_RANGE_DATA",
        payload: {[year]: currentYear },
      });
    }
    if(!state.allRangeData[`${year}_budget`] || formulasChanged){
      let budgetValue = await getAllRangeData(lastMonth, year, formulas_ids, true);
      dispatch({
        type: "SET_ALL_RANGE_DATA",
        payload: {[`${year}_budget`]: budgetValue },
      });
    }
    
    if(!state.allRangeData[lastYear] || formulasChanged){
      pastYear = await getAllRangeData(lastMonth, lastYear, formulas_ids);  

      dispatch({
        type: "SET_ALL_RANGE_DATA",
        payload: {[lastYear]: pastYear },
      });
    }

    
    
  };

  //dispatch events
  const setParameters = (key, value) => {
    dispatch({
      type: "SET_PARAMETERS",
      payload: { ...state.parameters, [key]: value },
    });
  };

  const setAllYearsData = (key, value) => {
    dispatch({
      type: "SET_ALL_YEARS_DATA",
      payload: {[key]: value },
    });
  };

  const setAllYearsDataForParameters = (key, value) => {
    
    paramsQueue.enqueue(() => new Promise(resolve => {
      
      dispatch({
        type: "SET_ALL_YEARS_DATA_FOR_PARAMETERS",
        payload: { ...state.allYearsDataForParameters, [key]: value },
      });
      
      resolve()
    }));
  };

  const setAllRangeData = (key, value) => {
    dispatch({
      type: "SET_ALL_RANGE_DATA",
      payload: { ...state.allRangeData, [key]: value },
    });
  };

  const setAllFormulas = (formulas, formulasById) => {
    dispatch({
      type: "SET_FORMULAS",
      payload: formulas,
    });
    dispatch({
      type: "SET_FORMULAS_BY_ID",
      payload: formulasById,
    });
  };

  const setEbitdaFormulas = (ebitda_formulas)=>{
    dispatch({
      type: "SET_EBITDA_FORMULAS",
      payload: ebitda_formulas,
    });
  }

  const setPatternParams = (patternParams, patternParamsById) => {
    dispatch({
      type: "SET_PATTERN_PARAMS",
      payload: patternParams,
    });
    dispatch({
      type: "SET_PATTERN_PARAMS_BY_ID",
      payload: patternParamsById,
    });
  };

  const setInheritParams = (inheritParams) => {
    dispatch({
      type: "SET_INHERIT_PARAMS",
      payload: inheritParams,
    });
  }

  const setDashboardData = (dashboardData) => {
    dispatch({
      type: "SET_DASHBOARD_DATA",
      payload: dashboardData,
    });
  };

  const setAllDashBoardFormulas = (formulas) => {
    dispatch({
      type: "SET_DASHBOARD_FORMULAS",
      payload: formulas,
    });
  };

  const setLastMonthsFromYears = (lastMonthsFromYears) => {
    dispatch({
      type: "SET_LAST_MONTHS_FROM_YEARS",
      payload: lastMonthsFromYears,
    });
  };

  const setPosition = (id, position) => {
    let call = originalAxios.put;
    let url = `/presentation_sheets/${id}/set_position/${position}`;
    call(url).then((res) => {
      console.log("reordenado")
    });
  };

  const saveSheet = () => {
    let call = props.presentation_sheet_id ? originalAxios.put : axios.post;
    let url = `/presentations/${props.id}/presentation_sheets`;
    if (props.presentation_sheet_id) {
      url = `/presentations/${props.id}/presentation_sheets/${props.presentation_sheet_id}`;
    }

    call(url, {
      parameters: state.parameters,
      kind: kind,
    }).then((res) => {
      const { id } = res.data;
      Turbolinks.visit(
        `/presentations/${props.id}/presentation_sheets/${id}/edit`
      );
    });
  };

  const fetchGraphFormulas = async (chartType)=>{
    let req = await axios.get(`/charts/situations/${chartType}.json`)
    let {charts, title} = req.data
    setChartFormulas(charts)
    setChartTitle(title)
    reloadPreVisualization()
  }
  
  const fetchDataForRange = async (fieldDate, fieldLabel) =>{
    let {startDate, endDate} = fieldDate
    startDate = moment(startDate).toDate();
    endDate = moment(endDate).toDate();
    
    if(Object.keys(state.lastMonthsFromYears).length == 0){
      return false
    }

    let isFilled = startDate && endDate
    
    
    if (isFilled) {
        axios.get('/formulas/borboleta.json',{params: {
            range: {start_at: startDate, end_at: endDate},
            is_budget: fieldLabel == "budget" ? true : null
        }}).then((report)=>{
            rangeReport[fieldLabel] = report.data
            setRangeReport(rangeReport)
            setupChartData()
            
            dispatch({
              type: "SET_ACCUMULATED_FORMULAS_REPORT",
              payload: {report: {[fieldLabel]: report.data}, year: endDate.getFullYear()},
            });
            
        })
        
    }
}
useEffect(()=>{
    setupChartData()
}, [rangeReport])

  const setupChartData = () =>{
    let data = {}
    let types = ["analisado", "budget", "comparado"]
    let self = this;
    if(kind != "borboleta_chart"){
      return false
    }
    types.map((type)=>{
            
            Object.entries(chartReport[type] || {}).map(([form_id, value])=>{
                try{
                    data[`[\"${form_id}\", \"${type}\"]`] ||= eval(value)
                }catch(e){
                    
                }
            })
            
            Object.entries(rangeReport[type] || {}).map(([form_id, value])=>{
                try{
                    data[`[\"${form_id}\", \"${type}-range\"]`] ||= eval(value)    
                }catch(e){

                }
                
            })
        
    })
    console.log("currentData", chartData)
    console.log("newData", {...chartData, ...data})
    setChartData({...chartData, ...data})
  }

  const fetchDataForChart = async (year=null)=>{
    let url = '/results/dre.json'
    let result = await axios.get(url, {params: {
        year
    }})
    
    const {formulas, report} = result.data
    
    setDreFormulas(formulas)
    setDreReport(report)
    
    
    
  }

  const fetchFlowData = async (year)=>{
    
    let result = await axios.get('/results/flow.json', {params: {
        year
    }})
    const {formulas} = result.data
    
    setFlowReport(result.data.report)
    setFlowFormulas(formulas)
    
    
    
  }

  const setupFlowChartData = ()=>{
    let data = {}
    
    let mixedReport = {...dreReport, ...flowReport}
    
    let formulas_labels = [
      ...Object.entries(dreFormulas).map(([key, value])=>{return [key, value.fr_id]}),
      ...Object.entries(flowFormulas).map(([key, value])=>{return [key, value.fr_id]})
    ]
    
    formulas_labels.map(([label, id])=> {
      Object.entries(mixedReport).map(([form_id, value])=>{
          try{
              data[`${id}`] = eval(mixedReport?.[label]?.[label]?.result)
          }catch(e){
              
          }
      })
    })
    setFlowChartData({...chartData, ...data})
    reloadPreVisualization()
    
  }
  useEffect(()=>{
    if(Object.keys(dreReport).length > 0 && Object.keys(flowReport).length > 0){
      setupFlowChartData()
    }
  }, [dreReport, flowReport, flowFormulas, dreFormulas])

  const setupFlowData = async (year)=>{
    if(year){
      fetchDataForChart(year)
      fetchFlowData(year)
    }
    
  }

  useEffect(()=>{
    if(kind == "flow_chart"){

      setupFlowData(flowCurrentYear())
      reloadPreVisualization()
    }
  }, [state.parameters?.primary_data_date, kind, state.parameters?.modality, state.parameters?.year])

  useEffect(()=>{
    reloadPreVisualization()
  }, [chartData, state.parameters?.flow_chart_type])

  

  const reloadPreVisualization = ()=>{
    setHidePrevisualization(true)
    setTimeout(()=>{
      setHidePrevisualization(false)
    }, 100)
  }

  useEffect(()=>{
    let {secondary_data_type, primary_data_date,  secondary_data_date} = state.parameters

    if(state.parameters?.interval_type == "month"){
      let primary_date = moment(primary_data_date?.endDate).toDate()
      let secondary_date = moment(secondary_data_date?.endDate).toDate()
      
      fetchReport(primary_date, null, "analisado", false)
      fetchReport(secondary_date, secondary_data_type == "budget" ? true : null, secondary_data_type, false)  

    }else if(state.parameters?.interval_type == "period" && primary_data_date && secondary_data_date){
        fetchDataForRange(primary_data_date, "analisado")
        fetchDataForRange(secondary_data_date, secondary_data_type == "budget" ? "budget" : "comparado")
    }
    
  }, [state.parameters?.primary_data_date, state.parameters?.secondary_data_date, state.parameters?.interval_type, state.parameters?.secondary_data_type, state.lastMonthsFromYears ])

  useEffect(() => {
    let { graphs } = state.parameters;
    let {secondary_data_date} = state.parameters
    let month = moment(secondary_data_date?.endDate).format('MM');
    
    if (kind == "line_graph") {
      graphs?.forEach(graph => {
        graph?.formulas?.forEach(formula => {
          // Criar novas datas com o ano da fórmula
          let newPrimaryDate = {
            startDate: `${formula.year}-01-01T00:00:00.000Z`,
            endDate: `${formula.year}-${month}-20T23:59:59.999Z`
          };

          let dateKey = `${newPrimaryDate.startDate}-${newPrimaryDate.endDate}`;

          // Verificar se a data já foi processada
          // if (!processedDates.has(dateKey)) {
            fetchDataForRange(newPrimaryDate, "analisado");
            fetchDataForRange(newPrimaryDate, "budget");
            // setProcessedDates(new Set(processedDates).add(dateKey));
          // }
        });
      });
    }
  }, [state.parameters.graphs, processedDates, state.parameters.secondary_data_date])

  const fetchReport = async (date, is_budget=null, entity=null, updatePairs=true)=>{
    let yearAgoDate = new Date(date)
    yearAgoDate.setFullYear(date.getFullYear() - 1)
    let newstate = {}
    newstate[entity + "Date"] = date
    
    if (entity == "analisado" && updatePairs) {
        newstate['budgetDate'] = date
        newstate['comparadoDate'] = yearAgoDate
    }

    
    let report = {}
    if(alreadLoadedDates[`${date}-${is_budget}`]){
        report = reportsByDate[`${date}-${is_budget}`]
    }else{
        alreadLoadedDates[`${date}-${is_budget}`] = true
        report = await axios.get('/formulas/borboleta.json',{params: {
            date: date,
            is_budget
        }})
        setReportsByDate({
            ...reportsByDate,
            ...{[`${date}-${is_budget}`]: report}
        })    
    }

    let response= chartReport
    
    
    response[entity] = report.data
    

    setChartReport(response)  
    setupChartData()
      // this.setComments();
      // if (entity == "analisado") {
          
      //     this.fetchReport(newstate['budgetDate'], true, 'budget')
      //     this.fetchReport(newstate['comparadoDate'], null, 'comparado')
      // }
      // if(entity != 'comparado'){
      //     this.fetchData(date.getFullYear(), entity, is_budget)    
      // }
      
  }

  const analisadoDate = ()=>{
    const {secondary_data_type, primary_data_date, secondary_data_date} = state.parameters
    return moment(primary_data_date?.startDate).toDate()
  }

  const currentMonth = ()=>{
    // em português o nome do mês atual que é extraido de primary_data_date
    let monthDict = {1: "janeiro", 2: "fevereiro", 3: "março", 4: "abril", 5: "maio", 6: "junho", 7: "julho", 8: "agosto", 9: "setembro", 10: "outubro", 11: "novembro", 12: "dezembro", 13: "total"}
    const {primary_data_date} = state.parameters
    if(state.parameters?.modality == "yearly"){
      return "total"
    }else{
      return monthDict[moment(primary_data_date?.endDate).month() + 1]
    }
    
  }

  const flowCurrentYear = ()=>{
    const {primary_data_date, modality, year} = state.parameters

    if(modality == "yearly"){
      return year
    }else{
      return moment(primary_data_date?.startDate || primary_data_date?.endDate).year()
    }
  }

  const secondaryDate = ()=>{
    const {secondary_data_date} = state.parameters
    return moment(secondary_data_date?.startDate).toDate()
  }

  const startDate = ()=>{
    const {secondary_data_type, primary_data_date, secondary_data_date} = state.parameters
    return {
      analisado: moment(primary_data_date?.startDate).toDate(),
      [secondary_data_type]: moment(secondary_data_date?.startDate).toDate(),
    }
  }
  const endDate = ()=>{
    const {secondary_data_type, primary_data_date, secondary_data_date} = state.parameters
    return {
      analisado: moment(primary_data_date?.endDate).toDate(),
      [secondary_data_type]: moment(secondary_data_date?.endDate).toDate(),
    }

  }
  const comparedLabel = ()=>{
    //switch case
    switch(state.parameters?.secondary_data_type){
      case "budget":
        return "Orçamento"
      case "comparado":
        return "Real"
    }
  }

  const principalDataLabel = ()=>{
    if(state.parameters?.interval_type == "period"){
      return "analisado-range"
    }else{
      return "analisado"
    }
      
  }

  const secondaryDataLabel = ()=>{
    if(state.parameters?.interval_type == "period"){
      return `${state.parameters?.secondary_data_type}-range`
    }else{
      return state.parameters?.secondary_data_type
    }
  }

  const getYears = ()=>{
    // use state.lastMonthsFromYears to get all the years(keys) but only when value is not empty

    let years = Object.entries(state.lastMonthsFromYears || {}).filter(([key, value])=>value).map(([key, value])=>key)
    return years

  }
  const back = ()=>{
      //check if state.parameters values are empty
      //if empty, go back to presentations
      //else go prompt user to alert that he will lose data

      let parameters = Object.values(state.parameters || {})
      let empty = parameters.filter((value)=>value).length == 0
      if(empty){
        Turbolinks.visit(`/presentations`)
      }else{
        let goBack = confirm("ATENÇÃO: Verifique alterações não salvas antes de sair. Sair sem salvar?", "Sim", "Não" )
        if(goBack){
          Turbolinks.visit(`/presentations`)
        }
      }

  }

  const deleteSheet = (id) => {
    let page = pages.filter((page)=> page.id == id)[0]
    let confirmation = confirm(`Tem certeza de que quer deletar a pagina '${page?.parameters?.title}'?`, "Sim", "Não" )
    if(confirmation){
      let call = originalAxios.delete;
      let url = `/presentations/${props.id}/presentation_sheets/${id}`;
      call(url).then((res) => {
        console.log("deletado", id)
        setPages(pages.filter((page)=> page.id != id))
      });
    }
    
  };

  const inputIsVisible = (sheetKind, inputKind)=>{
    if(sheetKind == "flow_chart" && inputKind == "year"){
      if(state.parameters?.modality == "yearly"){
        return true
      }else{
        return false
      }
    }else if(sheetKind == "flow_chart" && inputKind == "primary_data_date"){
      if(state.parameters?.modality == "monthly"){
        return true
      }else{
        return false
      }
      
    }else{
      return true
    }
    
    
  }

  const redirectTo = (url)=>{  
    let goAhead = confirm("ATENÇÃO: Verifique alterações não salvas antes de sair. Sair sem salvar?", "Sim", "Não" )
    if(goAhead){
      Turbolinks.visit(url)
    }
  }

  return (
    <Wrapper>
      {!props.hiddenMenu && <Form>
        <BackButton onClick={back}> <i className="fa fa-arrow-left"></i>Voltar</BackButton>
        <label style={{padding: 4}}>Escolha o tipo do slide</label>
        <Select style={{marginTop: 4}} value={kind} onChange={(e) => setKind(e.target.value)}>
          <option value="dashboard_widget">
            Widgets
          </option>
          <option value="formula_table">
            Tabela DRE Mensal
          </option>
          <option value="formula_table_balance">
            Tabela Balanço
          </option>
          <option value="borboleta_chart">
            Gráfico Visão Comparativa
          </option>
          <option value="flow_chart">
            Gráfico Fluxo de Caixa
          </option>
          <option value="line_graph">
            Gráficos Linha e Barra
          </option>
          <option value="segmented_chart">
            Gráficos Segmentado
          </option>
          <option value="empty_page">
            Página em branco
          </option>
          
        </Select>
        <hr />
        {Object.entries(maps[kind] || {}).map(([key, value], index) => {
          if(['period', 'first_month'].includes(key) && state.parameters.last_months_period){
            return <></>
          }
          return <InputWrapper key={index}>
            {key === "formulas" ? (
              <InputFormulas
                presentationKind={kind}
                kind={value}
                title={getTextByKindKey(key)}
                defaultFormulas={state.parameters[key]}
              />
            ) : value === "ebitda_formula" ? (
              <InputChartFormulas
                presentationKind={kind}
                kind={value}
                title={getTextByKindKey(key)}
                defaultFormulas={state.parameters[key]}
              />
            ) : key === "line_graph" ? (
              <InputLineGraph
                presentationKind={kind}
                years={getYears()}
               />
            ) : key === "segmented_charts" ? (
              <InputSegmented
                presentationKind={kind}
                years={getYears()}
               />
            ): key === "dashboard_formulas" ? (
              <InputFormulas
                presentationKind={kind}
                kind={value}
                title={getTextByKindKey(key)}
                defaultFormulas={state.parameters["formulas"]}
              />
            )
            : (
              inputIsVisible(kind, value) && <Input
                key={index}
                kind={value}
                lastMonthsFromYears={state.lastMonthsFromYears}
                years={getYears()}
                presentationKind={kind}
                checked={state.parameters[key]}
                parameters={state.parameters}
                title={getTextByKindKey(key)}
                formulaQuantity={formulaQuantity}
                setFormulaQuantity={setFormulaQuantity}
                value={state.parameters[key]}
                onChange={(e) => {
                  if(value === 'boolean'){
                    setParameters(key, e.target.checked)                    
                  }else{
                    setParameters(key, e.target.value)
                  }
                  
                }}
              />
            )}
          </InputWrapper>
        })}
        <Button style={{backgroundColor: '#06ab7a'}} onClick={saveSheet}>Salvar</Button>
        {pages?.length > 0 && (
          <>
              <hr />
              <Pages>
                  <header>Páginas dessa apresentação</header>
                  <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="pages">
                          {(provided) => (
                              <div ref={provided.innerRef} {...provided.droppableProps}>
                                  {pages?.map((page, index) => (
                                      <Draggable key={page.id} draggableId={String(page.id)} index={index}>
                                          {(provided) => (
                                              <Page
                                                  ref={provided.innerRef}
                                                  {...provided.draggableProps}
                                                  {...provided.dragHandleProps}
                                                  
                                              >
                                                  <a onClick={()=>{redirectTo(`/presentations/${props.id}/presentation_sheets/${page.id}/edit`)}} style={{ width: "100%" }}>
                                                      {page.parameters?.title}
                                                  </a>
                                                  <a href="javascript::void(0)" onClick={() => deleteSheet(page.id)}>
                                                      <i className="fa fa-trash" />
                                                  </a>
                                              </Page>
                                          )}
                                      </Draggable>
                                  ))}
                                  {provided.placeholder}
                              </div>
                          )}
                      </Droppable>
                  </DragDropContext>
                  <br />
                  <a
                      href="#"
                      onClick={() =>
                          Turbolinks.visit(`/presentations/${props.id}/presentation_sheets/new`)
                      }
                  >
                      Nova Página
                  </a>
              </Pages>
          </>
        )}
      </Form>}
      {!hidePrevisualization &&  <PreVisualization
        company_id={props.company_id}
        state={props.state}
        childreen={props.childreen}
        page={props.page}
        numPage={props.numPage}
        printing={props.printing}
        report={state.allYearsData}
        totalReport={state.allRangeData}
        kind={kind}
        formulasMappedById={state.formulasById}
        parameters={state.parameters}
        lastMonthsFromYears={state.lastMonthsFromYears}
        borboletaParameters={{
          disableTabs: true,
          chart: chartFormulas,
          chartTitle,
          chartData: kind == "flow_chart" ? flowChartData : chartData,
          situation: state.parameters?.flow_chart_type || "cash_flow",
          currentMonth: currentMonth(),
          analisadoDate: analisadoDate(),
          budgetDate: secondaryDate(),
          comparadoDate: secondaryDate(),
          startDate: startDate(),
          endDate: endDate(),
          comparedLabel: comparedLabel(),
          principalDataLabel: principalDataLabel(),
          secondaryDataLabel: secondaryDataLabel(),
          isRange: state.parameters?.interval_type == "period",
          year: state.parameters?.year,
        }}
      />}
    </Wrapper>
  );
}

const BackButton = styled.a`
  display: block;
  margin-bottom: 16px;
  color: #6e6e6e;
  font-size: 14px;
  cursor: pointer;
  i{
    margin-right: 8px;
  }

`

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  page-break-after: always;
`;

const Form = styled.form`
  background-color: #ededed;
  width: 381px;
  padding: 20px;
  overflow: visible;
`;

const Pages = styled.div`
  header {
    padding-bottom: 16px;
  }
`;

const Page = styled.div`
  cursor: pointer;
  background-color: #f5f5f5;
  color: #6e6e6e;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  padding: 8px;
  height: 20px;
  width: 240px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 0;
`;

const Select = styled.select`
  background-color: #f5f5f5;
  color: #6e6e6e;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  padding: 8px;
  height: 20px;
  width: 240px;
`;

const Button = styled.div`
  cursor: pointer;
  background-color: #298793;
  color: #ffffff;
  height: 20px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  width: 240px;
  padding: 8px;
`;


const monthDictByName = {
  "janeiro": 1,
  "fevereiro": 2,
  "março": 3,
  "abril": 4,
  "maio": 5,
  "junho": 6,
  "julho": 7,
  "agosto": 8,
  "setembro": 9,
  "outubro": 10,
  "novembro": 11,
  "dezembro": 12
}
