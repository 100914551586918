import React from "react";
import Select from 'react-select';
import AutoComplete from "./../AutoComplete";
import TextField from '@material-ui/core/TextField';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GreenButton } from "../../styled_components/default";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import pt from 'date-fns/locale/pt';
registerLocale('pt', pt)

class LiquidProfitYearEdit extends React.Component {
  state = {
    areas: [],
    query: "",
    users: [],
    editingArea: {},
    currentArea: {},
    permittedMonths: {},
    liquidProfitConfigurations: this.props.initial_liquid_profits,
  };



  async fetchPermittedMonths() {
    if(this.props.company){
      let result = await axios.get(`/permitted_upload_months.json`, {
        params: {
          query: this.state.query
        }
      });
      
      this.setState({permittedMonths: result.data.data })  
    }
  }

  handleChange(event) {
    let value = event.target.value
    
    this.setState((oldState)=>{
      oldState.currentArea.label = value
      return {...oldState}
    });
    
  }


  handleChangeEditingArea(event) {
    let value = event.target.value
    let self = this

    this.setState((oldState)=>{
      oldState.editingArea.label = value
      return {...oldState}
    });
    self.saveEditedArea()
    
  }

  async addMonth(){
    let {currentDate, permittedType} = this.state;

    let result = await axios.post(`/permitted_upload_months.json`, {
        permitted_upload_month:{
          permitted_type: permittedType,
          date: currentDate
        }
    });

    this.setState((oldState)=>{
      oldState.permittedMonths = result.data.data
      return {...oldState}
    })
    
  }

  async changePeriod(year, period){
    
    let result = await axios.post(`/liquid_profits/${year}/toggle.json`, {
        year,
        period
    });

    this.setState((oldState)=>{
      oldState.liquidProfitConfigurations = result.data
      return {...oldState}
    })
    
  }

  
  

 

  componentDidMount() {
    
    this.fetchPermittedMonths();
  }

  editArea(area){
    if(this.state.editingArea.id == area.id){
      this.setState({editingArea: {}})
    }else{
      this.setState({editingArea: area})  
    }
    
    
  }

  setDate(date){
    this.setState({currentDate: date})
    //this.setState({permittedMonths: [...this.state.permittedMonths, ...[date]]})
  }

  // fetch

  render() {
    let {years} = this.props;
    let {liquidProfitConfigurations } = this.state;
    
    return (
      <div>
        
        <table className={'table upload_settings'}>
          {years.filter((year)=> year).map((year)=>(
              <tr>
                <td>
                    {year}
                  </td>
                  {["monthly", "trimestral", "yearly"].map((type)=>(
                    <td 
                    onClick={()=> this.changePeriod(year, type)}
                    style={{
                      backgroundColor: (liquidProfitConfigurations?.[year] || "yearly") == type ? "#F6AB52" : "#2AB7BA",
                      color: 'white',
                      fontSize: 13,
                      textAlign: 'center',
                      cursor: 'pointer'
                    }}>
                      {periodDict[type]}
                    </td>
                  ))}
                
              </tr>
            ))}
        </table>
      </div>
    );
  }
}
const periodDict = {
  "monthly": "Mensal",
  "trimestral": "Trimestral",
  "yearly": "Anual"
}
const monthsDict = {
     1: "janeiro",
     2: "fevereiro",
     3: "março",
     4: "abril",
     5: "maio",
     6: "junho",
     7: "julho",
     8: "agosto",
     9: "setembro",
     10: "outubro",
     11: "novembro",
     12: "dezembro",
}

export default LiquidProfitYearEdit;
