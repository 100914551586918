// axiosConfig.js
import axios from 'axios';

const instance = axios.create({
  // Configurações globais do axios aqui, por exemplo:
  // baseURL: 'https://api.meusite.com'
});

instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    // Verifique se o erro é um erro de autorização
    if (error.response && error.response.status === 401) {
      // Redirecionar para a página inicial
      window.location.href = "/";
    } else {
      return Promise.reject(error);
    }
  }
);

export default instance;
