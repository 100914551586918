// create a show component that will display all the sheets in a presentation and export them
// to a pdf

import React, { useEffect, useState } from "react";
import Sheet from "./main.js";
import html2pdf from "html2pdf.js";
import downloadIco from 'bt_download.svg';
import downloadIconPDF from 'download-pdf.svg';
import downloadIconPPT from 'download-ppt.svg';
import loadingIco from 'Digitando.gif';
import html2canvas from 'html2canvas';
import { Footer } from "./components/footer";
import LoadingDialog from "../LoadingDialog.js";
import './show.css'
let pendingRequests = 0;

// Intercept XMLHttpRequest
const originalOpen = XMLHttpRequest.prototype.open;
XMLHttpRequest.prototype.open = function() {
  this.addEventListener('loadstart', function() {
    pendingRequests++;
  });
  this.addEventListener('loadend', function() {
    pendingRequests--;
  });
  originalOpen.apply(this, arguments);
};

const Show = ({sheets, presentation, map, company, company_id, childreen}) => {
    const [showDownload, setShowDownload] = useState(false);
    const [numPage, setNumPage] = useState(0);
    const [isDownloading, setIsDownloading] = useState(false);
    const [imagesLoaded, setImagesLoaded] = React.useState(false);
  
  

    useEffect(() => {
        
        
        const interval = setInterval(() => {
            if (pendingRequests === 0) {
                setShowDownload(true);
            }
        }, 1000);
        
        return () => {
            clearInterval(interval);
        };
    }, []);

    const generatePDF = () => {
        const element = document.querySelector('#report')
        const pages = element.getElementsByTagName('page');
        setIsDownloading(true)
        for (let i = 0; i < pages.length; i++) {
            pages[i].style.display = 'block';
        }
    
        // Convertendo 1600x900 pixels para milímetros (Assumindo 96 DPI)
        const widthInMM = (1600 / 96) * 25.4;
        const heightInMM = (900 / 96) * 25.4;
    
        let opt = {
            html2canvas: { scale: 2, logging: false },
            jsPDF: {
                unit: 'mm',
                format: [widthInMM * 1.1, heightInMM * 1.1],
                orientation: 'landscape'
            }
        }
    
        setTimeout(async () => {
            let res = await html2pdf().set(opt).from(element).toPdf().get('pdf');
            res.save();
            for (let i = 0; i < pages.length; i++) {
                pages[i].style.display = 'none';
            }
            pages[0].style.display = 'block';
            setIsDownloading(false)
        }, 1000)
    };


    function exportSheetsAsImages() {
        setIsDownloading(true)
        const element = document.querySelector('#report')
        const pages = element.getElementsByTagName('page');
        
        for (var i = 0; i < pages.length; i++) {
            pages[i].style.display = 'block';
        }
        
        const contentPages = element.getElementsByClassName('page_content');
        // const sheetElements = document.querySelectorAll('.sheet-container');
        try {
            const images = [];
            let imagesLoaded = 0;
            contentPages.forEach((sheetElement, index) => {
                console.log("image_load before...", moment())
                pages[0].style.display = 'block';
                html2canvas(sheetElement, {windowWidth: pages[0].offsetWidth, scale: 0.5}).then(canvas => {
                    canvas.getContext('2d', { willReadFrequently: true });
                    console.log("image_load after...", moment())
                    try {
                        const dataUrl = canvas.toDataURL('image/png');
                        images[index] = dataUrl;    
                    } catch (error) {
                        
                    }
                    
                    imagesLoaded += 1
                    if(imagesLoaded == contentPages.length){
                        sendImagesToServer(images);
                    }
                    
                });
            });
            
            setTimeout(() => {
                for (var i = 0; i < pages.length; i++) {
                    pages[i].style.display = 'none';
                }
                pages[0].style.display = 'block';
            }, 1000)    
        } catch (error) {
            setIsDownloading(false)
            alert("Erro ao tentar fazer download, tente novamente")
        }
        
    }
    
    function sendImagesToServer(images) {
        const formData = new FormData();
        images.forEach((image, index) => {
            const blob = dataURLToBlob(image);
            formData.append(`images[${index}]`, blob, `image${index}.png`);
        });
    
        axios.post('/presentations_powerpoint', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            responseType: 'blob' // Indica que a resposta será um arquivo binário
        })
        .then(response => {
            // Cria um URL a partir do arquivo binário
            const url = window.URL.createObjectURL(new Blob([response.data]));
            // Cria um link temporário para iniciar o download
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'presentation.pptx'); // O arquivo terá este nome
            document.body.appendChild(link);
            link.click(); // Simula um clique no link para iniciar o download
            window.URL.revokeObjectURL(url); // Limpa o URL criado
            link.remove(); // Remove o link do DOM
            setIsDownloading(false)
        })
        .catch(error => {
            alert("Erro ao tentar fazer download, tente novamente")
            setIsDownloading(false)
        });
    }
    
    function dataURLToBlob(dataUrl) {
        const arr = dataUrl.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        
        while(n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        
        return new Blob([u8arr], { type: mime });
    }

    function btnDownloads(){
        return (
            <div style={{marginLeft: 10, cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 100, float: 'right', margin: 10}}>
                {showDownload ? <div onClick={()=> generatePDF()}>
                <img src={downloadIconPDF} style={{width: 36, marginRight: 10}}></img>
                </div>: <div>
                    <img src={'/images/Digitando.gif'} style={{width: 36, marginRight: 10}}></img>
                </div>}
                {/* {showDownload ? <div onClick={()=> exportSheetsAsImages()}>
                <img src={downloadIconPPT} style={{width: 36, marginRight: 10}}></img>
                </div>: <div>
                    <img src={loadingIco} style={{width: 36, marginRight: 10}}></img>
                </div>} */}
            </div>
        )
    }

   

    return (
        <>  
            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: 20, flexDirection: 'column', height: 'calc(100vh - 141px)'}}>
                <div id={"report"} style={{width: '100%'}}>
                    
                    {sheets.map((sheet, index) => {
                        return <page className={'sheet-break sheet-container'} style={{display: index == numPage ? 'block' : 'none'}}>
                            <Sheet
                                printing={true}
                                childreen={childreen}
                                key={sheet.id}
                                kind={sheet.kind}
                                parameters={sheet.parameters}
                                presentation_sheet_id={sheet.id}
                                id={presentation.id}
                                hiddenMenu={true}
                                map={map}
                                page={index}
                                numPage={numPage}
                                company={company}
                                company_id={company_id}
                            />
                            
                        </page>
                    })}
                </div>
                <Footer totalPages={sheets.length} generatePDF={generatePDF} showDownload={showDownload} setNumPage={setNumPage} numPage={numPage} page={numPage}  />
            </div>
            <LoadingDialog open={ isDownloading } />
            
        </>
    );
}

export default Show;
