import React from "react";
import Select from 'react-select';
import AutoComplete from "./AutoComplete";
import TextField from '@material-ui/core/TextField';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { GreenButton } from "../styled_components/default";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import pt from 'date-fns/locale/pt';
registerLocale('pt', pt)

class CompanyUploadPermition extends React.Component {
  state = {
    areas: [],
    query: "",
    users: [],
    editingArea: {},
    currentArea: {},
    permittedMonths: {},
    permittedType: 'balance_sheet',
    activeTab: 0,
    roles: [
      {value: "admin", label: "Admin"},
      {value: "consultor", label: "Consultor"},
    ]
  };



  async fetchPermittedMonths() {
    if(this.props.company){
      let result = await axios.get(`/permitted_upload_months.json`, {
        params: {
          query: this.state.query
        }
      });
      
      this.setState({permittedMonths: result.data.data })  
    }
  }

  handleChange(event) {
    let value = event.target.value
    
    this.setState((oldState)=>{
      oldState.currentArea.label = value
      return {...oldState}
    });
    
  }

  
  handleChangeEditingArea(event) {
    let value = event.target.value
    let self = this

    this.setState((oldState)=>{
      oldState.editingArea.label = value
      return {...oldState}
    });
    self.saveEditedArea()
    
  }

  async addMonth(){
    let {currentDate, permittedType} = this.state;

    let result = await axios.post(`/permitted_upload_months.json`, {
        permitted_upload_month:{
          permitted_type: permittedType,
          date: currentDate
        }
    });

    this.setState((oldState)=>{
      oldState.permittedMonths = result.data.data
      return {...oldState}
    })
    
  }

  async toggleMonth(year, month){
    let {currentDate, permittedType} = this.state;

    let result = await axios.post(`/permitted_upload_months/toggle.json`, {
        year, 
        month, 
        permitted_type: permittedType
    });

    this.setState((oldState)=>{
      oldState.permittedMonths = result.data.data
      return {...oldState}
    })
    
  }
  

 

  componentDidMount() {
    
    this.fetchPermittedMonths();
  }

  editArea(area){
    if(this.state.editingArea.id == area.id){
      this.setState({editingArea: {}})
    }else{
      this.setState({editingArea: area})  
    }
    
    
  }

  setDate(date){
    this.setState({currentDate: date})
    //this.setState({permittedMonths: [...this.state.permittedMonths, ...[date]]})
  }

  handleChange(event, newValue){
    this.setState({activeTab: newValue});

    if (newValue == 0) {
      this.setState({permittedType: "balance_sheet"})
    }

    if (newValue == 1) {
      this.setState({permittedType: "cost_center"})
    }
  };

  render() {
    let { users, roles, currentDate, permittedMonths, permittedType } = this.state;
    return (
      <div>
        <div style={{ display: "flex", alignItems: 'center' }} className={'permitted-months'}>
          <DatePicker
                                                                locale="pt"
                                                                selected={currentDate}
                                                                onChange={date => this.setDate(date)}
                                                                selectsStart
                                                                dateFormat="MM/yyyy"
                                                                showMonthYearPicker
                                                                style={{
                                                                  height: 50
                                                                }}
                                                              />
          <GreenButton className={'margin-left-10'} onClick={this.addMonth.bind(this)}>ADICIONAR</GreenButton>
        </div>
        <div style={{display: 'flex', justifyContent: 'flex-end'}}>
          <Tabs
            value={this.state.activeTab}
            indicatorColor="primary"
            textColor="primary"
            onChange={(e, v)=> this.handleChange(e,v)}
            aria-label="disabled tabs example"
          >
            <Tab label="Balancete" />
            <Tab label="Centro de Custo" />
          </Tabs>
        </div>
        <table className={'table upload_settings'}>
          <tr>
            <td/>
            {Object.keys(permittedMonths).map((year)=>(
              <React.Fragment>
                <td>
                    {year}
                </td>
              </React.Fragment>
            ))}
          </tr>
          {Object.entries(monthsDict).map(([month_number, month_name])=>(
              <tr>
                <td>
                    {month_name}
                  </td>
                {Object.keys(permittedMonths).map((year)=>(
                  <td 
                  onClick={()=> this.toggleMonth(year, month_number)}
                  style={{
                    backgroundColor: permittedMonths?.[year]?.[permittedType]?.[month_number] ? "#2AB7BA" : "#F6AB52",
                    color: 'white',
                    fontSize: 13,
                    textAlign: 'center',
                    cursor: 'pointer'
                  }}>
                    {permittedMonths?.[year]?.[permittedType]?.[month_number] ? "liberado" : "bloqueado"}
                  </td>
                ))}
                
              </tr>
            ))}
        </table>
      </div>
    );
  }
}
const monthsDict = {
     1: "janeiro",
     2: "fevereiro",
     3: "março",
     4: "abril",
     5: "maio",
     6: "junho",
     7: "julho",
     8: "agosto",
     9: "setembro",
     10: "outubro",
     11: "novembro",
     12: "dezembro",
}

export default CompanyUploadPermition;
