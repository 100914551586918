 import styled from 'styled-components'
 import logo from "white_logo.png";

 export const Box =  styled.div`
  width: 100%;
  height: 75px;
  background-color: #f1f1f1;
  display: flex;
  align-items: center;

`
export const Logo = styled.image`
  width: 260px;
  height: 76px;
  background: url(${logo});
  background-repeat: no-repeat;
  background-position: center;
  background-color: #298793;
  background-size: 64%;
  display: block;
`
export const VerticalLine = styled.div`
  width: 1px;
  height: 60%;
  background-color: #e5e5e5;
  margin-left: 5px;
  margin-right: 5px;
`
export const Link = styled.a`
  
  height: 16px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.7px;
  color: #676767;
  margin: 0  12.5px;
  text-decoration: none;

`

export const LinkWrapper = styled.div`
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`

export const NotificationWrapper = styled.div`
  position: relative;
  &:after {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    top: 50px;
    right: 42px;
    background: white;
    border: 1px solid #e5e5e5;
    box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.1);
    z-index: -1;
  }
`
export const Notifications = styled.div`
  
  width: ${ props => props.width ? props.width + 'px' : '198px' };
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #e5e5e5;
  position: absolute;
  top: 60px;
  flex-direction: column;
  right: -${ props => (props.width / 2) - 120 + 'px' };
  background-color: white;
  padding: 30px;
  


`

export const HiddenOptions = styled.div`
  width: ${ props => props.width ? props.width + 'px' : '198px' };
  box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.1);
  border: solid 1px #e5e5e5;
  background-color: #f1f1f1;
  position: absolute;
  top: ${ props => props.top ? props.top + 'px' : '55px' };
  display: flex;
  flex-direction: column;
  ${ props => props.alignRight ? 'right: 0' : 'left: 0' };
  max-height: 269px;
  
`

export const HiddenLink = styled.a`
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.7px;
  color: #2a3170;
  text-decoration: none;
  height: 51px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  margin: 0;
  padding: ${ props => props.padding ? props.padding+ 'px' : '5px' };
  padding-left: 20px;
  opacity: ${ props => props.disabled ? '0.5' : '1' };
  pointer-events: ${ props => props.disabled ? 'none' : '1' };

`

export const Notification = styled.a`
  font-family: Roboto;
  font-size: 14px;
  letter-spacing: 0.7px;
  
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  padding-left: 20px;
  padding: ${ props => props.padding ? props.padding+ 'px' : '0' };
  color: ${ props => props.visited ? '#c4c4c4 !important' : '#2a3170' };
  opacity: ${ props => props.visited ? '0.7' : '1' };
  margin-bottom: 10px;
  overflow: hidden;
  

`
