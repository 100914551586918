import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { PresentationsContext } from "../contexts";
const SCOPE_LABEL={ "dre": "DRE", "dre_cc": "DRE Gerencial"}
const InputFormulas = (props) => {
  const { state, dispatch } = useContext(PresentationsContext);
  const { defaultFormulas, title, kind, presentationKind } = props;

  //local states
  const [formulas, setFormulas] = useState(defaultFormulas || []);

  useEffect(() => {
    if (formulas.length > 0) {
      setParameters(formulas);
    }
  }, [formulas]);

  const getRandomUUID = () => {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  };

  const changeValueForFormula = (index, value) => {
    let newFormulas = [...formulas];
    newFormulas[index].value = value;
    setFormulas(newFormulas);
  };

  const handleRemoveItem = (key) => {
    if (window.confirm("Tem certeza?")) {
      setFormulas(formulas.filter((item) => item.key !== key));
    }
  };

  //dispacth events
  const setParameters = (formulas) => {
    dispatch({
      type: "SET_PARAMETERS",
      payload: { ...state.parameters, ["selected_formula"]: formulas },
    });
  };

  let options = state?.[kind]

  if(kind == "dashboard_formulas"){
    options = Object.values(state?.[kind] || {})
  }
  

  return (
    <>
      {formulas.map((formula, index) => {
        return (
          <>
            {index === 0 && <label style={{ padding: 4 }}>{title}</label>}
            <Box key={formula.key}>
              <Select
                {...props}
                value={formula.value}
                onChange={(e) => {
                  if (e.target.value !== "") {
                    changeValueForFormula(index, e.target.value);
                  }
                }}
              >
                <option key={0}>Selecione...</option>
                {state?.ebitda_formulas?.map((formula) => {
                    return <option value={formula.id}>{formula.label}</option>;
                })}
                
              </Select>
              <ButtonRemove onClick={() => handleRemoveItem(formula.key)}>
                <i className="fa fa-trash" />
              </ButtonRemove>
            </Box>
          </>
        );
      })}

      <AddFormula
        key="add_formula"
        onClick={() => {
          if(formulas.length < 6 || props.presentationKind != "dashboard_widget"){
            setFormulas([...formulas, { key: getRandomUUID(), value: null }]);
          }else{
            alert("O limite é de 6 itens por página")
          }
        }}
      >
        Adicionar formula
      </AddFormula>
    </>
  );
};

export default InputFormulas;

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

const Select = styled.select`
  background-color: #f5f5f5;
  color: #6e6e6e;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  padding: 8px;
  height: 20px;
  width: 210px;
`;

const ButtonRemove = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6e6e6e;
  width: 20px;
  border-radius: 6px;
  &:hover {
    color: #ff0000;
  }
`;

const AddFormula = styled.div`
  background-color: #298793;
  color: #ffffff;
  height: 20px;
  width: 240px;
  cursor: pointer;
  text-align: center;
  padding: 8px;
  border-radius: 6px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
