import axios from "../../utils/axiosCached";
import { parseSelectedValue } from "../helpers";
const getAllYearsData = async (year = 2022, formulas_ids) => {
  const url = "/results/formulas/group.json";
  
  formulas_ids = formulas_ids.map((f) => {
    let {type, id } = parseSelectedValue(f);
    if(type == "formula"){
      return id
    }
  }).filter((f)=>f);

  
  return await axios.get(url, {
    params: {
      formula_key: "fr_id",
      year,
      formulas_ids
    },
  });
};

const getAllYearsDataForParameters = async (year = 2022, keys=[]) => {
  const url = `/data/parameters/${year}/report.json`;

  keys = keys.map((f) => {
    let {type, id } = parseSelectedValue(f);
    if(type == "parameter"){
      return id
    }
  }).filter((f)=>f);

  return await axios.get(url, {
    params: {
      year,
      keys
    },
  });
};



const getDashboardData = async (year = 2022) => {
  const url = "/api/dashboard.json";
  return await axios.get(url, {
    params: {
      year,
    },
  });
};

const getLastMonthsFromYears = async () => {
  const url = "/results/last_month_from_year.json";
  const result = await axios.get(url);
  return result?.data;
};

const getAllRangeData = async (lastMonth, year, formulas_ids, isBudget=false) => {
  let firstMonth = 1
  
  // use momentjs to get the date for first and last month, at first and last day
  const firstDate = moment().year(year).month(firstMonth - 1);
  const lastDate = moment().year(year).month(lastMonth - 1);

  
  let params = {
    scope: "without_scope",
    formulas_ids,
    range: {
      start_at: firstDate,
      end_at: lastDate,
    },
  };
  
  if(isBudget) {
    params.is_budget = isBudget;
  }
  
  const result = await axios.get("/formulas/borboleta.json", { params });

  return result.data;
};

const getAllFormulas = async () => {
  const formulas = await axios.get("/data/formulas/by_scope.json");
  let formulasById = {};
  try{
    if (formulas) {
      
      Object.entries(formulas.data || {}).map(([key, value])=>{
        value.map((f)=>{  
            formulasById[f.fr_id] = f;
        });
      })
    }
  }catch(e){
    
  }
  
    
    return { formulas: formulas.data, formulasById };
  
};

const getEbitdaFormulas = async () => {
  const formulas = await axios.get("/data/formulas/ebitda.json");
  
  return { ebitda_formulas: formulas.data };
  
};

const getAllParameters = async () => {
  const parameters = await axios.get("/data/parameters.json");
  let parametersById = {};
  try{
    if (parameters) {
      
      Object.entries(parameters.data || {}).map(([key, value])=>{
        value.map((f)=>{  
            parametersById[f.id] = f;
        });
      })
    }
  }catch(e){
    
  }
  
    
    return { parameters: parameters.data, parametersById };
  
};

const getAllParametersInherit = async () => {
  const parameters = await axios.get("/data/inherited_parameters.json");
  
  return { parameters: parameters.data };
};


const getAllDashBoardFormulas = async () => {
  const dashboard_formulas = await axios.get("/front_dashboard_formulas.json");
  return { dashboard_formulas: dashboard_formulas.data };
};

const getAllPresentations = async () => {
  const result = await axios.get("/presentations.json");
  return result?.data;
};

const getAllPages = async (id) => {
  const result = await axios.get(`/pages.json?id=${id}`);
  return result?.data;
};

export {
  getAllYearsData,
  getAllRangeData,
  getAllFormulas,
  getEbitdaFormulas,
  getAllPresentations,
  getAllPages,
  getLastMonthsFromYears,
  getAllDashBoardFormulas, 
  getDashboardData,
  getAllParameters,
  getAllParametersInherit,
  getAllYearsDataForParameters
};
