import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import downloadIco from 'bt_download.svg';

const useStyles = makeStyles((theme) => ({
  button: {
    padding: 0,
  },
  icon: {
    width: 36,
  },
}));

const DownloadButton = ({ can_download, year, scope, is_budget }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const simplifiedLink = `/export/xlsx/${year}/${scope}.xlsx?${is_budget ? 'is_budget=true' : ''}`;
  const budgetLink = `/export_with_budget/xlsx/${year}/${scope}.xlsx`;

  return (
    can_download && (
      <div>
        <Button
          aria-controls="download-menu"
          aria-haspopup="true"
          onClick={handleClick}
          className={classes.button}
        >
          <img src={downloadIco} className={classes.icon} alt="Download" />
        </Button>
        <Menu
          id="download-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose} component="a" href={simplifiedLink}>
            Versão Simplificada
          </MenuItem>
          <MenuItem onClick={handleClose} component="a" href={budgetLink}>
            Versão com Orçamento
          </MenuItem>
        </Menu>
      </div>
    )
  );
};

export default DownloadButton;
