import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Paper from '@material-ui/core/Paper';
import Draggable from 'react-draggable';
import Highcharts from 'highcharts'
import HighchartsMore from "highcharts/highcharts-more";
import HighchartsReact from 'highcharts-react-official'
import popupIco from 'popup-ico.svg';
import { popupCenter } from './popup/popupCenter'
import LoadingDialog from './LoadingDialog'
import downloadIco from 'bt_download.svg';
import './BorboletaChart.css';
import moment from 'moment';
import { BorboletaChartDownloadFooter, BorboletaChartHeader } from '../styled_components/default';
import { DownloadIcon } from './svg-icons/DownloadIcon';
import ReactTooltip from 'react-tooltip';
import LedgerModal from './ledgers/LedgerModal';
window.moment = moment;
var formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL"
});
Highcharts.setOptions({
    colors: ["#65BCFF", "#F7999C", "#4AB796", "#F4CF98", "#37BAC0", "#6C899E"]
});
const DEFAULT_HEIGHT_FOR_CHART = 400;
const range = (a, b) => Array.from(new Array(b > a ? b - a : a - b), (x, i) => b > a ? i + a : a - i);
HighchartsMore(Highcharts);
export default class BorboletaChart extends React.Component {
    requestsCounter = 0;
    state = {
        activeRow: {
            formula: {},
            area: {},
            param: {},
            cost_center: {}
        },
        dataLabels: {},
        legendData: {
            data1: this.props?.principalDataLabel?.replace("-range", ""),
            data2: this.props?.secondaryDataLabel?.replace("-range", "")
        },
        chartIsVisible: true,
        areasDict: {},
        activeCosts: {},
        formulaIsVisible: {},
        balanceRows: {},
        costCentersFetched: {},
        despesasType: {},
        costCenterMonthReports: {},
        reports: {},
        currentFormulaData: [],
        currentColumnId: null,

        options: {
            yAxis: {
                stackLabels: {
                    style: {
                        color: 'black'
                    },
                    enabled: true,
                    y: -30
                }
            },
            plotOptions: {
                column: {
                    stacking: 'normal',
                    pointPadding: 0,
                    groupPadding: 0,
                    dataLabels: {
                        enabled: false
                    }
                }
            },
            chart: {
                type: 'waterfall'
            },

            title: {
                text: `${this.props.title['data2']} x ${this.props.title['data1']}`
            },

            xAxis: {
                type: 'category'
            },

            yAxis: {
                title: {
                    text: 'R$'
                }
            },

            legend: {
                enabled: false
            },

            tooltip: {
                pointFormat: '<b>${point.y:,.2f}</b> R$'
            },

            series: [{
                upColor: "#65BCFF",
                color: "#F7999C",
                data: [{
                    name: 'Start',
                    y: 120000
                }, {
                    name: 'Product Revenue',
                    y: 569000
                }, {
                    name: 'Service Revenue',
                    y: 231000
                }, {
                    name: 'Positive Balance',
                    color: Highcharts.getOptions().colors[1]
                }, {
                    name: 'Fixed Costs',
                    y: -342000
                }, {
                    name: 'Variable Costs',
                    y: -233000
                }, {
                    name: 'Balance',
                    isSum: true,
                    color: Highcharts.getOptions().colors[1]
                }],
                dataLabels: {
                    enabled: true,
                    formatter: function () {
                        return formatter.format(this.y);
                    },
                    enabled: true,
                    // y: -70,
                    style: {
                        fontWeight: 'bold',
                        textShadow: 'none',
                        color: 'black'
                    }
                },
                pointPadding: 0
            }]
        }
    }

    componentDidUpdate(prevProps) {
        // Verifique se a prop 'ebitda_formula' mudou
        if (this.props.ebitda_formula_id !== prevProps.ebitda_formula_id) {
          // 'ebitda_formula' mudou, execute a ação desejada
          this.handleEbitdaFormulaChange();
        }
    }

    async handleEbitdaFormulaChange() {
        // this.requestsCounter += 1;
        if(this.props.ebitda_formula_id){
            let self = this;
            let ids = this.props.ebitda_formula_id.map((x)=> x.value)
            ids.map((id)=>{
                axios.get(`/chart_formulas/${id}.json`).then((data)=>{
                    self.setState((oldState)=>{
                        oldState.currentFormulaData[id] = data.data
                        return {...oldState}
                    })
                })
                
            })

            this.setState({
                currentColumnId: ids
            })
            
        }
    }

    async showDataFor(id, formula, currentTotal) {
        let self = this;
        this.setState({
            currentColumnId: id,
            currentFormula: formula,
            currentTotal
        })
        // this.requestsCounter += 1;
        let formulaData = await axios.get(`/chart_formulas/${id}.json`)
        this.setState({ currentFormulaData: formulaData.data })
        // this.requestsCounter -= 1;
    }

    setupChartData() {
        let self = this;
        let { comparedLabel } = this.props;
        let totalYvalue = 0
        let { principalDataLabel, secondaryDataLabel, isRange, chartData } = this.props;

        // if (isRange.toString() === "true") {
        //     principalDataLabel = principalDataLabel + "-range"
        //     secondaryDataLabel = secondaryDataLabel + "-range"
        // }

        let newChartData = {}

        Object.entries(chartData).map(([key, value]) => {

            newChartData[key.replace(principalDataLabel, "data1")] = value * -1
            newChartData[key.replace(secondaryDataLabel, "data2")] = value * -1
        })

        let entries = Object.values(this.props.chart)


        let data = entries.map(([label, formula, id], i) => {
            try {
                let newFormula = formula
                Object.entries(newChartData).map(([key, value]) => {

                    if (value.toString().search("Infinity") > -1) {
                        if (key.indexOf('[') > -1) {
                            newFormula = newFormula.replace(`${key}`, "0")
                        } else {
                            newFormula = newFormula.replace(`[${key}]`, "0")
                        }

                    } else {
                        if (key.indexOf('[') > -1) {
                            newFormula = newFormula.replace(`${key}`, value)
                        } else {
                            newFormula = newFormula.replace(`[${key}]`, value)
                        }

                    }

                })
                let yValue = eval(newFormula)

                if (typeof yValue != "number") {
                    yValue = 0
                }
                totalYvalue += yValue

                return {
                    events:{
                        click: ()=> {
                            // if(!this.props.disableOpenings){
                                !this.props.printing && self.showDataFor(id, formula, yValue)
                            // }
                            
                        }
                    },
                    color: i == 0 ? "#617e94" : null,
                    name: i == 0 ? this.props.title['data2_short'] + " " : label,
                    y: yValue.toString() == "NaN" ? 0 : yValue,
                    dataLabels: {
                        enabled: true,
                        formatter: function () {
                            return formatter.format(this.y);
                        },
                        enabled: true,
                        verticalAlign: yValue > 0 ? 'top' : 'bottom',
                        y: yValue > 0 ? -24 : 24,
                        style: {
                            fontWeight: 'bold',
                            textShadow: 'none',
                            color: 'black'
                        }
                    },
                }
            } catch (e) {

            }

        })

        data.push({
            color: "#617e94",
            isSum: true,
            name: this.props.title['data1_short'],
            dataLabels: {
                enabled: true,
                formatter: function () {
                    return formatter.format(this.y);
                },
                enabled: true,
                verticalAlign: totalYvalue > 0 ? 'top' : 'bottom',
                y: totalYvalue > 0 ? -24 : 24,
                style: {
                    fontWeight: 'bold',
                    textShadow: 'none',
                    color: 'black'
                }
            },
        })
        data = data.filter((x) => x /* só dados validos */)


        this.setState((oldState) => {
            oldState.options.series[0].data = data
            oldState.dataIsSetted = true
            return { ...oldState }
        })


        this.forceUpdate()
    }

    renderHighChart() {
        const { options, dataIsSetted } = this.state;
        let innerOptions = {
            ...options,
            chart: {
                height: this.props.height || DEFAULT_HEIGHT_FOR_CHART,
                ...(options.chart || {})
            }
        }
        return dataIsSetted && <HighchartsReact
            ob
            highcharts={Highcharts}
            options={{...innerOptions, exporting: { enabled: !this.props.printing }}}
        />
    }

    getIndicatorLine(indicator_months={}, data_label ){
        const {principalDataLabel, startDate, endDate} = this.props
        let {legendData} = this.state;
        let total = 0;

        let legend = legendData[data_label]

        if (principalDataLabel.search("range") >= 0) {
            let legend_str = legend.replace("-range", "")

            let startMonth = moment(startDate[legend_str]).month() + 1
            let endMonth = moment(endDate[legend_str]).month() + 1

            for (let month = startMonth; month <= endMonth; month++) {
                // console.log(monthsDict[month])
                try{
                    let monthValue = parseFloat(indicator_months[monthsDict[month]])
                    if(parseFloat(monthValue).toString() != "NaN"){
                        total += monthValue
                    }
                }
                catch(e){
                    debugger
                }
                
                
            }

        } else {
            total = indicator_months[monthsDict[this.getCurrentMonth(data_label)]]
        }

        return total


    }

    getBalanceSheetLineValue(line, data_label) {
        const { principalDataLabel, startDate, endDate } = this.props
        let { legendData } = this.state;
        let total = 0;

        let legend = legendData[data_label]
        try{
            if (principalDataLabel.search("range") >= 0) {
                let legend_str = legend.replace("-range", "")

                let startMonth = moment(startDate[legend_str]).toDate().getMonth() + 1
                let endMonth = moment(endDate[legend_str]).toDate().getMonth() + 1

                for (let month = startMonth; month <= endMonth; month++) {
                    total += line.months[monthsDict[month]] || 0
                }

            } else {
                total = line.months[monthsDict[this.getCurrentMonth(data_label)]]
            }

            return total
        }catch(e){
            console.log("ERRO BALANCE LINE VALUE", e)
            return 0
        }

    }

    async toggleActive(id, activeType) {
        try {
            const { activeRow } = this.state;
            this.setState({
                activeRow: {
                    ...activeRow,
                    [activeType]: { ...activeRow[activeType], [id]: !activeRow[activeType][id] }
                }
            })
        } catch (e) { }

    }


    async fetchDataForFormula(formula) {

        this.toggleActive(formula.fr_id, "formula");
        ["data1", "data2"].map(async (data_label) => {

            let year = this.getCurrentYear(data_label)
            const { principalDataLabel, secondaryDataLabel } = this.props
            let is_budget = null;

            if (secondaryDataLabel.search("budget") >= 0 && data_label == "data2") {
                is_budget = true
            }
            this.requestsCounter += 1;
            let params = { formula_ids: [formula.fr_id], year: year, is_budget, without_cost_centers: true }
            axios.get(`/results/dre_cc.json`, { params }).then((formulaData) => {
                this.requestsCounter -= 1;
                this.setState((oldState) => {
                    oldState.reports[[data_label, formula.id]] = formulaData.data
                    return { ...oldState }
                })
            })
            // this.toggleFormula(formula)

            try {
                // this.requestsCounter += 1;
                axios.get(`/results/cost_centers.json`, { params }).then((costCenterMonthData) => {
                    this.setState((oldState) => {
                        oldState.costCenterMonthReports[[data_label, year]] = costCenterMonthData.data
                        return { ...oldState }
                    })
                })
                // this.requestsCounter -= 1;

            } catch (e) {
                // console.log("ERROOO", e)

            }
        })
        this.setState((oldState) => {
            oldState.formulaIsVisible[formula.id] = !oldState.formulaIsVisible[formula.id]
            return { ...oldState }
        })
    }

    componentDidMount() {
        this.handleEbitdaFormulaChange();
        this.fetchAreas()
        if (this.props.popup) {
            this.setupChartData()
        }
        let { principalDataLabel, secondaryDataLabel } = this.props
        let { analisadoDate, budgetDate, comparadoDate, isRange, startDate, endDate } = this.props
        let dataDates = {}

        let dataLabels = {
            data1: translate[principalDataLabel] || "",
            data2: translate[secondaryDataLabel] || ""
        }
        if (isRange.toString() === "true") {
            dataDates = {
                data1: moment(startDate[principalDataLabel]).toDate(),
                data2: moment(startDate[secondaryDataLabel]).toDate()
            }
        } else {
            dataDates = {
                data1: principalDataLabel ? eval(principalDataLabel + 'Date'): new Date(),
                data2: secondaryDataLabel ? eval(secondaryDataLabel + 'Date'): new Date()   
            }    
        }


        this.setState({
            dataLabels,
            dataDates
        })
    }

    getCurrentMonth(data_label) {
        let { endDate, startDate, isRange } = this.props;
        let { legendData } = this.state;
        if (!this.state.dataDates) {
            return moment().month()
        }
        if (isRange.toString() === "true") {
            data_label = data_label.replace("-range", "")
            return moment(endDate[legendData[data_label]]).toDate().getMonth() + 1;
        } else {
            return moment(this.state.dataDates[data_label]).toDate().getMonth() + 1;
        }

    }

    getCurrentYear(data_label) {
        let { endDate, startDate, isRange } = this.props;
        let { legendData } = this.state;
        if (!this.state.dataDates) {
            return moment().year()
        }
        if (isRange.toString() === "true") {
            data_label = data_label.replace("-range", "")
            return moment(endDate[legendData[data_label]]).toDate().getFullYear();
        } else {
            return moment(this.state.dataDates[data_label]).toDate().getFullYear();
        }

    }

    async fetchAreaData(key, fixed, type_id, type_label) {


        ["data1", "data2"].map(async (data_label) => {
            let year = this.getCurrentYear(data_label)

            const { costCentersFetched } = this.state;
            const { cost_center_heads } = this.props;
            let fixed_label = Boolean(fixed) ? "fixed" : "not_fixed"


            if (!costCentersFetched[data_label]) {
                costCentersFetched[data_label] = {}
            }

            Object.entries(cost_center_heads).map(([id, label]) => {
                if (!costCentersFetched[data_label][label]) {
                    costCentersFetched[data_label][label] = {}
                }
            })

            if (costCentersFetched[data_label][type_label][key] && costCentersFetched[data_label][type_label][key][fixed_label]) {
                this.setState((oldState) => {
                    oldState.costCentersFetched[data_label][type_label][key][fixed_label] = null
                    return { ...oldState }
                })
            } else {
                //   this.requestsCounter += 1;
                let details = await axios.get(`/results/${key}/cost_center_detail.json`, {
                    params: {
                        year: year,
                        fixed: Boolean(fixed),
                        type: type_id,
                        is_budget: this.state.legendData[data_label].search("budget") >= 0 ? true : false
                    }
                })
                //   this.requestsCounter -= 1;
                this.setState((oldState) => {

                    if (!oldState.costCentersFetched[data_label][type_label][key]) { oldState.costCentersFetched[data_label][type_label][key] = {} }

                    if (!oldState.costCentersFetched[data_label][type_label][key][Object.keys(details.data)[0]]) { oldState.costCentersFetched[data_label][type_label][key][Object.keys(details.data)[0]] = {} }

                    oldState.costCentersFetched[data_label][type_label][key][Object.keys(details.data)[0]] = Object.values(details.data)[0]

                    return { ...oldState }
                })
            }
        })

    }

    toggleDespesasType(key, year) {
        let { cost_center_heads } = this.props;
        let [area_id, is_fixed] = key
        this.toggleActive(key, "area");
        Object.entries(cost_center_heads).map(([despesa_type_id, label]) => {
            this.fetchAreaData(area_id, is_fixed, despesa_type_id, label)
        })


        this.setState((oldState) => {
            oldState.despesasType[key] = !oldState.despesasType[key]
            return { ...oldState }
        })
    }

    async getBalanceRows(key) {
        this.toggleActive(key, "param");
    
        const requests = ["data1", "data2"].map(async (data_label) => {
            let year = this.getCurrentYear(data_label);
            let response = await axios.get(`/results/${key}/detail.json`, {
                params: {
                    year: year,
                    cost_center_mode: true,
                    is_budget: this.state.legendData[data_label] === "budget"
                }
            });
            return { data_label, year, details: response.data };
        });
    
        const results = await Promise.all(requests);
        
        this.setState((oldState) => {
            let newState = { ...oldState };
    
            results.forEach(({ data_label, year, details }) => {
                const detailValue = Object.values(details)[0];
                newState.balanceRows[[data_label, year, key]] = detailValue;
    
                Object.values(detailValue?.childreen || {}).forEach((row) => {
                    newState.balanceRows[[data_label, year, row.fr_id]] = row;
                });
            });
            
            return newState;
        });
    }

    numberToCurrency = function (number = 0) {
        if (String(parseFloat(number)) == "NaN" || parseFloat(number) == undefined) {
            number = 0
        }

        return parseFloat(number * -1).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL"
        });
    };

    getMonthRange(data, label, is_fixed, data_label) {

        try {
            let { isRange, startDate, endDate } = this.props;
            let { legendData } = this.state;

            if (isRange.toString() === "true") {
                let legend = legendData[data_label]

                let firstMonth = moment(startDate[legend]).toDate().getMonth() + 1
                let endMonth = moment(endDate[legend]).toDate().getMonth() + 1
                let result = 0
                range(firstMonth, endMonth + 1).map((month_i) => {
                    result += parseFloat(data[`["${label}", ${is_fixed}, ${month_i}]`]) || 0
                })
                return this.numberToCurrency(result)
            } else {
                return this.numberToCurrency(data[`["${label}", ${is_fixed}, ${this.getCurrentMonth(data_label)}]`])
            }
        } catch (e) {
            debugger
            console.log(e)
            return 0
        }

    }

    getFormatedFormula() {
        let { chartData, principalDataLabel, secondaryDataLabel } = this.props;
        let { currentFormula, currentFormulaData, currentTotal } = this.state
        if (this.props.isRange) {
            principalDataLabel = principalDataLabel.replace("-range", "") + "-range"
            secondaryDataLabel = secondaryDataLabel.replace("-range", "") + "-range"
        }

        let removeDataStr = currentFormula.replace("data1", principalDataLabel).replace("data2", secondaryDataLabel)

        let formulaData = {}

        Object.values(currentFormulaData).map((arr) => {
            arr.map((el) => {
                formulaData[el.fr_id] = el.label
            })
        })

        Object.entries(chartData).map(([key, value]) => {
            let [form_id, type] = JSON.parse(key)

            removeDataStr = removeDataStr.replace(key, `${formulaData[form_id]}-${translate[type]}(${this.numberToCurrency(value)})`)
        })

        let upColor = "#00529c"
        let color = "#ca2149"

        return <div style={{
            fontSize: 17,
            color: currentTotal > 0 ? upColor : color
        }}>
            {removeDataStr + " = " + this.numberToCurrency(currentTotal)}
        </div>
    }

    getFormattedDataLabel(data_label, withoutRange = false) {
        let { legendData } = this.state
        if (this.props.isRange.toString() === "true" && !withoutRange) {
            return legendData[data_label]?.replace("-range", "") + "-range"
        } else {
            return legendData[data_label]?.replace("-range", "")
        }
    }

    getChartData(fr_id, data_label, number_format = "money") {
        let { chartData } = this.props;
        let { legendData } = this.state
        let legended_data_label = this.getFormattedDataLabel(data_label)

        if (number_format == "money" || number_format == null) {
            return this.numberToCurrency(chartData[`["${fr_id}", "${legended_data_label}"]`])
        } else {
            return this.integerFormat(chartData[`["${fr_id}", "${legended_data_label}"]`])
        }

    }

    getCostCenterLineData(reports, data_label, formula, key, lines = false) {

        let { isRange, startDate, endDate } = this.props;
        let { legendData } = this.state;
        let linesByMonth = {
            "janeiro": 0,
            "fevereiro": 0,
            "março": 0,
            "abril": 0,
            "maio": 0,
            "junho": 0,
            "julho": 0,
            "agosto": 0,
            "setembro": 0,
            "outubro": 0,
            "novembro": 0,
            "dezembro": 0
        }
        if (lines) {
            const { balanceRows } = this.state

            linesByMonth = balanceRows[[data_label, this.getCurrentYear(data_label), key]]?.sum_of_lines || {}
            console.log("LINES_BY_MONTH", linesByMonth)
            // rows.map((line) => {
            //     Object.entries(line).map(([month, value]) => {
            //         linesByMonth[month] += value
            //     })
            // })

        }

        if (isRange.toString() === "true") {
            let legend = legendData[data_label]

            let firstMonth = moment(startDate[legend]).toDate().getMonth() + 1
            let endMonth = moment(endDate[legend]).toDate().getMonth() + 1

            let result = 0
            range(firstMonth, endMonth + 1).map((month_i) => {

                try {
                    if (lines) {
                        result += linesByMonth[monthsDict[month_i]] || 0
                    } else {
                        result += parseFloat(reports[`${data_label},${formula.id}`]["report"][formula.label][formula.label].params[key][monthsDict[month_i]].difference * -1) || 0
                    }

                } catch (e) {

                }

            })
            return this.numberToCurrency(result)
        } else {
            try {
                if (lines) {
                    return this.numberToCurrency(linesByMonth[monthsDict[this.getCurrentMonth(data_label)]])
                } else {
                    return this.numberToCurrency(reports[`${data_label},${formula.id}`]["report"][formula.label][formula.label].params?.[key]?.[monthsDict[this.getCurrentMonth(data_label)]].difference * -1)
                }

            } catch (e) {
                return 0
            }


        }

    }


    getCostCenterDetailMonths(cost_center, data_label) {
        let { isRange, startDate, endDate } = this.props;
        let { legendData } = this.state;

        try {
            if (isRange.toString() === "true") {
                let legend = legendData[data_label]

                let firstMonth = moment(startDate[legend]).toDate().getMonth() + 1
                let endMonth = moment(endDate[legend]).toDate().getMonth() + 1
                let result = 0
                range(firstMonth, endMonth + 1).map((month_i) => {
                    result += this.convertIntoNumber(cost_center.months[month_i])
                })
                return result
            } else {
                return cost_center.months[this.getCurrentMonth(data_label)]
            }
        } catch (e) {
            return 0
        }
    }
    integerFormat(number, title) {
        if (title) {
            return new Intl.NumberFormat('decimal', { minimumFractionDigits: 2 }).format(parseFloat(number).toFixed(2))
        } else {
            return new Intl.NumberFormat('decimal', { minimumFractionDigits: 2 }).format(parseFloat(number).toFixed(2) * -1)
        }

    }

    differenceBetweenData(data1="0", data2="0", type = "money") {
        // Não me pergunte porque fiz isso, pra mim isso não tem lógica, foi o cliente que pediu assim
        // Ultima alteração 19/02 e o céu está aberto, com algumas nuvens, acho que chove hoje

        let difference = 0
        try {
            if (data1.toString().search(',') == -1) {
                data1 = parseFloat(data1.toString().split("R$").join("").replace(/ /g, '').split(" ").join(''))
            } else {
                data1 = parseFloat(data1.toString().split(".").join("").split("R$").join("").split(",").join(".").replace(/ /g, '').split(" ").join(''))
            }

            if (data2.toString().search(',') == -1) {
                data2 = parseFloat(data2.toString().split("R$").join("").replace(/ /g, '').split(" ").join(''))
            } else {
                data2 = parseFloat(data2.toString().split(".").join("").split("R$").join("").split(",").join(".").replace(/ /g, '').split(" ").join(''))
            }

            if (data1 < 0 && data2 < 0) {
                difference = (data2 - data1)
            } else if (data1 == 0) {
                if (Math.sign(data2) == 1) {
                    difference = data2 * -1
                } else {
                    difference = data2
                }

            } else if (data2 == 0) {
                if (Math.sign(data1) == -1) {
                    difference = data1 * -1
                } else {
                    difference = data1
                }
            } else {
                difference = (data1 - data2)
            }





            if (type == null || type == "money") {
                return parseFloat(difference).toLocaleString("pt-BR", {
                    style: "currency",
                    currency: "BRL"
                });

            } else if (type == "integer") {
                return this.integerFormat(difference, true)
            }


        } catch (e) {
            console.log("ERRO_DIF", e)
            return 0
        }
    }

    otherDataLabel(data_label) {
        if (data_label == "data1") {
            return "data2"
        }
        if (data_label == "data2") {
            return "data1"
        }
    }

    monthsRangeForLedger(data_label) {
        const {principalDataLabel, startDate, endDate} = this.props
        let {legendData} = this.state;

        let legend = legendData[data_label]
        let startMonth = 0
        let endMonth = 0
        if (principalDataLabel.search("range") >= 0) {
            let legend_str = legend.replace("-range", "")

            startMonth = moment(startDate[legend_str]).month() + 1
            endMonth = moment(endDate[legend_str]).month() + 1
        }
        return [startMonth, endMonth]
    }

    renderCostCenterDetail(level, data_label, label, x) {
        const { currentFormulaData, costCentersFetched } = this.state;
        
        try {
            let costCenterKeys = {}
            Object.keys(currentFormulaData).map((key) => {
                Object.entries(costCentersFetched?.[key]?.[label]?.[x[0].area.id]?.[x[0].is_fixed ? `fixed` : `not_fixed`] || {}).map(([cc_key, value]) => {
                    costCenterKeys[cc_key] = value?.label
                })
            })
            
            return <React.Fragment>
                {Object.entries(costCenterKeys).map(([id, cc_label]) => {
                    let cost_center = costCentersFetched?.[data_label]?.[label]?.[x[0].area.id]?.[x[0].is_fixed ? `fixed` : `not_fixed`]?.[id] || { label: cc_label, months: {} }
                    let analisadoCostCenterLineValue = this.getCostCenterDetailMonths(cost_center, data_label);
                    let otherDataLabel = Object.keys(currentFormulaData).filter((x)=> x != data_label)[0]
                    let comparadoCostCenterLineValue = this.getCostCenterDetailMonths(costCentersFetched?.[otherDataLabel]?.[label]?.[x[0].area.id]?.[x[0].is_fixed ? `fixed` : `not_fixed`]?.[id], otherDataLabel)

                    let valueIsNotZero = (analisadoCostCenterLineValue != 0) || (comparadoCostCenterLineValue != 0)
                    return valueIsNotZero && <tr className={'borboleta cost-center-row'}>
                        <td style={{ textIndent: (level * 8) + 50 }}>{id}-{cost_center.label}</td>
                        <td className={'ledger-td'} style={{position: 'relative'}}>{this.numberToCurrency(analisadoCostCenterLineValue)}
                            { <div style={{position: 'absolute', bottom: 0, left: 8}} >
                                <LedgerModal
                                month={this.getCurrentMonth(data_label)}
                                range={this.props.isRange.toString() == "true" ? this.monthsRangeForLedger(data_label) : null}
                                year={this.getCurrentYear(data_label)}
                                account={id}
                                despesa_id={x[0].area.id}
                                is_fixed={x[0].is_fixed}
                                showIcon={!this.state.legendData[data_label].includes("budget")}
                                >
                                </LedgerModal></div>}
                        </td>
                        {Object.keys(currentFormulaData).map((key) => {
                            
                            return key != data_label &&
                                <React.Fragment>
                                    <td className={'ledger-td'} style={{position: 'relative'}}>{this.numberToCurrency(comparadoCostCenterLineValue)}
                                    {<div style={{position: 'absolute', bottom: 0, left: 8}} >
                                        <LedgerModal
                                            month={this.getCurrentMonth(key)}
                                            year={this.getCurrentYear(key)}
                                            range={this.props.isRange.toString() == "true" ? this.monthsRangeForLedger(key) : null}
                                            account={id}
                                            despesa_id={x[0].area.id}
                                            is_fixed={x[0].is_fixed}
                                            showIcon={!this.state.legendData[key].includes("budget")}
                                            >
                                        </LedgerModal>
                                    </div>}
                                    </td>
                                    <td>
                                        {this.differenceBetweenData(this.numberToCurrency(analisadoCostCenterLineValue), this.numberToCurrency(comparadoCostCenterLineValue))}
                                    </td>
                                </React.Fragment>
                        })}
                    </tr>
                })}
            </React.Fragment>
        } catch (e) {

            console.log("ERRO_DIF", e)
        }

    }

    async fetchAreas() {
        // this.requestsCounter += 1;
        let result = await axios.get(`/cost_center_areas.json`, {
            params: {
                query: this.state.query
            }
        });
        // this.requestsCounter -= 1;
        let hash_data = {}

        result.data.map((area) => {
            hash_data[area.id] = area.label
        })

        this.setState({ areasDict: hash_data })

    }

    toggleCost(label, area) {
        this.toggleActive(`${area},${label}`, "cost_center")
        this.setState((oldState) => {
            oldState.activeCosts[label] = !oldState.activeCosts[label]
            return { ...oldState }
        })
    }

    costIsActive(label) {
        return this.state.activeCosts[label]
    }


    convertIntoNumber(n = 0) {
        if ((parseFloat(n) != NaN) || parseFloat(n) != undefined) {
            return parseFloat(n)
        } else {
            return 0
        }
    }

    exportUrl = (data_label) => {
        let { startDate, endDate } = this.props;
        const { legendData } = this.state;

        let data_label_2 = this.otherDataLabel(data_label)
        let m1 = "month_1=" + this.getCurrentMonth(data_label)
        let m2 = "month_2=" + this.getCurrentMonth(data_label_2)
        let y1 = "year_1=" + this.getCurrentYear(data_label)
        let y2 = "year_2=" + this.getCurrentYear(data_label_2)
        let le1 = "label_1=" + legendData[data_label]
        let le2 = "label_2=" + legendData[data_label_2]

        let urlSafeParams = new URLSearchParams({
            "title_1": this.props.title['data1'],
            "title_2": this.props.title['data2'],
        })


        let months = {}
        if (this.props.principalDataLabel.search("range") != -1) {


            [data_label, data_label_2].map((dl) => {
                [startDate, endDate].map((date) => {
                    months[dl] ||= []
                    months[dl].push(moment(date[legendData[dl]]).toDate().getMonth() + 1)
                })

            })


            m1 = "month_1=" + months[data_label]
            m2 = "month_2=" + months[data_label_2]

        }

        let export_url = `/export/borboleta/${this.state.currentColumnId}.xlsx?${y1}&${m1}&${y2}&${m2}&${le1}&${le2}&${urlSafeParams.toString()}`

        return export_url

    }

    renderFormula(formula, data_label, alreadLoaded, level = 0, chartFormulaId=null) {
        let { options,
            reports,
            dataLabels,
            costCenterMonthReports,
            despesasType,
            costCentersFetched,
            balanceRows,
            currentFormula,
            legendData,
            currentFormulaData,
            formulaIsVisible,
            areasDict
        } = this.state;
        let { open, handleClose, chartData, indicatorsMap, isRange } = this.props
        
        if(chartFormulaId){
            currentFormulaData = currentFormulaData[chartFormulaId];
        }

        let formulas = []
        let indicators = {}
        let indicators_od = {}
        let self_indices = {}
        let self_params = {}
        let self_cost_centers = {}
        let params = {}
        let data = reports[`${data_label},${formula.id}`]?.["report"]?.[formula.label]?.[formula.label]
        let formulaInnerCostCenters = {}
        try{
            formulas = Object.entries(data.formulas)

            params = data.params

            self_indices = data.self_indices
            self_params = data.self_params
            self_cost_centers = data.self_cost_centers
            

            Object.keys(self_cost_centers).map((c_key)=>{
                let jsonString = c_key.replace(/=>/g, ':');
                // Transforme a string em um objeto JSON
                const jsonData = JSON.parse(jsonString)[0];
                
                formulaInnerCostCenters[jsonData.area.id] ||= {}
                formulaInnerCostCenters[jsonData.area.id][jsonData.is_fixed ? `fixed` : `not_fixed`] = true
            })

            
            



        } catch (e) { }

        try {
            indicators = reports[`${data_label},${formula.id}`]["report"][formula.label][formula.label].indicators || {}
            indicators_od = reports[`${this.otherDataLabel(data_label)},${formula.id}`]["report"][formula.label][formula.label].indicators || {}
        } catch (e) {
            indicators = {}
            indicators_od = {}
        }
        
        
        
        return <React.Fragment> {!alreadLoaded && (<React.Fragment>{level == 0 && !chartFormulaId && <thead>
            <tr>
                
                
                
            </tr>
                                                            <tr className={'first-line'}>
                                                                <th onClick={()=> this.fetchDataForFormula(formula)}>
                                                                    
                                                                </th>
                                                                <th>{this.props.title['data1']}</th>
                                                                <th>{this.props.title['data2']}</th>
                                                                <th>Diferença</th>
                                                                
                                                            </tr></thead>}
                                                            <tr id="principal" className={`formula-${formula.formula_type} formula-${formula.formula_type}-${formula.layout} format-${formula.number_format} formula-${formula.is_only_index ? "only-index" : "more-than-index"}`} >
                                                                <td style={{textIndent: level * 8}} onClick={()=> !this.props.withoutDrillDown && this.fetchDataForFormula(formula)}>
                                                                    {!this.props.withoutDrillDown && <Chevron active={this.state.activeRow["formula"][formula.fr_id]}/>}
                                                                    {formula.label}
                                                                </td>
                                                                <td>{this.getChartData(formula.fr_id, data_label, formula.number_format)}</td>
                                                                {Object.keys(currentFormulaData).map((key)=>(
                                                                    key != data_label && currentFormulaData[key].length > 0 
                                                                    &&  
                                                                    <React.Fragment>
                                                                        <td>{this.getChartData(formula.fr_id, key, formula.number_format)}</td>
                                                                        <td>{this.differenceBetweenData(this.getChartData(formula.fr_id, data_label), this.getChartData(formula.fr_id, key), formula.number_format, formula.is_only_index)}</td>
                                                                    </React.Fragment>
                                                                ))}
                                                                
                                                                
                                                            </tr></React.Fragment>)}
                    {formulaIsVisible?.[formula.id] && <React.Fragment>
                        {data?.['cost_center_params']?.ids?.map((x)=>{
                            if(formulaInnerCostCenters[x[0].area.id]?.[x[0].is_fixed ? `fixed` : `not_fixed`]){
                                return <React.Fragment>
                                        <tr className={'cost-center-area'} onClick={()=> this.toggleDespesasType([x[0].area.id, x[0].is_fixed], this.getCurrentYear(data_label))}>
                                            <td style={{textIndent: (level * 8) + 5}} >
                                                <Chevron active={this.state.activeRow['area'][`${x[0].area.id},${x[0].is_fixed}`]}></Chevron>
                                                {areasDict?.[x[0].area.id]} {x[0].is_fixed ? "Fixo" : "Variável"}
                                            </td>
                                            <td>{
                                                this.getMonthRange(costCenterMonthReports?.[`${data_label},${this.getCurrentYear(data_label)}`], areasDict?.[x[0].area.id], x[0].is_fixed, data_label)
                                            }</td>
                                            {Object.keys(currentFormulaData).map((key)=>{
                                                //debugger
                                                return key != data_label && 
                                                currentFormulaData[key].length > 0 &&  
                                                
                                                    costCenterMonthReports[`${key},${this.getCurrentYear(key)}`] && 
                                                        
                                                        <React.Fragment>
                                                            <td>{this.getMonthRange(costCenterMonthReports[`${key},${this.getCurrentYear(key)}`], areasDict?.[x[0].area.id], x[0].is_fixed, key)}</td>
                                                            <td>{this.differenceBetweenData(
                                                                    this.getMonthRange(costCenterMonthReports?.[`${data_label},${this.getCurrentYear(data_label)}`], areasDict?.[x[0].area.id], x[0].is_fixed, data_label),
                                                                    this.getMonthRange(costCenterMonthReports?.[`${key},${this.getCurrentYear(key)}`], areasDict?.[x[0].area.id], x[0].is_fixed, key)
                                                                                            )}</td>
                                                        </React.Fragment>
                                                    
                                                
                                                

                                            })}
                                        </tr>
                                        {despesasType[[x[0].area.id, x[0].is_fixed]] && Object.entries(this.props.cost_center_heads).map(([key, label])=>{
                                            let comparado_key = Object.keys(currentFormulaData).filter((x)=> x != data_label)[0]
                                            let analisado = "0";
                                            let comparado = "0";
                                            
                                            try{
                                                analisado = costCentersFetched[data_label][label] && costCentersFetched[data_label][label][x[0].area.id] && this.numberToCurrency(Object.entries(costCentersFetched[data_label][label][x[0].area.id][x[0].is_fixed ? `fixed` : `not_fixed`]).map(([id, cost_center])=>{return this.getCostCenterDetailMonths(cost_center, data_label)}).reduce((a,b)=> this.convertIntoNumber(a) + this.convertIntoNumber(b), 0))
                                            }catch(e){}

                                            try{
                                                comparado = costCentersFetched[comparado_key][label] && costCentersFetched[comparado_key][label][x[0].area.id] && this.numberToCurrency(Object.entries(costCentersFetched[comparado_key][label][x[0].area.id][x[0].is_fixed ? `fixed` : `not_fixed`]).map(([id, cost_center])=>{return this.getCostCenterDetailMonths(cost_center, comparado_key)}).reduce((a,b)=> this.convertIntoNumber(a) + this.convertIntoNumber(b), 0)) 
                                            }catch(e){}
                                                
                                            return <React.Fragment>
                                                <tr className={'borboleta cost-center-head'}>
                                                    <td onClick={()=> this.toggleCost(label, `${x[0].area.id},${x[0].is_fixed}`)}  style={{textIndent: (level * 8) + 15}}>
                                                        <Chevron active={this.state.activeRow['cost_center'][`${x[0].area.id},${x[0].is_fixed},${label}`]}></Chevron>
                                                        {label.toUpperCase()}
                                                    </td>
                                                    {currentFormulaData && Object.keys(currentFormulaData).map((otherdata_key)=>(
                                                        otherdata_key != data_label && currentFormulaData && currentFormulaData[otherdata_key].length > 0 &&  
                                                        <React.Fragment>
                                                            <td>{analisado}</td>
                                                            <td>{comparado}</td>
                                                            <td>{this.differenceBetweenData(analisado, comparado)}</td>
                                                        </React.Fragment>
                                                    ))}
                                                </tr>
                                                {this.costIsActive(label) && this.renderCostCenterDetail(level, data_label, label, x)}
                                            </React.Fragment>
                                        })}
                                    </React.Fragment>
                            }
                            
                            
                            
                        })}
                        {reports[`${data_label},${formula.id}`] && reports[`${data_label},${formula.id}`]["labels"] && Object.entries(reports[`${data_label},${formula.id}`]["labels"]).map(([chave, value])=>{
                            console.log("KEY", chave)
                            return (value.toUpperCase() != "ATIVOS") &&  self_params[chave] && <React.Fragment>
                            <BorboletaParamLine
                               isRange={isRange}
                               currentFormulaData={currentFormulaData}
                               getCostCenterLineData={this.getCostCenterLineData.bind(this)}
                               getBalanceRows={this.getBalanceRows.bind(this)}
                               activeRow={this.state.activeRow}
                               monthsRangeForLedger={this.monthsRangeForLedger.bind(this)}
                               legendData={this.state.legendData}
                               chave={chave}
                               level={level}
                               value={value}
                               reports={reports}
                               data_label={data_label}
                               formula={formula}
                               differenceBetweenData={this.differenceBetweenData.bind(this)}
                               balanceRows={balanceRows}
                               getBalanceSheetLineValue={this.getBalanceSheetLineValue.bind(this)}
                               getCurrentYear={this.getCurrentYear.bind(this)}
                               getCurrentMonth={this.getCurrentMonth.bind(this)}
                               numberToCurrency={this.numberToCurrency.bind(this)}
                                
                            >

                            </BorboletaParamLine>
                           
                    </React.Fragment>
                })}
                {
                    <React.Fragment>
                        {
                            Object.entries(indicators).map(([indicator_name, indicator_months]) => {

                                return self_indices[indicatorsMap[indicator_name]] && <tr id="indicator-row">
                                    <td style={{ textIndent: (level * 8) + 5 }}>{indicator_name}</td>
                                    <td>{this.integerFormat(this.getIndicatorLine(indicator_months, data_label), true)}</td>
                                    {Object.keys(currentFormulaData).map((other_data_label) => {

                                        return other_data_label != data_label &&
                                            <React.Fragment>
                                                <td>{this.integerFormat(this.getIndicatorLine(indicators_od?.[indicator_name], data_label), true)}</td>
                                                <td>{this.differenceBetweenData(this.getIndicatorLine(indicator_months, data_label), this.getIndicatorLine(indicators_od?.[indicator_name], data_label), "integer")}</td>
                                            </React.Fragment>

                                    })}
                                </tr>
                            })
                        }
                    </React.Fragment>
                }

                {
                    <React.Fragment>
                        {
                            formulas.map(([formula_id, formula_data]) => {

                                return this.renderFormula(formula_data.data, data_label, alreadLoaded, level + 1)
                            })
                        }
                    </React.Fragment>
                }
            </React.Fragment>}

        </React.Fragment>
    }

    closeTable() {
        this.setState({ currentColumnId: null })
    }
    renderContent() {
        let loadedFormulas = {}
        let botBorder = (showStyle) => {
            if (showStyle) {
                return {
                    borderBottom: "3px solid",
                    marginBottom: 10,
                    paddingBottom: 10
                }
            } else {
                return {}
            }

        }
        const { options,
            reports,
            dataLabels,
            costCenterMonthReports,
            despesasType,
            costCentersFetched,
            balanceRows,
            currentFormula,
            legendData,
            currentFormulaData
        } = this.state;
        return <div style={{ display: 'flex', flexDirection: 'column' }}>
            <BorboletaChartHeader>
                {!this.props.withoutDownloadButton && <a href={this.exportAllUrl()} style={{ marginLeft: 10 }} title="Download">
                    <DownloadIcon width={17} height={17} />
                </a>}
            </BorboletaChartHeader>
            <div style={{ width: "100%", height: this.props.height || DEFAULT_HEIGHT_FOR_CHART, ...botBorder(this.state.currentColumnId) }}>
                <h2 style={{ textAlign: 'center' }}>{this.props.chartTitle}</h2>
                {this.state.chartIsVisible && this.renderHighChart()}
            </div>
            {this.state.currentColumnId && !this.props.withoutDownloadButton && <BorboletaChartHeader>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', width: "100%", marginBottom: 10 }}>
                    <a href={this.exportUrl("data1")} style={{ marginLeft: 10 }} title="Download">
                        <DownloadIcon width={17} height={17} />
                    </a>
                </div>
            </BorboletaChartHeader>}
            {(this.state.currentColumnId && !this.props.ebitda_formula_id) && <div style={{ width: "100%", overflowY: 'scroll' }}>
                {/* <h2>{this.getFormatedFormula()}</h2> */}
                {Object.entries(currentFormulaData).map(([data_label, formulas]) => {
                    data_label = "data1" //inverção do analisado e comparado
                    return <table className="table borboletachart">
                        {formulas && formulas.map((formula) => {
                            let alreadLoaded = loadedFormulas[formula.id] ? true : false
                            loadedFormulas[formula.id] = true
                            return !alreadLoaded && this.renderFormula(formula, data_label, alreadLoaded)
                        })}
                    </table>
                })}


            </div>}
            {this.props.ebitda_formula_id && <div style={{ width: "100%", overflowY: 'scroll' }}>
                {/* <h2>{this.getFormatedFormula()}</h2> */}
                <table className="table borboletachart">
                                <thead>
                                    <tr className={'first-line'}>
                                        <th></th>
                                        <th>{this.props.title['data1']}</th>
                                        <th>{this.props.title['data2']}</th>
                                        <th>Diferença</th>
                                    </tr>
                                </thead>
                {this.props.ebitda_formula_id.map((ebitdaFormula)=>{
                    let formulaId = ebitdaFormula.value;
                    
                    return <>
                            {Object.entries(currentFormulaData[formulaId] || {}).map(([data_label, formulas]) => {
                                data_label = "data1" //inverção do analisado e comparado
                                return <>
                                    {formulas && formulas.map((formula) => {
                                        let alreadLoaded = loadedFormulas[formula.id] ? true : false
                                        loadedFormulas[formula.id] = true
                                        return !alreadLoaded && this.renderFormula(formula, data_label, alreadLoaded, 0, formulaId)
                                    })}  
                                </>  
                            })}
                    </>
                })}
                
                </table>

            </div>}

        </div>
    }
    generatePopUp() {
        let self = this
        // this.requestsCounter += 1;
        axios.post(`/borboleta_chart_popups.json`, {
            props: self.props
        }).then(function (result) {
            self.requestsCounter -= 1;
            popupCenter({ url: `/borboleta_chart_popups/${result.data.popup_id}`, title: 'Gráfico', w: window.innerWidth * 0.8, h: window.innerHeight * 0.8 })
        });

    }

    exportAllUrl() {
        let data_label = "data1"
        let { startDate, endDate } = this.props;
        let { legendData } = this.state;
        let data_label_2 = this.otherDataLabel(data_label)
        let m1 = "month_1=" + this.getCurrentMonth(data_label)
        let m2 = "month_2=" + this.getCurrentMonth(data_label_2)
        let y1 = "year_1=" + this.getCurrentYear(data_label)
        let y2 = "year_2=" + this.getCurrentYear(data_label_2)
        let le1 = "label_1=" + legendData[data_label]
        let le2 = "label_2=" + legendData[data_label_2]
        let urlSafeParams = new URLSearchParams({
            "title_1": this.props.title['data1'],
            "title_2": this.props.title['data2'],
        })
        console.log(this.props)
        let months = {}
        if (this.props.principalDataLabel.search("range") != -1) {


            [data_label, data_label_2].map((dl) => {
                [startDate, endDate].map((date) => {
                    months[dl] ||= []
                    months[dl].push(moment(date[legendData[dl]]).toDate().getMonth() + 1)
                })

            })


            m1 = "month_1=" + months[data_label]
            m2 = "month_2=" + months[data_label_2]

        }

        let export_url = `/export/borboleta.xlsx?${y1}&${m1}&${y2}&${m2}&${le1}&${le2}&${urlSafeParams.toString()}`

        return export_url


    }

    render() {

        let { open, handleClose, chartData } = this.props

        if (this.props.popup) {
            return (<div>
                {this.renderContent()}
            </div>)
        }
        else {
            return (
                <div>
                    <Dialog
                        onEnter={this.setupChartData.bind(this)}
                        open={open}
                        onClose={handleClose}
                        fullWidth={true}
                        maxWidth={"lg"}
                        PaperComponent={PaperComponent}

                    >
                        <DialogTitle className="chart-app" >
                            <BorboletaChartHeader>
                                <div
                                    title="Abrir em nova janela"
                                    className={'result-popup-button'}
                                    onClick={() => this.generatePopUp()}
                                    style={{
                                        backgroundColor: '#F5F5F5',
                                        width: 40,
                                        height: 40,
                                        borderRadius: 6,
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        cursor: 'pointer'
                                    }}>
                                    <img src={popupIco} style={{ width: 17 }}></img>
                                </div>
                                {this.props.can_download && <a href={this.exportAllUrl()} style={{ marginLeft: 10 }} title="Download">
                                    <DownloadIcon width={17} height={17} />
                                </a>}
                            </BorboletaChartHeader>
                            <small style={{ fontSize: 10 }}> R$/{this.props.divisor}</small>
                        </DialogTitle>
                        <DialogContent>
                            {this.renderContent()}

                        </DialogContent>
                        <DialogActions>
                            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                    <Button onClick={handleClose}
                                        className='button-app'>
                                        Fechar
                                    </Button>
                                </div>
                                <Button
                                    style={{
                                        border: '1px solid',
                                        zIndex: 20,
                                        visibility: this.state.currentColumnId ? 'visible' : 'hidden',
                                        color: "white",
                                        background: "#0099D8",
                                        position: 'absolute',
                                        width: 40,
                                        height: 40,
                                        right: 15
                                    }}
                                    onClick={() => this.closeTable()}
                                >
                                    <i className="fa fa-chevron-up"></i>
                                </Button>

                            </div>



                        </DialogActions>
                    </Dialog>

                    <LoadingDialog open={this.requestsCounter > 0} />
                </div>
            );
        }
    }
}

function BorboletaParamLine({ getCostCenterLineData,
    getBalanceRows,
    activeRow,
    isRange,
    chave: key,
    value,
    reports,
    data_label,
    formula,
    differenceBetweenData,
    balanceRows,
    getBalanceSheetLineValue,
    getCurrentYear,
    getCurrentMonth,
    numberToCurrency,
    level,
    currentFormulaData,
    monthsRangeForLedger,
    lines,
    legendData,
}) {
    console.log("JSON BALANCE ROWS", balanceRows)
    const [ledgerModalShowing, setLedgerModalShowing] = React.useState(false)
    let otherDataLabel = Object.keys(currentFormulaData).filter((x)=> x != data_label)[0]
    return (
        <><tr id="balance-param" onClick={() => getBalanceRows(key)}>
            <td style={{ textIndent: (level * 8) + 5 }}>
                <Chevron active={activeRow["param"][key]}></Chevron>
                {value}
                
            </td>
            
            <td>{reports[`${data_label},${formula.id}`] && getCostCenterLineData(reports, data_label, formula, key, lines)}</td>
            {Object.keys(currentFormulaData).map((other_data_label) => (
                other_data_label != data_label &&
                <React.Fragment>
                    <td>{reports?.[`${other_data_label},${formula.id}`] && getCostCenterLineData(reports, other_data_label, formula, key, lines)}</td>
                    <td>{differenceBetweenData(getCostCenterLineData(reports, data_label, formula, key, lines), getCostCenterLineData(reports, other_data_label, formula, key, lines))}</td>
                </React.Fragment>

            ))}
        </tr>
            {
                activeRow["param"][key] && balanceRows[[data_label, getCurrentYear(data_label), key]] && Object.entries(balanceRows[[data_label, getCurrentYear(data_label), key]].childreen)?.map(([key, values]) => {
                    return <BorboletaParamLine
                        getCostCenterLineData={getCostCenterLineData}
                        monthsRangeForLedger={monthsRangeForLedger}
                        debuga={true}
                        isRange={isRange}
                        lines={true}
                        legendData={legendData}
                        getBalanceRows={getBalanceRows}
                        activeRow={activeRow}
                        chave={values.fr_id}
                        value={values.label}
                        reports={reports}
                        data_label={data_label}
                        formula={formula}
                        differenceBetweenData={differenceBetweenData}
                        balanceRows={balanceRows}
                        getBalanceSheetLineValue={getBalanceSheetLineValue}
                        getCurrentYear={getCurrentYear}
                        getCurrentMonth={getCurrentMonth}
                        numberToCurrency={numberToCurrency}
                        level={level + 1}
                        currentFormulaData={currentFormulaData}
                    />
                })
            }
            {
                activeRow["param"][key] && balanceRows[[data_label, getCurrentYear(data_label), key]] && Object.entries(balanceRows[[data_label, getCurrentYear(data_label), key]].lines).map(([label_code, line]) => {
                    let analisadoLineValue = getBalanceSheetLineValue(line, data_label)
                    
                    let comparadoLineValue = getBalanceSheetLineValue(balanceRows[`${otherDataLabel},${getCurrentYear(otherDataLabel)},${key}`]?.lines[label_code], otherDataLabel)
                    let isVisible = (analisadoLineValue != 0) || (comparadoLineValue != 0)

                    return isVisible && <tr id="balance-sheet-line">
                        <td style={{ textIndent: (level * 8) + 50 }}>{line.label}</td>
                        <td className={'ledger-td'} style={{position: 'relative'}}>
                            {numberToCurrency(analisadoLineValue)}
                            { <div style={{position: 'absolute', bottom: 0, left: 8}} >
                                <LedgerModal
                                range={isRange.toString() == "true" ? monthsRangeForLedger(data_label) : null}
                                month={getCurrentMonth(data_label)}
                                year={getCurrentYear(data_label)}
                                account={line.id}
                                showIcon={!legendData[data_label].includes("budget")}
                                >
                                </LedgerModal></div>}
                        </td>
                        {Object.keys(currentFormulaData).map((other_data_label) => {
                            let fx = balanceRows[`${other_data_label},${getCurrentYear(other_data_label)},${key}`]
                            
                            return (fx && (other_data_label != data_label)) &&
                                <React.Fragment>
                                    <td className={'ledger-td'} style={{position: 'relative'}}>
                                        {numberToCurrency(comparadoLineValue)}
                                        { <div style={{position: 'absolute', bottom: 0, left: 8}} >
                                            <LedgerModal
                                            range={isRange.toString() == "true" ? monthsRangeForLedger(other_data_label) : null}
                                            month={getCurrentMonth(other_data_label)}
                                            year={getCurrentYear(other_data_label)}
                                            account={line.id}
                                            showIcon={!legendData[other_data_label].includes("budget")}
                                            >
                                        </LedgerModal></div>}
                                    </td>
                                    <td style={{position: 'relative'}}>
                                        {differenceBetweenData(numberToCurrency(analisadoLineValue), numberToCurrency(comparadoLineValue))}
                                        
                                    </td>
                                </React.Fragment>
                        }
                        )}
                    </tr>
                })
            }</>)
}

function Chevron({ active }) {
    return (
        <i style={{ margin: "0 10px" }} className={`fa fa-chevron-${active ? 'down' : 'right'}`}></i>
    )
}

function PaperComponent(props) {
    return (
        <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
        </Draggable>
    );
}


const monthsDict = {
    1: "janeiro",
    2: "fevereiro",
    3: "março",
    4: "abril",
    5: "maio",
    6: "junho",
    7: "julho",
    8: "agosto",
    9: "setembro",
    10: "outubro",
    11: "novembro",
    12: "dezembro",
}

const translate = {
    "analisado": "Real",
    "comparado": "Real",
    "budget": "Orçado"
}