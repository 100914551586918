import axios from 'axios';


function generateRequestHash(method, url, config) {
    // Para GET, os parâmetros relevantes estão em `config.params`
    const paramsString = config && config.params ? JSON.stringify(config.params) : '';
    return [method, url, paramsString].join("-");
}

function makeRequestWithCache(method, url, config) {
    const hash = generateRequestHash(method, url, config);

    if (requestCache.has(hash)) {
        // Retorna a promessa da requisição em cache
        return requestCache.get(hash);
    }

    // Adiciona o método ao objeto de configuração
    config = { ...config, method };

    const promise = axios(url, config)
        .then(response => {
            requestCache.delete(hash);
            return response;
        })
        .catch(error => {
            requestCache.delete(hash);
            throw error;
        });

    requestCache.set(hash, promise);
    return promise;
}

const cachedAxios = {
    get: (url, config) => makeRequestWithCache('GET', url, config),
    post: (url, data, config) => makeRequestWithCache('POST', url, { ...config, data }),
    put: (url, data, config) => makeRequestWithCache('PUT', url, { ...config, data }),
    // ... e outros métodos conforme necessário
};



export default cachedAxios;