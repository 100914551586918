import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import PieChartIcon from '@material-ui/icons/PieChart';
import TimeLineIcon from '@material-ui/icons/Timeline';
Highcharts.setOptions({
  colors: ["#65BCFF", "#F7999C", "#4AB796", "#F4CF98", "#37BAC0", "#6C899E"]
});
var moneyFormatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL"
});

var option = {
  style: 'percent',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
};
var percentFormatter = new Intl.NumberFormat("pt-BR",option);

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'fit-content',
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    '& svg': {
      margin: theme.spacing(1.5),
    },
    '& hr': {
      margin: theme.spacing(0, 0.5),
    },
  },
}));

export default function ResultChart(props) {
  const [open, setOpen] = React.useState(false);
  const [chartStyle, setChartStyle] = React.useState("line");
  const classes = useStyles();
  let options = {}
  
  
  options = {
    title: {
      text: props.p_key
    },
    chart: {
      type: 'column'
    },
    xAxis: {
      type: 'category',
      labels: {
        rotation: -45,
        style: {
          fontSize: '13px',
          fontFamily: 'Verdana, sans-serif'
        }
      }
    },
    yAxis: {
      title: {
        text: 'Valores'
      }
    },
    tooltip: {
      formatter: function(){
        return props.format != "percentage" ?  moneyFormatter.format(this.y) : percentFormatter.format(this.y / 100)
      }
    },
    legend: {
      enabled: false
    },
    dataLabels: {
      enabled: true,
      rotation: -90,
      color: '#00ff00',
      align: 'right',
      format: '{point.y:.1f}', // one decimal
      y: 10, // 10 pixels down from the top
      style: {
        fontSize: '13px',
        fontFamily: 'Verdana, sans-serif'
      }
    },
    series: [{
      colorByPoint: true,
      data: [...data()]
    }]
    
  }
  
  function tryEval(str) {
    try {
      let formulaSolved = eval(str)
      return formulaSolved ? formulaSolved : 0;
    } catch (e) {
      return 0
    }
  }

  function dataForFormula(result){
    const { months} = props;
    let data = []
    let i = 1
    Object.entries(months).map(([name, number]) => {
      if(i < 12){
        data.push(tryEval(result?.[name]))  
      }
      i += 1
    })
    return data
  }

  function tryEval(str) {
    try {
      let formulaSolved = eval(str)
      return formulaSolved ? formulaSolved : 0;
    } catch (e) {
      return 0
    }
  }
  function percentage(value, company_id){
    let{companyData, p_key, companies, currentMonth, currentCompanyId} = props;
    let total = 0
    Object.entries(companies).map(([name, row_company_id]) => {
      if(currentCompanyId != row_company_id){
        total += tryEval(companyData?.[row_company_id]?.["report"]?.[p_key]?.[p_key]?.["result"]?.[currentMonth])
      }
    })
    if(currentCompanyId != company_id){
      return parseFloat((value / total) * 100).toFixed(2) + " %"
    }
    
  }

  function data(){
    
    let result = []
    let{companyData, p_key, companies, currentMonth} = props;

    Object.entries(companies).map(([name, company_id]) => {
      let columnValue = tryEval(companyData?.[company_id]?.["report"]?.[p_key]?.[p_key]?.["result"]?.[currentMonth])
      result.push({
        name: name,
        y: columnValue,
        showInLegend: true,
        states: {
            borderColor: '#ff0000',
            hover: {
                enabled: false
            }
        },
        dataLabels: {
          enabled: true,
          formatter: function () {
            if(props.format != "percentage"){
              return percentage(tryEval(companyData?.[company_id]?.["report"]?.[p_key]?.[p_key]?.["result"]?.[currentMonth]), company_id)
            }else{
              return percentFormatter.format(this.y / 100)
            }
          },
          enabled: true,
          verticalAlign: columnValue > 0 ? 'top' : 'bottom',
          y: columnValue > 0 ? -24 : 24,
          style: {
              fontWeight: 'bold',
              textShadow: 'none',
              color: 'black'
          }
      },
      })
      
    })
          
    
    return result
  }

  // function data(){
    
  //   let result = []
  //   let{companyData, p_key, companies, currentMonth} = props;

  //   Object.entries(companies).map(([name, company_id]) => {
  //     result.push ([
  //       name,
  //       tryEval(companyData?.[company_id]?.["report"]?.[p_key]?.[p_key]?.["result"]?.[currentMonth])
  //     ])
  //   })
          
    
  //   return result
  // }

  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  return (
    <div>
      
	  <div style={{marginLeft: 10}} onClick={handleClickOpen}>
	 	 <i style={{fontSize: 16}}  className={'fa fa-chart-bar'}/>
	  </div>
      
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={'lg'}
      >
        
        <DialogContent >
          <div className={'flex'} style={{justifyContent: 'space-between'}}>
            <Grid container alignItems="center" className={classes.root}>
              {/*<PieChartIcon style={{cursor: 'pointer', color: chartStyle == "pie" ? 'rgb(113,172,233)' : 'rgba(0, 0, 0, 0.54)'}} onClick={()=> setChartStyle("pie")} />*/}
            </Grid>
            <Grid container alignItems="center" className={classes.root}>
              {props?.years?.map((year)=>{
                return <React.Fragment>
                  <div 
                    onClick={()=> props.loadYear(year)}
                    style={{
                    height: 24 + 10.5,
                    width: 24 + 10.5,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                    color: props?.allYearsData?.[year] ? 'rgb(113,172,233)' : 'rgba(0, 0, 0, 0.54)'
                  }}>{year}</div>
                  <Divider orientation="vertical" flexItem />  
                </React.Fragment>
              })}
              
            </Grid>
          </div>
          {open  && <HighchartsReact
		    highcharts={Highcharts}
		    options={options}
		  />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}



