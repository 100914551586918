import React from 'react';
import { withStyles, useTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Typography from '@material-ui/core/Typography';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    minWidth: 1000
  },
}))(MuiDialogActions);


export default class CustomizedDialogs extends React.Component {

  state = {
    editorState: EditorState.createEmpty()
  }
  componentDidMount(){
    this.fetchText();
  }

  async fetchText(){
    

    let result = await axios.get(`/companies/report_situation.json`);
    
    const html = result.data.content;
    this.setState({currentHtml: html})
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      this.setState({editorState: editorState})
      
    }
    
  }

  async saveText(){
    let {editorState} = this.state;
    let newReportSituation = draftToHtml(convertToRaw(editorState.getCurrentContent()))

    let result = await axios.put(`/companies/report_situation.json`, {
        report_situation: newReportSituation
      });

    this.props.handleClose(result.data.content)
    
  }
  
  onEditorStateChange = (editorState) => {
    this.setState({
      editorState
    });
  }

  render(){
    let {open, handleClose} = this.props;
    let {editorState, currentHtml} = this.state;
    

    return (
      <div>
        <Dialog 
          maxWidth={'lg'}
          
          onClose={()=> this.props.handleClose(currentHtml)} 
          aria-labelledby="customized-dialog-title" 
          open={open}>
          <DialogTitle id="customized-dialog-title" onClose={handleClose}>
            Observações
          </DialogTitle>
          <DialogContent dividers>
            
            <Editor
              editorState={editorState}
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={this.onEditorStateChange}
            />
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={this.saveText.bind(this)} color="primary">
              Salvar
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}