import React from 'react';
import ReportSituationDialog from './ReportSituationDialog'
import blocoIco from 'icon_bloconotas.svg';

import styled from "styled-components";
import { FileIcon } from './svg-icons/FileIcon';
import ReactTooltip from 'react-tooltip';
export default class NoticeButton extends React.Component {
  state = {
    edit: false,
    modalIsOpen: false,
  }
  componentDidMount() {
    this.fetchText()
  }

  async fetchText() {
    let result = await axios.get(`/companies/report_situation.json`);
    this.setState({ reportText: result.data.content })

    if (this.state.reportText && this.state.reportText.length > 0) {
      $("#report-text").html(this.state.reportText)
    } else {
      $("#report-text").html("O campo está vazio")
    }

  }
  popupCenter = ({ url, title, w, h }) => {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !== undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !== undefined ? window.screenTop : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const newWindow = window.open(url, title,
      `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    )

    if (window.focus) newWindow.focus();
  }
  openPopup() {
    this.popupCenter({ url: '/report_situation', title: 'Editar', w: window.innerWidth * 0.8, h: window.innerHeight * 0.8 })
  }

  handleOpen() {
    this.setState({
      modalIsOpen: true
    })

  }
  handleClose(newText = this.state.reportText) {
    $("#report-text").html(newText)
    this.setState({ modalIsOpen: false })
  }
  render() {
    let { isMobile } = this.props;
    return (

      <Button onClick={() => this.openPopup()} title="Notas">
        <FileIcon width={24} height={24} />
        <ReactTooltip id="notas" place='left' type="dark" effect="solid">
          <span>Notas</span>
        </ReactTooltip>
      </Button>
    );
  }
}
const ButtonImg = styled.img`
    width: 26.2px;
    margin-right: 10px;
    
`

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 6px;
  background-color: #31b7bc;
  color: white;
  margin: 3px;
  cursor: pointer;

  &:hover {
    transition: all 0.2s;
    filter: brightness(0.85);
  }
`