import React from "react";

export function BalancoIcon(props) {
  return (
    <svg viewBox="0 0 11 9" fill="none" {...props}>
      <path
        stroke="currentColor"
        strokeWidth={1.45}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M.725 7.775h9.55"
      />
      <circle cx={8.5} cy={3} r={1.5} stroke="currentColor" strokeWidth={1.45} />
      <circle cx={2.5} cy={3} r={1.5} stroke="currentColor" strokeWidth={1.45} />
    </svg>
  )
}