import React from "react";
import formula_tutorial from "formula_tutorial.jpeg";
import FormulaMentionInput from "./FormulaMentionInput";
import {
  WhiteBox,
  Title,
  TextInput,
  Select,
  Label,
  Line,
  TextArea,
  GreenButton,
  EntryBox,
  BlueButton,
  FilterLabel,
  FilterSection
} from "../styled_components/default.js";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';

class FrontDashboardFormula extends React.Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();

    let custom_formula = this.props.custom_formula || {};

    this.state = {
      data: [],
      hashtagData: [],
      pattern: custom_formula.pattern || "",
      customData: this.props.customData
    };
  }

  componentDidMount() {
    try {

      this.setState({
        data: this.props.params.map(([display, id]) => ({ id, display })),
        hashtagData: this.props.formulas.map(([group, display, id]) => ({
          id,
          display,
          group
        }))
      });
    } catch (e) {
      console.log(e);
    }
  }

  render() {
    const { data, hashtagData, customData } = this.state;
    const {titleData, costCenterAreaData, customFormulaId} = this.props;
    return (
        <div style={{ display: 'flex', flexDirection: "column" }}>
          <input type={"hidden"} name={"front_dashboard_formula[custom_formula_attributes][id]"} value={customFormulaId}/>
          <input type={"hidden"} name={"front_dashboard_formula[custom_formula_attributes][pattern]"} value={this.state.pattern}/>
          <FormulaMentionInput
              inputRef={this.input}
              label={"Escolher indicador do balanço"}
              titleData={titleData}
              data={data}
              customData={customData}
              costCenterAreaData={costCenterAreaData}
              hashtagData={hashtagData}
              value={this.state.pattern}
              onChange={(e, n, x, m) => {
                this.setState({ pattern: n });
              }}
          />
        </div>
    );
  }
}

export default FrontDashboardFormula;
