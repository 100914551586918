export const overlayStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'transparent',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  zIndex: 1000
};

export const modalStyle = {
  position: 'relative',
  overflow: 'visible',
  width: '700px',
  backgroundColor: '#617E94',
  padding: '20px',
  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
  zIndex: 1001,
  borderRadius: '4px'
};

export const modalContentStyle = {
  position: 'relative',
  padding: '5px'
};

export const modalTitleStyle = {
  fontSize: '20px',
  fontWeight: 'bold',
  marginBottom: '5px',
  color: 'white'
};

export const modalDescriptionStyle = {
  color: 'rgba(255, 255, 255, 0.7)'
};

export const closeButtonStyle = {
  position: 'absolute',
  right: '10px',
  top: '10px',
  background: 'none',
  border: 'none',
  fontSize: '35px',
  color: 'white',
  cursor: 'pointer',
  zIndex: 1100
};
