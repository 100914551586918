import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { PresentationsContext } from "../contexts";
import DropdownTreeSelect from 'react-dropdown-tree-select';
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import 'react-dropdown-tree-select/dist/styles.css';
import "./style.css";

const SCOPE_LABEL={ "dre": "DRE", "dre_cc": "DRE Gerencial"}
const InputFormulas = (props) => {
  const { state, dispatch } = useContext(PresentationsContext);
  const { defaultFormulas, title, kind, presentationKind } = props;

  //local states
  const [formulas, setFormulas] = useState(defaultFormulas || []);

  useEffect(() => {
    if (formulas.length > 0) {
      setParameters(formulas);
    }
  }, [formulas]);

  const getRandomUUID = () => {
    return (
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15)
    );
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(formulas);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFormulas(items);
  };

  const changeValueForFormula = (index, value, label) => {
    let newFormulas = [...formulas];
    newFormulas[index].value = value;
    newFormulas[index].label = label;
    setFormulas(newFormulas);
  };

  const handleRemoveItem = (key) => {
    if (window.confirm("Tem certeza?")) {
      setFormulas(formulas.filter((item) => item.key !== key));
    }
  };

  //dispacth events
  const setParameters = (formulas) => {
    dispatch({
      type: "SET_PARAMETERS",
      payload: { ...state.parameters, ["formulas"]: formulas },
    });
  };

  let options = state?.[kind]

  if(kind == "dashboard_formulas"){
    options = Object.values(state?.[kind] || {})
  }
  

  return (
    <>
      <label style={{ padding: 4 }}>{title}</label>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="formulas">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {formulas.map((formula, index) => (
                <Draggable key={formula.key} draggableId={formula.key} index={index}>
                  {(provided) => (
                    <Box
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <i className="fa fa-bars"></i>
                    
                      {presentationKind != "formula_table_balance" && <Select
                        {...props}
                        value={formula.value}
                        onChange={(e) => {
                          if (e.target.value !== "") {
                            changeValueForFormula(index, e.target.value);
                          }
                        }}
                      >
                        <option key={0}>Selecione...</option>
                        {presentationKind == "formula_table" && Object.entries(options).map(([scope, formulas]) => {
                          return ["dre", "dre_cc"].includes(scope) && <optgroup label={SCOPE_LABEL[scope]}>
                            {formulas.map((option, index) => (
                              <option key={option.fr_id} value={option.fr_id}>
                                  {option?.label || option?.title}
                                </option>
                            ))}
                          </optgroup>
                        })}
                        {presentationKind == "formula_table_balance" && Object.entries(state.patternParams || {}).map(([head, params]) => {
                          return <optgroup label={head}>
                            {params.map((option, index) => (
                              <option key={"parameter_" + option.key} value={"parameter_" + option.key}>
                                  {option?.label || option?.title}
                                </option>
                            ))}
                          </optgroup>
                        })}
                        {props.kind == "dashboard_formulas" && options.map((option, index) => (
                          <option key={option.id} value={option.title}>
                              {option?.title}
                          </option>
                        ))}
                        
                      </Select>}
                      
                      {
                        presentationKind == "formula_table_balance" && <>
                        <div>
                          <label>{formula?.label ? `parametro selecionado: "${formula?.label}"`: "Escolha um parametro"}</label>
                          <DropdownTreeSelect
                            
                            className="mdl-demo"
                            mode={"radioSelect"}
                            texts={{placeholder: formula?.label ? `${formula?.label}`: "Escolha um parametro"}}
                            value={{value: formula.value, label: "Caixa e equivalência de caixa"}}
                            onChange={(currentNode) => {
                              changeValueForFormula(index, currentNode?.value, currentNode?.label)}
                            }
                            data={state?.inheritParams|| {}} />
                        </div>
                        </>
                      }
                      <ButtonRemove onClick={() => handleRemoveItem(formula.key)}>
                        <i className="fa fa-trash" />
                      </ButtonRemove>
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <AddFormula
        key="add_formula"
        onClick={() => {
          if(formulas.length < 6 || props.presentationKind != "dashboard_widget"){
            setFormulas([...formulas, { key: getRandomUUID(), value: null }]);
          }else{
            alert("O limite é de 6 itens por página")
          }
        }}
      >
        Adicionar formula
      </AddFormula>
    </>
  );
};

export default InputFormulas;

const Box = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
`;

const Select = styled.select`
  background-color: #f5f5f5;
  color: #6e6e6e;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  padding: 8px;
  height: 20px;
  width: 210px;
`;

const ButtonRemove = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #6e6e6e;
  width: 20px;
  border-radius: 6px;
  &:hover {
    color: #ff0000;
  }
`;

const AddFormula = styled.div`
  background-color: #298793;
  color: #ffffff;
  height: 20px;
  width: 240px;
  cursor: pointer;
  text-align: center;
  padding: 8px;
  border-radius: 6px;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
