import React from "react";
import LoadingModal from './LoadingModal'
class LoadingDialog extends React.Component {
  
  constructor(props) {
    super(props);
    this.state = {
      opened: this.props.open,
      balanceSheet: {}
    }
    
  }

  handleClickOpen(){
    this.setState({opened: true});
  };

  handleClose(value){
    this.setState({opened: false});
    try{
      setSelectedValue(value);
    }
    catch(e){}
  };

  render() {
    
    return (
      <div>
        <LoadingModal  open={this.props.open} onClose={this.handleClose.bind(this)} />
      </div>
    );
  }
}

export default LoadingDialog;
