import React from 'react';
import ReactDOM from 'react-dom';
import { Column, Table, AutoSizer } from 'react-virtualized';
import 'react-virtualized/styles.css'; // only needs to be imported once

// // Table data as an array of objects
// const list = [
//   { name: 'Brian Vaughn', description: 'Software engineer' }
//   // And so on...
// ];

// Render your table

export default class SampleTable extends React.Component {
  render(){
    const {list} = this.props;
    return (
      <AutoSizer disableHeight>
        {({ width }) => (
        <Table
          width={width}
          height={700}
          headerHeight={60}
          rowHeight={50}
          rowCount={list.length}
          rowGetter={({ index }) => list[index].attributes}
        >
        <Column 
        height={30}
        width={45}
        dataKey={'code'}
        className={'dontoverflow'}
        
        cellRenderer={
            ({ cellData, rowIndex, dataKey }) => (
              this.props.recordIsSetted(list[rowIndex].attributes.code)?
              <div className={'selected'}></div>
              : <span class="radio" >
                <label for="company_category_group_type">
                  <input
                    onChange={e => this.props.toggleRecordSelected(e.target.value)}
                    checked={this.props.isRecordSelected(cellData)}
                    class="radio_buttons optional"
                    type="checkbox"
                    value={cellData}
                    name="pattern_param"
                  />
                </label>
              </span>
              )
            }/>
        <Column
        width={width/8} 
        label="code" 
        dataKey='code'
        cellRenderer={
          ({ cellData, rowIndex, dataKey }) => (
            this.props.recordIsSetted(list[rowIndex].attributes.code) ?
            <div className={'selected'}>
              {cellData}
            </div>
            :
            <React.Fragment>
              {cellData}
            </React.Fragment>


          )
        }
        />

        <Column
        width={width/4} 
        label="description" 
        dataKey='description'
        cellRenderer={
          ({ cellData, rowIndex, dataKey }) => (
            this.props.recordIsSetted(list[rowIndex].attributes.code) ?
            <div className={'selected'}>
              {cellData}
            </div>
            :
            <React.Fragment>
              {cellData}
            </React.Fragment>


          )
        }
        />

        <Column
        width={width/8} 
        label="saldo_anterior" 
        dataKey='saldo_anterior'
        cellRenderer={
          ({ cellData, rowIndex, dataKey }) => (
            this.props.recordIsSetted(list[rowIndex].attributes.code) ?
            <div className={'selected'}>
              Destino: {this.props.configuredPattern[list[rowIndex].attributes.code]}
            </div>
            :
            <React.Fragment>
              {cellData}
            </React.Fragment>


          )
        }
        />

        <Column
        width={width/8} 
        label="debito" 
        dataKey='debito'
        cellRenderer={
          ({ cellData, rowIndex, dataKey }) => (
            this.props.recordIsSetted(list[rowIndex].attributes.code) ?
            <div className={'selected'}>
              
            </div>
            :
            <React.Fragment>
              {cellData}
            </React.Fragment>


          )
        }
        />

        <Column
        width={width/8} 
        label="credito" 
        dataKey='credito'
        cellRenderer={
          ({ cellData, rowIndex, dataKey }) => (
            this.props.recordIsSetted(list[rowIndex].attributes.code) ?
            <div className={'selected'}>
              
            </div>
            :
            <React.Fragment>
              {cellData}
            </React.Fragment>


          )
        }
        />

        <Column
        width={width/9} 
        label="saldo_atual" 
        dataKey='saldo_atual'
        cellRenderer={
          ({ cellData, rowIndex, dataKey }) => (
            this.props.recordIsSetted(list[rowIndex].attributes.code) ?
            <div className={'selected'}>
              <div 
                onClick={()=> this.props.removeRecord(list[rowIndex].attributes.code)}
                style={{borderRadius: 8,
                        backgroundColor: "white",
                        width: 80,
                        textAlign: "center",
                        margin: 10,
                        cursor: 'pointer',
                        color: '#31b7bc',
                        textTransform: 'lowercase'}}>
                remover
              </div>
            </div>
            :
            <React.Fragment>
              {cellData}
            </React.Fragment>


          )
        }
        />

        </Table>
        )}
      </AutoSizer>
      
    )
  }
}