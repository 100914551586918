import React, {useEffect} from "react";
import { PopUp } from "../popUp";
import {
  Cell,
  Container,
  Content,
  HeaderCell,
  Row,
  TableCaption,
  TableContainer,
} from "./styles";
import logoCoruja from 'coruja.png';


export function Table({
  parameters,
  formulas,
  formulasMappedById,
  report,
  monthLimit,
  totalReport,
  logoCompany
}) {

  useEffect(()=>{

    console.log("PARAMETERS >>>>",parameters)
    try{
      adjustRowHeights()
    }catch(e){}
  })

  function adjustRowHeights() {
    const table1 = document.getElementById('table-mensal');
    const table2 = document.getElementById('table-total');

    const rows1 = table1.getElementsByTagName('tr');
    const rows2 = table2.getElementsByTagName('tr');

    if (rows1.length !== rows2.length) {
      console.error('As duas tabelas devem ter o mesmo número de linhas');
      return;
    }
    
    for (let i = 0; i < rows1.length; i++) {
      const rowHeight1 = rows1[i].clientHeight;
      const rowHeight2 = rows2[i].clientHeight;
      const maxHeight = Math.max(rowHeight1, rowHeight2);

      rows1[i].style.height = `${maxHeight}px`;
      rows2[i].style.height = `${maxHeight}px`;
    }
  }

  const tryEval = (str) => {
    try {
      let formulaSolved = eval(str);
      return formulaSolved ? formulaSolved : 0;
    } catch (e) {
      return 0;
    }
  };

  const percentFormat = (number) => {
    return `${parseFloat(number).toFixed(2)}%`;
  };

  const numberToCurrency = (number = 0) => {
    if (
      String(parseFloat(number)) == "NaN" ||
      parseFloat(number) == undefined
    ) {
      number = 0;
    }

    return parseFloat(number * -1).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
  };

  const getNumberFormatted = (value, format) => {
    let result = value;
    if (format == "percentage") {
      result = percentFormat(value);
    }
    if (format == "integer") {
      result = new Intl.NumberFormat("decimal", {
        minimumFractionDigits: 2,
      }).format(parseFloat(value));
    }
    if (!format || format == "money") {
      result = numberToCurrency(value * -1);
    }

    return result;
  };

  const comparationPercentage = (analisado, comparado, numberFormat) =>{
    try {
        let value = 0
        if (numberFormat == "percentage") {
            value = ((analisado.toFixed(2) - comparado.toFixed(2)))
        } else {
            value = ((analisado.toFixed(2) / comparado.toFixed(2)) * 100) - 100
        }
        if (String(value) != "NaN") {
            let fValue = new Intl.NumberFormat('decimal', { minimumFractionDigits: 2 }).format(parseFloat(value).toFixed(2))
            if (numberFormat == "percentage") {
                if (analisado <= 0 && comparado <= 0) {
                    fValue = parseFloat(fValue) * -1
                }
                // fValue = new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(value * -1).toFixed(2))
                return `${fValue} pp`.replace('.', ',')
            } else {
                return `${fValue}%`.replace('.', ',')
            }

        } else {
            return "-"
        }

    } catch (e) {
        console.log(e)
        return "-"
    }

  }

  return (
    <Container>
      {(parameters?.title || parameters?.observation) && (
        <>
          <div style={{background: 'white', borderBottom: 'solid 1px #e5e1e1', width: '100%'}}>
            <div style={{display: 'flex'}}>
              <div style={{display: 'flex', width: '100%'}}>
                <img src={logoCompany} className="logo" style={{width: '100px', margin: 10}}></img>
                <div style={{border: 'solid 15px white'}}></div>
                <span style={{paddingTop: '3%', fontSize: '24px', width: '50%'}}>{parameters?.title}</span>
                {parameters?.observation && <PopUp content={parameters.observation} />}
              </div>
            </div>
          </div>
        </>
      )}
      <Content>
        <TableContainer id={'table-mensal'} style={{ flex: 2 }}>
          <TableCaption></TableCaption>
          <thead>
            {parameters?.period && (
              <tr>
                <th></th>
                {[
                  ...new Array(
                    parseInt(parameters?.period === "6" ? "3" : parameters?.period)
                  ),
                ].map((v, index) => {
                  let month = index + parseInt(parameters?.first_month || 0);
                  return (
                    <HeaderCell>
                      {monthsDict[month]}/{parameters?.year || 2020}
                    </HeaderCell>
                  );
                })}
              </tr>
            )}
          </thead>
          <tbody>
            {formulas?.map((formula) => {
              let label = formulasMappedById[formula?.value]?.label;
              let numberFormat = formulasMappedById[formula?.value]?.number_format;

              const formulaMapped = formulasMappedById[formula?.value];
             
              //pegar o tipo da formula
              let monthValues = [];
              try{
                monthValues = [
                  ...new Array(parseInt(parameters?.period === "6" ? "3" : parameters?.period)),
                ].map((v, index) => {
                  let month = index + parseInt(parameters?.first_month || 0);
                  return (
                    tryEval(
                      report?.[parameters?.year || 0]?.report?.[label]?.[label]
                        ?.result?.[monthsDict[month]]
                    ) 
                  );
                });
              }catch(e){

              }
              

              return (
                <Row className={`${formulaMapped?.layout}-${formulaMapped?.formula_type}`} formulaStyle={formulaMapped?.layout} formulaType={formulaMapped?.formula_type}>
                  <Cell>{label}</Cell>
                  {monthValues.map((monthValue) => {
                    return (
                      <Cell>
                        {getNumberFormatted(monthValue, numberFormat)}
                      </Cell>
                    );
                  })}
                </Row>
              );
            })}
          </tbody>
        </TableContainer>
        {formulas?.length > 0 && (
          <TableContainer id={'table-total'} style={{ flex: 1 }}>
            <TableCaption hasBorder>Acumulado</TableCaption>
            <thead>
              <tr>
                <HeaderCell>{parameters?.year}</HeaderCell>
                {parameters?.show_budget && <HeaderCell>Orçado {parameters?.year}</HeaderCell>}
                <HeaderCell>{parameters?.year - 1}</HeaderCell>
                {parameters?.show_budget &&  <HeaderCell title={"% Orçado"}>% Orç.</HeaderCell>}
                <HeaderCell title={"% Comparado"}>% Comp.</HeaderCell>
                
              </tr>
            </thead>
            <tbody>
              {formulas?.map((formula) => {
                let label = formulasMappedById[formula?.value]?.label;
                let numberFormat = formulasMappedById[formula?.value]?.number_format;

                
                let currentYear = tryEval(
                  totalReport?.[parameters?.year || 0]?.[formula.value]
                );
                let yearPast = tryEval(
                  totalReport?.[String((parameters?.year || 0) - 1)]?.[
                    formula.value
                  ]
                );

                let budgetCurrentYear = tryEval(
                  totalReport?.[`${parameters?.year}_budget` || 0]?.[formula.value]
                );
                
                let comparadoPercentage = comparationPercentage(currentYear, yearPast, numberFormat)
                let orcadoPercentage = comparationPercentage(currentYear, budgetCurrentYear, numberFormat)
                
                const formulaMapped = formulasMappedById[formula?.value];
                let style =
                  formulaMapped?.formula_type === "grouped"
                    ? "grouped"
                    : formulaMapped?.layout;

                return (
                  <Row className={`${formulaMapped?.layout}-${formulaMapped?.formula_type}`} formulaStyle={formulaMapped?.layout} formulaType={formulaMapped?.formula_type}>
                    <Cell style={{textAlign: 'right'}}>{getNumberFormatted(currentYear * -1, numberFormat)}</Cell>
                    {parameters?.show_budget && <Cell>{getNumberFormatted(budgetCurrentYear * -1, numberFormat)}</Cell>}
                    <Cell>{getNumberFormatted(yearPast * -1, numberFormat)}</Cell>
                    {parameters?.show_budget && <Cell>{orcadoPercentage}</Cell>}
                    <Cell>{comparadoPercentage}</Cell>
                  </Row>
                );
              })}
            </tbody>
          </TableContainer>
        )}
      </Content>
    </Container>
  );
}

const monthsDict = {
  1: "janeiro",
  2: "fevereiro",
  3: "março",
  4: "abril",
  5: "maio",
  6: "junho",
  7: "julho",
  8: "agosto",
  9: "setembro",
  10: "outubro",
  11: "novembro",
  12: "dezembro",
};
