const styles = {
  bgContent: {
    width: '100%',
    height: '100%',
    background: '#FFFFFF',
    border: '0.5px solid #E5E5E5',
    boxShadow: '2px 2px 10px rgba(0, 0, 0, 0.1)',
    borderRadius: '5px',
    padding: '0px',
    boxSizing: 'border-box',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '15px 20px',
    background: '#E5E5E5',
    borderRadius: '5px 5px 0 0'
  },
  bottomPreview: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '15px 20px',
    background: '#F4A837',
    height: '40px',
    borderRadius: '0px 0 5px 5px'
  },
  contentBetween: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    padding: '15px 20px'
  },
  blockTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '18px',
    color: '#494949',
    fontWeight: 'bold',
  },
  icon: {
    fontSize: '24px',
    marginRight: '5px',
  },
  searchInput: {
    height: '40px',
    borderRadius: '6px',
    border: '0.5px solid #ACACAC',
    padding: '0 10px',
    flex: 1,
    marginLeft: '20px',
    boxSizing: 'border-box',
  },
  tabs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    height: '50px'
  },
  tab: {
    flex: 1,
    textAlign: 'center',
    background: '#F5F5F5',
    border: '0.5px solid #ACACAC',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '12px',
    color: '#676767',
    padding: '10px 0',
    boxSizing: 'border-box',
    cursor: 'pointer',
    borderRadius: '5px'
  },
  tabActive: {
    background: '#1598D3',
    color: '#FFFFFF',
  },
  listItem: {
    padding: '10px 20px',
    borderBottom: '1px solid #E5E5E5',
    display: 'flex',
    justifyContent: 'space-between',
  },
  dataContainer: {
    maxHeight: '600px',
    overflowY: 'auto',
    fontSize: '16px',
  },
};
export default styles;