import React from "react";
import LoadingDialog from './../LoadingDialog'
import Comment from './../Comment'
import ResultChart from './../ResultChart'
import MonthFilter from './../MonthFilter'
import { BlueLink, ViewerButton } from '../../styled_components/default';
import PopHover from './../PopHover'
import popupIco from 'popup-ico.svg';
import { popupCenter } from './../popup/popupCenter'
import FormulaLine from "../report/FormulaLine";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from 'react-select/creatable';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import EyeOff from "../svg-icons/EyeOff";
import { EyeOn } from "../svg-icons/EyeOn";
import axios from '../utils/axiosConfig'

class Budget extends React.Component {

  constructor(props) {
    super(props)
    let months = {}

    Object.entries(props.months).map(([key, value]) => {
      if (Object.keys(props.visible_months?.[props.defaultYear] || {}).includes(key)) {
        months[key] = value
      }
      if (props.is_budget) {
        months[key] = value
      }
    })

    this.loading = 0
    this.state = {
      indicators: {},
      formulasVisible: true,
      primary_budget_version_branch_id: null,
      formulasMappedByLabel: {},
      currentReplacementVersion: null,
      budget_version_branches: [],
      replacementVersions: [],
      budget_version_branch_id: null,
      mapping: [],
      areaMapping: {},
      budgetRows: [],
      mappingFormulas: [],
      formulasTotalByYear: {},
      areasDict: {},
      allYearsData: {},
      showZeroLines: false,
      formulas: [],
      report: [],
      labels: [],
      years: [],
      year: this.props.defaultYear,
      recordsFetched: {},
      cost_center_mode: false,
      costCentersFetched: {},
      costCenterAreas: [],
      despesasType: {},
      costCenterRecordsGrouped: {},
      activeAreaType: {},
      activeKeys: {},
      formulasTotal: {},
      loading: 0,
      budgetRowsReplacements: {},
      visibleMonths: props.visible_months[props.defaultYear] || {
        janeiro: true,
        fevereiro: true,
        março: true,
        abril: true,
        maio: true,
        junho: true,
        julho: true,
        agosto: true,
        setembro: true,
        outubro: true,
        novembro: true,
        dezembro: true,
      },
      months: months


    }
  }

  tryEval(str) {
    try {
      let formulaSolved = eval(str)
      return formulaSolved ? formulaSolved : 0;
    } catch (e) {
      return 0
    }
  }

  async setParamMapping() {
    let mapping = await axios.get('/data/contabil_pattern_param_map.json')

    this.setState({ mapping: mapping.data })
  }

  async setBudgetReplacements(replacementVersion = null) {
    try {
      let parameter = this.state.budget_version_branch_id
      // fetch replacements that using parameter current_version_branch url: 'budget_replacements/:current_year' passing parameter by axios
      let replacements = await axios.get(`/budget_replacements/${this.state.year}.json`, {
        params: {
          budget_version_branch_id: parameter,
          version: replacementVersion
        }
      })
      let branch_version_id = this.state.budget_version_branch_id || this.state.primary_budget_version_branch_id


      this.setState({
        budgetRowsReplacements: replacements.data?.replacements,
        currentReplacementVersion: replacements.data?.version,
        replacementVersions: replacements.data?.versions?.[this.state.year]?.[branch_version_id] || []
      })
    } catch (e) {
      console.log(e)

    }
  }

  async setAreaMapping() {
    let mapping = await axios.get('/data/area_map.json')

    this.setState({ areaMapping: mapping.data })
  }
  async setIndicators() {
    let mapping = await axios.get('/data/indicators.json')

    this.setState({ indicators: mapping.data })
  }

  async fetchBudgetRows() {
    let budgetRows = await axios.get(`/data/budget_rows/${this.props.year}.json`)

    this.setState({ budgetRows: budgetRows.data.budget_rows, budget_rows_by_cost_center_code: budgetRows.data.budget_rows_by_cost_center_code })
  }

  async fetchFormulas() {
    let mappingFormulas = await axios.get('/data/formulas.json')
    let formulasMappedByLabel = {}
    mappingFormulas.data.map((o) => {
      formulasMappedByLabel[o.label] = o
    })
    this.setState({ mappingFormulas: mappingFormulas.data, formulasMappedByLabel })
  }

  async setComments(year) {

    let comments = await axios.get('/comments.json', {
      params: {
        year
      }
    })


    this.setState({ comments: comments.data })
  }

  async setYears() {
    const { year } = this.state;
    let years = {}
    if (this.props.is_budget) {
      years = await axios.get('/budgets/years.json')
    } else {
      years = await axios.get('/balance_sheets/years.json')
    }

    this.setState({ years: years.data, year: (year || years.data[years.data.length - 1]) })
  }

  async fetchCostCenterData(year = null) {
    const { is_budget } = this.props;
    this.loading += 1
    let result = await axios.get('/results/cost_centers.json', {
      params: {
        year,
        is_budget
      }
    })

    const { data } = result
    this.loading -= 1
    this.setState({
      costCenterRecordsGrouped: data
    })
  }

  async fetchData(year = null, loadUniqueData = true) {
    const { costCenterMode, is_budget } = this.props;
    this.loading += 1
    let url = costCenterMode ? '/results/dre_cc.json' : '/results/dre.json'
    let result = await axios.get(url, {
      params: {
        year,
        is_budget
      }
    })
    const { formulas, report, labels, areas, cost_center_grouped_by_month } = result.data

    if (loadUniqueData) {
      this.setState({
        formulas,
        report,
        labels,
        costCenterAreas: areas,
        recordsFetched: {},
        costCenterGroupedByMonth: cost_center_grouped_by_month
      })
    }

    this.loading -= 1

    this.setState((oldState) => {
      oldState.allYearsData[year] = {
        formulas,
        report,
        labels,
        costCenterAreas: areas,
        recordsFetched: {},
        costCenterGroupedByMonth: cost_center_grouped_by_month
      }
      return { ...oldState }
    })
  }

  async componentDidMount() {
    let x = await this.setYears()
    this.fetchBudgetVersionBranches()
    this.setComments(this.state.year);
    this.fetchCostCenterData(this.state.year)
    // this.fetchData(this.state.year)
    // this.fetchDataForRange(this.state.year)
    this.setParamMapping();
    this.setBudgetReplacements();
    this.setAreaMapping();
    this.setIndicators();
    this.fetchBudgetRows();
    this.fetchFormulas();
    this.fetchAreas()
    this.changeVisibleMonths()

  }

  async fetchAreaData(key, fixed, type_id, type_label) {

    const { year, costCentersFetched } = this.state;
    const { cost_center_heads, is_budget } = this.props;
    let fixed_label = Boolean(fixed) ? "fixed" : "not_fixed"

    Object.entries(cost_center_heads).map(([id, label]) => {
      if (!costCentersFetched[label]) {
        costCentersFetched[label] = {}
      }
    })

    if (costCentersFetched[type_label][key] && costCentersFetched[type_label][key][fixed_label]) {
      this.setState((oldState) => {
        oldState.costCentersFetched[type_label][key][fixed_label] = null
        return { ...oldState }
      })
    } else {
      let details = await axios.get(`/results/${key}/cost_center_detail.json`, {
        params: {
          year: year,
          fixed: Boolean(fixed),
          type: type_id,
          is_budget,
        }
      })

      this.setState((oldState) => {
        if (!oldState.costCentersFetched[type_label][key]) { oldState.costCentersFetched[type_label][key] = {} }

        oldState.costCentersFetched[type_label][key][Object.keys(details.data)[0]] = Object.values(details.data)[0]
        return { ...oldState }
      })
    }

  }

  async toggleAreaType(key, fixed, type_id, type_label) {

    this.setState((oldState) => {
      oldState.activeAreaType[`${key},${fixed}`] = oldState.activeAreaType[`${key},${fixed}`] ? oldState.activeAreaType[`${key},${fixed}`] : {}
      oldState.activeAreaType[`${key},${fixed}`][`${type_id}-${type_label}`] = !oldState.activeAreaType[`${key},${fixed}`][`${type_id}-${type_label}`]
      return { ...oldState }
    })

  }

  formulaIsGrouped(key) {
    const { formulas } = this.state

    return formulas[key].formula_type == "grouped"

  }

  setupAllYearsData() {
    let { years } = this.state;
    years.map((year) => {
      this.loadYear(year, false)
    })
  }

  loadYear(year, loadUniqueData = false) {
    this.fetchData(year, loadUniqueData)
    this.fetchDataForRange(year, false)


  }

  defineDefaultVisibleMonths(year) {
    let months = {}
    Object.entries(this.props.months).map(([key, value]) => {
      if (Object.keys(this.props.visible_months[year]).includes(key)) {
        months[key] = value
      }
    })

    this.setState({ months, visibleMonths: this.props.visible_months[year] });
  }

  changeYear(year) {
    this.setState({ year })
    this.props.changeYear(year)
    this.fetchData(year)
    this.fetchCostCenterData(year)
    this.setComments(year)
    this.setBudgetReplacements();
    this.setState({
      costCentersFetched: {},
      despesasType: {}
    })
    this.fetchDataForRange(year)
    this.defineDefaultVisibleMonths(year)

  }

  renderLine(line, options = {}, level = 1) {

    const { months, showZeroLines } = this.state;
    let totalLine = 0
    Object.entries(months).map(([name, number]) => {
      if (parseFloat(line.months[name]).toString() != "NaN") {
        totalLine += parseFloat(line.months[name])
      }
    })
    if (!showZeroLines && totalLine == 0) {
      return <></>
    }

    return <tr className={"account-line"}>
      <td>
        <div style={{ paddingLeft: level * 10 }}>{line.label}</div>
      </td>
      {Object.entries(months).map(([name, number]) => {
        return (
          <td className={'comment-wrapper'}>
            {this.state.comments && <Comment
              refreshComments={() => this.setComments(this.state.year)}
              comments={this.state.comments}
              model_reference={'parameter_lines'}
              month={number}
              monthName={name}
              year={this.state.year}
              model_reference_id={line.label}>
            </Comment>}
            {line.months[name] ? (parseFloat(line.months[name]) * - 1).toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL"
            }) : "-"}
          </td>
        );
      })}
      <td className={'comment-wrapper flex-chart'}>
        {this.state.comments && <Comment
          refreshComments={() => this.setComments(this.state.year)}
          comments={this.state.comments}
          model_reference={'parameter_lines'}
          month={"total"}
          monthName={name}
          year={this.state.year}
          model_reference_id={line.label}>
        </Comment>}
        <p>
          {(totalLine * -1).toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL"
          })}
        </p>
        <ResultChart
          loadAllYears={() => { this.setupAllYearsData() }}
          loadYear={(year) => this.loadYear(year)}
          years={this.state.years}
          year={this.state.year}
          title={line.label}
          data={this.dataForFormula(options["p_value"])}
          param={options["param"]}
          p_key={options["p_key"]}
          line_key={options["line_key"]}
          dataKey={"param-lines"}
          formula_id={options["key"]}
          months={this.props.months}
          allYearsData={this.state.allYearsData}
        />

      </td>

    </tr>
  }


  costCenterGroupedBySpendType(despesa_id, fixed, despesa_type_label, month) {
    const { months } = this.props;
    const { costCentersFetched } = this.state;

    let fixed_label = "not_fixed"
    if (fixed) {
      fixed_label = "fixed"
    }

    let rows = null;
    let result = 0
    try {

      rows = costCentersFetched[despesa_type_label][despesa_id][fixed_label]


      Object.values(rows).map((despesa) => {
        if (String(parseFloat(despesa.months[month])) != "NaN") {
          result += parseFloat(despesa.months[month])
        }

      })

    } catch (e) {

    }



    return result
  }

  renderCostCenterRows(despesa_id, fixed, despesa_type_label) {
    const { months, showZeroLines } = this.state;
    const { costCentersFetched } = this.state;

    let numberToCurrency = function (number = 0) {
      if (String(parseFloat(number)) == "NaN" || parseFloat(number) == undefined) {
        number = 0
      }

      return parseFloat(number * -1).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });
    };

    let fixed_label = "not_fixed"
    if (fixed) {
      fixed_label = "fixed"
    }

    let rows = null;

    try {

      rows = costCentersFetched[despesa_type_label][despesa_id][fixed_label]

    } catch (e) {

    }
    let sum = {}
    {
      rows && Object.entries(rows).map(([key, data]) => {
        {
          Object.entries(months).map(([name, number]) => {
            sum[key] ||= 0
            sum[key] += data.months[number] ? parseFloat(data.months[number]) : 0
          })
        }
      })
    }



    return <React.Fragment>
      {rows && Object.entries(rows).map(([key, data]) => {
        if (!showZeroLines && sum[key] == 0) {
          return <></>
        }
        return <tr className={'cost-center-row'}>
          <td style={{ textIndent: 32 }}>{key}-{data.label}</td>
          {Object.entries(months).map(([name, number]) => {
            return (
              <td className={'cost-center-type-column'}>
                {numberToCurrency(data.months[number]) || "-"}
              </td>
            );
          })}
          <td className={'cost-center-type-total'}>{numberToCurrency(sum[key])}</td>
        </tr>
      })}

    </React.Fragment>

  }
  numberToCurrency(number = 0) {
    if (String(parseFloat(number)) == "NaN" || parseFloat(number) == undefined) {
      number = 0
    }

    return parseFloat(number * -1).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL"
    });
  }

  renderRow(row, options = {}, level = 1, key = null) {

    let childrenLines = (entry, month_name) => {
      try {
        let initial = Object.values(entry.lines).map((x) => x.months[month_name]).filter((a) => parseFloat(a).toString() != "NaN").reduce((a, b) => parseFloat(a) + parseFloat(b), 0)

        if (Object.values(entry.childreen).length > 0) {
          Object.values(entry.childreen).map((child) => {
            initial += childrenLines(child, month_name)
          })
        }

        return initial
      }
      catch (e) {
        console.log("ERRO AQUI", e)
        return 0
      }

    }

    const { months } = this.state;
    const { costCentersFetched, activeKeys } = this.state;
    let sum = 0



    return <React.Fragment>
      {!row.months ?
        <tr className="account-number-row">
          <td ><div
            onClick={() => {

              this.setState((oldState) => {
                if (row.label) {

                  oldState.activeKeys[key] = oldState.activeKeys[key] ? oldState.activeKeys[key] : {}
                  oldState.activeKeys[key][row.label] = oldState.activeKeys[key][row.label] ? null : {}
                } else {
                  oldState.activeKeys[key] = oldState.activeKeys[key] ? null : {}
                }

                return { ...oldState }
              })
            }}
            style={{ paddingLeft: level * 10 }}>
            {row.label}

          </div>
          </td>
          {Object.entries(months).map(([name, number]) => {
            let monthValue = childrenLines(row, name)
            if (monthValue.toString() != "NaN") {
              sum += monthValue
            }

            console.log(sum)
            return (
              <td>
                {this.numberToCurrency(monthValue)}

              </td>
            );
          })}
          <td className={'line-total flex-chart'} style={{ justifyContent: 'flex-end' }}>
            <div style={{ marginRight: 10 }}>{this.numberToCurrency(sum)}</div>
            <ResultChart
              loadAllYears={() => { this.setupAllYearsData() }}
              loadYear={(year) => this.loadYear(year)}
              years={this.state.years}
              year={this.state.year}
              title={row.label}
              data={this.dataForFormula(options["p_value"])}
              param={options["param"]}
              p_key={options["p_key"]}
              line_key={options["line_key"]}
              dataKey={"param-head"}
              formula_id={options["key"]}
              months={this.props.months}
              allYearsData={this.state.allYearsData}
            />
          </td>
        </tr>
        :
        this.renderLine(row, options, level + 1)
      }


      {activeKeys[key] && row.childreen && Object.entries(row.childreen).map(([child_key, child]) => {

        return this.renderRow(child, { ...options, line_key: child_key }, level + 1)
      })}
      {(activeKeys[key] && activeKeys[key][row.label]) && row.lines && Object.keys(row.childreen).length < 1 && Object.entries(row.lines).map(([line_key, line]) => {

        return this.renderLine(line, { ...options, line_key }, level + 1)
      })}
    </React.Fragment>
  }

  renderChildreenFor(key = null, options) {

    let data = this.state.recordsFetched[key]
    if (data) {
      return (<React.Fragment>
        {Object.entries(data).map(([row_id, row]) => (this.renderRow(row, { ...options, line_key: row_id }, 0, key)))}
      </React.Fragment>)
    }
  }

  async fetchDetail(key) {
    const { year, recordsFetched } = this.state;
    const { cost_center_heads, is_budget } = this.props;

    if (recordsFetched[key]) {
      this.setState((oldState) => {
        oldState.activeKeys[key] = {}
        oldState.recordsFetched[key] = null
        return { ...oldState }
      })
    } else {
      let details = await axios.get(`/results/${key}/detail.json`, {
        params: {
          year: year,
          cost_center_mode: this.state.cost_center_mode && cost_center_heads[key],
          is_budget
        }
      })

      this.setState((oldState) => {

        if (Object.values(Object.values(details.data)[0].childreen).length > 0) {
          oldState.recordsFetched[key] = Object.values(details.data)[0].childreen
        } else {
          oldState.recordsFetched[key] = Object.values(details.data)[0].lines
        }

        return { ...oldState }
      })
    }

  }
  async fetchDataForRange(year, loadUniqueData = true) {
    const { is_budget, scope } = this.props;
    this.loading += 1
    let report = await axios.get('/formulas/borboleta.json', {
      params: {
        scope,
        range: {
          start_at: (new Date(`01/01/${year}`)),
          end_at: (new Date(`12/12/${year}`))
        },
        is_budget
      }
    })
    this.loading -= 1
    if (loadUniqueData) {
      this.setState({
        formulasTotal: report.data
      })
    }


    this.setState((oldState) => {
      oldState.formulasTotalByYear[year] = report.data
      return { ...oldState }
    })
  }

  paramLabel(value, param) {
    let label;
    if (param == "difference") {
      label = <label title={"diferença"} style={{ color: "#232D6A" }}>dif</label>
    }
    if (param == "f_saldo_atual") {
      label = <label title={"saldo atual"} style={{ color: "#3FABAE" }}>sat</label>
    }
    if (param == "f_saldo_anterior") {
      label = <label title={"saldo anterior"} style={{ color: "#EFBF6A" }}>san</label>
    }

    // return <div>{value} {label}</div>
    return <div>{value}</div>
  }

  toggleDespesasType(key) {
    let { cost_center_heads } = this.props;
    let [area_id, is_fixed] = key

    Object.entries(cost_center_heads).map(([despesa_type_id, label]) => {
      this.fetchAreaData(area_id, is_fixed, despesa_type_id, label)
    })

    this.setState((oldState) => {
      oldState.despesasType[key] = !oldState.despesasType[key]
      if (!oldState.despesasType[key]) {
        this.state.activeAreaType[`${key}`] = {}
      }

      return { ...oldState }
    })
  }


  renderCostCenterAreas(number_format = "money") {
    const { months, cost_center_heads } = this.props;
    const { costCentersFetched, costCenterAreas, despesasType, costCenterGroupedByMonth } = this.state;

    let areaByMonth = (b_fixed, despesa_id, number) => {

      try {
        let value = costCenterGroupedByMonth[b_fixed ? "fixed" : "not_fixed"][`[${despesa_id}, ${number}]`]

        return value

      } catch (e) {

      }

    }


    let lines = Object.entries(costCenterAreas).map(([key, value]) => {
      const [fixed, despesa_id] = JSON.parse(key);
      let b_fixed = Boolean(fixed)
      //this.fetchAreaData(id, fixed)
      return <React.Fragment><tr>
        <td ><div onClick={() => this.toggleDespesasType([despesa_id, b_fixed])} >
          <i className={`pull-left fa fa-chevron-${despesasType[[despesa_id, b_fixed]] ? 'down' : 'right'}`} />
          {value.label}</div></td>
        {Object.entries(months).map(([name, number]) => {

          return (
            <td className={'comment-wrapper'}>

              {areaByMonth(b_fixed, despesa_id, number)}
            </td>
          );
        })}
        <td>

        </td>
      </tr>
        {despesasType[[despesa_id, b_fixed]] && Object.entries(cost_center_heads).map(([despesa_type_id, label]) => {
          let exist = false
          try {
            exist = !!costCentersFetched[label][despesa_id][b_fixed ? "fixed" : "not_fixed"]

          } catch (e) {

          }

          return <React.Fragment>
            <tr>
              <td style={{ textIndent: 16 }} onClick={() => this.fetchAreaData(despesa_id, b_fixed, despesa_type_id, label)} >
                <i className={`pull-left fa fa-chevron-${exist ? 'down' : 'right'}`} />
                {label}
              </td>
            </tr>
            {this.renderCostCenterRows(despesa_id, b_fixed, label)}
          </React.Fragment>

        })}
      </React.Fragment>

    })

    return <React.Fragment>{lines}</React.Fragment>

  }

  percentFormat(number) {
    return `${parseFloat(number).toFixed(2)}%`
  }

  renderCostCenterParams(report, number_format = "money") {

    let { despesasType } = this.state;

    let { cost_center_heads } = this.props;
    let numberToCurrency = function (number = 0, number_format = number_format) {
      if (String(parseFloat(number)) == "NaN" || parseFloat(number) == undefined) {
        number = 0
      }
      if (number_format == "percentage") {
        this.percentFormat(parseFloat(number * -1))
      }
      if (number_format == "integer") {
        return new Intl.NumberFormat('decimal', { minimumFractionDigits: 2 }).format(parseFloat(number * -1))
      }

      if (!number_format || number_format == "money") {
        return parseFloat(number * -1).toLocaleString("pt-BR", {
          style: "currency",
          currency: "BRL"
        });
      }
    };
    const { months } = this.state;
    const { costCenterRecordsGrouped, costCenterGroupedByMonth, areasDict } = this.state;

    return <React.Fragment>
      {report.cost_center_params.ids.map((id) => {

        let total = 0
        return <React.Fragment>{!this.isCostCenterZero(id) && <tr>
          <td onClick={() => this.toggleDespesasType([id[0].area.id, id[0].is_fixed])}>

            <i className={`pull-left fa fa-chevron-${despesasType[[id[0].area.id, id[0].is_fixed]] ? 'down' : 'right'}`} />
            {areasDict[id[0].area.id]} - {id[0].is_fixed ? "Fixo" : "Variável"}
          </td>
          {Object.values(months).map(month => {
            let row = parseFloat(costCenterRecordsGrouped[`["${areasDict[id[0].area.id]}", ${id[0].is_fixed}, ${month}]`])

            if (String(row) != "NaN") {
              total += row
            }

            if (costCenterGroupedByMonth[id[0].is_fixed ? "fixed" : "not_fixed"][`[${id[0].area.id}, ${month}]`]) {
              return <td>{numberToCurrency(costCenterGroupedByMonth[id[0].is_fixed ? "fixed" : "not_fixed"][`[${id[0].area.id}, ${month}]`])}</td>
            } else {
              return <td >{numberToCurrency(costCenterRecordsGrouped[`["${id[0].area.label}", ${id[0].is_fixed}, ${month}]`])}</td>
            }

          })}
          <td className={'flex-chart'}>
            <p>{numberToCurrency(total)}</p>
            <ResultChart
              loadAllYears={() => { this.setupAllYearsData() }}
              loadYear={(year) => this.loadYear(year)}
              years={this.state.years}
              year={this.state.year}
              title={id[0].area.label}
              is_fixed={id[0].is_fixed}
              months={months}
              area_id={id[0].area.id}
              areas={true}
              dataKey={"area_header"}
              allYearsData={this.state.allYearsData}
              costCenterRecordsGrouped={costCenterRecordsGrouped}>
            </ResultChart>

          </td>
        </tr>}
          {despesasType[[id[0].area.id, id[0].is_fixed]] && Object.entries(cost_center_heads).map(([despesa_type_id, label]) => {
            let exist = false
            try {
              exist = !!costCentersFetched[label][id[0].area.id][id[0].is_fixed ? "fixed" : "not_fixed"]

            } catch (e) {

            }
            total = 0
            return <React.Fragment>
              {!this.isDespendZero(id, label) && <tr>
                <td style={{ textIndent: 16 }} onClick={() => this.toggleAreaType(id[0].area.id, id[0].is_fixed, despesa_type_id, label)} >
                  <i className={`pull-left fa fa-chevron-${exist ? 'down' : 'right'}`} />
                  {label}
                </td>
                {Object.entries(months).map(([name, number]) => {
                  let value = this.costCenterGroupedBySpendType(id[0].area.id, id[0].is_fixed, label, number)
                  if (String(value) != "NaN") {
                    total += value
                  } else {

                  }
                  return (
                    <td>
                      {numberToCurrency(value)}
                    </td>
                  );
                })}
                <td className={'cost-center-type-total'}>{numberToCurrency(total)}</td>
              </tr>}
              {this.state.activeAreaType[`${id[0].area.id},${id[0].is_fixed}`] && this.state.activeAreaType[`${id[0].area.id},${id[0].is_fixed}`][`${despesa_type_id}-${label}`] && this.renderCostCenterRows(id[0].area.id, id[0].is_fixed, label)}
            </React.Fragment>

          })}
        </React.Fragment>
      })}




    </React.Fragment>


  }

  getNumerFormatted(value, format) {
    let result = value
    if (format == "percentage") {
      result = this.percentFormat(value)
    }
    if (format == "integer") {
      result = new Intl.NumberFormat('decimal', { minimumFractionDigits: 2 }).format(parseFloat(value))
    }
    if (!format || format == "money") {
      result = this.numberToCurrency(value * -1)
    }

    return result
  }

  dataForFormula(result) {
    const { months } = this.props;
    let data = []
    let i = 1
    Object.entries(months).map(([name, number]) => {
      if (i < 12) {
        data.push(this.tryEval(result[name]))
      }
      i += 1
    })
    return data
  }

  getFloat(number) {
    if (parseFloat(number).toLocaleString() == "NaN") {
      return 0
    } else {
      return parseFloat(number)
    }
  }

  async fetchAreas() {

    let result = await axios.get(`/cost_center_areas.json`, {
      params: {
        query: this.state.query
      }
    });

    let hash_data = {}

    result.data.map((area) => {
      hash_data[area.id] = area.label
    })

    this.setState({ areasDict: hash_data })

  }

  isParamZero(p_value, param) {
    if (this.state.showZeroLines) {
      return false
    }

    const { months } = this.props;
    let isZero = true
    Object.entries(months).map(([name, number]) => {

      if (this.getFloat(p_value[name][param]) != 0) {
        isZero = false
      }
    })
    return isZero

  }
  isDespendZero(id, label) {
    if (this.state.showZeroLines) {
      return false
    }

    const { months } = this.props;
    let isZero = true
    Object.entries(months).map(([name, number]) => {
      let row = this.costCenterGroupedBySpendType(id[0].area.id, id[0].is_fixed, label, number)
      if (this.getFloat(row) != 0) {
        isZero = false
      }
    })
    return isZero

  }

  isCostCenterZero(id) {
    if (this.state.showZeroLines) {
      return false
    }

    const { months } = this.props;
    const { costCenterRecordsGrouped, areasDict } = this.state;

    let isZero = true
    Object.values(months).map(month => {

      let row = parseFloat(costCenterRecordsGrouped[`["${areasDict[id[0]?.['area']?.id]}", ${id[0].is_fixed}, ${month}]`])

      if (this.getFloat(row) != 0) {
        isZero = false
      }
    })
    return isZero

  }

  changeVisibleMonths(event) {
    // event.target.name]: event.target.checked
    let { name, checked } = event.target
    let props = this.props;
    this.setState((oldState) => {
      oldState.visibleMonths[name] = checked
      oldState.months = {}
      Object.entries(oldState.visibleMonths).map(([name, checked]) => {
        if (checked) {
          oldState.months[name] = props.months[name]
        }
      })

      return { ...oldState }
    });
  }

  propsSetState(newState) {
    this.props.propsSetState(newState)
  }

  mapFormulasByLabel() {
    mappingFormulas
  }
  //toggle versionModal visibility
  toggleVersionModal() {
    this.setState({
      ['showVersionModal']: !this.state.showVersionModal
    });
  }

  // fetch budget_version_branches using axios and set state with the response
  async fetchBudgetVersionBranches() {
    let result = await axios.get(`/budget_version_branches.json`);

    this.setState({
      budget_version_branches: result.data,
      primary_budget_version_branch_id: result.data[0].id
    });
  }

  // define handleChangeVersion
  handleChangeVersion(event) {
    this.setState({
      budget_version_branch_id: event.target.value
    });
    setTimeout(() => {
      this.setBudgetReplacements();
    }, 100);

    // 
  }

  //render dialog for version
  renderVersionModal() {
    return <Dialog
      open={this.state.showVersionModal}
      onClose={this.toggleVersionModal}
      fullWidth={true}
      maxWidth={"xs"}
    >
      <DialogTitle>
        Em qual ramo deseja salvar a versão?
      </DialogTitle>
      <DialogContent>

        {/* select a budget_version_branch using <select>*/}
        <FormControl>

          <select
            value={this.state.budget_version_branch_id}
            onChange={this.handleChangeVersion.bind(this)}
          >
            {this.state.budget_version_branches.map((budget_version_branch) => {
              return (
                <option key={budget_version_branch.id} value={budget_version_branch.id}>
                  {budget_version_branch.label}
                </option>
              )
            })}
          </select>
        </FormControl>



      </DialogContent>
      <DialogActions>
        <Button onClick={this.saveBudgetReplacements.bind(this)} color="primary">
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  }

  async saveBudgetReplacements() {
    let { year, budgetRowsReplacements } = this.state

    // close modal
    this.toggleVersionModal()

    let result = await axios.post(`/budget_replacements.json`, {
      budget_replacement: {
        year,
        budget_version_branch_id: this.state.budget_version_branch_id || this.state.primary_budget_version_branch_id,
      },
      budget_row_replacements: budgetRowsReplacements
    });
    // fetch budget replacements
    this.setBudgetReplacements()

    alert("Atualização de orçamento concluida")
  }




  updateBudgetRow(account, month, newValue) {
    newValue = parseFloat(newValue)
    if (newValue.toString() == "NaN") {
      newValue = 0
    }
    let newBudgetRows = this.state.budgetRowsReplacements
    newBudgetRows[account] = { ...this.state.budgetRows[account], ...newBudgetRows[account] }
    newBudgetRows[account][month] = newValue
    // this.setState({formulasVisible: false})

    this.setState({ budgetRowsReplacements: newBudgetRows })
    this.forceUpdate();
  }

  render() {
    const { report, labels, formulas, year, years, recordsFetched, cost_center_mode, formulasTotal, showZeroLines, mappingFormulas } = this.state;
    const { months, mapping, budgetRows, areaMapping, formulasMappedByLabel, indicators, budgetRowsReplacements, replacementVersions, currentReplacementVersion } = this.state;




    let numberToCurrency = function (number) {
      return parseFloat(number).toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL"
      });
    };

    return (
      <React.Fragment>
        <div className="header" style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingLeft: 20 }}>
            <div
              title="Abrir em nova janela"
              className={'result-popup-button'}
              onClick={() => popupCenter({ url: `/popups/${this.props.activeTab("dre") ? "dre" : "dre_cc"}`, title: 'Editar', w: window.innerWidth * 0.8, h: window.innerHeight * 0.8 })}
              style={{
                backgroundColor: '#F5F5F5',
                width: 36,
                height: 36,
                borderRadius: 4,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer'
              }}>
              <img src={popupIco} style={{ width: 14 }}></img>
            </div>
            <div className="label" style={{ marginRight: 16 }}>RESULTADO MENSAL</div>


            <MonthFilter changeVisibleMonths={(e) => this.changeVisibleMonths(e)} visibleMonths={this.state.visibleMonths} months={this.props.months}></MonthFilter>

            <ViewerButton title={showZeroLines ? "Mostrar linhas zeradas" : "Ocultar linhas zeradas"} active={showZeroLines} onClick={() => this.setState({ showZeroLines: !showZeroLines })}>
              {showZeroLines ? <EyeOff height={30} width={30} /> : <EyeOn height={30} width={30} />}
            </ViewerButton>
          </div>
          <form className="form flex" style={{ paddingRight: 35, alignItems: 'center', height: '100%' }}>
            {!this.props.is_budget &&
              <React.Fragment>
                <BlueLink
                  bordered
                  active={this.props.activeTab("dre")}
                  onClick={() => this.propsSetState({ activeTab: "dre" })}
                > <div style={{ paddingBottom: this.props.activeTab("dre") ? 19 : 0 }}>DRE</div> </BlueLink>
                <BlueLink
                  bordered
                  active={this.props.activeTab("dre_cc")}
                  onClick={() => this.propsSetState({ activeTab: "dre_cc" })}
                > <div style={{ paddingBottom: this.props.activeTab("dre_cc") ? 19 : 0 }}>DRE - Gerencial</div></BlueLink>
              </React.Fragment>
            }


            <div><BlueLink
              bordered
              onClick={() => this.toggleVersionModal()}
            > <div>Salvar</div></BlueLink></div>

            <div className={'with-date-ico'}>


              <select
                className={'margin-left-20 margin-right-20 '} onChange={(e) => this.changeYear(e.target.value)}>
                {years.map((option_year) => (
                  <option value={option_year} selected={year == option_year}> {option_year} </option>
                ))}


              </select>

            </div>
            <div>
              <label for={"version"}>Versão</label>
              {/* select for budget_version_branches */}
              <select
                value={this.state.budget_version_branch_id}
                onChange={this.handleChangeVersion.bind(this)}
              >
                {this.state.budget_version_branches.map((budget_version_branch) => {
                  return (
                    <option key={budget_version_branch.id} value={budget_version_branch.id}>
                      {budget_version_branch.label}
                    </option>
                  )
                })}
              </select>
              <select
                id={"version"}
                className={'margin-left-20 margin-right-20 '} onChange={(e) => this.setBudgetReplacements(e.target.value)}>
                {Object.keys(replacementVersions || {}).map((version) => (
                  <option value={version} selected={currentReplacementVersion == version}> {version} </option>
                ))}
              </select>
            </div>
          </form>
        </div>
        <div>
          <small style={{ fontSize: 10, padding: "10px 0 0 43px", display: 'block', color: 'grey' }}>* Você está visualizando números no formato n/{this.props.divisor}</small>
        </div>
        <div id="table-result-wrapper">
          <div className="content margin-left-40 margin-right-40 padding-top-40">
            <table id="table-result">
              <thead>
                <tr>
                  <th>Nome</th>
                  {Object.values(months).map(month => (
                    <th>{monthDict[`${month}`.padStart(2, "0")]}/{year}</th>
                  ))}

                  <th>Acumulado</th>
                </tr>
              </thead>

              {mappingFormulas.map((formula) => {
                return Object.keys(indicators).length > 0 && this.state.formulasVisible && (Object.keys(budgetRows).length > 0 && Object.keys(mapping).length > 0) && Object.keys(areaMapping).length > 0 && <FormulaLine
                  originalBudgetRows={budgetRows}
                  budgetRowsReplacements={budgetRowsReplacements}
                  mergedBudgetRows={{ ...budgetRows, ...budgetRowsReplacements }}
                  indicators={indicators}
                  year={year}
                  updateBudgetRow={(account, month, e) => this.updateBudgetRow(account, month, e)}
                  formulas={formulasMappedByLabel}
                  areaMapping={areaMapping}
                  mapping={mapping}
                  months={months}
                  formula={formula} />
              })}
            </table>
          </div>
        </div>
        <LoadingDialog open={this.loading > 0} />
        {this.renderVersionModal()}
      </React.Fragment>
    );
  }
}

export default Budget;
const monthDict = {
  "01": "jan",
  "02": "fev",
  "03": "mar",
  "04": "abr",
  "05": "mai",
  "06": "jun",
  "07": "jul",
  "08": "ago",
  "09": "set",
  "10": "out",
  "11": "nov",
  "12": "dez",
}
