export default class PromiseQueue {
    constructor() {
      this.queue = [];
      this.processing = false;
    }
  
    enqueue(task) {
      this.queue.push(task);
      this.processNext();
    }
  
    async processNext() {
      if (this.processing || this.queue.length === 0) return;
      this.processing = true;
      const task = this.queue.shift();
      await task();
      this.processing = false;
      this.processNext();
    }
  }