import React from 'react';
import ReportSituationDialog from './ReportSituationDialog'

import styled from "styled-components";
export default class ReportSituationWidget extends React.Component {
  state = {
    edit: false,
    modalIsOpen: false,
  }
  componentDidMount(){
    this.fetchText()
  }

  async fetchText(){
    let result = await axios.get(`/companies/report_situation.json`);
    this.setState({reportText: result.data.content})

    if(this.state.reportText && this.state.reportText.length > 0){
      $("#report-text").html(this.state.reportText)  
    }else{
      $("#report-text").html("O campo está vazio")
    }
    
  }
  popupCenter = ({url, title, w, h}) => {
    // Fixes dual-screen position                             Most browsers      Firefox
    const dualScreenLeft = window.screenLeft !==  undefined ? window.screenLeft : window.screenX;
    const dualScreenTop = window.screenTop !==  undefined   ? window.screenTop  : window.screenY;

    const width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
    const height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

    const systemZoom = width / window.screen.availWidth;
    const left = (width - w) / 2 / systemZoom + dualScreenLeft
    const top = (height - h) / 2 / systemZoom + dualScreenTop
    const newWindow = window.open(url, title, 
      `
      scrollbars=yes,
      width=${w / systemZoom}, 
      height=${h / systemZoom}, 
      top=${top}, 
      left=${left}
      `
    )

    if (window.focus) newWindow.focus();
}
  openPopup(){
    this.popupCenter({url: '/report_situation',title: 'Editar', w: window.innerWidth * 0.8, h: window.innerHeight * 0.8})
  }

  handleOpen(){
        this.setState({
            modalIsOpen: true
        })
        
    }
    handleClose(newText=this.state.reportText){
        $("#report-text").html(newText)
        this.setState({modalIsOpen: false})
    }
  render(){
    let {isMobile} = this.props;
      return (
        <>
          {!isMobile && <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Button onClick={()=> this.handleOpen()}>
              <i style={{marginRight: 6}} className={'fa fa-edit'}/> Editar
            </Button>
          
            <Button onClick={()=> this.openPopup()}>
              <i style={{marginRight: 6}} className={'fa fa-eye'}/> Abrir popup
            </Button>
          </div>}
          <div class="report-widget" 
              onMouseEnter={()=>{this.setState({edit: true})}}
              onMouseLeave={()=>{this.setState({edit: false})}}
              >
              
                  
              
              <div style={{width: "100%"}}>
                <div id={"report-text"}></div>
                
              </div>
            

            
            <ReportSituationDialog handleClose={this.handleClose.bind(this)} open={this.state.modalIsOpen} />
          </div>
        </>
      );
    }
}

const Button = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 19px;
  padding: 0 10px; 
  font-size: 12px;
  border-radius: 14px;
  background-color: #acacac;
  color: white;
  margin: 3px;
  cursor: pointer;
`