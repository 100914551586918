import React from "react";
import PropTypes from "prop-types";
import Fuse from "fuse.js";
import {
  Select,
  FlexCenter,
  GreenLabel,
  BlueButton
} from "../../styled_components/default";

class Edit extends React.Component {
  state = {
    stage_text: "Salvar colunas"
  };

  componentDidMount() {
      Object.entries(this.props.sugestions).map(([key, value]) => {
          this.setState({[this.props.default_columns[key]]: value})
      })
  }

  optionsRender(attribute) {
    let sugestionValue = this.props.sugestions[attribute];

    return this.props.sample_data[0].map((key, index) => (
      key && <option selected={index === sugestionValue} value={index}>{key}</option>
    ));
  }

  saveHeaderData() {
    this.setState({ saving: true, stage_text: "Salvando linhas..." });

    axios.post(`/ledgers/${this.props.ledger_id}/configure_columns.json`, {
      columns: this.state
    }).then(result => {
      this.setState({ 
        saving: false, 
        stage_text: result.data.success ? "Redirecionando para configuração..." : this.state.stage_text 
      });
      Turbolinks.visit("/ledgers")
    });
  }

  render() {
    const {sample_data} = this.props;
    return (
      <div>
        <FlexCenter>
          {Object.entries(this.props.default_columns).map(([attribute_name, attribute_label]) => (
            <div key={attribute_name} className={"margin-10"} style={{ flex: 1 }}>
              <GreenLabel>{attribute_name}</GreenLabel>
              <Select
                onChange={e => this.setState({ [attribute_label]: e.target.value })}
              >
                <option value={""}>-- selecione a coluna --</option>
                {this.optionsRender(attribute_name)}
              </Select>
            </div>
          ))}
        </FlexCenter>
        <FlexCenter>
          <BlueButton onClick={() => this.saveHeaderData()}>
            {this.state.stage_text}
          </BlueButton>
        </FlexCenter>

        <table className={'bordered margin-top-20'} >
          <thead>
            <tr>
              <th colSpan={Object.keys(this.props.default_columns).length} style={{textAlign: 'center'}}>Demonstração das colunas escolhidas</th>
            </tr>
          </thead>
          <tr>
            {Object.keys(this.props.default_columns).map(attribute => (
              <th key={attribute}>{attribute}</th>
            ))}
          </tr>
          <tbody>
            {sample_data.map((row, index) => (
              <tr key={index}>
                {Object.values(this.props.default_columns).map((attribute, index) => (
                  <td key={index}>{row[this.state[attribute]] || "-"}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
}

export default Edit;