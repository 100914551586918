import React from "react"
import PropTypes from "prop-types"
import styled from 'styled-components'
import {FlexCenter} from '../styled_components/default'
import {Box, Link, LinkWrapper, Logo, HiddenOptions, HiddenLink, VerticalLine} from '../styled_components/header/box'
import CompanySelect from "./CompanySelect"
import NotificationComponent from "./Notification.js"
import actionCable from 'actioncable';
import SystemSelector from "./system_select/index.js"
const CableApp = {}

CableApp.cable = actionCable.createConsumer('/cable')

class Header extends React.Component {
  state = {}
  async setCurrentCompany(id, url="/"){
    let confirmation = confirm("Você deseja mudar de empresa?")
    if (confirmation) {
      let result = await axios.post(`/set_current_company.json`, {
        company_id: id
      });
      Turbolinks.visit(url)  
    }
    
  }

  async setupCable(){
    let self = this
    CableApp.cable.subscriptions.create({
      channel: 'CompanyChangeChannel'
    },
    {
      received: (data)=>{
        //turbolinks redirect to home
        Turbolinks.visit('/');
      }
    }
    )
  }
  
  componentDidMount(){
    //this.fetchNotifications()
    this.setupCable();

  }

  showNotifications(){
    this.setState({notification_visibility: !this.state.notification_visibility})
    //axios post to mark as visited
    axios.post(`/notifications/opened.json`)
  }

  ellipsisSubstring(str, maxLength){
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "..."
    }else{
      return str
    }
  }

  render () {
    const { picture, companies, user_scope, authorizations, user_name, all_companies } = this.props;
    const {can_manage_company} = authorizations
    const {config_menu_visibility, notification_visibility, notifications, nonReadCount} = this.state;

    return (
      <Box  style={{zIndex: 999}}>
        <SystemSelector
          currentSystem={'fin'}
        ></SystemSelector>
      	
        <VerticalLine/>
        {user_scope == "company_user"  && 
          <CompanySelect 
            currentCompanyId={this.props.currentCompanyId}
            onChange={this.setCurrentCompany.bind(this)}
            options={companies}
            all_options={all_companies}
          />

        }
        <div style={{display: 'flex', justifyContent: 'flex-end', flex: 1, margin: 30}}>
        	<FlexCenter>
            {user_scope == "saga_admin" && <React.Fragment>
              <Link href="/companies">Empresas</Link>
              <Link href="/users">Usuários</Link>
              <Link href="/admins/menu">Configuração Master</Link>
            </React.Fragment>}
        	</FlexCenter>
          <NotificationComponent 
            currentCompanyId={this.props.currentCompanyId} 
            setCurrentCompany={(id, url) => this.setCurrentCompany(id, url)}
          />
          <FlexCenter>
            <VerticalLine/>
            <LinkWrapper style={{zIndex: 999, position: 'relative'}} >
              <img 
                src={picture} 
                style={{width: 50, height: 50, padding: 6, borderRadius: 50, border: '1px #e5e5e5 solid'}}
              />
              <div onClick={()=> this.setState({config_menu_visibility: !config_menu_visibility})}>
                <Link onClick={()=> this.setState({config_menu_visibility: !config_menu_visibility})}>{user_name}</Link>
                <i className={`fa fa-chevron-${config_menu_visibility ? "up" : "down"}`} style={{color: '#676767'}}/>
              </div>
              {config_menu_visibility && <HiddenOptions alignRight={true} top={77}>
                {user_scope == "company_user" && can_manage_company && 
                  <HiddenLink href="/edit_company" >
                    <Icon className={'fa fa-cog'}> </Icon>Configurações
                  </HiddenLink>
                }
                <HiddenLink href="/users/edit?profile=true">
                  <Icon className={'fa fa-user'}> </Icon>Perfil
                </HiddenLink>
                <HiddenLink target={"_blank"} href="https://cleverview.com.br/central-ajuda">
                  <Icon className={'fa fa-user'}> </Icon>Tutorial
                </HiddenLink>
                <HiddenLink href="/users/sign_out" data-method="delete">
                  <Icon className={'fa fa-sign-out'}> </Icon>Sair
                </HiddenLink>
              </HiddenOptions>}
            </LinkWrapper>
          </FlexCenter>
        </div>
      </Box>
      
    );
  }
}

export default Header
const Icon = styled.i`
  margin-right: 10px
`
