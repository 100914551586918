import React, { useRef, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import BarChartIcon from '@material-ui/icons/BarChart';
import TimeLineIcon from '@material-ui/icons/Timeline';
import chartIco from 'chart-ico.svg';
import {numberToCurrency} from './utils/BrlCurrencyComponent'
import { ChartIcon } from './svg-icons/ChartIcon';
import { ChartGrid, ChartGridItem, YearButton, YearsButtons } from '../styled_components/default';
import MemoCartColumnIcon from './svg-icons/CartColumnIcon';
import MemoChartListIcon from './svg-icons/ChartListIcon';
import MemoChartTableIcon from './svg-icons/ChartTableIcon';
import LoadingDialog from './LoadingDialog'
const COLORS = ["#65BCFF", "#F7999C", "#4AB796", "#F4CF98", "#37BAC0", "#6C899E"];

Highcharts.setOptions({
  colors: COLORS
});
const useStyles = makeStyles((theme) => ({
  root: {
    width: 'fit-content',
    border: `none`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: 'transparent',
    color: theme.palette.text.secondary,
    '& svg': {
      margin: theme.spacing(1.5),
    },
    '& hr': {
      margin: theme.spacing(0, 0.5),
    },
  },
}));




export default function BorboletaFormulaChart(props) {
  const totalColumnRef = useRef(null);
  let self = this;
  let requestsCounter = 0;
  const [open, setOpen] = useState(false);
  const [chartStyle, setChartStyle] = useState("line");
  const [lineResults, setLineResults] = useState({});
  const [chartVisible, setChartVisible] = useState(true)
  const [fetched, setFetched] = useState({});
  const [alreadySetup, setAlreadySetup] = useState(false)
  const [totalColumnWidth, setTotalColumnWidth] = useState(0);
  const currentYear =  [...(props.years || [])]?.sort()?.reverse()?.[0] || new Date().getFullYear(); 
  const [loadedYears, setLoadedYears] = React.useState({[currentYear]: true});

  const classes = useStyles();
  let options = {}
  let optionsYTD = {}
  let monthsArray = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', "Jun", "Jul", "Ago", 'Set', 'Out', 'Nov', 'Dez']

  useEffect(() => {
    if (totalColumnRef.current) {
      setTotalColumnWidth(totalColumnRef.current.offsetWidth);
    }
  }, [chartStyle]);

  useEffect(()=>{
    if(open){
      Object.keys(loadedYears)?.map((year)=>{
        if(!props.allYearsData?.[year]?.["analisado_total"]?.report?.[props.formula_id]){
          props.loadYear(year, props.formula_fr_id)
        }
      })
      
    }
  }, [open])


  const formatYear = (year) => {
    if (props.dataKey == "result_dashboard") {
      //switch case year
      switch (year) {
        case "budget_total":
          return "Orçamento " + props.year
        case "current_year":
          return props.year
        case "last_year":
          return props.year - 1
        //end switch case year

        default: return year
      }
    } else {
      return year
    }
  }

  let getChartStyle = () => {
    let response = chartStyle == "table" ? "column" : chartStyle
    return response
  }


  const fetchParamLines = (year) => {
    
    }
 
  const getNumerFormatted = (value, format)=>{
      let result = value
      if(format == "percentage"){
        result = `${parseFloat(value).toFixed(2)}%`
      }
      if(format == "integer"){
        result = new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(value))
      }
      if(!format || format == "money"){
        result = numberToCurrency(value) 
      }
      return result
    }

  const calculateYtd = (year, serie = "analisado_total") => {   
	  let lastMonthUpload = props.lastMonthUpload.getMonth()
    let result = data(dataForFormula(props.allYearsData?.[year]?.[serie]?.report?.[props.formula_id]?.[props.formula_id]?.[props.dataKey]))
	  result = result.slice(0, lastMonthUpload + 1).reduce(function (accumulator, currentValue) {
	  	return accumulator + currentValue;
	  }, 0);
    return result 
  }

  function dataForFormula(result) {
    const { months } = props;
    let data = []
    let i = 1
    Object.entries(months).map(([name, number]) => {
      if (i < 13) {
        data.push(tryEval(result?.[name]))
      }
      i += 1
    })    
    return data
  }


	
  if(props.allYearsData){
    let loadedData = (year, serie) =>{    
      return data(dataForFormula(props.allYearsData?.[year]?.[serie]?.report?.[props.formula_id]?.[props.formula_id]?.[props.dataKey]))	  
    } 

    requestsCounter -= 1

    let serieDict = {
      analisado_total: "Real",
      budget_total: "Orçado"
    }

    const getSeries = () => {
      let dataToLoad = Object.entries(loadedYears).flatMap(([year, x], index) => {
      if(year == currentYear) {
        return ["analisado_total", "budget_total"].map((serie) => {
          return {
            type: getChartStyle(),
            name: `${year} ${serieDict[serie]}`,
            data: loadedData(year, serie),
            showInLegend: true,
            states: {
              borderColor: '#ff0000',
              hover: {
                enabled: false
              }
            },
            dataLabels: {
            enabled: true,
            formatter: function () {
              return getNumerFormatted(this.y, props.numberFormat)
            },
            style: {
              fontWeight: 'bold'
            }
            }
          }
        })
      } else {
        return {
          type: getChartStyle(),
          name: `${year} Real`,
          data: loadedData(year, "analisado_total"),
          showInLegend: true,
          states: {
            borderColor: '#ff0000',
            hover: {
              enabled: false
            }
          },
          dataLabels: {
            enabled: true,
            formatter: function () {
              return getNumerFormatted(this.y, props.numberFormat)
            },
            style: {
              fontWeight: 'bold'
            }   
          }
        }
      }
    })
      return dataToLoad
    }

    options = {
      title: {
	      text: props.title
      },
      xAxis: {
	      categories: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', "Jun", "Jul", "Ago", 'Set', 'Out', 'Nov', 'Dez']
      },
      series: getSeries()
    }
  } else {
    options = {
      title: {
        text: props.title
      },
      xAxis: {
        categories: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', "Jun", "Jul", "Ago", 'Set', 'Out', 'Nov', 'Dez']
      },
      series: [{
        type: 'column',
        dataLabels: {
          enabled: true,
          formatter: function () {
            return getNumerFormatted(this.y, props.numberFormat)
          },
          style: {
            fontWeight: 'bold'
          }
        },
        data: data()
      }]
    }

  }  

  function tryEval(str) {
    try {
      let formulaSolved = eval(str)
      return formulaSolved ? formulaSolved : 0;
    } catch (e) {
      return 0
    }
  }

  function getSeriesYtd(){
    let optionsLabel = {
      enabled: true,
      formatter: function () {
        return getNumerFormatted(this.y, props.numberFormat)
      },
      style: {
        fontWeight: 'bold'
      }
    }

    const result = [{data:[],showInLegend: false, dataLabels: optionsLabel}]

    let index = 0

    Object.entries(loadedYears).map(([year, x]) => {
      
      result[0].data.push({
            y: tryEval(props.formulasTotalByYearYTD?.[year]?.["analisado_total"]?.[props.formula_fr_id]) * -1, 
            name: year.toString(),
            color: COLORS[index],
            index })
      index += 1

      if(year == currentYear){
        result[0].data.push({
            y: tryEval(props.formulasTotalByYearYTD?.[year]?.["budget_total"]?.[props.formula_fr_id]) * -1, 
            name: "Orçamento " + year.toString(),
            color: COLORS[index],
            index})	
        index += 1
      } 
    })
    
    return result
  }

  optionsYTD = {
    chart: {
      type: 'bar',
      horizontal: true,
      height: 200,
      events: {
        load: function() {
           // var negative = this.series?.[0]?.data?.[1]?.negative;
          
          let allValues = getSeriesYtd()[0].data
          let negative = allValues.filter((x)=> x.y <= 0).length == allValues.length
          
          
          this.yAxis[0].update({
            reversed: negative
          });
        }
      },
    },
    title: {
      text: 'Periodo Acumulado'
    }, 
    xAxis: {
      categories: getNameCategorie(),
    }, 
    yAxis: {
		title: {
        text: 'Valores'
      }
    },
    series: getSeriesYtd(),
    colorByPoint:true,
  }

  React.useEffect(() => {
    if (["param-lines", "param-head", "result"].includes(props.dataKey)) {
      if (!alreadySetup) {
        fetchParamLines(props.year)
        setAlreadySetup(true)
      }
    }
    if ((props.dataKey == "cost_center_type") || (props.dataKey == "cost_center_type_line")) {
      let { area_id,
        is_fixed,
        despesa_type_id,
        year,
        label } = props;
      if (!alreadySetup) {

        fetchAreaData(area_id, is_fixed, despesa_type_id, label, year)
        setAlreadySetup(true)
      }
    }
  });

  function toNumber(number = 0) {
    return parseFloat(number || 0)
  }



  function loadedDataTable(year, isBudget){
    let kind = isBudget ? "budget_total" : "analisado_total"
    return data(dataForFormula(props.allYearsData?.[year]?.[kind]?.report?.[props.formula_id]?.[props.formula_id]?.[props.dataKey], year))
  }


  function data(desordenedMonthData = props.data) {
    let getNumber = (value) => {

      if (props.inversedSignal) {
        return parseFloat(value) * -1
      } else {
        return parseFloat(value)
      }
    }

    let result = []
    let { title, is_fixed, months, costCenterRecordsGrouped } = props;
    if (props.areas) {
      result = Object.values(months).map((month) => {
        return parseFloat(costCenterRecordsGrouped[`["${title}", ${is_fixed}, ${month}]`])
      })
    } else if (props.param) {
      result = desordenedMonthData.map((entry) => {
        try {
          return parseFloat(entry[props.param])
        } catch (e) {
          return 0
        }

      })
    } else {
      try {
        result = desordenedMonthData?.map((x) => getNumber(x))
      } catch (e) {
        console.log("erro data", e)
      }
    }

    return result
  }
  function handleClickOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function getColorForButton(year){
    if ((props.dataKey == "cost_center_type") || (props.dataKey == "cost_center_type_line")) {
      return costCentersFetchedByYear[year] ? true : false
    } else if (props.dataKey == "param-lines") {
      return (props?.allYearsData?.[year] && lineResults[year]) ? true : false
    } else {
      return props?.allYearsData?.[year] ? true : false
    }
  } 

  function loadYear(year){
    if (["param-lines", "param-head", "result"].includes(props.dataKey)) {
      fetchParamLines(year)
      props.loadYear(year)
    } else {
      fetchParamLines(year)
      props.loadYear(year)
    }

  }


  function getTotal(year, type){
    return tryEval(props.allYearsData?.[year]?.[type]?.["total"] || props.allYearsData?.[year]?.[type]?.["range"])
  }

	function getNameCategorie() {
    const labelSerie = []

    Object.entries(loadedYears).map(([year, x], index) => {
      labelSerie.push(year)

      if(parseInt(year) == parseInt(currentYear)){
        labelSerie.push("Orçamento " + year)
      }
      
    })
    
    return labelSerie 
  }

	function renderHighcharts() {
	  return (
		<div>
		  {props.dataKey === 'result' ? (
			<div>
			  <HighchartsReact highcharts={Highcharts} options={options} />
			  <hr />
			  <HighchartsReact highcharts={Highcharts} options={optionsYTD} />
			</div>
		  ) : (
			<div>
			  <HighchartsReact highcharts={Highcharts} options={options} />
			</div>
		  )}
		</div>
	  );
	}

  function renderTableTR(year, isBudget=false){
    let label = isBudget ? `Orçamento ${year}` : formatYear(year)
    let kind = isBudget ? "budget_total" : "analisado_total"
    return <tr>
            <td>{label}</td>
            {loadedDataTable(year, isBudget)?.map((monthData) => {
              return <td>{getNumerFormatted(toNumber(monthData), props.numberFormat)}</td>
            })}
            <td style={{ position: 'sticky',right: `${totalColumnWidth}px`, zIndex: 1, backgroundColor: 'white'}}>
              {getNumerFormatted(tryEval(props.formulasTotalByYearYTD?.[year]?.[kind]?.[props.formula_fr_id]) * -1, props.numberFormat)}
            </td>
            <td style={{ position: 'sticky',right: '0', zIndex: 1, backgroundColor: 'white'}}>
              {getNumerFormatted(tryEval(props.formulasTotalByYear?.[year]?.[kind]?.[props.formula_fr_id]) * -1, props.numberFormat)}
            </td>
          </tr>
  }

  


  return (
    <div>

      <div style={{ marginRight: props.marginRight || 20 }} onClick={handleClickOpen}>
        {/*<i style={{fontSize: props.fontSize || 16}}  className={'fa fa-chart-bar'}/>*/}
        <ChartIcon width={12} height={12} />
      </div>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth={'lg'}
      >

        <DialogContent >
          <div className={'flex'} style={{ justifyContent: 'space-between' }}>
            <Grid container alignItems="center" className={classes.root}>
              <ChartGrid attachment={props.attachment}>
                <ChartGridItem hasChartStyle={chartStyle === "column"} onClick={() => setChartStyle("column")}>
                  <MemoCartColumnIcon />
                </ChartGridItem>
                <ChartGridItem hasChartStyle={chartStyle === "line"} onClick={() => setChartStyle("line")}>
                  <MemoChartListIcon />
                </ChartGridItem>
		            <ChartGridItem hasChartStyle={chartStyle === "table"} onClick={() => setChartStyle("table")}>
		              <MemoChartTableIcon />
		            </ChartGridItem>
              </ChartGrid>
            </Grid>
            <Grid container alignItems="center" className={classes.root}>
              {props.years && (
                <YearsButtons size={props?.years.length}>
                  {props.dataKey == "result" && props?.years?.map((year) => (
                    <YearButton hasChartStyle={loadedYears[year]} onClick={() => {
                      loadYear(year)
                      setLoadedYears({...loadedYears, [year]: true})
                    }}>{year}</YearButton>
                  ))}
                </YearsButtons>
              )}
            </Grid>
          </div>
          {open && chartStyle == "table" ?
            <React.Fragment>
              <h2 colSpan={14} style={{ textAlign: 'center' }} className={'table-chart-title'}>{props.title}</h2>
              <div className={'table-chart-container'}>
                <table className={'table table-chart'}>
                  <tr>
                    <td></td>
                    {monthsArray.map((month) => {
                      return <td>{month}</td>
                    })}
                    <td style={{ position: 'sticky', right: `${totalColumnWidth}px`, zIndex: 1, backgroundColor: 'white'}}>YTD</td>
                    <td ref={totalColumnRef} style={{ position: 'sticky', right: '0', zIndex: 1, backgroundColor: 'white'}}>Total</td>
                  </tr>
                  {props.years.map((year) => {
                    if(year == currentYear){
                      return <>
                         {renderTableTR(year)}
                         {renderTableTR(year, true)}
                      </>
                    }else{
                      return renderTableTR(year)
                    }
                    
                  })}

                </table>
              </div>
            </React.Fragment>
	          : renderHighcharts()}
        </DialogContent>
        <DialogActions>
          <Button className='button-app' onClick={handleClose} color="primary">
            Fechar
          </Button>
        </DialogActions>
      </Dialog>
      <LoadingDialog open={requestsCounter > 0} />
    </div>
  );
}


