export const setParameters = {
  type: "SET_PARAMETERS",
  payload: {},
};

export const setAllYearsData = {
  type: "SET_ALL_YEARS_DATA",
  payload: {},
};

export const setAllRangeData = {
  type: "SET_ALL_RANGE_DATA",
  payload: {},
};

export const setFormulas = {
  type: "SET_FORMULAS",
  payload: [],
};

export const setFormulasById = {
  type: "SET_FORMULAS_BY_ID",
  payload: {},
};

export const setLastMonthsFromYears = {
  type: "SET_LAST_MONTHS_FROM_YEARS",
  payload: {},
};
