import { Number } from "core-js/shim";
import { Time } from "highcharts";
import React from "react";
import Between from './Between';
import BudgetRowReplaceModal from "./BudgetRowReplaceModal";
const SPACE_PADDING = 45;
export default class FormulaLine extends React.Component {
    constructor(props){
        super(props)
        
        this.state={
            pattern: this.replaceFormulas(props.formula.pattern, props.formulas ),
            indicators: [],
            parameters: [],
            parameterMap: {},
            montlyPattern: {},
            monthValues: {},
            areasPerMonth: {},
            indicatorsMap: [],
            paramIsActive: {},
            areasMap: {},
            activeCostCenters: {}
            
        }
    }

    replaceFormulas(pattern, propFormulas){
        let formulas = Between.get(pattern, "#[", "]")
        
        if(formulas.length > 0){
            formulas.map((formula)=>{
                let currentFormula = propFormulas?.[formula]
                let newPattern = currentFormula?.pattern
                if (currentFormula?.pattern){
                    pattern = pattern.replaceAll(`#[${formula}]($[${currentFormula?.fr_id}])`,  "/*"+currentFormula.label+" start*/("+ newPattern +")/*"+currentFormula.label+" end*/" )
                }
                
            })
            try{
                pattern = this.replaceFormulas(pattern, propFormulas)
            }catch(e){
                debugger
            }
            
        }
        
        return pattern
        
        
        
    }
    

    componentDidMount() {
        let {months} = this.props
        let montlyPattern = {}
        let replacedPattern = this.replaceFormulas(this.props.formula.pattern, this.props.formulas)
        

        Object.keys(months).map((month)=>{montlyPattern[month] = replacedPattern})
        this.setState({montlyPattern})
        this.replaceMarks(replacedPattern)
        
        // this.setState({pattern: this.replaceMarks(replacedPattern)})
        
    }

    

    setupParameters(parameters, pattern){
        
        let {mergedBudgetRows, months} = this.props
        let parameterMap = {}
        let parameterValues = {}
        parameters.map((parameter)=>{
            let id = Between.get(pattern, `@[${parameter}](`, `)` )[0]
            parameterMap[id] = parameter 
        })
        
        let monthValues = {}
        Object.entries(parameterMap).map(([key, label])=> {
            this.props.mapping[key]?.map((account)=>{
                
                Object.entries(months).map(([month, month_number]) => {
                    monthValues[key] ||= {}
                    monthValues[key][month] ||= 0
                    try{
                        monthValues[key][month] += mergedBudgetRows[account][month_number]
                    }catch(e){
                        
                    }
                    
                })
            })
        })
        
        this.setState({monthValues})
        this.setState({parameterMap, paramSetupCompleted: true})
        
    }
    
    setupAreas(areas, pattern){
        let {mergedBudgetRows, areaMapping, months} = this.props;
        let areasMap = {}
        let areasPerMonth = {}
        
        areas.map((area)=>{

            try{
                let areaHash = Buffer.from(Between.get(pattern, `?[${area}]($[@cc`, `])` )[0], 'base64').toString()
                areasMap[area] = JSON.parse(areaHash)[0]
            }catch(e){
                    
            }
            
        })
        
        
        Object.entries(areasMap).map(([key, value])=>{
            let fixed_label = value.is_fixed ? "fixed" : "not_fixed"
            let cc_ids = Object.values(areaMapping[value?.area?.label]?.[fixed_label] || {}).reduce((a,b)=> a?.concat(b), []) || []
            
            Object.entries(months).map(([month, month_number]) => {
                cc_ids.map((account)=>{
                    try{
                        areasPerMonth[value.area.id] ||= {}
                        areasPerMonth[value.area.id][fixed_label] ||= {}
                        areasPerMonth[value.area.id][fixed_label][month] ||= 0
                        areasPerMonth[value.area.id][fixed_label][month] += mergedBudgetRows[account][month_number]
                    }catch(e){
                        
                        
                    }
                    
                })
                
            })
        })
        
        this.setState({areasMap})
        
        this.setState({areasPerMonth: areasPerMonth, areaSetupCompleted: true})
        
        
        
    }

    setupFormulas(formulas, pattern){
        let formulasMap = {}
        
        formulas.map((formula)=>{
            let id = Between.get(pattern, `#[${formula}]($[`, `])` )[0]
            formulasMap[id] = formula 
        })
        // console.log("formulas", formulasMap)
        this.setState({formulasMap})
    }
    // setupIndicators(mapped_indicators, pattern){
        
    //     let {mergedBudgetRows, months, indicators} = this.props
    //     let parameterMap = {}
    //     let parameterValues = {}
        
    //     // // console.log("parameters", parameterMap)
    //     // let monthValues = {}
    //     // Object.entries(parameterMap).map(([key, label])=> {
            
    //     //     this.props.mapping[key]?.map((account)=>{
                
    //     //         Object.entries(months).map(([month, month_number]) => {
    //     //             monthValues[key] ||= {}
    //     //             monthValues[key][month] ||= 0
    //     //             try{
    //     //                 monthValues[key][month] += mergedBudgetRows[account][month_number]
    //     //             }catch(e){
    //     //                 debugger
    //     //             }
                    
    //     //         })
    //     //     })
    //     // })

    // }

    replaceMarks(pattern){
        let indicators = Between.get(pattern, "![", "]")
        let formulas = Between.get(pattern, "#[", "]")
        let parameters = Between.get(pattern, "@[", "]")
        let areas = Between.get(pattern,"?[", "]")

        // console.log("formula", pattern)
        this.setState({indicatorsMap: indicators})
        this.setupParameters(parameters, pattern)
        this.setupAreas(areas, pattern)
        // this.setupFormulas(formulas, pattern)

        // console.log(this.state.parameterMap)
    }

    replaceIndicatorsByMonth(month, pattern){
        let {indicators, year} = this.props;
        let {indicatorsMap, monthValues} = this.state;
        
        
        indicatorsMap.map((key)=>{
            let monthValue = indicators?.[key]?.[year]?.[monthDict[month]] || 0
            pattern = pattern.replaceAll(`![${key}]`,  monthValue )
        })
        return pattern
    }
    replaceIndicators(pattern){
        let {indicators, year} = this.props;
        let {indicatorsMap} = this.state;
        let {months} = this.props;
        
        indicatorsMap.map((key)=>{
            let indicatorTotal = 0
            Object.keys(months).map((month)=>{
                indicatorTotal += indicators?.[key]?.[year]?.[monthDict[month]] || 0
            })
            
            pattern = pattern.replaceAll(`![${key}]`,  indicatorTotal )
        })
        return pattern
    }

    replaceParamsByMonth(month){
        let {parameterMap, monthValues} = this.state;
        let {pattern} = this.state;
        let {formula} = this.props;
        
        Object.entries(parameterMap).map(([key, label])=>{
            let monthValue = monthValues?.[key]?.[month] || 0
            
            pattern = pattern.replaceAll(`@[${label}](${key}).difference`,  monthValue )
            pattern = pattern.replaceAll(`@[${label}](${key})`,  monthValue )
        })
        
        return pattern
    }

    replaceParams(){
        let {parameterMap, monthValues} = this.state;
        let {pattern} = this.state;
        let {months} = this.props;
        Object.entries(parameterMap).map(([key, label])=>{
            let totalValue = 0
            Object.keys(months).map((month)=>{
                totalValue += monthValues?.[key]?.[month] || 0
            })
            
            pattern = pattern.replaceAll(`@[${label}](${key})`,  totalValue )
        })
        return pattern
    }

    replaceCostCentersByMonth(month, pattern){
        let {areasMap, areasPerMonth} = this.state;
        let {formula} = this.props;
        
        Object.entries(areasMap).map(([label, area])=>{
            let fixed_label = area.is_fixed ? "fixed" : "not_fixed"
            
            let monthValue = areasPerMonth?.[area?.area?.id]?.[fixed_label]?.[month] || 0
            
            let areaHash = Between.get(pattern, `?[${label}]($[@cc`, `])` )[0]
            
            pattern = pattern.replaceAll(`?[${label}]($[@cc${areaHash}])`,  monthValue )
            
        })
        
        return pattern
    }

    replaceCostCenters(pattern){
        let {areasMap, areasPerMonth} = this.state;
        let {months} = this.props;
        // console.log(monthValuesForAreas)
        Object.entries(areasMap).map(([label, area])=>{
            let fixed_label = area.is_fixed ? "fixed" : "not_fixed"
            let totalValue = 0
            Object.keys(months).map((month)=>{
                totalValue += areasPerMonth?.[area?.area?.id]?.[fixed_label]?.[month] || 0
            })
            
            let areaHash = Between.get(pattern, `?[${label}]($[@cc`, `])` )[0]
            
            pattern = pattern.replaceAll(`?[${label}]($[@cc${areaHash}])`,  totalValue )
            
        })
        return pattern
    }

    replaceChaves(pattern){
        pattern = pattern.replaceAll("{",  '/*chave inicio*/ (')
        pattern = pattern.replaceAll("}",  '/*chave fim*/ )')
        return pattern
    }

    valueForMonth(month){
        let {formula} = this.props;
        let {pattern} = this.state;
        let {areasPerMonth, areasMap} = this.state;
        let final_pattern = pattern
        if(this.state.totalUpdating){
            return "atualizando..."
        }
        
        final_pattern = this.replaceParamsByMonth(month)
        final_pattern = this.replaceCostCentersByMonth(month, final_pattern)
        final_pattern = this.replaceIndicatorsByMonth(month, final_pattern)
        final_pattern = this.replaceChaves(final_pattern) // isso é temporário, precisa poder contemplar .difference .saldo_anterior .saldo_atual

        return this.tryEval(final_pattern)
    }

    totalValue(){
        let {formula} = this.props;
        let {pattern} = this.state;
        let {areasPerMonth, areasMap} = this.state;
        let final_pattern = pattern
        
        final_pattern = this.replaceParams()
        final_pattern = this.replaceCostCenters(final_pattern)
        final_pattern = this.replaceIndicators(final_pattern)
        final_pattern = this.replaceChaves(final_pattern)
        
        if(formula.fr_id == 2638){
            debugger
        }
        return this.tryEval(final_pattern)
    }
    

    tryEval(str, format) {
        let percentFormat = (number)=>{
            return `${parseFloat(number).toFixed(2)}%`
          }
        let numberToCurrency = function(number=0, number_format=number_format) {
            if (String(parseFloat(number)) == "NaN" || parseFloat(number) == undefined) {
              number = 0
            }
            if(number_format == "percentage"){
              return percentFormat(parseFloat(number))
            }
            if(number_format == "integer"){
              return new Intl.NumberFormat('decimal', {minimumFractionDigits: 2}).format(parseFloat(number))
            }
      
            if(!number_format || number_format == "money"){
              return parseFloat(number).toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL"
              });
            }
          };

        try {
          let formulaSolved = eval(str)
          return formulaSolved ? numberToCurrency(formulaSolved, this.props.formula.number_format || format) : 0;
        } catch (e) {
          return 0
        }
      }
    setupCompleted(){
        let {areaSetupCompleted, paramSetupCompleted} = this.state;
        return areaSetupCompleted && paramSetupCompleted
    }
    setParamActive(param){
        this.setState({paramIsActive: {...this.state.paramIsActive, ...{[param]: !this.state.paramIsActive[param]}}})
    }
    toggleCostCenterDD(key, group=null){
        const {activeCostCenters} = this.state;
        if(group){
            this.setState({activeCostCenters: {
                ...activeCostCenters,
                ...{[key]: {
                    ...activeCostCenters[key],
                    ...{[group]: !activeCostCenters[key][group]}
                }}
            }})
        }else{
            this.setState({activeCostCenters: {
                ...activeCostCenters,
                ...{[key]: activeCostCenters[key] ? null : {}}
            }})
        }
        
    }

    renderBalanceLines(){
        let {formula, months, mapping, mergedBudgetRows, originalBudgetRows, budgetRowsReplacements} = this.props;
        let {parameterMap, paramIsActive} = this.state;
        
        return <>{ formula.formula_type != "formula" && Object.entries(parameterMap).map(([parameter_id, parameter_label])=>{
            let paramTotal = 0
            return <><tr>
                <td onClick={()=> this.setParamActive(parameter_id)}>{parameter_label}</td>
                {Object.values(months).map(month_number => {
                    let total = 0
                    mapping[parameter_id]?.map((account)=>{
                        total += mergedBudgetRows?.[account]?.[month_number]
                    })
                    paramTotal += total //total do parametro ex: ISS s/ vendas
                    return <td>{this.tryEval(total)}</td>
                })}  
                <td>{this.tryEval(paramTotal)}</td>
            </tr>
            
            {paramIsActive[parameter_id] && mapping[parameter_id]?.map((account)=>{
                let lineTotal = 0
                return <>
                <tr>
                    <td style={{paddingLeft: 45}}>{account} {this.state?.a?.toString()}</td>
                    {Object.entries(months).map(([month_label, month_number]) => {
                        // return <td><input style={{width: 50}} onChange={(e)=> this.updateBudgetRow(account, month_number, e)} value={mergedBudgetRows?.[account]?.[month_number]}></input></td>
                        lineTotal += mergedBudgetRows?.[account]?.[month_number] || 0
                        return <td>
                                    <BudgetRowReplaceModal
                                        currentAmount={mergedBudgetRows?.[account]?.[month_number]}
                                        originalAmount={originalBudgetRows?.[account]?.[month_number]}
                                        replacementAmount={budgetRowsReplacements?.[account]?.[month_number]}
                                        currentRows={mergedBudgetRows}
                                        originalRows={originalBudgetRows}
                                        valueForMonth={(month)=> this.valueForMonth(month)}
                                        account={account}
                                        tryEval={(n)=> this.tryEval(n)}
                                        mapping={mapping[parameter_id]}
                                        formula={formula}
                                        year={this.props.year}
                                        month={month_label}
                                        onConfirm={(newValue)=> this.updateBudgetRow(account, month_number, newValue)}
                                    >
                                    </BudgetRowReplaceModal>
                                </td>
                    })}
                    <td>{this.tryEval(lineTotal)}</td>
                </tr>
                </>  
            })}
            </>
        })}</>
    }
    renderIndicators(){
        let {formula, months, year, indicators: propsIndicators} = this.props;
        let {indicatorsMap} = this.state;
        
        return <>{ formula.formula_type != "formula" && indicatorsMap.map((indicator_label)=>{
            let total = 0
            return <tr>
                <td>{indicator_label}</td>
                {Object.values(months).map(month_number => {
                    total += (parseFloat(propsIndicators?.[indicator_label]?.[year]?.[month_number]) || 0)
                    return <td>{this.tryEval(propsIndicators?.[indicator_label]?.[year]?.[month_number], "integer")}</td>
                })} 
                <td>{this.tryEval(total, "integer")}</td>
            </tr>
        })}</>
    }
    
    
    renderCostCenterLines(){
        const {areasMap, activeCostCenters} = this.state;
        const {months, formula, areaMapping, mergedBudgetRows, originalBudgetRows, budgetRowsReplacements} = this.props;
        if (formula.formula_type == "formula"){
            return <></>
        }
        
        return <>
            {Object.entries(areasMap).map(([key, value])=>{
                let fixed_label = value.is_fixed ? "fixed" : "not_fixed"
                let groups = areaMapping?.[value.area.label]?.[fixed_label] || {}
                let areaTotal = 0
                
                return <>
                    <tr>
                        <td onClick={()=> this.toggleCostCenterDD(key)}>{key}</td>
                        {Object.values(months).map(month_number => {
                            let total = 0
                            let accounts = Object.values(groups).reduce((a,b)=>{return a.concat(b)}, [])
                            {accounts.map((account)=>{
                                total += mergedBudgetRows?.[account]?.[month_number] || 0
                                areaTotal += mergedBudgetRows?.[account]?.[month_number] || 0
                            })}
                            return <td>{this.tryEval(total)}</td>
                        })}  
                        <td>{this.tryEval(areaTotal)}</td>
                    </tr>
                    {activeCostCenters?.[key] && ["trabalhistas", "operacionais"].map((group)=>{
                        let groupTotal = 0
                        return <>
                            <tr>
                                <td style={{paddingLeft: SPACE_PADDING}} 
                                    onClick={()=> this.toggleCostCenterDD(key, group)}>{group}</td>
                                {Object.values(months).map(month_number => {
                                    let total = 0
                                    {groups[group].map((account)=>{
                                        total += mergedBudgetRows?.[account]?.[month_number] || 0
                                    })}
                                    groupTotal += total
                                    return <td>{this.tryEval(total)}</td>
                                })} 
                                <td>{this.tryEval(groupTotal)}</td>
                            </tr>
                            {activeCostCenters?.[key]?.[group] && groups[group].map((account)=>{
                                let accountTotal = 0
                                return <tr>
                                    <td style={{paddingLeft: SPACE_PADDING * 2}}>{account}</td>
                                    {Object.entries(months).map(([month_label, month_number]) => {  
                                        accountTotal +=   mergedBudgetRows?.[account]?.[month_number] || 0        
                                        return <td>
                                            <BudgetRowReplaceModal
                                                currentAmount={mergedBudgetRows?.[account]?.[month_number]}
                                                originalAmount={originalBudgetRows?.[account]?.[month_number]}
                                                replacementAmount={budgetRowsReplacements?.[account]?.[month_number]}
                                                currentRows={mergedBudgetRows}
                                                originalRows={originalBudgetRows}
                                                valueForMonth={(month)=> this.valueForMonth(month)}
                                                account={account}
                                                tryEval={(n)=> this.tryEval(n)}
                                                formula={formula}
                                                year={this.props.year}
                                                month={month_label}
                                                onConfirm={(newValue)=> this.updateBudgetRow(account, month_number, newValue)}
                                            >
                                            </BudgetRowReplaceModal>
                                        </td>
                                    })} 
                                    <td>{this.tryEval(accountTotal)}</td>
                                </tr>
                            })}
                        </>
                    })}
                </>
            })}
        </>
    }

    updateBudgetRow(account, month, newValue){
        this.props.updateBudgetRow(account, month, newValue)
        
        self = this;
        setTimeout(()=>{
            let replacedPattern = self.replaceFormulas(self.props.formula.pattern, self.props.formulas)
            self.replaceMarks(replacedPattern)
        }, 10)
    }

    
    render(){
        let {formula, months, mapping, mergedBudgetRows} = this.props;
        
        let {parameterMap} = this.state;
        return <>
            {this.renderCostCenterLines()}
            {this.renderBalanceLines()}
            {this.renderIndicators()}
            <tr id="principal" className={`formula-${formula.formula_type} formula-${formula.formula_type}-${formula.layout} format-${formula.number_format} formula-${formula.is_only_index ? "only-index" : "more-than-index"}`}>
                <td>{formula.label}</td>
                { this.setupCompleted() && Object.keys(months).map(month => (
                    <td>{!this.state.totalUpdating && this.valueForMonth(month)}</td>
                ))}
                <td>{this.setupCompleted() && this.totalValue()}</td>
            </tr>
            <tr></tr>
        </>
    }

}
const monthDict = {
  "janeiro": "1" ,
  "fevereiro": "2" ,
  "março": "3" ,
  "abril": "4" ,
  "maio": "5" ,
  "junho": "6" ,
  "julho": "7" ,
  "agosto": "8" ,
  "setembro": "9" ,
  "outubro": "10", 
  "novembro": "11", 
  "dezembro": "12", 
}