import React, { createContext, useReducer } from "react";
import { initialState, presentationsReducer } from "./stores";

export const PresentationsContext = createContext(initialState);

export const PresentationsProvider = ({ children, parameters }) => {
  initialState.parameters = parameters || {}
  
  const [state, dispatch] = useReducer(presentationsReducer, initialState);
  return (
    <PresentationsContext.Provider value={{ state, dispatch }}>
      {children}
    </PresentationsContext.Provider>
  );
};
