import React from "react";
import DifferenceModal from './DifferenceModal'
class WarningDialog extends React.Component {
 
  constructor(props) {
    super(props);
    this.state = {
      opened: false,
      balanceSheet: {},
      justificatory: this.props.justificatory
    }
  }
  
  hasDifference(){
    const {balanceSheet} = this.state;
    
    try{
      return balanceSheet.before_difference.length > 0 || balanceSheet.after_difference.length > 0   
    }catch(e){}
    
  }

  componentDidMount(){
    this.setState({balanceSheet: JSON.parse(this.props.balanceSheet)})
  }

  handleClickOpen(){
    this.setState({opened: true});
  };

  handleClose(value){
    this.setState({opened: false});
    if(value){
      this.setState({justificatory: value});
    }
    
  };

  render() {
    const {balanceSheet, justificatory} = this.state;
    
    
    return (
      <div>
        {this.hasDifference() && 
          <i onClick={this.handleClickOpen.bind(this)} 
          className={ 'fa fa-exclamation-circle'} 
          style={{fontSize: 20, color: justificatory ? "green" : "#FDD04B", cursor: "pointer"}}
          />
        }
        <DifferenceModal balanceSheet={balanceSheet} justificatory={justificatory}  open={this.state.opened} onClose={this.handleClose.bind(this)} />
      </div>
    );
  }
}

export default WarningDialog;
