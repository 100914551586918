import React from 'react';
import Fuse from "fuse.js";
import styles from "./styles/FormulasLayoutStyles";
import Button from "@material-ui/core/Button";

class IndicadoresFormulasSearch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paramsQueryIndicadores: "",
        };
    }

    handleAddButtonClick = (row) => {
        if (this.props.onAddItem) {
            this.props.onAddItem(row);
        }
    }

    renderParams(indicadores) {
        let filtered = {};

        var combinedData = indicadores.map(item => ({
            title: "INPUT",
            id: item.id,
            display: item.display || ""
        }));

        if (this.state.paramsQueryIndicadores.length < 1) {
            filtered.indicadores = combinedData;
        } else {
            var options = {
                shouldSort: true,
                threshold: 0.3,
                distance: 1000,
                maxPatternLength: 1500,
                minMatchCharLength: 3,
                keys: ["display"]
            };

            var fuse = new Fuse(combinedData, options);
            filtered.indicadores = fuse.search(this.state.paramsQueryIndicadores);
        }

        const htmlRender = filtered.indicadores.map((item, index) => (
            <div
                style={{
                    ...styles.listItem,
                    backgroundColor: index % 2 === 0 ? '#f2f2f2' : 'white',
                }}
                key={index}
            >
                <Button onClick={() => this.handleAddButtonClick(item)}>
                    <small style={{ fontStyle: 'italic', color: "#bababa", fontSize: '10px', whiteSpace: 'normal' }}>
                        {item.title ? (item.title + " -> ") : ""}
                    </small>
                    &nbsp;{item.display}
                </Button>
            </div>
        ));

        return htmlRender;
    }

    render() {
        const { indicadores } = this.props;

        return (
            <div style={styles.bgContent}>
                <div style={styles.header}>
                    <div style={styles.blockTitle}>
                        <span style={styles.icon}>!</span> INPUTS (INDICADORES)
                    </div>
                    <div>
                        <input
                            style={styles.searchInput}
                            placeholder={"Buscar um item nos inputs"}
                            onChange={e => {
                                let newValue = e.target.value;
                                this.setState({ paramsQueryIndicadores: newValue });
                            }}
                        />
                    </div>
                </div>
                <div style={{...styles.dataContainer, overflowY: 'auto', maxHeight: 'calc(100% - 70px)'}}>
                    {indicadores ? this.renderParams(indicadores) : <div>Carregando...</div>}
                </div>
            </div>
        );
    }
}

export default IndicadoresFormulasSearch;
