import React from "react";
import Select from 'react-select';
import AutoComplete from "./AutoComplete";
import TextField from '@material-ui/core/TextField';
import { GreenButton } from "../styled_components/default";

class CompanyAreas extends React.Component {
  state = {
    areas: [],
    query: "",
    users: [],
    editingArea: {},
    currentArea: {},
    roles: [
      {value: "admin", label: "Admin"},
      {value: "consultor", label: "Consultor"},
    ]
  };



  async fetchAreas() {
    if(this.props.company){
      let result = await axios.get(`/companies/${this.props.company}/cost_center_areas.json`, {
        params: {
          query: this.state.query
        }
      });
      
      this.setState({areas: result.data })  
    }
  }

  handleChange(event) {
    let value = event.target.value
    
    this.setState((oldState)=>{
      oldState.currentArea.label = value
      return {...oldState}
    });
    
  }

  
  handleChangeEditingArea(event) {
    let value = event.target.value
    let self = this

    this.setState((oldState)=>{
      oldState.editingArea.label = value
      return {...oldState}
    });
    
    
  }

  async addNewArea(){

    let result = await axios.post(`/companies/${this.props.company}/cost_center_areas.json`, {
        label: this.state.currentArea.label,
    });

    this.setState((oldState)=>{
      oldState.areas = result.data
      return {...oldState}
    })
    
  }

  async saveEditedArea(area){
    let {editingArea} = this.state
    let result = await axios.put(`/companies/${this.props.company}/cost_center_areas/${area.id}.json`,{
      cost_center_area: editingArea
    });
    this.setState({editingArea: {}})
    
  }

  async removeArea(area){
    
    let result = await axios.delete(`/companies/${this.props.company}/cost_center_areas/${area.id}.json`);

    this.setState((oldState)=>{
      oldState.areas = result.data
      return {...oldState}
    })
  }

  componentDidMount() {
    
    this.fetchAreas();
  }

  editArea(area){
    if(this.state.editingArea.id == area.id){
      this.setState({editingArea: {}})
    }else{
      this.setState({editingArea: area})  
    }
    
    
  }

  render() {
    let { users, roles, editingArea } = this.state;
    return (
      <div>
        {!this.props.no_edit && <div style={{ display: "flex", alignItems: 'center' }}>
          <TextField id="outlined-basic" label="Nova area" variant="outlined" onChange={this.handleChange.bind(this)} />
          
          <GreenButton className={'margin-left-10'} onClick={this.addNewArea.bind(this)}>ADICIONAR</GreenButton>
        </div>}
        <table className={'table'}>
          {this.state.areas.map((area)=>(
            <tr>
              <td>
                {
                   this.state.editingArea.id == area.id
                   ?
                   <TextField defaultValue={area.label} id="outlined-basic" label="Nome" variant="filled"  onChange={this.handleChangeEditingArea.bind(this)} />
                   :
                   area.label
                }
              </td>
              {!this.props.no_edit && <React.Fragment>
                <td>
                  {
                    (editingArea.id != area.id) ?
                    <i onClick={()=> this.editArea(area)} style={{cursor: "pointer"}} className={'fa fa-pencil'}/>:
                    <i onClick={()=> this.saveEditedArea(area)} style={{cursor: "pointer"}} className={'fa fa-check'}/>
                  }

                </td> 
                
                <td>
                  <i onClick={()=> this.removeArea(area)} style={{cursor: "pointer"}} className={'fa fa-trash'}/>
                </td>
              </React.Fragment>}
            </tr>
          ))}
        </table>
      </div>
    );
  }
}

export default CompanyAreas;
