import React from 'react';
import { useState } from "react";
import { PopUpButton, PopUpContainer, PopUpContent } from "./styles";
import ButtonSvg from 'buttonsvg.svg'

export function PopUp({ content }) {
  const [showPopUp, setShowPopUp] = useState(false)

  return (
    <PopUpContainer>
      <PopUpButton show={showPopUp} onClick={() => setShowPopUp(state => !state)}>
        <img src={ButtonSvg} />
      </PopUpButton>
      <PopUpContent show={showPopUp}>
        {content}
      </PopUpContent>
    </PopUpContainer>
  )
}