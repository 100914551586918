import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {GreyButton} from "../styled_components/balance_sheets.js";


function createData(code) {
  return { code };
}



export default class CodeTable extends React.Component {
  state = {
    bannedCodes: []
  }
  setupRows(){
    let rows = []
    this.setState((oldState)=>{
      this.props.bannedCodes.map((code)=>{
        rows.push(createData(code))
      })  
      oldState.bannedCodes = rows;
      return {...oldState}
    })
  }
  
  async restore(code){
    let response = await this.props.restoreCode(code)
    
    setTimeout(()=>{
      this.setupRows();
    }, 1000)
    
  }


  componentDidMount(){
    this.setupRows();
  }
  
  render(){
    let {bannedCodes} = this.state;
    return (
      <TableContainer component={Paper}>
        <Table  aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Código</TableCell>
              <TableCell>Restaurar</TableCell>
              
            </TableRow>
          </TableHead>
          <TableBody>
            {bannedCodes.map((row) => (
              <TableRow key={row.code}>
                <TableCell component="th" scope="row">
                  {row.code}
                </TableCell>
                <TableCell >
                  <GreyButton onClick={()=> this.restore(row.code)}>
                    restaurar
                  </GreyButton>
                </TableCell>
                
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }
  
}