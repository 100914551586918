import styled, { css } from "styled-components";
import PropTypes from "prop-types";
import greenLogo from 'green_logo.png';
import ImgSrc from "lupa.png";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  position: relative;

  width: 100%;
  height: 100%;
  border-radius: 8px;
  max-width: 100%;
  max-height: 100%;
  
  header {
    background-color: #e5e5e5;
    display: flex;
    align-items: center;  
    width: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    img{
      height: 136px;
      margin-right: 16px;
    }
    span {
      font-size: 1rem;
      line-height: 1.4;
      color: #494949;
      text-transform: uppercase;
      text-align: left;
      font-weight: 700;
      border-left: 0.5px solid #80808096;
      padding-left: 20px;
      height: 112px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
`;

export const Content = styled.div`
  width: 100%;
  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  height: 100%;
  padding: 3rem;
  position: relative;
  box-sizing: border-box;
  display: flex;
  align-items: flex-start;
  gap: 1.25rem;
  &>*{
    opacity: 0.85
  }
  :after{
    background-image: url(${greenLogo});
    position: absolute;
    content: "";
    left: 1438px;
    top: 739px;
    width: 170px;
    background-size: 100px;
    background-repeat: no-repeat;
    height: 27px;
}
  }
`;

export const TableContainer = styled.table`
  border-spacing: 0;

  thead {
    tr {
      border-bottom: 1px solid #000;
    }
  }
`;

export const TableCaption = styled.caption`
  height: 1.5rem;
  font-size: 1rem;
  font-weight: 700;

  ${(props) =>
    props.hasBorder &&
    css`
      border-bottom: 1px solid #000;
    `}
`;

export const Row = styled.tr`
  height: 2rem;
  
  ${(props) =>
    props.mainRow &&
    css`
      td {
        background-color: #f2f2f2;
      }
    `}

  ${(props) =>
    props.formulaStyle === "normal" &&
    css`
      td {
        color: #617e94 !important;
        background-color: ${props.formulaType == "formula" ? "#c0e9eb" : "transparent"};
      }
    `}

  ${(props) =>
    props.formulaStyle === "especial" &&
    css`
      td {
        color: #5d92c5 !important;
        font-weight: bold;
        font-style: italic;
      }
    `}

  ${(props) =>
    props.formulaType === "grouped" &&
    css`
      td {
        color: #617e94 !important;
        background-color: ${props.formulaStyle == "grupo_especial" ? "#c0e9eb" : "transparent"};
      }
    `}

  ${(props) =>
    props.isSub &&
    css`
      td {
        font-style: italic;
      }
    `}

  td {
    padding: 0.25rem 0.75rem;
    margin: 0;
    font-weight: 700;
    text-align: right;
    &:first-child {
      text-align: left;
    }
  }
`;

export const HeaderCell = styled.th`
  font-weight: 700;
  text-align: right;
  padding-right: 20px;
  ${(props) =>
    props.children &&
    css`
      border-bottom: 1px solid #000;
    `}
`;

const colorOfCellText = {
  black: "#000",
  blue: "#4985bb",
  red: "#fc303c",
};

export const Cell = styled.td`
  font-size: 1rem;
  line-height: 1.2;
  white-space: nowrap;
  ${(props) =>
    props.cellWidth &&
    css`
      width: ${props.cellWidth}%;
    `}

  color: ${(props) =>
    props.color ? colorOfCellText[props.color] : colorOfCellText.black}
`;
