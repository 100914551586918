import React from "react";

export function ResultadoIcon(props) {
  return (
    <svg viewBox="0 0 7 5" fill="none" {...props}>
      <path
        stroke="currentColor"
        strokeWidth={1.45}
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M.725 4.275h5.55M.725 1.275h5.55"
      />
    </svg>
  )
}