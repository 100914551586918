import React, {useRef} from "react";

import { MentionsInput, Mention } from "react-mentions";

import provideExampleValue from "./provideExampleValue";

import defaultStyle from "./styles/defaultStyle";
import defaultMentionStyle from "./styles/defaultMentionStyle";
import hashtagMentionStyle from "./styles/hashtagMentionStyle";

const charFormulaMentionInputStyle = {
    ...defaultStyle,
    suggestions: {
        ...defaultStyle.suggestions,
        list: {
            position: 'fixed',
            top: '47%',
            left: '53%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: "#f5f5f5",
            border: '1px solid rgba(0,0,0,0.15)',
            fontSize: 14,
            maxHeight: '600px',
            overflowY: 'auto',
            width: '1000px'
        }
    }
};


function SingleLine({
  value,
  data,
  hashtagData,
  customData,
  titleData,
  onChange,
  onAdd,
  inputRef,
  onMudado,
  costCenterAreaData
}) {
  return (
    <div className="single-line">
          <MentionsInput
              singleLine
              value={value}
              onChange={onChange}
              style={charFormulaMentionInputStyle}
              placeholder={"Referencie parametros usando '#'"}
          >

              <Mention
                  key={2}
                  className={"hashtag_mention"}
                  markup="#[__display__]($[__id__])"
                  renderSuggestion={(entry, search, highlightedDisplay, index, focused)=>{

                      return <div>
                          <small style={{fontStyle: 'italic', color: "#bababa"}}>{entry.group} - </small>
                          {entry.display}
                      </div>
                  }}
                  trigger="#"
                  data={hashtagData}
                  style={{ backgroundColor: "#2B7DE999" }}
                  appendSpaceOnAdd={true}
              />
          </MentionsInput>
    </div>
  );
}

export default SingleLine;
