import React from "react";
import Dre from './results/Dre'
import Attachment from './results/Attachment'
import Segmentado from './results/Segmentado'
import SegmentadoByMonth from './results/SegmentadoByMonth'
import Balance from './results/Balance'
import Borboleta from './Borboleta'
import Flow from './results/Flow'
import { BlueLink } from '../styled_components/default';
import Budget from "./results/Budget";
import { BorboletaIcon } from "./svg-icons/BorboletaIcon";
import { ResultadoIcon } from "./svg-icons/ResultadoIcon";
import { BalancoIcon } from "./svg-icons/BalancoIcon";
import { FluxoCaixaIcon } from "./svg-icons/FluxoCaixaIcon";
import { AnexoIcon } from "./svg-icons/AnexoIcon";
import { SegmentadoIcon } from "./svg-icons/SegmentadoIcon";

class Result extends React.Component {
    constructor(props) {
        super(props)
        let initial_tab = "dre_cc"
        if (props.withoutDreCC) {
            initial_tab = "dre"
        }

        let current_tab = props.current_tab || initial_tab

        // if(props.is_group_type){
        //     current_tab = props.current_tab || "segmented"
        // }

        this.state = {
            activeTab: current_tab,
            year: props.initial_year
        }
    }


    activeTab(tab) {
        return this.state.activeTab == tab
    }

    changeYear(year) {
        this.setState({ year })
    }

    normal_render() {
        const { months, report, is_group_type, has_childreen } = this.props;
        const { year } = this.state;

        let numberToCurrency = function (number) {
            return parseFloat(number).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        }

        return (
            <div>
                <div className={"flex flex-result-title"} style={{ justifyContent: 'space-between' }}>
                    {this.props.mobile ? <></> : <h1> Resultado </h1>}
                    {this.props.mobile ? <></> : <div style={{ display: 'flex', alignItems: 'center' }}>
                        {this.props.is_budget ? <>
                            <BlueLink
                                active={this.activeTab("budget")}
                                onClick={() => this.setState({ activeTab: "budget" })}
                            > Orçamento </BlueLink>
                        </> :
                            <><React.Fragment>
                                <BlueLink
                                    active={this.activeTab("borboleta")}
                                    onClick={() => this.setState({ activeTab: "borboleta" })}
                                > <BorboletaIcon width={9} height={13} /> Visão Comparativa </BlueLink>
                                <BlueLink
                                    active={this.activeTab("dre") || this.activeTab("dre_cc")}
                                    onClick={() => this.setState({ activeTab: "dre_cc" })}
                                > <ResultadoIcon width={7} height={5} /> Resultado Mensal </BlueLink>

                                <BlueLink
                                    active={this.activeTab("balance")}
                                    onClick={() => this.setState({ activeTab: "balance" })}
                                > <BalancoIcon width={11} height={9} /> Balanço </BlueLink>

                                <BlueLink
                                    active={this.activeTab("flow")}
                                    onClick={() => this.setState({ activeTab: "flow" })}
                                > <FluxoCaixaIcon width={11} height={7} /> Fluxo de Caixa </BlueLink>

                                <BlueLink
                                    active={this.activeTab("attachment_table")}
                                    onClick={() => this.setState({ activeTab: "attachment_table" })}
                                > <AnexoIcon width={9} height={15} /> Anexos </BlueLink>
                            </React.Fragment>
                                {has_childreen && <BlueLink
                                    active={this.activeTab("segmented")}
                                    onClick={() => this.setState({ activeTab: "segmented" })}
                                ><SegmentadoIcon width={13} height={13} /> Segmentado </BlueLink>}
                                {/*has_childreen && is_group_type && <BlueLink 
                            active={this.activeTab("segmented_by_month")}
                            onClick={()=> this.setState({activeTab: "segmented_by_month"})}
                         > Segmentado Por Mês</BlueLink>*/}</>}
                    </div>}
                </div>
                <div className="flex">
                    <div className="bg-content flex-1 with-header">
                        {this.activeTab("borboleta") && <Borboleta scope={"borboleta"} propsSetState={this.setState.bind(this)} activeTab={this.activeTab.bind(this)} changeYear={this.changeYear.bind(this)} defaultYear={year} {...this.props} />}
                        {this.activeTab("dre") && <Dre scope={"dre"} propsSetState={this.setState.bind(this)} activeTab={this.activeTab.bind(this)} changeYear={this.changeYear.bind(this)} defaultYear={year} {...this.props} />}
                        {this.activeTab("dre_cc") && <Dre scope={"dre_cc"} propsSetState={this.setState.bind(this)} activeTab={this.activeTab.bind(this)} changeYear={this.changeYear.bind(this)} costCenterMode={true} defaultYear={year} {...this.props} />}
                        {this.activeTab("balance") && <Balance changeYear={this.changeYear.bind(this)} defaultYear={year} {...this.props} />}
                        {this.activeTab("flow") && <Flow changeYear={this.changeYear.bind(this)} defaultYear={year} {...this.props} />}
                        {this.activeTab("segmented") && <Segmentado changeYear={this.changeYear.bind(this)} defaultYear={year} {...this.props} />}
                        {this.activeTab("segmented_by_month") && <SegmentadoByMonth changeYear={this.changeYear.bind(this)} defaultYear={year} {...this.props} />}
                        {this.activeTab("attachment_table") && <Attachment scope={"attachment_table"} propsSetState={this.setState.bind(this)} activeTab={this.activeTab.bind(this)} changeYear={this.changeYear.bind(this)} defaultYear={year} {...this.props} />}
                    </div>

                </div>
            </div>);
    }

    budget_render() {
        const { months, report } = this.props;
        const { year } = this.state;

        let numberToCurrency = function (number) {
            return parseFloat(number).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
        }

        return (
            <div>
                <div className={"flex"} style={{ justifyContent: 'space-between' }}>
                    <h1> Orçamento </h1>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <BlueLink
                            active={this.activeTab("dre_cc")}
                            onClick={() => this.setState({ activeTab: "dre_cc" })}
                        > Orçamento</BlueLink>
                    </div>
                </div>
                <div className="flex">
                    <div className="bg-content flex-1 with-header">
                        {this.activeTab("dre_cc") && <Budget changeYear={this.changeYear.bind(this)} costCenterMode={true} defaultYear={year} {...this.props} />}
                    </div>

                </div>
            </div>);
    }
    render() {
        if (this.props.is_budget) {
            // return this.budget_render()
            return this.normal_render()
        } else {
            return this.normal_render()
        }

    }
}

export default Result;



