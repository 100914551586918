import React, { useRef, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/core/styles';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import BarChartIcon from '@material-ui/icons/BarChart';
import TimeLineIcon from '@material-ui/icons/Timeline';
import TableChart from '@material-ui/icons/TableChartTwoTone';
import chartIco from 'graph-svg.svg';
import greyIco from 'icon_finallinhacinza.svg';
import { WidgetChartIcon } from './svg-icons/WidgetChartIcon';
import { ChartGrid, ChartGridItem, LoadAllYearsButton, YearButton, YearsButtons } from '../styled_components/default';
import MemoChartListIcon from './svg-icons/ChartListIcon';
import MemoCartColumnIcon from './svg-icons/CartColumnIcon';
import MemoChartTableIcon from './svg-icons/ChartTableIcon';
const COLORS = ["#65BCFF", "#F7999C", "#4AB796", "#F4CF98", "#37BAC0", "#6C899E"]
Highcharts.setOptions({
  colors: COLORS
});
var formatter = new Intl.NumberFormat("pt-BR", {
  style: "currency",
  currency: "BRL"
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: 'fit-content',
    border: 'none',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    color: 'theme.palette.text.secondary',
    '& svg': {
      margin: theme.spacing(1.5),
    },
    '& hr': {
      margin: theme.spacing(0, 0.5),
    },
  },
}));

export const numberToCurrency = function(number) {
  return parseFloat(number).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL"
  });
};



export default function ResultChart(props) {
  const totalColumnRef = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [chartStyle, setChartStyle] = React.useState("line");
  const [lineResults, setLineResults] = React.useState({});
  const [lineResultsSaldo, setLineResultsSaldo] = React.useState({});
  const [fetched, setFetched] = React.useState({});
  const [costCentersFetchedByYear, setCostCentersFetchedByYear] = React.useState({});
  const [chartVisible, setChartVisible] = React.useState(true)
  const [alreadySetup, setAlreadySetup] = React.useState(false)
  const [isFetchingCostCenter, setIsFetchingCostCenter] = React.useState(false);
  const [totalColumnWidth, setTotalColumnWidth] = React.useState(0);
  const [loadingLines, setLoadingLines] = React.useState(false);
  const currentYear =  [...props.years]?.sort()?.reverse()?.[0] || new Date().getFullYear(); 
  const [loadedYears, setLoadedYears] = React.useState({[currentYear]: true});
  const [visibleMonths, setVisibleMonths] = React.useState(props.visibleMonths)

  useEffect(() => {
    if (totalColumnRef.current) {
      setTotalColumnWidth(totalColumnRef.current.offsetWidth);
    }
  }, [chartStyle]);

  useEffect(()=>{
    if(props.dataKey == "result_dashboard"){
      let months = {}
      Object.entries(props.data?.current_year).map(([month, value])=>{
        try{
          let val = eval(value)
          if(Math.abs(val) > 0){
            months[month] = true
          }
        }catch(e){

        }
      })
      setVisibleMonths(months)
    }
  }, [])

  const classes = useStyles();
  let monthsArray = ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', "Jun", "Jul", "Ago", 'Set', 'Out', 'Nov', 'Dez']
  let options = {}
  let optionsFlow = {}
  let optionsBalance = {}
  
  const formatYear = (year, label) => {
    if (props.dataKey == "result_dashboard") {
      //switch case year
      switch (year) {
        case "budget":
          return "Orçamento " + props.year
        case "current_year":
          return props.year
        case "last_year":
          return props.year - 1
        //end switch case year

        default: return year
      }
    } else {
      if(label == 'budget') {
        return "Orçamento " + year
      }
      return year
    } 
  }

  const ytdOn = ()=>{
    if(props.dataKey == "result_dashboard"){
      return true
    }else{
      return visibleMonths
    }
    
  }

  const fetchAreaData = async (key, fixed, type_id, type_label, year, is_budget=false) => {
    const { cost_center_heads } = props;
    let fixed_label = Boolean(fixed) ? "fixed" : "not_fixed"
    if (isFetchingCostCenter) {
      return false
    } else {
      setIsFetchingCostCenter(true)
    }

    let yearLabel = year
    if(is_budget){
      yearLabel = `${year}_budget`
    }

    let newCostCentersFetchedByYear = costCentersFetchedByYear

    if (newCostCentersFetchedByYear?.[yearLabel]?.[type_label]?.[key]) {
      return false
    }
    let details = await axios.get(`/results/${key}/cost_center_detail.json`, {
      params: {
        year: year,
        fixed: Boolean(fixed),
        type: type_id,
        is_budget,
      }
    })

    


    newCostCentersFetchedByYear[yearLabel] ||= {}
    newCostCentersFetchedByYear[yearLabel][type_label] ||= {}
    newCostCentersFetchedByYear[yearLabel][type_label][key] ||= {}

    newCostCentersFetchedByYear[yearLabel][type_label][key][Object.keys(details.data)[0]] = Object.values(details.data)[0]
    setCostCentersFetchedByYear({ ...costCentersFetchedByYear, ...newCostCentersFetchedByYear })
    setIsFetchingCostCenter(false)

    if(!is_budget && (props.year == year) && !props.cashFlowChart){
      fetchAreaData(key, fixed, type_id, type_label, year, true)
    }


  }
  
  const fetchParamLines = (year, isBudget=false) => {
    
    let yearLabel = isBudget ? `${year}_budget` : year;
    if(fetched[yearLabel]){
      return;
    }
    setFetched(prevFetched => ({ ...prevFetched, [yearLabel]: true }));
    setChartVisible(false);
    let valueParamList = ["difference"]
    if(props.isFlowChart){
      valueParamList.push("f_saldo_atual")
    }

    valueParamList.map((valueParam)=>{
      axios.get(`/results/${props.p_key}/detail.json`, {
        params: {
          year: year,
          cost_center_mode: false,
          is_budget: isBudget,
          value_param: valueParam
        }
      }).then((result) => {
        if(valueParam == "f_saldo_atual"){
          setLineResultsSaldo(prevLineResults => ({ ...prevLineResults, [yearLabel]: result.data }));
          setChartVisible(prev => true);
        }else{
          setLineResults(prevLineResults => ({ ...prevLineResults, [yearLabel]: result.data }));
          setChartVisible(prev => true);
        }
      });
    })
    
  }

  React.useEffect(() => {
    if (["param-lines", "param-head"].includes(props.dataKey)) {
      if (!alreadySetup) {
        fetchParamLines(props.year)
        !props.cashFlowChart && fetchParamLines(props.year, true)  
        setAlreadySetup(true)
      }
    }
    if ((props.dataKey == "cost_center_type") || (props.dataKey == "cost_center_type_line")) {
      let { area_id,
        is_fixed,
        despesa_type_id,
        year,
        label } = props;
      if (!alreadySetup) {

        fetchAreaData(area_id, is_fixed, despesa_type_id, label, year)
        setAlreadySetup(true)
      }


    }

  }, []);
  function tryEval(str){
		try {
		  let formulaSolved = eval(str)
		  return formulaSolved ? formulaSolved : 0;
		} catch (e) {
		  return 0
		}
  }

  const getNumerFormatted = (value, format) => {
    let result = value
    if (format == "percentage") {
      result = `${parseFloat(value).toFixed(2)}%`
    }
    if (format == "integer") {
      result = new Intl.NumberFormat('decimal', { minimumFractionDigits: 2 }).format(parseFloat(value))
    }
    if (!format || format == "money") {
      result = numberToCurrency(value)
    }

    return result
  }


  const calculateYtd = (year, serie = "analisado") => {   
	  let lastMonthUpload = 2
      let result = data(dataForFormula(props.allYearsData?.[year]?.[serie]?.report?.[props.formula_id]?.[props.formula_id]?.[props.dataKey]))
	    result = result.slice(0, lastMonthUpload + 1).reduce(function (accumulator, currentValue) {
		  return accumulator + currentValue;
	  }, 0);
	  return result
  }

  let getChartStyle = () => {
    let response = chartStyle == "table" ? "column" : chartStyle


    return response
  }


  let loadedData = (year, label, params = null) => {
    let { area_id, is_fixed, months } = props;

    if (props.dataKey == "area_header") {

      let result = Object.values(months).map((month) => {
        return parseFloat(props.allYearsData?.[year]?.[label]?.costCenterGroupedByMonth?.[is_fixed ? "fixed" : "not_fixed"]?.[`[${area_id}, ${month}]`]) * -1
      })

      return result
    }
    if (props.dataKey == "cost_center_type") {
      let fixed_label = props.is_fixed ? "fixed" : "not_fixed"
      let cc_by_code = {}
      try {
        let yearLabel = label == "budget" ? `${year}_budget` : year;
        yearLabel = year.includes("_budget") ? year : yearLabel
        cc_by_code = Object.values(costCentersFetchedByYear[yearLabel][props.label][props.area_id][fixed_label])
      } catch (e) {

      }

      let allMonths = {}
      Object.entries(monthDict).map(([month, value]) => {
        Object.values(cc_by_code).map((entry) => {
          Object.entries(entry.months).map(([entryMonth, amountValue]) => {
            if (value == entryMonth) {
              allMonths[month] ||= 0
              allMonths[month] += amountValue * -1
            }
          })
        })
      })
      return dataForFormula(allMonths)
    }
    if (props.dataKey == "cost_center_type_line") {
      let fixed_label = props.is_fixed ? "fixed" : "not_fixed"
      let cc_by_code = {}

      try {
        let yearLabel = label == "budget" ? `${year}_budget` : year;
        yearLabel = year.includes("_budget") ? year : yearLabel
        cc_by_code = Object.entries(costCentersFetchedByYear[yearLabel][props.label][props.area_id][fixed_label])

      } catch (e) {

      }


      let allMonths = {}
      Object.entries(monthDict).map(([month, value]) => {
        Object.values(cc_by_code).map(([code, entry]) => {

          Object.entries(entry.months).map(([entryMonth, amountValue]) => {
            if (value == entryMonth && code == props.cc_key) {
              allMonths[month] ||= 0
              allMonths[month] += amountValue * -1
            }
          })
        })
      })
      return dataForFormula(allMonths)
    }

    if (props.dataKey == "indicators") {
      return data(dataForFormula(props.allYearsData?.[year]?.[label]?.report?.[props.formula_id]?.[props.formula_id]?.[props.dataKey]?.[props.p_key]))
    }

    if (props.dataKey == "balance-params") {
      return dataForFormula(props?.allYearsData?.[year]?.params?.[props.parameter_id] || {})
    }
    if (props.dataKey == "balance-detail-line") {
      
      let sufixesRegex = new RegExp(`-(${props.sufixes.join('|')})$`, 'g');
      let cleanId = props.line.id.replace(sufixesRegex, '');

      let lineValues = Object.values(props?.allYearsData?.[year] || {})
        .reduce((a, b) => { return { ...a, ...b } }, {})
        ?. [cleanId];
       
      return dataForFormula(lineValues?.months || {}, null, true)
    }

    if (props.dataKey == "params") {
      let param, paramsData;

      if(props.rangeMonths){
        param = props.allYearsData?.[year]?.["report"]?.[props.formula_id]?.[props.formula_id]?.["lines"]?.[props.p_key]?.[0]
        paramsData = props.allYearsData?.[year]?.["report"]?.[props.formula_id]?.[props.formula_id]?.[props.dataKey]?.[props.p_key]
      }else{
        param = props.allYearsData?.[year]?.["analisado"]?.["report"]?.[props.formula_id]?.[props.formula_id]?.["lines"]?.[props.p_key]?.[0]
        paramsData = props.allYearsData?.[year]?.[label]?.["report"]?.[props.formula_id]?.[props.formula_id]?.[props.dataKey]?.[props.p_key]
      }
      

      let multiplication = 1
      if(params == "balance"){
        param = "f_saldo_atual"
        multiplication = -1
      }

      
      let mappedParamsDataHash = {}

      Object.entries(paramsData || {}).map(([key, value]) => {
        mappedParamsDataHash[key] = value?.[param] * multiplication || (typeof (value) == "object" ? 0 : value) 
      })
	
      return dataForFormula(mappedParamsDataHash)
    }
    if (props.dataKey == "attachment_params") {
      let allYearsHash = props.allYearsData?.[props.first_level_key]?.[year]
      let mappedParamsDataHash = {}
      if(props.head){
        Object.entries(allYearsHash || {}).map(([key, value]) => {
          console.log("ENTRY KEY", key)
          console.log("ENTRY VALUE", value)
          // value.map((entry)=>{
          //   let sum = entry.sum_of_lines
          //   mappedParamsDataHash[key] += sum * -1
          // })
          
        })
      }else{
        Object.entries(allYearsHash?.[props.title]?.sum_of_lines || {}).map(([key, value]) => {
          mappedParamsDataHash[key] = value * -1
        })
      }
      console.log("PARAM_ATTACHMENT", dataForFormula(mappedParamsDataHash || {}, year))
      return dataForFormula(mappedParamsDataHash || {}, year)

    }


    if (props.dataKey == "param-lines") {
      let getParam = (node, key = props.line_key) => {
        if (node?.lines?.[key]) {
          return node?.lines?.[key]?.months
        } else {
          let result = Object.values(node.childreen).map((child) => {
            return getParam(child, key)
          })
          try {
            return result.filter((x) => Object.keys(x || {}).length > 0)[0]
          } catch (e) {
            console.log("ERRO NO PARAM-LINES", e)
          }

        }
      }
      let resultVar = params == "balance" ? lineResultsSaldo : lineResults
      let yearLabel = label == "budget" ? `${year}_budget` : year
      if (Object.values(Object.values(resultVar?.[yearLabel] || {})?.[0]?.childreen || {}).length > 0) {
        let allMonths = getParam(Object.values(resultVar?.[yearLabel])[0])
        
        let invertedMonths = {}
        Object.entries(allMonths || {}).map(([month, value]) => {
          let f_value = parseFloat(value) || 0
          invertedMonths[month] = f_value * -1
        })

        return dataForFormula(invertedMonths)
      } else {

        return dataForFormula(Object.values(resultVar?.[yearLabel] || {})?.[0]?.lines?.[props.line_key]?.months || {}, year, true)
      }



    }

    if (props.dataKey == "param-head") {
      let yearLabel = label == "budget" ? `${year}_budget` : year
      let findInChildreen = (param) => {
      
      }
      let resultVar = params == "balance" ? lineResultsSaldo : lineResults
      try {
        if (Object.values(Object.values(resultVar?.[year])[0].childreen).length > 0) {

          let extractLines = (node, param) => {
            let response = "morudanga"
            if (node.childreen[param]) {
              response = node.childreen[param].sum_of_lines
            } else {
              response = Object.values(node.childreen).map((child) => {
                return extractLines(child, param)
              }).filter((x) => x)[0]
            }

            return response
          }

          return dataForFormula(extractLines(Object.values(Object.values(resultVar?.[yearLabel]))[0], props.title), null, true)
        } else {

          return dataForFormula(Object.values(resultVar?.[yearLabel])?.[0]?.lines?.[props.line_key]?.months)
        }

      } catch (e) {

      }
      dataForFormula([])

    }

    if (props.dataKey == "result") {
      if(props.rangeMonths){
        return data(dataForFormula(props.allYearsData?.[year]?.report?.[props.formula_id]?.[props.formula_id]?.[props.dataKey], year))
      }else{
        return data(dataForFormula(props.allYearsData?.[year]?.[label]?.report?.[props.formula_id]?.[props.formula_id]?.[props.dataKey], year))
      }
      
    }
    if (props.dataKey == "result_dashboard") {
      return data(dataForDashboardFormula(props.allYearsData, year))  // na verdade year não é year, mas a label do dashboard
    }
    if (props.dataKey == "result-segmentado") {
      let returnArray = Object.entries(props.allYearsData[year]).map(([month, data]) => tryEval(data[props.formula_id]))
      returnArray.splice(-1, 1)
      return data(returnArray)
      // return data(dataForFormula(props.allYearsData?.[year]?.report?.[props.formula_id]?.[props.formula_id]?.[props.dataKey], year))  
    }

    if (props.dataKey == "indicators-segmentado") {
      //companyData?.[company_id]?.['report']?.[key]?.[key]?.["params"]?.[p_key]?.[monthName]?.[param]
      let array = []
      let monthsData = Object.values(props.allYearsData[year]).map((x) => x.report?.[props.formula_key]?.[props.formula_key]?.["indicators"]?.[props.param_key] || {})

      Object.entries(props.months).map(([month_name, month_number]) => {
        array[month_number - 1] ||= 0

        monthsData.map((companyData) => {
          array[month_number - 1] ||= 0

          if (companyData?.[month_name]) {
            array[month_number - 1] += (parseFloat(companyData?.[month_name]) * - 1)
          } else {
            array[month_number - 1] += 0
          }
        })
      })

      return array
      // return data(dataForFormula(props.allYearsData?.[year]?.report?.[props.formula_id]?.[props.formula_id]?.[props.dataKey], year))  
    }

    if (props.dataKey == "line-segmentado") {
      //companyData?.[company_id]?.['report']?.[key]?.[key]?.["params"]?.[p_key]?.[monthName]?.[param]

      let array = []
      let monthsData = Object.values(props.allYearsData[year]).map((x) => x.report?.[props.formula_key]?.[props.formula_key]?.["params"]?.[props.param_key] || {})

      Object.entries(props.months).map(([month_name, month_number]) => {
        array[month_number - 1] ||= 0

        monthsData.map((companyData) => {
          array[month_number - 1] ||= 0

          if (companyData?.[month_name]?.[props.param]) {
            array[month_number - 1] += companyData?.[month_name]?.[props.param]
          } else {
            array[month_number - 1] += 0
          }
        })
      })



      return array
      // return data(dataForFormula(props.allYearsData?.[year]?.report?.[props.formula_id]?.[props.formula_id]?.[props.dataKey], year))  
    }

    if (props.dataKey == "cost-center-area-segmentado") {

      let areaByMonth = (monthName) => {
        let { fixed, despesa_id } = props;
        try {
          let value = Object.values(props.allYearsData[year]).map((company) => company?.costCenterGroupedByMonth[fixed ? "fixed" : "not_fixed"][`[${despesa_id}, ${monthDict[monthName]}]`]).filter((x) => x).reduce((a, b) => a + b, 0)
          return value
        } catch (e) {
          return 0
        }
      }
      let array = []
      Object.entries(props.months).map(([month_name, month_number]) => {
        array.push(areaByMonth(month_name) * -1)
      })
      return array
    }
  }
  const getCategories = () => {
    const { rangeMonths } = props
    if (rangeMonths) {
      let monthLabels = []
      Object.entries(rangeMonths).map(([year_month, name]) => {
        let [iYear, number] = year_month.split(",")
        monthLabels.push(`${name.substring(0, 3)}/${iYear}`)
      })
      return monthLabels
    } else {
      return monthsArray
    }
  }


  if (props.allYearsData) {
    const getSeries = (params = null, uniqSerie = true) => {
      if (props.onlyLineData) {
        const { startYear, endYear } = props;
        let maxNum = Object.keys(props.rangeMonths).length
        let intervalData

        if(props.data){
          intervalData = props.data
        }else{
          intervalData = range(startYear, endYear).map((year) => loadedData(year)).reduce((a, b) => [...a, ...b], [])
        }
          

        // delete intervalData bigger than maxNum
        intervalData.splice(maxNum, intervalData.length - maxNum)
        return [{
          name: "Intervalo",
          data: intervalData,
          type: getChartStyle(),
          dataLabels: {
            enabled: true,
            formatter: function () {
              return getNumerFormatted(this.y, props.numberFormat)
            },
            style: {
              fontWeight: 'bold'
            }
          }
        }]
      } else if ((props.dataKey == "cost_center_type") || (props.dataKey == "cost_center_type_line")) {

        return Object.keys(costCentersFetchedByYear).map((year) => {
          return {
            type: getChartStyle(),
            name: year,
            data: loadedData(year),
            showInLegend: true,
            states: {
              borderColor: '#ff0000',
              hover: {
                enabled: false
              }
            },
            dataLabels: {
              enabled: true,
              formatter: function () {
                return getNumerFormatted(this.y, props.numberFormat)
              },
              style: {
                fontWeight: 'bold'
              }
            }
          }
        })
      }
      else {
        let years = Object.keys(loadedYears)

        if(props.dataKey == "result_dashboard"){
          years = props.years  
        }
        
        if(props.dataKey == "param-lines"){
          let lineYears = Object.keys(lineResults)
          years = years.filter(elem => lineYears.includes(elem))
        }

        let dataToLoad = years?.flatMap((year) => {
          if(year == currentYear && props.dataKey != "result_dashboard" && uniqSerie == false && !props.cashFlowChart && !props.dataKey.includes("balance")) {
            return ["analisado", "budget"].map((label) => {
              return {
                type: getChartStyle(),
                name: formatYear(year, label),
                data: loadedData(year, label),
                showInLegend: true,
                states: {
                  borderColor: '#ff0000',
                  hover: {
                  enabled: false
                  }
                },
                dataLabels: {
                  enabled: true,
                  formatter: function () {
                  return getNumerFormatted(this.y, props.numberFormat)
                  },
                  style: {
                  fontWeight: 'bold'
                  }
                }
              }
            })
          } else {
            return {
              type: getChartStyle(),
              name: formatYear(year, "analisado"),
              data: loadedData(year, "analisado", params),
              showInLegend: true,
              states: {
                borderColor: '#ff0000',
                hover: {
                enabled: false
                }
              },
              dataLabels: {
                enabled: true,
                formatter: function () {
                  return getNumerFormatted(this.y, props.numberFormat)
                },
                style: {
                  fontWeight: 'bold'
                }
              }
            }
          }
        })
        return dataToLoad
      }
    }


    options = {
      title: {
        text: props.title
      },

      xAxis: {
        categories: getCategories()
      },
      yAxis: {
	    title: {
            text: 'Valores'
        }
      },
      series: getSeries(null, false)
    }

    optionsFlow = {
      title: {
        text: `Fluxo de caixa | ${props.title}`
      },
      xAxis: {
        categories: getCategories()
      },
      series: getSeries()
    }

	optionsBalance = {
      title: {
        text: `Balanço | ${props.title}`
      },

      xAxis: {
        categories: getCategories()
      },
      series: getSeries("balance")
    }

  } else {
    options = {
      title: {
        text: props.title
      },
      xAxis: {
        categories: getCategories()
      },
      series: [{
        type: getChartStyle(),
        data: data()
      }]
    }
  }

  const getTotal = (year) => {
    return tryEval(props.allYearsData?.[year]?.["total"] || props.allYearsData?.[year]?.["range"])
  }
  
  function getNameCategorie() {
    let series = getSerieYtd()
    return series?.[0].data?.map((x)=> x?.name)
    
    
  }

  function mergeLines(obj) {
    let result = {};
  
    for (const key in obj?.childreen || []) {
      const child = obj.childreen[key];
      if (Object.keys(child.lines).length > 0) {
        Object.assign(result, child.lines);
      } else if (Object.keys(child.childreen).length > 0) {
        Object.assign(result, mergeLines(child));
      }
    }
  
    return result;
  }
  


  function getSerieYtd(){
    let optionsLabel = {
      enabled: true,
      formatter: function () {
        return getNumerFormatted(this.y, props.numberFormat)
      },
      style: {
        fontWeight: 'bold'
      }
    }

    const result = [{data:[],showInLegend: false, dataLabels: optionsLabel}]

    let index = 0

    Object.entries(loadedYears).map(([year, x]) => {
      let analisadoSum = 0
      let orcadoSum = 0

      
      if(props.dataKey == "result"){
        analisadoSum = tryEval(props.formulasTotalByYearYTD?.[year]?.["analisado"]?.[props.formula_fr_id]) * -1
        orcadoSum = tryEval(props.formulasTotalByYearYTD?.[year]?.["budget"]?.[props.formula_fr_id]) * -1
      }

      if(["params", "param-head"].includes(props.dataKey)){
        let analisado = props.allYearsData?.[year]?.["analisado"]?.["report"]?.[props.formula_id]?.[props.formula_id]?.["params"]?.[props.p_key]
        let orcado = props.allYearsData?.[year]?.["budget"]?.["report"]?.[props.formula_id]?.[props.formula_id]?.["params"]?.[props.p_key]
        
        Object.keys(visibleMonths || {}).map((month)=>{
          analisadoSum += analisado?.[month]?.[props.param]  
          orcadoSum += orcado?.[month]?.[props.param]  
          
        })
      }
      if (props.dataKey == "indicators") {
        let analisadoInd = props.allYearsData?.[year]?.["analisado"]?.report?.[props.formula_id]?.[props.formula_id]?.[props.dataKey]?.[props.p_key]
        let orcadoInd = props.allYearsData?.[year]?.["budget"]?.report?.[props.formula_id]?.[props.formula_id]?.[props.dataKey]?.[props.p_key]

        Object.keys(visibleMonths || {}).map((month)=>{
          analisadoSum += parseFloat(analisadoInd?.[month])
          orcadoSum += parseFloat(orcadoInd?.[month])
        })
      }


      if(props.dataKey == "area_header"){
        let { area_id, is_fixed, months } = props;
        let analisado = props.allYearsData?.[year]?.["analisado"]?.costCenterGroupedByMonth?.[is_fixed ? "fixed" : "not_fixed"]
        let orcado = props.allYearsData?.[year]?.["budget"]?.costCenterGroupedByMonth?.[is_fixed ? "fixed" : "not_fixed"]
        
        
        Object.keys(visibleMonths || {}).map((month)=>{
          analisadoSum += parseFloat(analisado?.[`[${area_id}, ${monthDict[month]}]`]) * -1
          orcadoSum += parseFloat(orcado?.[`[${area_id}, ${monthDict[month]}]`]) * -1
          
        })
      }

      if(["area_header", "params", "result", "param-head", "indicators"].includes(props.dataKey) ){
        result[0].data.push({
            y: analisadoSum || 0, 
            name: year.toString(),
            color: COLORS[index],
            index })
          index += 1

        if((year == currentYear) && !props.cashFlowChart){
          result[0].data.push({
              y: orcadoSum || 0, 
              name: "Orçamento " + year.toString(),
              color: COLORS[index],
              index})	
          index += 1
        }   
       

      }
      
    })
    
    Object.keys(costCentersFetchedByYear).map((year)=>{
      let analisadoSum = 0
      let orcadoSum = 0
      if(year.toString().includes("budget")){
        return;
      }
      if(props.dataKey == "cost_center_type_line"){
        let analisado = costCentersFetchedByYear?.[year]?.[props.label]?.[props.area_id]?.[props.is_fixed ? "fixed" : "not_fixed"]
        let orcado = costCentersFetchedByYear?.[`${year}_budget`]?.[props.label]?.[props.area_id]?.[props.is_fixed ? "fixed" : "not_fixed"]
        
        Object.keys(visibleMonths || {}).map((month)=>{
          Object.entries(analisado || {}).map(([key, value])=>{
            if(key == props.cc_key && value?.months?.[monthDict[month]]){
              analisadoSum += value?.months?.[monthDict[month]] 
            }
          })
          Object.entries(orcado || {}).map(([key, value])=>{
            if(key == props.cc_key && value?.months?.[monthDict[month]]){
              orcadoSum += value?.months?.[monthDict[month]] 
            }
            
          })
        })
        
      }
      if(props.dataKey == "cost_center_type"){
        let analisado = costCentersFetchedByYear?.[year]?.[props.label]?.[props.area_id]?.[props.is_fixed ? "fixed" : "not_fixed"]
        let orcado = costCentersFetchedByYear?.[`${year}_budget`]?.[props.label]?.[props.area_id]?.[props.is_fixed ? "fixed" : "not_fixed"]
  
        Object.keys(visibleMonths || {}).map((month)=>{
          Object.entries(analisado || {}).map(([key, value])=>{
            if(value?.months?.[monthDict[month]]){
              analisadoSum += value?.months?.[monthDict[month]]
            }
          })
          Object.entries(orcado || {}).map(([key, value])=>{
            if(value?.months?.[monthDict[month]]){
              orcadoSum += value?.months?.[monthDict[month]]
            }
            
          })
        })
      }

      if(["cost_center_type_line", "cost_center_type"].includes(props.dataKey)){
        result[0].data.push({
            y: analisadoSum * -1, 
            name: year.toString(),
            color: COLORS[index],
            index })
          index += 1

        if((year == currentYear) && !props.cashFlowChart){
          result[0].data.push({
              y: orcadoSum * -1, 
              name: "Orçamento " + year.toString(),
              color: COLORS[index],
              index})	
          index += 1
        }   
      }
    })
    Object.keys(lineResults).map((year)=>{
      let analisadoSum = 0
      let orcadoSum = 0
      if(year.toString().includes("budget")){
        return;
      }
      if(props.dataKey == "param-lines"){
        
        let analisado = Object.values(lineResults[year] || {})?.[0]
        let orcado = Object.values(lineResults[`${year}_budget`] || {})?.[0]
  
        Object.keys(visibleMonths || {}).map((month)=>{
          if(Object.keys(analisado.lines).length > 0){
            analisadoSum += analisado?.lines?.[props.line_key]?.months?.[month] * -1 || 0
            orcadoSum += orcado?.lines?.[props.line_key]?.months?.[month] * -1 || 0
          }else{
            analisadoSum += mergeLines(analisado)?.[props.line_key]?.months?.[month] * -1 || 0
            orcadoSum += mergeLines(orcado)?.[props.line_key]?.months?.[month] * -1 || 0
          }
          
        })

        if(["param-lines"].includes(props.dataKey)){
          result[0].data.push({
              y: analisadoSum, 
              name: year.toString(),
              color: COLORS[index],
              index })
            index += 1
  
          if((year == currentYear) && !props.cashFlowChart){
            result[0].data.push({
                y: orcadoSum, 
                name: "Orçamento " + year.toString(),
                color: COLORS[index],
                index})	
            index += 1
          }   
        }
      }

      
    })
    
    
    return result
  }



  let optionsHorizontalColumn = {
    chart: {
      type: 'bar',
      horizontal: true,
      height: 200,
	    events: {
      		load: function() {
         		// var negative = this.series?.[0]?.data?.[1]?.negative;
            
            let allValues = getSerieYtd()[0].data
            let negative = allValues.filter((x)=> x.y <= 0).length == allValues.length
            
            
            this.yAxis[0].update({
              reversed: negative
            });
        	}
		  }
    },
    title: {
      text: props.title || 'Período Acumulado'
    }, 
    xAxis: {
      categories: getNameCategorie(),
      title: {
        text: null
      },
      gridLineWidth: 1,
      lineWidth: 0
    }, 
    yAxis: {
	  title: {
         text: 'Valores'
      }
    },
    series: getSerieYtd(),
    
    
  }


  function dataForDashboardFormula(result, label) {
    // budget, current_year, last_year
    const { months } = props;
    let data = []
    let i = 1

    Object.entries(months).map(([name, number]) => {
      if (i < 13) {
        data.push(tryEval(result?.[label]?.[name]))
      }
      i += 1
    })


    return data
  }

  function dataForFormula(result, year = null, inverse = false) {
    const { months, rangeMonths, onlyLineData } = props;
    let data = []
    let i = 1

    if (year && onlyLineData) {
      Object.entries(rangeMonths).map(([year_month, name]) => {
        let [iYear, number] = year_month.split(",")

        if (iYear == year) {
          data.push(tryEval(result?.[name]) * (inverse ? -1 : 1))
        }

      })
    } else {
      Object.entries(months).map(([name, number]) => {
        if (i < 13) {
          data.push(tryEval(result?.[name]) * (inverse ? -1 : 1))
        }
        i += 1
      })
    }

    return data
  }


  function data(desordenedMonthData = props.data) {

    let getNumber = (value) => {

      if (props.inversedSignal) {
        return parseFloat(value) * -1
      } else {
        return parseFloat(value)
      }
    }

    let result = []
    let { title, is_fixed, months, costCenterRecordsGrouped } = props;
    if (props.areas) {
      result = Object.values(months).map((month) => {
        return getNumber(costCenterRecordsGrouped[`["${title}", ${is_fixed}, ${month}]`])
      })

    } else if (props.param) {
      result = desordenedMonthData.map((entry) => {
        try {
          return getNumber(entry[props.param])
        } catch (e) {
          return 0
        }

      })
    } else {
      try {
        result = desordenedMonthData?.map((x) => getNumber(x))
      } catch (e) {
        console.log("erro data", e)
      }

    }
    return result
  }

  function handleClickOpen() {
    setOpen(true);
    Object.entries(loadedYears).map(([year, loaded])=>{
      if (props.dataKey != "balance-params") {
        if(loaded){
          props.loadYear(year)
        }
        
      }
    })
    

  }

  function handleClose() {
    setOpen(false);
  }

  function toNumber(number = 0) {

    return parseFloat(number || 0)


  }

  
  const loadYear = (year) => {
    if (["param-lines", "param-head"].includes(props.dataKey)) {
      fetchParamLines(year)
      props.loadYear(year)
    } else if ((props.dataKey == "cost_center_type") || (props.dataKey == "cost_center_type_line")) {
      let { area_id,
        is_fixed,
        despesa_type_id,
        label } = props;
      fetchAreaData(area_id, is_fixed, despesa_type_id, label, year)

    } else {
      props.loadYear(year)
    }

  }

  const loadAllYears = () => {
    if ((props.dataKey == "cost_center_type") || (props.dataKey == "cost_center_type_line") || (props.dataKey == "param-lines")) {
      props.years.map((year) => {
        loadYear(year)
      })
    } else {
      props.loadAllYears()
    }
  }

  function loadedDataTable(year, isBudget=false){
    let kind = isBudget ? "budget" : "analisado"
    if(props.dataKey == "result"){
      return data(dataForFormula(props.allYearsData?.[year]?.[kind]?.report?.[props.formula_id]?.[props.formula_id]?.[props.dataKey], year))
    }else{
      let data = loadedData(year.toString(), kind)
      return data
    }
    
  }

  const renderHighChart = () => {
    try {
      const shouldRender = getSerieYtd()[0]?.data.filter((x)=> Math.abs(x.y) > 0).length > 0;

      if(props.renderOnlyHorizontal){
        return <>
            {shouldRender && <HighchartsReact highcharts={Highcharts} options={optionsHorizontalColumn} />}
          </>
      }else{
        return (
          <div>
            {props.dataKey === 'result' ? (
            <div>
              <HighchartsReact highcharts={Highcharts} options={options} />
              <hr />
              {shouldRender && <HighchartsReact highcharts={Highcharts} options={optionsHorizontalColumn} />}
            </div>
            ) : ['params', 'param-lines', 'param-head'].includes(props.dataKey) && props.isFlowChart ? (
            <div>
              <HighchartsReact highcharts={Highcharts} options={optionsFlow} />
              <hr />
              <HighchartsReact highcharts={Highcharts} options={optionsBalance} />
            </div>
            ) : 
            <div>
              <HighchartsReact highcharts={Highcharts} options={options} />
              {props.showAccumulatedChart &&  <>
                <hr />
                {shouldRender && <HighchartsReact highcharts={Highcharts} options={optionsHorizontalColumn} />}
              </>}
            </div>
            }
          </div>
        );
      }
	  
      // return <div>{JSON.stringify(options)}</div>
    } catch (e) {
      return <></>
    }
  }

  function renderTableTR(year, isBudget=false){
    let kind = isBudget ? "budget" : "analisado"
    let label = isBudget ? `${formatYear(year)}-Orçamento` : formatYear(year)
    const meses = ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"];
    let ultimoMesIndex = -1;
    for (let i = meses.length - 1; i >= 0; i--) {
        if (visibleMonths?.[meses[i]]) {
            ultimoMesIndex = i;
            break;
        }
    }
    return <tr>
      <td>{label}</td>
        {loadedDataTable(year, isBudget)?.map((monthData) => {
          return <td>{getNumerFormatted(toNumber(monthData), props.numberFormat)}</td>
        })}
        {!props.dataKey.includes("balance") && (props.formulasTotalByYear ?
          <>
            {props.formulasTotalByYearYTD && <td style={{ position: 'sticky',right: `${totalColumnWidth}px`, zIndex: 1, backgroundColor: 'white'}}>
              {getNumerFormatted(tryEval(props.formulasTotalByYearYTD?.[year]?.[kind]?.[props.formula_fr_id]) * -1, props.numberFormat)}
            </td>}
            <td style={{ position: 'sticky',right: '0', zIndex: 1, backgroundColor: 'white'}}>
              {getNumerFormatted(tryEval(props.formulasTotalByYear?.[year]?.[kind]?.[props.formula_fr_id]) * -1, props.numberFormat)}
            </td>
          </>
          :
          <>
          {(ytdOn() && loadedData(year.toString(), kind)) && 
            <td style={{ position: 'sticky',right: `${totalColumnWidth}px`, zIndex: 1, backgroundColor: 'white'}}>
              { 
                getNumerFormatted(loadedData(year.toString(), kind)?.slice(0, ultimoMesIndex + 1)?.reduce((a, b) => {
                  return toNumber(a) + toNumber(b);
                }, 0), props.numberFormat)
              }
            </td>
          }
          <td style={{ position: 'sticky',right: '0', zIndex: 1, backgroundColor: 'white'}}>{
            props.dataKey == "result_dashboard" ?
              getNumerFormatted(getTotal(year), props.numberFormat)
              :
              getNumerFormatted(loadedData(year.toString(), kind)?.reduce((a, b) => {
                return toNumber(a) + toNumber(b)
              }, 0), props.numberFormat)}
          </td>
          
          </>
        )}
        
    </tr>
  }
  if(props.withoutModal){
    return renderHighChart()
  }else{
    return (
      <div>

        {props.isWidget && (
          <button className='widget-button' onClick={handleClickOpen}>
            <WidgetChartIcon width={23} height={23} />
          </button>
        )}

        {!props.isWidget && (
          <div style={{ marginRight: props?.icon?.marginRight || 20, cursor: 'pointer' }} onClick={handleClickOpen}>
            {/*<i style={{fontSize: 16}}  className={'fa fa-chart-bar'}/>*/}
            <img src={props.blueIco ? chartIco : greyIco} style={{ width: props?.icon?.width || 16, heigth: props?.icon?.height || 16 }}></img>
          </div>
        )}

        {open && <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          fullWidth={true}
          maxWidth={'lg'}
        >

          <DialogContent >
            <div className={'flex'} style={{ justifyContent: 'space-between', paddingBottom: 15, borderBottom: '2px solid #eaeaea', marginBottom: 15 }}>
              <Grid container alignItems="center" className={classes.root}>
                <ChartGrid attachment={props.attachment}>
                  <ChartGridItem hasChartStyle={chartStyle === "column"} onClick={() => setChartStyle("column")}>
                    <MemoCartColumnIcon />
                  </ChartGridItem>
                  <ChartGridItem hasChartStyle={chartStyle === "line"} onClick={() => setChartStyle("line")}>
                    <MemoChartListIcon />
                  </ChartGridItem>
                  {!props.attachment ? (
                    <ChartGridItem hasChartStyle={chartStyle === "table"} onClick={() => setChartStyle("table")}>
                      <MemoChartTableIcon />
                    </ChartGridItem>
                  ) : null}
                </ChartGrid>
              </Grid>

              {!props.onlyLineData && <Grid container alignItems="center" className={classes.root}>
                <YearsButtons size={props?.years.length}>
                  {props.dataKey != "result_dashboard" && props?.years?.map((year) => (
                    <YearButton hasChartStyle={loadedYears[year]} onClick={() => {
                      loadYear(year)
                      setLoadedYears({...loadedYears, [year]: true})
                    }}>{year}</YearButton>
                  ))}
                </YearsButtons>
                {/* {
                  props.loadAllYears
                  && props.dataKey != "result_dashboard"
                  && <LoadAllYearsButton onClick={() => loadAllYears()}>Todos os anos</LoadAllYearsButton>
                } */}
              </Grid>}
            </div>
            {open &&
              chartStyle == "table" ?
              <React.Fragment>
                <h2 colSpan={14} style={{ textAlign: 'center' }} className={'table-chart-title'}>{props.title}</h2>
                <div className={'table-chart-container'}>
                  <table className={'table table-chart'}>
                    <tr>
                      <td></td>
                      {(monthsArray).map((month) => {
                        return <td>{month}</td>
                      })}
                      {(!props.dataKey.includes("balance") && (props.formulasTotalByYearYTD || ytdOn()))  && <td style={{ position: 'sticky', right: `${totalColumnWidth}px`, zIndex: 1, backgroundColor: 'white'}}>YTD</td>}
                      {!props.dataKey.includes("balance") && <td ref={totalColumnRef} style={{ position: 'sticky',right: '0', zIndex: 1, backgroundColor: 'white'}}>Total</td>}
                    </tr>
                    {(props.isWidget ? props.years : Object.keys(loadedYears || {}))?.map((year) => {
                      if((currentYear == year) && !props.cashFlowChart && (!["result_dashboard"].includes(props.dataKey) && !props.dataKey.includes("balance"))){
                        return <>
                          {renderTableTR(year)}
                          {renderTableTR(year, true)}
                        </>
                      }else{
                        return renderTableTR(year)
                      }
                      
                  })}

                  </table>
                </div>
              </React.Fragment>

              :
              chartVisible ? renderHighChart() : <div style={{ height: 400 }}></div>}
          </DialogContent>
          <DialogActions>
            <Button className='button-app' onClick={handleClose} color="primary">
              Fechar
            </Button>
          </DialogActions>
        </Dialog>}
      </div>
    );
  }
}



const range = (min, max) => [...Array(max - min + 1).keys()].map(i => i + min);
const monthDict = {
  "janeiro": 1,
  "fevereiro": 2,
  "março": 3,
  "abril": 4,
  "maio": 5,
  "junho": 6,
  "julho": 7,
  "agosto": 8,
  "setembro": 9,
  "outubro": 10,
  "novembro": 11,
  "dezembro": 12,
  "total": 13
}
