import React, { Children } from "react";
import PropTypes from "prop-types";
import Fuse from "fuse.js";
import {
  Select,
  FlexCenter,
  GreenLabel,
  BlueButton
} from "../../styled_components/default";

class Edit extends React.Component {
  state = {
    stage_text: "Salvar colunas"
  };

  optionsRender() {
    
    return this.props.cost_center_options.map(option => (
      <option value={option[1]}>{option[0]}</option>
    ));
  }
  saveHeaderData() {
    this.setState({ saving: true });
    this.setState({ stage_text: "Salvando linhas..." });

    axios
      .post(`/cost_centers/${this.props.cost_center_id}/configure_header`, {
        header_columns: this.state
      })
      .then(result => {
        if (result.data.success) {
          if (result.data.same_codes) {
            Turbolinks.visit("/cost_centers?same_codes=true");
          }else{
            this.setState({ stage_text: "Redirecionando para configuração..." });
            location.reload();
          }
          
          
        }

        this.setState({ saving: false });
      });
  }

  render() {
    const {sample_data} = this.props
    return (
      <div>
        <FlexCenter>
           <div className={"margin-10"} style={{ flex: 1 }}>
            <GreenLabel> Código Centro de Custo </GreenLabel>
            <Select
              onChange={e => this.setState({ code_column: e.target.value })}
            >
              <option>-- selecione a coluna --</option>
              {this.optionsRender()}
            </Select>
          </div>
          
          <div className={"margin-10"} style={{ flex: 1 }}>
            <GreenLabel> Descrição CC </GreenLabel>
            <Select
              onChange={e =>
                this.setState({ description_column: e.target.value })
              }
            >
              <option>-- selecione a coluna --</option>
              {this.optionsRender()}
            </Select>
          </div>
          {this.props.is_cost_center_type && <div className={"margin-10"} style={{ flex: 1 }}>
            <GreenLabel> Código Conta </GreenLabel>
            <Select
              onChange={e => this.setState({ balance_sheet_code_column: e.target.value })}
            >
              <option>-- selecione a coluna --</option>
              {this.optionsRender()}
            </Select>
          </div>}
          <div className={"margin-10"} style={{ flex: 1 }}>
            <GreenLabel> Descrição Conta</GreenLabel>
            <Select
              onChange={e =>
                this.setState({ count_description_column: e.target.value })
              }
            >
              <option>-- selecione a coluna --</option>
              {this.optionsRender()}
            </Select>
          </div>
           <div className={"margin-10"} style={{ flex: 1 }}>
            <GreenLabel> Saldo Anterior </GreenLabel>
            <Select
              onChange={e =>
                this.setState({ saldo_anterior_column: e.target.value })
              }
            >
              <option>-- selecione a coluna --</option>
              {this.optionsRender()}
            </Select>
          </div>
          <div className={"margin-10"} style={{ flex: 1 }}>
            <GreenLabel> Débito </GreenLabel>
            <Select
              onChange={e => this.setState({ debito_column: e.target.value })}
            >
              <option>-- selecione a coluna --</option>
              {this.optionsRender()}
            </Select>
          </div>
          <div className={"margin-10"} style={{ flex: 1 }}>
            <GreenLabel> Crédito </GreenLabel>
            <Select
              onChange={e => this.setState({ credito_column: e.target.value })}
            >
              <option>-- selecione a coluna --</option>
              {this.optionsRender()}
            </Select>
          </div>
         
          <div className={"margin-10"} style={{ flex: 1 }}>
            <GreenLabel> Saldo Atual </GreenLabel>
            <Select
              onChange={e =>
                this.setState({ saldo_atual_column: e.target.value })
              }
            >
              <option>-- selecione a coluna --</option>
              {this.optionsRender()}
            </Select>
          </div>
        </FlexCenter>
        <FlexCenter>
          <BlueButton onClick={() => this.saveHeaderData()}>
            {this.state.stage_text}
          </BlueButton>
        </FlexCenter>

        <table className={'bordered margin-top-20'} >
            <thead>
              <tr>
                <th colspan={ this.props.is_cost_center_type ? 8 : 7} style={{textAlign: 'center'}}>Demonstração das colunas escolhidas</th>
              </tr>
            </thead>
            <tr>
               <th>Código Centro de Custo</th>
               <th>Descrição CC</th>
               {this.props.is_cost_center_type &&
                 <th>Código Conta</th>
               }
               <th>Descrição Cont</th>
               <th>Saldo Anterior</th>
               <th>Débito</th>
               <th>Crédito</th>
               <th>Saldo Atual</th>
            </tr>
            <tbody>
              {sample_data.map((row)=>(
                  <tr>
                    <td>{row[this.state.code_column] ? row[this.state.code_column] : "-"} </td>
                    <td>{row[this.state.description_column] ? row[this.state.description_column] : "-"} </td>
                    {this.props.is_cost_center_type &&
                      <td>{row[this.state.balance_sheet_code_column] ? row[this.state.balance_sheet_code_column] : "-"} </td>
                    }
                    <td>{row[this.state.count_description_column] ? row[this.state.count_description_column] : "-"} </td>
                    <td>{row[this.state.saldo_anterior_column] ? row[this.state.saldo_anterior_column] : "-"} </td>
                    <td>{row[this.state.debito_column] ? row[this.state.debito_column] : "-"} </td>
                    <td>{row[this.state.credito_column] ? row[this.state.credito_column] : "-"} </td>
                    <td>{row[this.state.saldo_atual_column] ? row[this.state.saldo_atual_column] : "-"} </td>
                  </tr>
              ))}
            </tbody>
            
          

        </table>
      </div>
    );
  }
}

export default Edit;
