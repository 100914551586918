import React from "react"
import ReactTooltip from 'react-tooltip';
import PropTypes from "prop-types"
import styled from 'styled-components'
import {FlexCenter} from '../styled_components/default'
import alertIcon from "icon_exclamation__Prancheta 1.svg";
import {LateralMenuLink, SubMenuLink, Animated} from '../styled_components/lateral_menu'
class ColapsedItem extends React.Component {
  state = {subMenuVisible: false}

  onClick(){
    this.props.onClick();
  }

  render () {
    const {subMenuVisible} = this.state;
    const {submenuLinks, active, expanded, icoSrc, label, href, collapsed, has_inconsistence} = this.props;
    let validLinks = submenuLinks ? submenuLinks.filter((e)=> e) : []

    return (
      <Animated
        data-tip data-for={label} 
        title={ has_inconsistence ? "Existe um ou mais arquivos que não estão corretos" : ""} 
        styled={{ width: collapsed ? 100 : 260 }}>
        <LateralMenuLink className={'lateral-menu-button'} has_inconsistence={has_inconsistence} style={{position: 'relative'}} href={href} active={active} onClick={()=> this.onClick()}>
          {has_inconsistence && <img src={alertIcon} style={{
              width: 17,
              position: 'absolute',
              left: 57,
              top: 12,
              zIndex: 2
            }}></img>}
          <div style={{minWidth: 60}}>
            <div
              className={'lateral-menu-icon'}
              style={{
                WebkitMaskImage: `url(${icoSrc})`,
                maskImage: `url(${icoSrc})`,
                width: 34,
                background: '#617E94',
                maskRepeat: "no-repeat",
                WebkitMaskRepeat: "no-repeat",
                maskPosition: "center",
                WebkitMaskPosition: "center",
                height: 34
              }}
            ></div>
            
          </div>
          
          { collapsed && 
            (<ReactTooltip id={label} place="right" type="dark" effect="solid">
              <h4>{label}</h4>
            </ReactTooltip>)
          }
          <div style={{flex: 1}}>{!collapsed && label}</div>
          
        </LateralMenuLink>
        {submenuLinks && <div style={{
            height: expanded ? (60 * validLinks.length) : 0, 
            overflow: 'hidden', 
            transition: '0.3s all ease-in-out'}}>
            {submenuLinks && validLinks.map((item, index)=>
              <SubMenuLink key={index} href={item.href} >{item.label}</SubMenuLink>
            )}
          </div>}
      </Animated>
      
    );
  }
}

export default ColapsedItem
