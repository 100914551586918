

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import PopHover from './PopHover'
import CurrencyInput from './CurrencyInput'

const useStyles = makeStyles((theme) => ({
  typography: {
    padding: theme.spacing(2),
    color: "rgb(68,185,189)"
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 360,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  buttonClass: {
    backgroundColor: '#fff',
    marginBottom: 3,
    color: '#2a3170',
    borderColor: '#2a3170',
    borderWidth: 1
  }
}));

export default function RemovedAmount(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [value, setValue] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  let  updateComment = async function(){
    
    let {year, month, reference_id, reference_model} = props
    await axios.put("/removed_amounts.json", {
      year,
      month,
      reference_id,
      reference_model,
      value
    })
    props.refreshRemovedAmounts();
    handleClose()
  }

  let changeValue= function(e){
    let amount = e.target.value
    
    setValue(amount)
  }
  let numberToCurrency = function(number=0) {
    if (String(parseFloat(number)) == "NaN" || parseFloat(number) == undefined) {
      number = 0
    }
    
    return parseFloat(number).toLocaleString("pt-BR", {
      style: "currency",
      currency: "BRL"
    });
  };


  let comment = function(){
    let {year, month, reference_model, reference_id} = props
    let key = `[${year}, "${month}", "${reference_model}", "${reference_id}"]`

    return props.removedAmounts[key]
    
  }

  return (
    <div className={'comment-block'}>
      <i className={'fa fa-edit edit'}  onClick={handleClick} >
        
      </i>
      {numberToCurrency(comment())}
      {
        comment() && <PopHover description={comment()}/>
      }
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div>
          <Typography className={classes.typography}>Valor Removido</Typography>
          <FormControl variant="outlined" className={classes.formControl}>
            <div className={'currency-input'}>
              <CurrencyInput
                id="outlined-multiline-static"
                label="Valor a ser removido"
                defaultValue={comment()}
                placeHolder="R$ 00,00"
                variant="outlined"
                onChange={(e)=> changeValue(e)}
                className={'currency-input'}
              />
            </div>
          </FormControl>
        </div>
        <Button style={{
          float: 'right',
          margin: "0 10px 10px"
        }} variant="contained" color="primary" onClick={()=> updateComment()}>
          Salvar
        </Button>
        
        
  
      </Popover>
    </div>
  );
}